import { forwardRef, type ForwardedRef } from 'react';
import SearchBottomSheetModal from '@ui-modules/globalSearch/components/SearchBottomSheetModal';
import GlobalSearchResultList from '@ui-modules/globalSearch/components/GlobalSearchResultList/GlobalSearchResultList';
import { useRef, useState, useDeferredValue, useImperativeHandle } from '@hooks';
import { useExpandableResultsBlockPersist } from '../ExpandableResultsBlock';
import styles from './GlobalSearchBottomSheetModal.module.css';
import type { IBottomSheetModalMethods } from '@ui-kit';
import type { IGlobalSearchResultListProps } from '@ui-modules/globalSearch/components//GlobalSearchResultList/GlobalSearchResultList';

const GlobalSearchBottomSheetModal = (
	{ omitDefinitions, pickDefinitions, hitsPerPage, showInitialResults, onSelect }: IGlobalSearchBottomSheetModalProps,
	ref: ForwardedRef<IBottomSheetModalMethods>,
) => {
	const bottomSheetModalRef = useRef<IBottomSheetModalMethods>(null);
	const [searchQuery, setSearchQuery] = useState('');
	const deferredSearchQuery = useDeferredValue(searchQuery);

	useImperativeHandle(ref, () => bottomSheetModalRef.current as IBottomSheetModalMethods);

	const { removeSessionStorageExpandableDefinition } = useExpandableResultsBlockPersist();

	return (
		<SearchBottomSheetModal
			height="80vh"
			ref={bottomSheetModalRef}
			searchQuery={searchQuery}
			onChangeSearchQuery={setSearchQuery}
			onExitComplete={() => {
				removeSessionStorageExpandableDefinition();
			}}
		>
			<div className={styles.globalSearchBottomSheetModal}>
				<GlobalSearchResultList
					hitsPerPage={hitsPerPage}
					omitDefinitions={omitDefinitions}
					pickDefinitions={pickDefinitions}
					searchQuery={deferredSearchQuery}
					showInitialResults={showInitialResults}
					onSelect={(searchResult) => {
						onSelect(searchResult);
						bottomSheetModalRef.current?.hide?.();
					}}
				/>
			</div>
		</SearchBottomSheetModal>
	);
};

export interface IGlobalSearchBottomSheetModalProps
	extends Pick<
		IGlobalSearchResultListProps,
		'omitDefinitions' | 'pickDefinitions' | 'onSelect' | 'hitsPerPage' | 'showInitialResults'
	> {}

export interface IGlobalSearchBottomSheetModalMethods extends IBottomSheetModalMethods {}

export default forwardRef(GlobalSearchBottomSheetModal);
