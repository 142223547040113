import { Slider, QuestionTooltip, When } from '@ui-kit';
import styles from './FractionInput.module.css';
import clsx from 'clsx';
import { CrossErrorIcon, CheckedGreenIcon } from '@ui-kit';

const FractionInput = ({
	minValue = 0,
	maxValue = 100,
	step = 1,
	title,
	description,
	value,
	onChange,
	disabled,
	hasError,
	showCheckedIcon,
}: IFractionInputProps) => {
	return (
		<div className={styles.fractionInput} data-disabled={disabled}>
			<div className={styles.fractionInput__body}>
				<div className={styles.fractionInput__header}>
					<span className={styles.fractionInput__title}>{title}</span>
					<When condition={!!description?.length}>
						<QuestionTooltip className={styles.fractionInput__questionTooltip} content={String(description)} />
					</When>
				</div>
				<div>
					<Slider
						isDisabled={disabled}
						maxValue={maxValue}
						minValue={minValue}
						step={step}
						value={value}
						onChange={(value) => onChange(value)}
					/>
				</div>
			</div>
			<div className={styles.fractionInput__footer}>
				<input
					className={clsx(
						styles.fractionInput__numberInput,
						hasError ? styles.fractionInput__hasError : showCheckedIcon && !disabled && styles.fractionInput__validated,
					)}
					disabled={disabled}
					value={value}
					onChange={(event) => {
						const inputtedValue = parseInputValue(event.target.value);
						const nextValue = normalizeNextInputValue(inputtedValue, value);
						const newValue = clampInputValue(nextValue, minValue, maxValue);
						onChange(newValue);
					}}
				/>
				{hasError ? (
					<CrossErrorIcon height={42} width={12} />
				) : showCheckedIcon && !disabled ? (
					<CheckedGreenIcon height={42} width={12} />
				) : null}
			</div>
		</div>
	);
};

function parseInputValue(inputValue: string): number {
	const inputtedNumber = Number.parseInt(inputValue, 10) || 0;
	return inputtedNumber;
}

function normalizeNextInputValue(inputValue: number, previousValue: number): number {
	if (previousValue === 0 && inputValue >= 10) return inputValue / 10; // prevents leading zeros on first input (@see T21C-7270) [@DmitriyNikolenko]
	return inputValue;
}

function clampInputValue(inputValue: number, minValue: number, maxValue: number): number {
	const clampedNumber = Math.max(minValue, Math.min(maxValue, inputValue));
	return clampedNumber;
}

export interface IFractionInputProps {
	title: string;
	value: number;
	description?: string;
	minValue?: number;
	maxValue?: number;
	step?: number;
	disabled?: boolean;
	onChange: (value: number) => void;
	hasError?: boolean;
	showCheckedIcon?: boolean;
}

export default FractionInput;
