import { useNetworksOrChaptersNotificationSettings } from '@ui-modules/settings/hooks/useNetworksNotificationSettings';
import { useCollapsedNotificationsSettings } from '@ui-modules/settings/hooks/useNotificationSettingsVisibility';
import { ActivityIndicator, Checkbox, Gap } from '@ui-kit';
import { isArray, without } from '@utils';
import React, { useState } from 'react';
import { NOTIFICATION_SETTING_CHANNEL } from '@constants';
import type { TSupportedChannel, TCommunityNotificationSetting, TCommunityNotificationSettings } from '@typings';
import SettingsCard from '@ui-modules/settings/components/SettingsCard';
import styles from './ChapterSettingsCard.module.css';
import { usePostCommunitiesNotificationSettingsMutation } from '@ui-modules/settings/hooks/usePostCommunitiesNotificationSettings';
import { useService } from '@hooks';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

type CommunitySettings = Record<string, boolean>;

const ChapterSettingsCard = ({ settings }: INetworksSettingsCardProps) => {
	const { notificationSettings, isNotificationSettingsLoading } = useNetworksOrChaptersNotificationSettings('chapter');
	const { collapsedNotificationsSettings, addCollapsedNotificationsSettings, removeCollapsedNotificationsSettings } =
		useCollapsedNotificationsSettings();
	const { t } = useTranslation();
	const [localChapterSettings, setLocalChapterSettings] = useState<CommunitySettings>({});
	const [updatingChapterId, setUpdatingChapterId] = useState<string | null>(null);

	const reactQuery = useService('ReactQueryService');
	const queryClient = useQueryClient();
	const { mutate: postCommunitiesNotificationSettings, isLoading: isPostingCommunitiesNotificationSettings } =
		usePostCommunitiesNotificationSettingsMutation();
	const handleCheckboxChange = (chapter: TCommunityNotificationSettings, newValue: boolean) => {
		setUpdatingChapterId(chapter.communityId);

		setLocalChapterSettings((prev) => ({
			...prev,
			[chapter.communityId]: newValue,
		}));

		postCommunitiesNotificationSettings(
			{
				membershipId: chapter.membershipId!,
				isMassEmailAllowed: newValue,
			},
			{
				onSettled: () => {
					queryClient.invalidateQueries(reactQuery.queryKeys.getCommunitiesNotificationSettings());
					setUpdatingChapterId(null);
				},
			},
		);
	};
	const renderEmailChapter = (chapter: TCommunityNotificationSettings) => (
		<div className={styles.chapterSettingsCard__item} key={`item-${chapter.communityId}`}>
			<div>{t('Chapter Emails')}</div>
			<Checkbox
				disabled={isPostingCommunitiesNotificationSettings && updatingChapterId === chapter.communityId}
				value={localChapterSettings[chapter.communityId] ?? chapter.massEmailAllowed}
				onChange={() =>
					handleCheckboxChange(chapter, !(localChapterSettings[chapter.communityId] ?? chapter.massEmailAllowed))
				}
			/>
		</div>
	);
	if (isNotificationSettingsLoading) return <ActivityIndicator size="medium" type="fit" />;
	if (!notificationSettings) return null;

	return (
		<div>
			{notificationSettings.map((chapter) => {
				const networkIdentifier = `Chapter_${chapter.communityId}`;
				const showNewCommentForModeratorSettings =
					!isArray(chapter.membershipModeratorType) || chapter?.membershipModeratorType?.length > 0;
				const showNetworkConnectionSettings =
					!!chapter.networkConnectionsName && chapter.communityFeatures.includes('chapter');

				const { initialSettings, isInitialExpanded } = getIndividualNetworkSettings(
					chapter.notificationSettings,
					settings,
					showNewCommentForModeratorSettings,
					showNetworkConnectionSettings,
				);
				const isDefaultExpanded =
					isInitialExpanded || collapsedNotificationsSettings.includes(networkIdentifier) || !!chapter.massEmailAllowed;
				const settingsArray = settings.map((setting) => ({
					...setting,
					name: setting.name.replace(
						/\[(networkConnectionName|networkConnection)\]/g,
						String(chapter.networkConnectionsName),
					),
				}));

				return (
					<div key={chapter.communityId + isDefaultExpanded}>
						<SettingsCard
							creationParams={{ sourceId: chapter.communityId, constraint: 'community' }}
							groupName={'Chapters'}
							initialSettings={initialSettings}
							isDefaultExpanded={isDefaultExpanded}
							settingsArray={settingsArray}
							title={chapter.communityName}
							onChangeCollapsed={(collapsed) =>
								collapsed
									? addCollapsedNotificationsSettings(networkIdentifier) && handleCheckboxChange(chapter, false)
									: removeCollapsedNotificationsSettings(networkIdentifier) && handleCheckboxChange(chapter, true)
							}
							EmailChapter={() => renderEmailChapter(chapter)}
						/>
						<Gap gap={12} />
					</div>
				);
			})}
		</div>
	);
};

type TSettingOption = {
	name: string;
	options: TSupportedChannel[];
	notificationId: string;
	group: string | null;
};

export interface INetworksSettingsCardProps {
	settings: TSettingOption[];
}

const getIndividualNetworkSettings = (
	notificationSettings: TCommunityNotificationSetting[],
	settings: TSettingOption[],
	showNewCommentForModeratorSettings: boolean,
	showNetworkConnectionSettings: boolean,
) => {
	const networkSettings = notificationSettings.map(({ id, notificationId, channels }) => ({
		id,
		notificationId,
		options: without(channels, NOTIFICATION_SETTING_CHANNEL.chat),
	}));
	const initialSettings = settings
		.map((defaultsSetting, index) => {
			const initialSetting = networkSettings.find(
				(settingsItem) => settingsItem.notificationId === defaultsSetting.notificationId,
			) || {
				id: null,
				notificationId: settings[index].notificationId,
				options: without(settings[index].options, NOTIFICATION_SETTING_CHANNEL.chat),
			};
			return initialSetting;
		})
		.filter((settings) =>
			(settings.notificationId === 'new_comment_for_moderator' && !showNewCommentForModeratorSettings) ||
			([
				'chapter_connection_created',
				'chapter_connection_reply_created',
				'new_chapter_connection_post_like',
				'new_chapter_connection_reply_like',
			].includes(settings.notificationId) &&
				!showNetworkConnectionSettings)
				? false
				: true,
		);

	const isNew = initialSettings.every((setting) => setting.id === null);
	const isSomeEnabled = initialSettings.some((setting) => !!setting.options.length);

	return { initialSettings, isInitialExpanded: isNew || isSomeEnabled };
};

export default ChapterSettingsCard;
