const DocumentFileViewer = ({ fileUrl }: IDocumentFileViewerProps) => {
	return (
		<iframe
			height="100%"
			src={prepareMicrosoftPreviewerUrl(fileUrl)}
			style={{ border: 'none' }}
			title="Document Viewer"
			width="100%"
		/>
	);
};

function prepareMicrosoftPreviewerUrl(fileUrl: string) {
	const url = new URL('https://view.officeapps.live.com/op/embed.aspx');
	url.searchParams.append('src', fileUrl);
	return url.toString();
}

DocumentFileViewer.canHandleFileExtension = (fileExtension: string) =>
	['docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx'].includes(fileExtension);
DocumentFileViewer.canHandleMimeType = (mimeType: string) =>
	[
		'application/msword',
		'application/vnd.ms-excel',
		'application/vnd.ms-powerpoint',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	].includes(mimeType);

export interface IDocumentFileViewerProps {
	fileUrl: string;
}

export default DocumentFileViewer;
