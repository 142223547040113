import React from 'react';
import styles from './MeetingPage.module.css';
import { BackIcon, Button, Gap, ShareArrowIcon } from '@ui-kit';
import { useHandleBackButton, useTranslation, useLocation } from '@hooks';
import { ROUTES } from '@constants';
import type { TEvent } from '@typings';
import { useInternalShareContext } from '@ui-modules/share/hooks/useInternalShare';

const MeetingPageHeader = ({ meeting }: IMeetingPageHeaderProps) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { onBackButtonPress } = useHandleBackButton();
	const { setInternalShare } = useInternalShareContext();

	const isSideBarVisible = pathname.includes(ROUTES.calendar());

	const onShareClickHandler = () => {
		setInternalShare({
			type: 'meeting',
			id: meeting?.id,
		});
	};

	return (
		<header className={styles.meetingPage__header}>
			{!isSideBarVisible && (
				<>
					<Button
						icon={<BackIcon fill="#333333" height={14} width={13} />}
						iconPosition="left"
						title={t('Back')}
						type="outline"
						onClick={onBackButtonPress}
					/>
					<h4>{meeting?.title || '...'}</h4>
				</>
			)}

			<>
				{isSideBarVisible && (
					<>
						<Gap />
						<Gap />
					</>
				)}
				<Button
					icon={<ShareArrowIcon height={20} strokeWidth={4} width={20} />}
					iconPosition="right"
					title={t('Share')}
					onClick={onShareClickHandler}
				/>
			</>
		</header>
	);
};

export interface IMeetingPageHeaderProps {
	meeting: TEvent | undefined;
}
export default MeetingPageHeader;
