import { createContext, type PropsWithChildren, useContext, useMemo } from 'react';
import { useRouteSearchQueryState } from '@hooks';
import { useState } from 'reinspect';

const NetworkSearchContext = createContext<INetworkSearchContext | null>(null);

export const NetworkSearchProvider = ({ children }: PropsWithChildren) => {
	const [routeSearchLearnQuery, setRouteSearchLearnQuery] = useRouteSearchQueryState();
	const [searchLearnQuery, setSearchLearnQuery] = useState(routeSearchLearnQuery);
	const [isSearchActive, setIsSearchActive] = useState(false);

	const contextValue = useMemo(
		() => ({ isSearchActive, setIsSearchActive, searchLearnQuery, setRouteSearchLearnQuery, setSearchLearnQuery }),
		[isSearchActive, searchLearnQuery, setRouteSearchLearnQuery, setSearchLearnQuery],
	);

	return <NetworkSearchContext.Provider value={contextValue}>{children}</NetworkSearchContext.Provider>;
};

export const useNetworkSearchContext = () => {
	const context = useContext(NetworkSearchContext);
	if (!context) {
		throw new Error('useNetworkSearchContext must be used within a NetworkSearchProvider');
	}
	return context;
};

interface INetworkSearchContext {
	isSearchActive: boolean;
	setIsSearchActive: (active: boolean) => void;
	searchLearnQuery: string;
	setRouteSearchLearnQuery: (query: string) => void;
	setSearchLearnQuery: (query: string) => void;
}
