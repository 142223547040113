import { Virtuoso } from '@ui-kit';
import { Document, Page, PasswordResponses } from 'react-pdf';
import { useRef, useState, useTranslation } from '@hooks';
import styles from './PdfViewer.module.css';
import PasswordFormModal, { type IPasswordFormModalMethods } from '@ui-modules/forms/components/PasswordFormModal';

const PdfFileViewer = ({ fileUrl, onClose }: IPdfFileViewerProps) => {
	const { t } = useTranslation();

	const [numPages, setNumPages] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const passwordFormModalRef = useRef<IPasswordFormModalMethods>(null);
	const setPasswordCallbackRef = useRef<(password: string) => void>();

	const onDocumentLoadSuccess = (pdf: { numPages: number }) => {
		setNumPages(pdf.numPages);
		setIsLoading(false);
	};

	const renderUserItem = (index: number) => {
		return (
			<Page
				className={styles.page}
				key={`page_${index + 1}`}
				loading={
					<div className={styles.pageLoading}>
						<span>{t('Loading page…')}</span>
					</div>
				}
				pageNumber={index + 1}
				renderAnnotationLayer={false}
				renderTextLayer={false}
			/>
		);
	};

	return (
		<>
			<Document
				className={styles.document}
				file={fileUrl}
				loading={
					<div className={styles.emptyBlock}>
						<span>{t('Loading PDF...')}</span>
					</div>
				}
				onLoadSuccess={onDocumentLoadSuccess}
				onPassword={(setPasswordCallback, passwordResponse) => {
					setPasswordCallbackRef.current = setPasswordCallback;
					passwordFormModalRef.current?.show({
						initialError:
							passwordResponse === PasswordResponses.INCORRECT_PASSWORD ? t('Incorrect password') : undefined,
					});
				}}
			>
				{isLoading ? (
					<div className={styles.emptyBlock} />
				) : (
					<div className={styles.listWrapper}>
						<Virtuoso
							className={styles.virtuoso}
							data={Array.from(new Array(numPages))}
							itemContent={renderUserItem}
							totalCount={numPages}
						/>
					</div>
				)}
			</Document>

			<PasswordFormModal
				ref={passwordFormModalRef}
				onCancel={onClose}
				onSubmitPassword={(password) => setPasswordCallbackRef.current?.(password)}
			/>
		</>
	);
};

PdfFileViewer.canHandleFileExtension = (fileExtension: string) => fileExtension === 'pdf';
PdfFileViewer.canHandleMimeType = (mimeType: string) => mimeType === 'application/pdf';

interface IPdfFileViewerProps {
	fileUrl: string | Blob;
	onClose?: () => void;
}

export default PdfFileViewer;
