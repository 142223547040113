import {
	useMessageContext,
	ReactionsList,
	messageHasReactions,
	Attachment,
	useChannelActionContext,
	useComponentContext,
} from 'stream-chat-react';
import styles from './OwnMessage.module.css';
import { useMemo, useNavigate, useTranslation } from '@hooks';
// For GIFs we use the default `<Attachment>` component from Stream, because it
// allows showing a GIF card, and can also handle actions like send/shuffle/cancel.
// Only when attachment is NOT a gif, we use our custom `<ChatAttachment>` component.
import ChatAttachment from '@ui-modules/chat/components/ChatAttachment';
import ChatEventMessage from '@ui-modules/chat/components/ChatEventMessage';
import QuotedMessage from '@ui-modules/chat/components/QuotedMessage';
import { MessageOptions } from '@ui-modules/chat/components/Message/MessageOptions';
import ChatMessageText from '@ui-modules/chat/components/ChatMessageText';
import { clsx, InternalShareEntity, isOnlyEmoji } from '@utils';
import { customReactionEmojis } from '../Message/customReactionsEmojiData';
import type { MessageResponse } from 'stream-chat';
import { messageHasGiphyAttachment } from './../Message/Message.utils';
import ReactMarkdown from 'react-markdown';
import { GIF_SENDING_HELPER_MARKDOWN } from '@constants';
import MessageStatus from '@ui-modules/chat/components/Message/MessageStatus';
import { getRootClassName } from './OwnMessage.utils';
import ChatLinksPreview from '../ChatLinksPreview';
import ShareableCard from '@ui-modules/share/components/ShareableCard';
import ChatReactionSelector from '../ChatReactionSelector';

const Message = ({ quotedMessage }: IMessageParams) => {
	const { handleAction, message } = useMessageContext();
	const isMessageBubble = message.text?.trim() !== '' || quotedMessage?.id;
	const mentionedUsers = useMemo(() => message.mentioned_users?.map((el) => `@${el.name}`), [message]);
	const internalShareEntity = new InternalShareEntity();
	const messageIsEmojiOnly = isOnlyEmoji(message);
	const eventId = message?.eventId as string | undefined;
	const navigate = useNavigate();

	return (
		<div className={!messageIsEmojiOnly && isMessageBubble ? 'str-chat__message-bubble' : ''}>
			<div
				className={clsx(
					!messageIsEmojiOnly && isMessageBubble
						? styles.ownMessage__textContainer
						: styles.ownMessage__textContainer_withoutBg,
				)}
			>
				{quotedMessage?.id ? (
					<RepliedMessage quotedMessage={quotedMessage} />
				) : (
					<>
						{message?.attachments && messageHasGiphyAttachment(message as MessageResponse) && (
							<>
								<Attachment actionHandler={handleAction} attachments={message.attachments} />
								{message.type === 'ephemeral' && <ReactMarkdown>{GIF_SENDING_HELPER_MARKDOWN}</ReactMarkdown>}
							</>
						)}
						<ChatLinksPreview text={message.text} />
						{message?.attachments && !messageHasGiphyAttachment(message as MessageResponse) && (
							<ChatAttachment attachments={message.attachments} text={message.text} />
						)}
						{!!(message?.sharedContent && !message.deleted_at) && (
							<ShareableCard
								containerClassName={styles.ownMessage__sharedContentContainer}
								sharedContent={message?.sharedContent as string}
								onClick={() => {
									navigate(internalShareEntity.internalShareToRoute(message?.sharedContent as string), {});
								}}
							/>
						)}
						<>{message?.eventId && !message.deleted_at ? <ChatEventMessage eventId={eventId} /> : null}</>
						{message.type !== 'ephemeral' && !!message.text?.trim() && (
							<ChatMessageText
								className={clsx(!messageIsEmojiOnly ? styles.ownMessage__textMessage : styles.ownMessage__emojiMessage)}
								mentions={mentionedUsers}
								text={message.text}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
};

/** @note component to reduce cognitive complexity */
const RepliedMessage = ({ quotedMessage }: { quotedMessage: MessageResponse }) => {
	const { jumpToMessage } = useChannelActionContext();
	const { handleAction, message } = useMessageContext();
	const eventId = message?.eventId as string | undefined;
	const mentionedUsers = useMemo(() => message.mentioned_users?.map((el) => `@${el.name}`), [message]);

	return (
		<div
			onClick={(e) => {
				// Fix jumping to reply message when interacting with message buttons (like send gif message) T21C-5627 [@juliayojji]
				const target = e.target as HTMLInputElement;
				if (target?.localName === 'button') return;
				jumpToMessage(quotedMessage.id);
			}}
			onKeyDown={(e) => {
				// Fix jumping to reply message when interacting with message buttons (like send gif message) T21C-5627 [@juliayojji]
				const target = e.target as HTMLInputElement;
				if (target?.localName === 'button') return;
				jumpToMessage(quotedMessage.id);
			}}
		>
			<QuotedMessage quotedMessage={quotedMessage} />
			<ChatLinksPreview text={message.text} />
			{message?.attachments && messageHasGiphyAttachment(message as MessageResponse) && (
				<Attachment actionHandler={handleAction} attachments={message.attachments} />
			)}
			{message?.attachments && !messageHasGiphyAttachment(message as MessageResponse) && (
				<ChatAttachment attachments={message.attachments} text={message.text} />
			)}
			<>
				{!!(message?.sharedContent && !message.deleted_at) && (
					<ShareableCard
						containerClassName={styles.ownMessage__sharedContentContainer}
						sharedContent={message?.sharedContent as string}
					/>
				)}
			</>
			<> {eventId && !message.deleted_at && <ChatEventMessage eventId={eventId} />}</>
			{/* NOTE: When we implement @mentions we should switch from `message.text` to stream's default `<MessageText />`
                                because the later supports @mentions out of the box along with other good things. But to use MessageText we
                                will also have to fix default quoted message themeing. */}
			{!!message.text?.trim() && (
				<ChatMessageText className={styles.ownMessage__textMessage} mentions={mentionedUsers} text={message.text} />
			)}
		</div>
	);
};

const OwnMessage = ({ quotedMessage }: IMessageParams) => {
	const { ReactionSelector = ChatReactionSelector } = useComponentContext();
	const {
		endOfGroup,
		firstOfGroup,
		groupedByUser,
		highlighted,
		isMyMessage,
		message,
		reactionSelectorRef,
		showDetailedReactions,
	} = useMessageContext();

	const { t } = useTranslation();
	const hasReactions = messageHasReactions(message);
	const { onMentionsClickMessage } = useMessageContext();

	const rootClassName = getRootClassName(
		{
			endOfGroup,
			firstOfGroup,
			groupedByUser,
			highlighted,
			isMyMessage,
			message,
		},
		hasReactions,
	);

	if (message.type === 'deleted') {
		return (
			<div className={rootClassName} key={message.id}>
				<div className={styles.ownMessage__deleteMessageContainer}>
					<div className={styles.ownMessage__textMessage}>{t('This message was deleted...')}</div>{' '}
				</div>
			</div>
		);
	}
	return (
		<div className={clsx(rootClassName)} key={message.id}>
			<div className={clsx('str-chat__message-inner')} data-testid="message-inner">
				<MessageOptions />
				<div className="str-chat__message-reactions-host">
					{hasReactions && <ReactionsList reactionOptions={customReactionEmojis} reverse />}
					{showDetailedReactions && (
						<ReactionSelector reactionOptions={customReactionEmojis} ref={reactionSelectorRef} />
					)}
				</div>
				<button onClick={onMentionsClickMessage}>
					<Message quotedMessage={quotedMessage} />
				</button>
			</div>
			<MessageStatus />
		</div>
	);
};

export default OwnMessage;

export interface IMessageParams {
	quotedMessage: MessageResponse | undefined;
}
