import { useImperativeHandle, useRef, useEffect } from '@hooks';
import { forwardRef } from 'react';
import type { ForwardedRef } from 'react';

const Camera = ({ onReady, onError }: ICameraProps, ref: ForwardedRef<ICameraApi>) => {
	const cameraRef = useRef<HTMLVideoElement>(null);
	const streamRef = useRef<MediaStream | null>(null);

	const startCamera = async () => {
		try {
			streamRef.current = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
			if (cameraRef.current) {
				cameraRef.current.srcObject = streamRef.current;
				cameraRef.current.play().then(() => onReady?.());
			}
		} catch (error) {
			if (error instanceof Error) onError?.(error);
		}
	};

	const stopCamera = () => {
		streamRef.current?.getTracks().forEach((track) => track.stop());
	};

	const takePhoto = async () => {
		const canvas = document.createElement('canvas');
		canvas.width = cameraRef.current?.videoWidth || 0;
		canvas.height = cameraRef.current?.videoHeight || 0;

		const context = canvas.getContext('2d');
		if (!context) return;

		context.drawImage(cameraRef.current as HTMLVideoElement, 0, 0, canvas.width, canvas.height);
		const imageBlob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve, 'image/png'));
		if (!imageBlob) return;

		const imageUrl = URL.createObjectURL(imageBlob);
		return { url: imageUrl, blob: imageBlob };
	};

	useImperativeHandle(ref, () => ({ takePhoto, startCamera, stopCamera }));

	useEffect(function handleCameraEnabling() {
		startCamera();
		return () => stopCamera();
	}, []);

	return (
		<video autoPlay height="100%" ref={cameraRef} width="100%">
			<track kind="captions" />
			Video stream not available.
		</video>
	);
};

export interface ICameraApi {
	takePhoto: () => Promise<{ url: string; blob: Blob } | undefined>;
	startCamera: () => Promise<void>;
	stopCamera: () => void;
}

export interface ICameraProps {
	onReady?: () => void;
	onError?: (error: Error) => void;
}

export default forwardRef(Camera);
