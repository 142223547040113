import { useService } from '@services';

export const useChat = () => {
	const { useAppSelector, chat, useBindActionCreators } = useService('ReduxService');

	const {
		editMessage,
		quotedMessageId,
		selectedChannel,
		shouldUpdateChannel,
		selectedUsersIds,
		channelHasPauseMembers,
		removedChannelIds,
		chatName,
		selectedEvent,
		eventId,
	} = useAppSelector((store) => store.chat);

	const {
		setEditMessage,
		setEventId,
		setSelectedChannel,
		setShouldUpdateChannel,
		setQuotedMessageId,
		setSelectedUsersIds,
		setChatName,
		setRemovedChannelsId,
		setChannelHasPausedMembers,
		resetState,
		setSelectedEvent,
	} = useBindActionCreators(chat);

	return {
		editMessage,
		setEditMessage,
		reset: resetState,
		quotedMessageId,
		setQuotedMessageId,
		selectedChannel,
		setSelectedChannel,
		setShouldUpdateChannel,
		shouldUpdateChannel,
		selectedUsersIds,
		setSelectedUserIds: setSelectedUsersIds,
		channelHasPauseMembers,
		setChannelHasPausedMembers,
		removedChannelIds,
		setRemovedChannelsId,
		chatName,
		setChatName,
		selectedEvent,
		setSelectedEvent,
		eventId,
		setEventId,
	};
};
