import { useService, useInfiniteQuery, useMemo } from '@hooks';
import { dayjs, noop } from '@utils';
import { GLOBAL_SEARCH } from '@constants';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';
import type { TAlgoliaSearchResponse, TSearchByIndexResponse, TSearchableDefinition } from '@typings';

export const useSearchInIndexInfiniteQuery = (
	searchQuery: string,
	definition: TSearchableDefinition,
	searchOptions?: {
		hitsPerPage?: number;
		showInitialResults?: boolean;
	},
	queryOptions?: UseInfiniteQueryOptions<TAlgoliaSearchResponse<TSearchByIndexResponse>>,
) => {
	const algoliaSearch = useService('AlgoliaSearchService');

	const finalSearchQuery = searchQuery.trim();
	const searchEnabled = searchOptions?.showInitialResults
		? finalSearchQuery.length === 0 || finalSearchQuery.length >= GLOBAL_SEARCH.validSearchQueryLength
		: finalSearchQuery.length >= GLOBAL_SEARCH.validSearchQueryLength;

	const infiniteQuery = useInfiniteQuery<TAlgoliaSearchResponse<TSearchByIndexResponse>>(
		['globalSearchInfinite', definition, searchQuery],
		async ({ pageParam }) => {
			const searchResults = await algoliaSearch.searchInIndex(
				finalSearchQuery,
				definition,
				searchOptions?.hitsPerPage,
				pageParam,
			);
			if (!searchResults) {
				throw new Error('No search results');
			}
			return searchResults;
		},
		{
			enabled: searchEnabled,
			staleTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			cacheTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			getNextPageParam: (lastPage) => {
				const isPagePresent = typeof lastPage.page === 'number';
				const maybeNextPage = lastPage.page + 1;
				const areMorePages = maybeNextPage < lastPage.nbPages;
				if (isPagePresent && areMorePages) {
					return maybeNextPage;
				}
				return undefined;
			},
			...queryOptions,
		},
	);

	const data = useMemo(
		function normalizeData() {
			return infiniteQuery.data
				? (infiniteQuery.data.pages.map((page) => page.hits).flat() as TSearchByIndexResponse[])
				: [];
		},
		[infiniteQuery.data],
	);
	const fetchMore = infiniteQuery.hasNextPage ? infiniteQuery.fetchNextPage : noop;

	return {
		...infiniteQuery,
		data,
		fetchMore,
		finalSearchQuery,
		searchEnabled,
	};
};
