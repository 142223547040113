import type { TFullUser } from '@typings';
import type { TFunction } from 'i18next';

export const mapSuggestionData = (users: TFullUser[] | undefined) =>
	users?.map((user) => ({
		...user,
		display: `${user.firstName} ${user.lastName}`,
		id: user.id,
	})) || [];

export const suggestionTitle = (t: TFunction) => (value?: string) => {
	if (!value) {
		return t('Suggestions');
	}
	return Number(value?.length) < 1 ? t('Suggestions') : t('Matches');
};
