import { Gap, Input, Modal } from '@ui-kit';
import { useTranslation, useFormik } from '@hooks';
import { editFolderSchema } from '@schemas';
import type { TEditFolderForm } from '@schemas';
import type { TFolder } from '@typings';
import type { IModalProps } from '@ui-kit';
import type { IFilesWidgetApiCallbacks } from '../FilesWidgetApiCallbacks.interface';

/** Asks for a new name of the folder in a modal window and renames it. */
const RenameFolderModal = ({ folderName, visible, onClose, onRenameFolder }: IRenameFolderModalProps) => {
	const { t } = useTranslation();

	const releaseDialog = () => {
		resetForm();
		onClose();
	};
	const {
		values,
		errors,
		handleChange,
		handleBlur,
		handleSubmit,
		submitForm,
		resetForm,
		isSubmitting,
		isValid,
		dirty,
	} = useFormik<TEditFolderForm>({
		validationSchema: editFolderSchema,
		initialValues: { name: folderName },
		onSubmit: async (formValues) => {
			await onRenameFolder(formValues);
			releaseDialog();
		},
	});

	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Save Changes')}
			confirmTrackingName="files--rename-folder"
			isConfirmDisabled={!isValid || !dirty}
			isConfirmLoading={isSubmitting}
			modalContent={
				<form onSubmit={handleSubmit}>
					<Gap gap={8} />
					<Input
						autoFocus
						errorMessage={errors.name}
						label={t('Folder name')}
						placeholder={t('Folder name')}
						required
						value={values.name}
						onBlur={handleBlur('name')}
						onChange={handleChange('name')}
					/>
				</form>
			}
			title={t('Rename Folder')}
			titleCentered
			variant="medium"
			visible={visible}
			onCancel={releaseDialog}
			onConfirm={submitForm}
		/>
	);
};

export interface IRenameFolderModalProps
	extends Pick<IModalProps, 'visible'>,
		Pick<IFilesWidgetApiCallbacks, 'onRenameFolder'> {
	/** Target Folder name to use as initial value for the rename form. */
	folderName: TFolder['name'];
	/** Calls when the modal should be closed to allow to toggle 'visible' property value. */
	onClose: () => void;
}

export default RenameFolderModal;
