import { Page } from '../../components';
import { useTranslation, useParams, useHandleEntityPageQueryError } from '@hooks';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { ActivityIndicator, Button, EditIcon, When } from '@ui-kit';
import styles from './TipPage.module.css';
import { clsx, some, CommunityEntity } from '@utils';
import TipDetails from '@ui-modules/tipJar/components/TipDetails';
import TipHeader from '@ui-modules/tipJar/components/TipHeader';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import TipReviewList from '@ui-modules/tipJar/components/TipReviewList';
import TipPrimaryReviewSection from '@ui-modules/tipJar/components/TipPrimaryReviewSection';
import type { TProfile } from '@typings';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import { TipReviewEntity } from '@ui-modules/tipJar/utils/TipReviewEntity';
import { memo } from 'react';
import { useTipContextQueryParams } from '@ui-modules/tipJar/hooks/useTipContextQueryParams';
import { useTipImageIndexState } from '@ui-modules/tipJar/hooks/useTipImageIndexState';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';

const TipPage = () => {
	const { t } = useTranslation();
	const { tipId } = useParams();
	const tipContext = useTipContextQueryParams();
	const handleEntityPageQueryError = useHandleEntityPageQueryError('tip');
	const { data: tip, isInitialLoading } = useTipQuery(tipId, tipContext, {
		onError: handleEntityPageQueryError,
	});

	return (
		<Page title={t('Tip Selected Page')}>
			{isInitialLoading ? (
				<ActivityIndicator size="medium" type="fit" />
			) : !tip ? (
				<div>{t('Tip not found')}</div>
			) : tipId && tip ? (
				<div className={styles.tipSelectedPage}>
					<TipHeader contextCommunityId={tipContext.contextCommunityId} tipId={tipId} />
					<TipReviewList Header={TipPageBody} tip={tip} tipContext={tipContext} />
				</div>
			) : null}
		</Page>
	);
};

/** Should be in the separate component to avoid unnecessary rerenders being callback for TipReviewList */
const TipPageBody = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { tipId } = useParams();
	const tipContext = useTipContextQueryParams();
	const tipShownInContext = some(tipContext, Boolean);
	const contextCommunityId = tipContext?.contextCommunityId;
	const { data: group } = useCommunityQuery(contextCommunityId as string);
	const isCommunityModerator = group ? CommunityEntity.userIsModerator(group) : undefined;
	const { data: tip } = useTipQuery(tipId, tipContext);
	const [, , initialCoverImageIndex] = useTipImageIndexState(tip);

	const openProfile = (profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId));

	if (!tip) return null;
	return (
		<div className={styles.tipSelectedPage__content}>
			<div className={clsx(styles.tipSelectedPage__header)}>
				<TipDetails
					initialDisplayedGalleryItemIndex={initialCoverImageIndex || 0}
					isTipEditable={false}
					tip={tip}
					tipContext={tipContext}
				/>
				{tipShownInContext ? null : (
					<TipPrimaryReviewSection
						tip={tip}
						onClickUser={
							TipEntity.isTipWithDetails(tip) && tip.details.review
								? () => tip.details.review && openProfile(tip.details.review.owner.profileID)
								: undefined
						}
					/>
				)}

				<div className={styles.tipSelectedPage__moreMembersReview}>
					{tipShownInContext ? (
						<>
							<h3 id="reviews">{t('Reviews')}</h3>
							<When condition={TipReviewEntity.canCreate(tip) || isCommunityModerator}>
								<Button
									icon={<EditIcon height={14} width={14} />}
									title={t('Add Review')}
									type="outline"
									variant="medium"
									onClick={() => navigate(ROUTES.tipReview(tip.id, 'new', tipContext))}
								/>
							</When>
						</>
					) : (
						<>
							<h3 id="reviews">{t('More Member Reviews')}</h3>
							<When condition={TipReviewEntity.canCreate(tip)}>
								<Button
									icon={<EditIcon height={14} width={14} />}
									title={t('Add Review')}
									type="outline"
									variant="medium"
									onClick={() => navigate(ROUTES.tipReview(tip.id, 'new'))}
								/>
							</When>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default memo(TipPage);
