import type { Components, ItemContent, TableVirtuosoProps } from 'react-virtuoso';
import { Virtuoso } from 'react-virtuoso';
import type { TTipReviewWithReaction, TProfile, TCommunity, TEvent, TTipWithDetails, TTipContext } from '@typings';
import { useTipReviewsQuery } from '@ui-modules/tipJar/hooks/useTipReviewsQuery';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import TipReviewCard from '@ui-modules/tipJar/components/TipReviewCard';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import { useTipReviewThumbUpMutation } from '@ui-modules/tipJar/hooks/useTipReviewThumbUpMutation';
import { useTranslation } from '@hooks';
import styles from './TipReviewList.module.css';
import { Gap, EmptyStateMessage } from '@ui-kit/components';
import ActivityIndicator from '@ui-kit/components/ActivityIndicator';
import { useGetTipReviewMeatballMenuOptions } from '@ui-modules/tipJar/hooks/useGetTipReviewMeatballMenuOptions';
import { useTipContextQueryParams } from '@ui-modules/tipJar/hooks/useTipContextQueryParams';

const TipReviewList = ({ tip, tipContext, Header, ...virtuosoListProps }: ITipReviewListProps) => {
	const navigate = useNavigate();
	const openProfile = (profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId));
	const { t } = useTranslation();
	const { contextCommunityId } = useTipContextQueryParams();
	const { data: tipReviews = [], isLoading, fetchMore, isFetchingNextPage } = useTipReviewsQuery(tip.id, tipContext);
	const tipReviewThumbUpMutation = useTipReviewThumbUpMutation(tip.id);
	const [meatballMenuOptions, TipReviewMeatballMenuModals] = useGetTipReviewMeatballMenuOptions(
		tip.id,
		contextCommunityId,
	);

	const renderTipReviewItem = useCallback<ItemContent<TTipReviewWithReaction, unknown>>(
		(_, tipReview) => {
			return (
				<div className={styles.tipReviewList__container}>
					<TipReviewCard
						meatballsMenuOptions={meatballMenuOptions(tipReview)}
						tipReview={tipReview}
						onClickUser={() => openProfile(tipReview.owner.profileID)}
						onThumbUp={() => tipReviewThumbUpMutation.mutate(tipReview)}
					/>
				</div>
			);
		},
		[tipReviews],
	);

	return (
		<>
			<Virtuoso<TTipReviewWithReaction>
				components={{
					Header: Header,
					Footer: () =>
						isLoading || isFetchingNextPage ? (
							<ActivityIndicator size={'small'} type="fit" />
						) : !tipReviews?.length ? (
							<EmptyStateMessage text={t('No reviews yet')} />
						) : (
							<Gap gap={24} />
						),
				}}
				{...virtuosoListProps}
				className={styles.tipReviewList}
				data={tipReviews}
				endReached={fetchMore}
				itemContent={renderTipReviewItem}
			/>
			{TipReviewMeatballMenuModals}
		</>
	);
};

export interface ITipReviewListProps
	extends Omit<
		TableVirtuosoProps<TTipReviewWithReaction, ReactNode>,
		'data' | 'components' | 'onEndReached' | 'headerFooterTag' | 'topItemCount'
	> {
	tip: TTipWithDetails;
	tipContext?: TTipContext;
	Header?: Components['Header'];
}

export default TipReviewList;
