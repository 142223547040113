import { Post } from '@ui-kit';
import FeedReplies from '../FeedReplies';
import PostVideos from '../PostVideos';
import PostLinksPreview from '../PostLinksPreview';
import ShareableCard from '@ui-modules/share/components/ShareableCard';
import EventListItemAttachment from '@ui-modules/events/components/EventListItemAttachment';
import { useDebouncedCallback, useNavigate, useService, useTranslation } from '@hooks';
import { useMutateReactionMeatballMenuOptions } from '@ui-modules/feed/hooks/useMutateReplyMeatballMenuOptions';
import { useToggleCommentLikeMutation } from '@ui-modules/feed/hooks/useToggleCommentLikeMutation';
import { useHandleActivityLink } from '@ui-modules/feed/hooks/useHandleActivityLink';
import { clsx, CommentEntity, InternalShareEntity } from '@utils';
import { ROUTES, FEED_COMMENT_ID_DOM_ATTRIBUTE } from '@constants';
import styles from './FeedComment.module.css';
import type { TActivity, TComment, TUser } from '@typings';
import type { ReactNode } from 'react';

const FeedComment = ({
	comment,
	feedSlug,
	activity,
	isReply,
	onClickReply,
	onLikeToggled,
	renderReply,
	onDelete,
	onEdit,
	onFileClick,
}: IFeedCommentProps) => {
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const api = useService('ApiService');

	const navigate = useNavigate();
	const meatballMenuOptions = useMutateReactionMeatballMenuOptions(comment, feedSlug, onDelete, onEdit);
	const { t } = useTranslation();

	const commentEntity = new CommentEntity(comment);
	const avatarSize = isReply ? 26 : 40;

	// Action handlers.
	const { mutate: toggleLike } = useToggleCommentLikeMutation(activity);
	const debouncedToggleLike = useDebouncedCallback(
		(hasMyLike) => {
			toggleLike(comment);
			onLikeToggled?.(hasMyLike);
		},
		300,
		{ leading: true, trailing: false },
	);
	const handleClickReply = () => onClickReply(comment);
	const profileLink = commentEntity.isInactive ? undefined : ROUTES.memberProfile(commentEntity.profileId);
	const openLink = useHandleActivityLink();
	const isCommentReported = !!comment.own_children?.report?.length;

	const onMentionClick = async (userId: TUser['id']) => {
		const profile = await queryClient.fetchQuery(queryKeys.getProfileByUserId(userId), () =>
			api.profile.getProfileByUserId(userId),
		);

		await navigate(ROUTES.memberProfile(profile?.[0]?.id as string));
	};

	const headerElement = (
		<Post.Header
			avatarProps={{
				imageUri: commentEntity.avatarUri,
				outline: commentEntity.isChair,
				size: avatarSize,
				isInactive: commentEntity.isInactive,
				title: commentEntity.getDisplayingUserName(t),
			}}
			createdAt={commentEntity.isInactive ? commentEntity.createdAt : undefined}
			profileLink={profileLink}
			userId={comment.user?.data.userId}
			userName={commentEntity.getDisplayingUserName(t)}
		/>
	);

	if (isCommentReported) {
		return (
			<div className={clsx(isReply && styles.feedComment, !isReply && styles.feedComment_withFooterSeparator)}>
				<Post
					bodyClassName={clsx(isReply && styles.feedComment__replyBody)}
					footerClassName={styles.footer}
					headerElement={headerElement}
					isCard={false}
					key={comment.id}
					withFooterSeparator={false}
				>
					<div className={styles.reportMessage}>
						<span className={styles.reportMessage__text}>{t('Content reported')}</span>
					</div>
				</Post>

				<FeedReplies comment={comment} renderReply={renderReply} />
			</div>
		);
	}

	return (
		<div
			className={clsx(isReply && styles.feedComment, !isReply && styles.feedComment_withFooterSeparator)}
			{...{ [FEED_COMMENT_ID_DOM_ATTRIBUTE]: comment.id }} // to be able to scroll to feed comment (@see T21C-6667) [@DmitriyNikolenko]
		>
			<Post
				bodyClassName={clsx(isReply && styles.feedComment__replyBody)}
				footerElement={
					<Post.CommentActionBar
						className={clsx(isReply && styles.feedComment__replyActionBar)}
						createdAt={commentEntity.createdAt}
						hasMyLike={commentEntity.hasMyLike}
						likes={commentEntity.likes}
						likeTitle={t('Like')}
						replyTitle={t('Reply')}
						onClickReply={handleClickReply}
						onLike={(hasMyLike) => debouncedToggleLike(hasMyLike)}
					/>
				}
				headerElement={headerElement}
				isCard={false}
				key={comment.id}
				meatballMenuOptions={meatballMenuOptions}
				withFooterSeparator={false}
			>
				{commentEntity.content ? (
					<Post.Content
						collapsedTitle={t('Show more')}
						expandedTitle={t('Show less')}
						onClickLink={openLink}
						onMentionClick={onMentionClick}
					>
						{commentEntity.htmlContent}
					</Post.Content>
				) : null}
				{commentEntity.links?.length ? <PostLinksPreview links={commentEntity.links} /> : null}
				{commentEntity.files?.length ? (
					<Post.Attachments attachmentUrls={commentEntity.files} onClick={onFileClick} />
				) : null}
				{commentEntity.images?.length ? (
					<Post.ImageGallery
						avatarOutline={commentEntity.isChair}
						createdAt={commentEntity.createdAt}
						images={commentEntity.images}
						imageUri={commentEntity.avatarUri}
						sourceName=""
						userId={comment.user?.data.userId}
						userName={commentEntity.userName}
					/>
				) : null}
				{commentEntity.videos?.length ? <PostVideos videos={commentEntity.videos} /> : null}
				{commentEntity.eventId ? <EventListItemAttachment eventId={commentEntity.eventId} /> : null}
				{commentEntity.sharedContent ? (
					<ShareableCard
						sharedContent={commentEntity.sharedContent}
						onClick={() => {
							const internalShareEntity = new InternalShareEntity();
							navigate(internalShareEntity.internalShareToRoute(commentEntity.sharedContent!));
						}}
					/>
				) : null}
			</Post>

			<FeedReplies comment={comment} renderReply={renderReply} />
		</div>
	);
};

export interface IFeedCommentProps {
	comment: TComment;
	feedSlug?: string;
	activity: TActivity;
	isReply: boolean;
	userSlug: TUser['slug'];
	onClickReply: (comment: TComment) => void;
	onLikeToggled?: (newLiked: boolean) => void;
	renderReply: ({ comment }: { comment: TComment }) => ReactNode;
	onFileClick: (url: string) => void;
	onDelete: (comment: TComment) => void;
	onEdit: (comment: TComment) => void;
}

export default FeedComment;
