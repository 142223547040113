import { forwardRef, type ForwardedRef } from 'react';
import GlobalSearchResultInfiniteList from '../GlobalSearchResultInfiniteList/GlobalSearchResultInfiniteList';
import SearchBottomSheetModal from '@ui-modules/globalSearch/components/SearchBottomSheetModal';
import { useRef, useState, useDeferredValue, useImperativeHandle, useEvent } from '@hooks';
import styles from './GlobalSearchResultInfiniteListBottomSheetModal.module.css';
import type { IBottomSheetModalMethods } from '@ui-kit';
import type { IGlobalSearchResultInfiniteListProps } from '../GlobalSearchResultInfiniteList/GlobalSearchResultInfiniteList';
import type { TSearchResult } from '@tiger21-llc/connect-shared/src/typings';

const GlobalSearchResultInfiniteListBottomSheetModal = (
	{ definition, EmptyComponent, onSelect, showInitialResults }: IGlobalSearchResultInfiniteListBottomSheetModalProps,
	ref: ForwardedRef<IBottomSheetModalMethods>,
) => {
	const bottomSheetModalRef = useRef<IBottomSheetModalMethods>(null);
	const [searchQuery, setSearchQuery] = useState('');
	const deferredSearchQuery = useDeferredValue(searchQuery);

	useImperativeHandle(ref, () => bottomSheetModalRef.current as IBottomSheetModalMethods);

	const selectGlobalSearchItem = useEvent((searchResult: TSearchResult) => {
		onSelect(searchResult);
		bottomSheetModalRef.current?.hide?.();
	});

	return (
		<SearchBottomSheetModal
			height="80vh"
			ref={bottomSheetModalRef}
			searchQuery={searchQuery}
			onChangeSearchQuery={setSearchQuery}
		>
			<GlobalSearchResultInfiniteList
				className={styles.globalSearchResultInfiniteListBottomSheetModal}
				definition={definition}
				EmptyComponent={EmptyComponent}
				searchQuery={deferredSearchQuery}
				showInitialResults={showInitialResults}
				onSelect={selectGlobalSearchItem}
			/>
		</SearchBottomSheetModal>
	);
};

export interface IGlobalSearchResultInfiniteListBottomSheetModalProps
	extends Pick<
		IGlobalSearchResultInfiniteListProps,
		'definition' | 'EmptyComponent' | 'showInitialResults' | 'onSelect'
	> {}

export interface IGlobalSearchResultInfiniteListBottomSheetModalMethods extends IBottomSheetModalMethods {}

export default forwardRef(GlobalSearchResultInfiniteListBottomSheetModal);
