import { useFormik, useState, useTranslation } from '@hooks';
import { forwardRef, useImperativeHandle, type ForwardedRef } from 'react';
import { Gap, Input, Modal } from '@ui-kit';
import { yup } from '@utils';

const PasswordFormModal = (
	{ onSubmitPassword, onCancel }: IPasswordFormModalProps,
	ref: ForwardedRef<IPasswordFormModalMethods>,
) => {
	const { t } = useTranslation();

	const [modalVisible, setModalVisible] = useState(false);
	const { values, errors, handleChange, handleBlur, handleSubmit, submitForm, resetForm, isValid, dirty } = useFormik<{
		password: string;
	}>({
		validationSchema: yup.object().shape({
			password: yup.string().required(t('Password is required')),
		}),
		initialValues: { password: '' },
		onSubmit: (formValues) => {
			onSubmitPassword(formValues.password);
			resetForm();
			setModalVisible(false);
		},
	});

	useImperativeHandle(ref, () => ({
		show: (options) => {
			resetForm({
				errors: {
					password: options?.initialError,
				},
			});
			setModalVisible(true);
		},
		hide: () => setModalVisible(false),
	}));

	if (!modalVisible) return null;
	return (
		<>
			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('Apply')}
				isConfirmDisabled={!isValid || !dirty}
				modalContent={
					<form onSubmit={handleSubmit}>
						<Gap gap={8} />
						<Input
							errorMessage={errors.password}
							label={t('Password')}
							secureTextEntry
							value={values.password}
							onBlur={handleBlur('password')}
							onChange={handleChange('password')}
						/>
					</form>
				}
				title={t('Password is required')}
				variant="medium"
				visible={modalVisible}
				onCancel={() => {
					setModalVisible(false);
					onCancel?.();
				}}
				onConfirm={submitForm}
			/>
		</>
	);
};

export interface IPasswordFormModalProps {
	onSubmitPassword: (password: string) => void;
	onCancel?: () => void;
}
export interface IPasswordFormModalMethods {
	show: (options?: { initialError?: string }) => void;
	hide: () => void;
}

export default forwardRef(PasswordFormModal);
