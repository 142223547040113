import NetworkConnectionStatusButton from '../NetworkConnectionStatusButton';
import NetworkConnectionDonateButton from '../NetworkConnectionDonateButton';
import NetworkConnectionBookmarkButton from '../NetworkConnectionBookmarkButton';
import { useService } from '@hooks';
import styles from './NetworkConnectionSubHeader.module.css';
import type { TCommunity, TNetworkConnection } from '@typings';
import { useTranslation } from 'react-i18next';
import { NetworkConnectionEntity } from "@utils";

const NetworkConnectionSubHeader = ({
	networkConnection,
	networkConnectionName,
	networkId,
}: INetworkConnectionSubHeaderProps) => {
	const analytics = useService('AnalyticsService');
	const { t } = useTranslation();
	const isClosed = NetworkConnectionEntity.isNetworkClosed(networkConnection);

	return (
		<div className={styles.networkConnectionSubHeader}>
			<h1 className={styles.networkConnectionSubHeaderTitle}>
				{isClosed ? `${t('CLOSED:')} ${networkConnection.title}` : networkConnection.title}
			</h1>
			<div className={styles.networkConnectionSubHeader__actionBar}>
				<NetworkConnectionStatusButton
					networkConnectionId={networkConnection.id}
					networkConnectionsName={networkConnectionName}
				/>
				<NetworkConnectionDonateButton
					networkConnection={networkConnection}
					networkConnectionsName={networkConnectionName}
				/>
				<NetworkConnectionBookmarkButton
					networkConnectionId={networkConnection.id}
					networkId={networkId}
					onToggled={(isNewToggled) => {
						analytics.trackGenericEvent(
							'{{connectionName}}Interactions',
							{
								interaction_type: 'bookmark',
								interaction_action: isNewToggled ? 'added' : 'removed',
								'{{connectionName}}_id': networkConnection.id,
								'{{connectionName}}_name': networkConnection.title,
							},
							{
								connectionName: networkConnectionName,
							},
						);
					}}
				/>
			</div>
		</div>
	);
};

export interface INetworkConnectionSubHeaderProps {
	networkConnection: TNetworkConnection;
	networkConnectionName: string;
	networkId: TCommunity['id'];
}

export default NetworkConnectionSubHeader;
