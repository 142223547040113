import { ChatAutoComplete, useMessageInputContext } from 'stream-chat-react';
import RichMessageInput, { mapAttachmentsToRichTextInputFormat } from '@ui-modules/forms/components/RichMessageInput';
import ShareableCard from '@ui-modules/share/components/ShareableCard';
import ChatInputBarEditMessageContainer from './components/ChatInputBarEditMessageContainer';
import EventListItemAttachment from '@ui-modules/events/components/EventListItemAttachment';
import {
	useCallback,
	useChat,
	useEffect,
	useMemo,
	useSearchParams,
	useState,
	useTranslation,
	usePrevious,
} from '@hooks';
import { useHandleChatAttachmentsState } from './hooks/useHandleChatAttachmentsState';
import { usePasteCopiedChatMessageEffect } from '@ui-modules/chat/hooks/usePasteCopiedChatMessageEffect';
import { useSendMessage } from '@ui-modules/chat/hooks/useSendMessage';
import { useDraftAPI } from '@ui-modules/chat/hooks/useDraftAPI';
import { useStoredShareContent } from '@ui-modules/share/hooks/useStoredShareContent';
import { isEqual, map, onClipboardPaste } from '@utils';
import {
	getChatInputPlaceholder,
	parseAttachmentsFromEditingMessage,
	shouldDisableSendButton,
} from './ChatInputBar.utils';
import { MAXIMUM_ALLOWED_ATTACHMENTS_COUNT } from '@constants';
import styles from './ChatInputBar.module.css';
import type { TAttachments } from '@typings';

const ChatInputBar = ({ disabled, cid, channelIsEmpty }: IChatInputBarProps) => {
	const { t } = useTranslation();
	const { setText, text, textareaRef, handleChange, mentioned_users } = useMessageInputContext();
	const { draftMessage, updateMessageDraft, removeMessageDraft } = useDraftAPI(cid as string);

	const [showGifInput, setShowGifInput] = useState<boolean>(false);
	const { editMessage, quotedMessageId, reset, eventId, setEventId, setEditMessage, setQuotedMessageId } = useChat();
	const [searchParams] = useSearchParams();

	const internalShare = searchParams.get('internalShare');
	const { storedShareContent, clearStoredShareContent, setStoredShareContent } = useStoredShareContent(
		internalShare || draftMessage?.sharedContent,
	);

	// Store sharedContent in draft when it changes
	useEffect(() => {
		if (storedShareContent !== draftMessage?.sharedContent) {
			updateMessageDraft({ sharedContent: storedShareContent });
		}
	}, [storedShareContent, draftMessage?.sharedContent, updateMessageDraft]);

	useEffect(() => {
		const draftMentions = draftMessage?.mentioned_users || [];

		const mentionsDiffer = JSON.stringify(draftMentions) !== JSON.stringify(mentioned_users);
		if (mentioned_users?.length && mentionsDiffer) {
			updateMessageDraft({ mentioned_users });
		}
	}, [mentioned_users, draftMessage, updateMessageDraft]);

	useEffect(() => {
		setText(draftMessage?.text ?? '');
		setEventId(draftMessage?.eventId ?? null);
		!!draftMessage?.sharedContent && setStoredShareContent(draftMessage?.sharedContent ?? null);
		setAttachments(draftMessage?.attachments ?? []);

		if (draftMessage?.editingMessage) {
			setEditMessage({
				...draftMessage.editingMessage,
				text: draftMessage?.text,
				attachments: draftMessage?.attachments,
				eventId: draftMessage?.eventId,
				sharedContent: draftMessage?.sharedContent,
			});
		}
	}, []);

	const [attachments, setAttachments] = useState<TAttachments[]>([]);
	const FileAttachments = useHandleChatAttachmentsState({
		attachments,
		setAttachments,
		onlyFileAccepted: true,
		shouldUploadToStream: true,
		isChat: true,
		cid,
		forbidDuplicates: true,
	});

	const MediaAttachments = useHandleChatAttachmentsState({
		attachments,
		setAttachments,
		onlyMediaAccepted: true,
		shouldUploadToStream: true,
		isChat: true,
		cid,
	});

	const loadingStateAttachments = useMemo(
		() => [...FileAttachments.loadingStateAttachments, ...MediaAttachments.loadingStateAttachments],
		[FileAttachments.loadingStateAttachments, MediaAttachments.loadingStateAttachments],
	);

	const { mutate: onSendPressed, isLoading: sending } = useSendMessage(editMessage, quotedMessageId, () => resetData());

	const isFilesLoading = !!FileAttachments.loadingStateAttachments.length;
	const isMediaLoading = !!MediaAttachments.loadingStateAttachments.length;
	const isLoading = isFilesLoading || isMediaLoading;
	const isMessageEmpty = shouldDisableSendButton(attachments, text, eventId, storedShareContent);

	const previousStoredSharedContent = usePrevious(storedShareContent);

	const shouldDisableEditSendButton = () => {
		const attachmentsUrls = map(editMessage?.attachments || [], (item) => item.image_url || item.asset_url);
		const isAttachmentsEqual = isEqual(
			attachmentsUrls.sort(),
			map([...(MediaAttachments.attachments || []), ...(FileAttachments.attachments || [])], 'url').sort(),
		);

		const isSharedContentEqual = editMessage?.sharedContent === storedShareContent;

		// Detect if shared content was cleared (exists previously but is now empty)
		const wasSharedContentCleared =
			previousStoredSharedContent && !storedShareContent && editMessage?.sharedContent !== null;

		const isEventEqual = (editMessage?.eventId ?? undefined) === (eventId ?? undefined);
		return (
			isMessageEmpty ||
			((editMessage?.text || '') === (text || '') &&
				isAttachmentsEqual &&
				isEventEqual &&
				isSharedContentEqual &&
				!wasSharedContentCleared)
		);
	};

	const disableSendButton = editMessage ? shouldDisableEditSendButton() : isMessageEmpty;
	const itemsExceedingCount = attachments.length > MAXIMUM_ALLOWED_ATTACHMENTS_COUNT;

	useEffect(() => {
		if (!editMessage) return;

		textareaRef.current?.focus();

		const text = editMessage?.text?.trim() ?? '';
		const eventId = editMessage.eventId as string;
		const attachments = parseAttachmentsFromEditingMessage(editMessage.attachments);
		const sharedContent = editMessage.sharedContent as string;

		updateMessageDraft({
			text,
			eventId,
			attachments,
			sharedContent,
			editingMessage: editMessage,
		});

		setAttachments(attachments);
		setText(text);
		setStoredShareContent(sharedContent);
		setEventId(eventId);
	}, [editMessage]);

	useEffect(() => {
		if (!quotedMessageId) return;
		resetData();
		setQuotedMessageId(quotedMessageId);
	}, [quotedMessageId]);

	usePasteCopiedChatMessageEffect(text, setText, setAttachments, setEventId, setStoredShareContent);

	/** reset data when message send */
	const resetData = () => {
		FileAttachments.setAttachments([]);
		FileAttachments.setLoadingStateAttachments([]);
		MediaAttachments.setAttachments([]);
		MediaAttachments.setLoadingStateAttachments([]);
		setText('');
		clearStoredShareContent();
		reset();
		setEventId(null);
		removeMessageDraft();
		setShowGifInput(false);
	};

	const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback(
		(event) => {
			const deletePressed =
				event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType === 'deleteContentBackward';

			if (text.length === 1 && deletePressed) {
				setShowGifInput(false);
			}

			updateMessageDraft({ text: event.target.value });

			handleChange(event);
		},
		[text, setShowGifInput], // eslint-disable-line
	);

	const onSubmit = async (event?: React.BaseSyntheticEvent) => {
		if (disableSendButton) return;
		if (event) {
			const currentEvent = event as unknown as KeyboardEvent;
			event.preventDefault();
			if (currentEvent.keyCode !== undefined && currentEvent.keyCode !== 13) {
				return;
			}
		}

		onSendPressed({ attachments, text, eventId, storedShareContent });
	};

	const richMessageInputAttachments = useMemo(
		() =>
			mapAttachmentsToRichTextInputFormat(attachments, loadingStateAttachments, {
				removeFile: FileAttachments.removeAttachment,
				removeMedia: MediaAttachments.removeAttachment,
			}),
		[attachments, loadingStateAttachments],
	);

	return (
		<div className="str-chat__input-flat str-chat__input-flat--send-button-active">
			<div className={styles.chatInputBar_container}>
				<RichMessageInput initialAppearance={channelIsEmpty ? 'column' : 'row'}>
					<ChatInputBarEditMessageContainer editing={!!editMessage} replying={!!quotedMessageId} onReset={resetData} />
					<RichMessageInput.InputBox
						sendDisabled={disableSendButton || disabled || isLoading || itemsExceedingCount}
						sending={sending}
						onSend={onSubmit}
					>
						<RichMessageInput.AttachmentsWithPreview attachments={richMessageInputAttachments} />
						<ChatAutoComplete
							placeholder={getChatInputPlaceholder(t)({ showGifInput, disabled })}
							value={text}
							onChange={onChange}
							onPaste={(event) => onClipboardPaste(event, (files) => MediaAttachments.onAddAttachmentsSuccess(files))}
							handleSubmit={onSubmit}
							/** added a custom list of mentioned users to keep them highlighted [PATCH - T21C-7872] [@author HrantAbrahamyan] */
							mentioned_users={mentioned_users?.length ? mentioned_users : draftMessage?.mentioned_users}
						/>

						{eventId ? (
							<div className={styles.event}>
								<EventListItemAttachment
									eventId={eventId}
									onRemove={() => {
										setEventId(null);
										updateMessageDraft({ eventId: null });
									}}
								/>
							</div>
						) : null}

						{storedShareContent ? (
							<div className={styles.shareableCardWrapper}>
								<ShareableCard
									clearInternalShare={() => {
										clearStoredShareContent();
										updateMessageDraft({ sharedContent: null });
									}}
									sharedContent={storedShareContent}
								/>
							</div>
						) : null}
					</RichMessageInput.InputBox>
					<RichMessageInput.ActionsWithPickers
						attachmentAmountExceeded={attachments.length > MAXIMUM_ALLOWED_ATTACHMENTS_COUNT}
						onSelectEvent={(eventId) => {
							setEventId(eventId);
							updateMessageDraft({ eventId });
						}}
						onSelectFiles={FileAttachments.onAddAttachmentsSuccess}
						onSelectMedia={MediaAttachments.onAddAttachmentsSuccess}
						onSelectShareContent={(sharedContent) => {
							setStoredShareContent(sharedContent);
							updateMessageDraft({ sharedContent });
						}}
					/>
				</RichMessageInput>
			</div>
		</div>
	);
};

export type TRenderAttachments = {
	data: TAttachments[];
};

interface IChatInputBarProps {
	disabled: boolean;
	channelIsEmpty: boolean;
	cid?: string;
}

export default ChatInputBar;
