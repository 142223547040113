import { FormPageLayout, Page } from '../../components';
import { useTranslation, useMemo, useLayoutEffect, useRef } from '@hooks';
import styles from './EditProfileExpertisePage.module.css';
import { Button, Formik } from '@ui-kit';
import type { TEditProfileExpertise } from '@schemas';
import { editProfileExpertiseSchema } from '@schemas';
import { useUpdateProfileMutation } from '@ui-modules/profile/hooks/useUpdateProfileMutation';
import { useProfileTags } from '@ui-modules/profile/hooks/useProfileTags';
import SelectExpertiseField from '@ui-modules/profile/components/SelectExpertiseField';
import { ROUTES } from '@constants';
import type { FormikProps } from 'formik';

const EditProfileExpertisePage = () => {
	const { t } = useTranslation();
	const { profile, mutateAsync } = useUpdateProfileMutation();
	const { clearSelectedTags, openTagsSelector, selectedTags } = useProfileTags('expertise');
	const formikRef = useRef<FormikProps<TEditProfileExpertise>>(null);

	const initialValues: TEditProfileExpertise = useMemo(
		() => ({ expertise: profile.expertise }) as TEditProfileExpertise,
		[profile, selectedTags],
	);

	const onSubmit = async (values: TEditProfileExpertise) => {
		await mutateAsync(values);
		clearSelectedTags();
	};

	useLayoutEffect(() => {
		if (selectedTags) {
			formikRef.current?.setFieldValue('expertise', selectedTags);
			formikRef.current?.setFieldTouched('expertise');
		}
	}, [selectedTags]);

	return (
		<Page title={t('Edit Profile Expertise')}>
			<Formik<TEditProfileExpertise>
				enableReinitialize
				initialValues={initialValues}
				innerRef={formikRef}
				validationSchema={editProfileExpertiseSchema}
				onSubmit={onSubmit}
			>
				{(formProps) => {
					const { values, setFieldValue } = formProps;
					return (
						<FormPageLayout
							allowedNextPaths={[ROUTES.editProfileExpertise(), ROUTES.editProfileTags()]}
							formProps={formProps}
							headerTitle={t('My Expertise')}
							saveTrackingName="profile--edit-expertise"
						>
							<div className={styles.expertise}>
								<div className={styles.expertise__container}>
									<div>
										<span className={styles.expertise__label}>{t('I have expertise in')}</span>
										<SelectExpertiseField
											data={values.expertise.map((el, ind) => ({ id: `${el}-${ind}`, value: el }))}
											onDrag={(values: string[]) => setFieldValue('expertise', values)}
											onRemove={(removingIndex: number) => {
												const filteredValue = values.expertise.filter((_, index) => index !== removingIndex);
												setFieldValue('expertise', filteredValue);
											}}
										/>
									</div>

									<div className={styles.expertise__buttonContainer}>
										<Button
											block="fit"
											title={t('Add Expertise')}
											type="outline"
											variant="small"
											onClick={() => openTagsSelector(values.expertise)}
										/>
									</div>
								</div>
							</div>
						</FormPageLayout>
					);
				}}
			</Formik>
		</Page>
	);
};

export interface IEditProfileExpertisePageParams extends Record<string, string | undefined> {
	id: string;
}

export default EditProfileExpertisePage;
