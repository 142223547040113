import { useState } from 'reinspect';
import { useMe, useMemo, useNavigate, useNotification, useParams, useService, useTranslation } from '@hooks';

import useDealFiltersApi from '@ui-modules/deals/hooks/useDealFiltersApiQuery';
import EditNetworkDealFiltersForm from '@ui-modules/deals/components/EditNetworkDealFiltersForm';

import { noop } from '@utils';
import { getEditDealFiltersSchema } from '@schemas';
import { dealStatusTabs as tabs, ROUTES } from '@constants';
import { ActivityIndicator, Formik, Navigate } from '@ui-kit';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useDealFilterViaCommunityId } from '@ui-modules/deals/hooks/useDealFilterViaCommunityIdQuery';

import styles from '../AllDealFiltersPage/AllDealFiltersPage.module.css';

import { FormPageLayout, Page } from '../../components';

import type { TEditDealFilters } from '@schemas';
import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { useDealsDisabledEffect } from '@ui-modules/deals/hooks/useDealsDisabledEffect';
import { getFilterMode } from '@ui-modules/deals/utils/getFilterMode';
import { useIsDealFiltersFetching } from '@ui-modules/deals/hooks/useIsDealFiltersFetching';

const DealFiltersPage = () => {
	const { t } = useTranslation();
	const { communityId } = useParams<IDealFiltersPageParams>();
	const { data: community } = useCommunityQuery(communityId);
	const { user } = useMe();
	const dealStatusTabs = tabs(t);
	const { showSuccess, showError } = useNotification();
	const navigate = useNavigate();
	const isDealFiltersFetching = useIsDealFiltersFetching();

	useDealsDisabledEffect();
	const { enabled } = useDealsEnabled();

	const { data: dealFilters, isLoading } = useDealFilterViaCommunityId(communityId as string);
	const filter = dealFilters?.find((item) => item?.ownerIri === user?.['@id']);

	const statusIndex = dealStatusTabs?.findIndex((item) => item.value === String(filter?.status));

	const { patchDealFilters: applyFilters } = useDealFiltersApi(filter?.id);

	const initialValues: TEditDealFilters = useMemo(() => {
		return {
			status: statusIndex < 0 ? 0 : statusIndex,
			dealTypes: filter?.dealTypes || [],
			dealSubClasses: filter?.dealSubClasses || [],
			minInvestment: String(filter?.minInvestment || 0),
		};
	}, [filter?.dealSubClasses, filter?.dealTypes, filter?.minInvestment, statusIndex]);

	const initialOptionIndex = getFilterMode(filter?.dealTypes || [], filter?.dealSubClasses || []);

	const [optionIndex, setOptionIndex] = useState<number>(initialOptionIndex);
	const [availableDealTypes, setAvailableDealTypes] = useState<ICheckmarkState[] | null>(null);

	const onSubmit = async (values: TEditDealFilters) => {
		if (optionIndex === 1 && !values.dealSubClasses?.length) {
			showError({ title: t('Please select at least one sub-class') });
			return;
		}
		if (
			optionIndex === 1 &&
			!!values.dealSubClasses?.length &&
			!values.dealTypes?.length &&
			!!availableDealTypes?.length
		) {
			showError({ title: t('Please select at least one deal-type') });
			return;
		}

		await applyFilters({
			owner: user['@id'],
			dealSubClasses: values.dealSubClasses,
			minInvestment: values.minInvestment ? Number(values.minInvestment) : 0,
			status: dealStatusTabs[values?.status as number].value,
			dealTypes: values.dealTypes,
		});
		setTimeout(() => {
			showSuccess({
				title: t('Filter updated'),
			});
			navigate(-1);
		}, 1200); // T21C-7645 avoid show prev data
	};

	if (!enabled) return <Navigate replace={true} to={ROUTES.home()} />;
	if (!communityId) return null;
	if (isLoading) return <ActivityIndicator />;

	return (
		<Page title={t('Filtering {{communityName}}', { communityName: community?.name })}>
			{filter && community && (
				<Formik<TEditDealFilters>
					initialValues={getEditDealFiltersSchema().validateSync(initialValues)}
					validationSchema={getEditDealFiltersSchema()}
					onSubmit={onSubmit}
				>
					{(formProps) => (
						<FormPageLayout
							additionalDisabled={isDealFiltersFetching}
							allowedNextPaths={[ROUTES.dealFilters(''), ROUTES.allDealFilters()]}
							buttonBordered={false}
							customHeaderStyles={styles.customHeader}
							customLeftIcon={<>{t('Cancel')}</>}
							formProps={formProps}
							headerTitle={t('Filter Deals')}
							saveButtonTitle={t('Apply')}
							onSuccessSubmit={noop}
						>
							<EditNetworkDealFiltersForm
								activeStatus={dealStatusTabs[statusIndex < 0 ? 0 : statusIndex].id}
								community={community}
								setAvailableDealTypes={setAvailableDealTypes}
								setOptionIndex={setOptionIndex}
								formProps={formProps}
								// Setting initial values based on the length of provided arrays [@hrant]
								optionIndex={optionIndex}
							/>
						</FormPageLayout>
					)}
				</Formik>
			)}
		</Page>
	);
};

export interface IDealFiltersPageParams extends Record<string, string | undefined> {
	id: string;
}

export default DealFiltersPage;
