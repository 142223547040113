import { CameraIcon, GalleryIcon, CalendarEmptyIcon, AttachFileIcon, EllipsisIcon, LampIconSolid } from '@ui-kit/icons';
import { useMe, useMemo, useTranslation } from '@hooks';
import { compact } from '@utils';
import { ProfileEntity } from '@ui-modules/profile/utils/ProfileEntity';
import type { TRichMessageInputAction } from '../components/RichMessageInputAction';

export const useRichMessageInputActions = (
	{ attachmentAmountExceeded, availableActions }: IUseChatRichMessageInputCurrentState,
	callbacks: IUseChatRichMessageInputCallbacks,
) => {
	// const { openModal } = useModal();
	const { t } = useTranslation();

	const { user } = useMe();

	const actions: Array<TRichMessageInputAction<TChatRichMessageInputActionName>> = useMemo(() => {
		const tipsEnabled = ProfileEntity.isFeatureEnabled(user, 'tips');
		const actions = compact([
			{
				name: 'camera' as const,
				title: t('Camera'),
				Icon: CameraIcon,
				color: `var(--color-link)`,
				onPress: callbacks.onOpenCamera,
			},
			{
				name: 'media' as const,
				title: t('Media'),
				Icon: GalleryIcon,
				color: `var(--color-success)`,
				onPress: () => {
					if (attachmentAmountExceeded) return callbacks?.onMaximumFilesAmountReached();
					callbacks.onOpenMediaPicker();
				},
			},
			{
				name: 'events' as const,
				title: t('Events'),
				Icon: CalendarEmptyIcon,
				color: `var(--color-error)`,
				onPress: callbacks.onOpenEventPicker,
			},
			{
				name: 'files' as const,
				title: t('Files'),
				Icon: AttachFileIcon,
				color: `var(--color-darkGold)`,
				onPress: () => {
					if (attachmentAmountExceeded) return callbacks?.onMaximumFilesAmountReached();
					callbacks.onOpenFilePicker();
				},
			},
			tipsEnabled && {
				name: 'tips' as const,
				title: t('Tips'),
				Icon: LampIconSolid,
				color: `var(--color-teal)`,
				onPress: callbacks.onOpenTipPicker,
			},
			{
				name: 'shared' as const,
				title: t('Search T21 Connect'),
				Icon: EllipsisIcon,
				description: t('Attach network discussions, events, meetings, articles, tips etc.'),
				color: `var(--color-mediumGrey)`,
				onPress: callbacks.onOpenSharedContentPicker,
			},
		]);
		if (!availableActions) return actions;
		return actions.filter((action) => availableActions.includes(action.name));
	}, [attachmentAmountExceeded, user.features]);

	return { actions };
};

export type TChatRichMessageInputActionName = 'camera' | 'media' | 'events' | 'files' | 'tips' | 'shared';

export interface IUseChatRichMessageInputCurrentState {
	attachmentAmountExceeded: boolean;
	availableActions?: TChatRichMessageInputActionName[];
}
interface IUseChatRichMessageInputCallbacks {
	onOpenCamera: () => void;
	onOpenMediaPicker: () => void;
	onOpenFilePicker: () => void;
	onOpenEventPicker: () => void;
	onOpenTipPicker: () => void;
	onOpenSharedContentPicker: () => void;
	onMaximumFilesAmountReached: () => void;
}
