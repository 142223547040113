import { useEffect, useReducer, useService, useState } from '@hooks';
import type { TAttachments, TFileType } from '@typings';
import { byteToMb, extractVideoIdFromVideoUrl, getVideoCover } from '@utils';
import { extractFileStats } from '../utils/extractFileStats';

export const usePostAttachmentsState = ({ savedAttachments }: IUsePostAttachmentsStateProps) => {
	const [{ attachments, filesSize }, setAttachments] = useReducer<TAttachmentsReducer>(
		(_, newAttachments) => ({ attachments: newAttachments, filesSize: calculateFileSize(newAttachments) }),
		{ attachments: [], filesSize: 0 },
	);
	const [loadingStateAttachments, setLoadingStateAttachments] = useState<TAttachments[]>([]);

	const apiVideo = useService('ApiVideoService');

	const onAddAttachmentsSuccess = async (files: File[]) => {
		const transformedFiles = files.filter((file) => {
			const isAttachmentPresent = attachments.find((attachment) => attachment.name === file.name);
			if (isAttachmentPresent) return false;
			return file;
		});

		const mappedFiles: TAttachments[] = [];
		for (const file of transformedFiles) {
			const fileType = getType(file);
			const url = URL.createObjectURL(file);
			const fileConverted: TAttachments = {
				id: file.name,
				name: file.name,
				isFile: fileType === 'file',
				size: file.size,
				url,
				mimeType: file.type,
				mediaType: file.type,
				file: file,
				fileType,
				thumb_url: fileType === 'video' ? await getVideoCover(url) : undefined,
			};
			mappedFiles.push(fileConverted);
		}
		setAttachments([...mappedFiles, ...attachments]);
	};

	const removeAttachment = async (file: TAttachments) => {
		const filterAttachment = (prevFile: TAttachments) => {
			return `${prevFile.name}-${prevFile.url}` !== `${file.name}-${file.url}`;
		};

		setAttachments(attachments.filter(filterAttachment));
		setLoadingStateAttachments((attachments) => attachments.filter(filterAttachment));
	};

	const setInitialAttachments = async ({ images = [], files = [], videos = [] }: IInitialAttachments) => {
		const convertedImages = images.map((image) => ({
			...extractFileStats(image),
			fileType: 'image' as TFileType,
		}));

		const convertedFiles = files.map((file) => ({
			...extractFileStats(file),
			fileType: 'file' as TFileType,
		}));

		const convertedVideos = await Promise.all(
			videos.map(async (video) => {
				const fileStats = extractFileStats(video);
				const apiVideoDetails = await apiVideo.getVideoDetails(extractVideoIdFromVideoUrl(fileStats.url)).catch(() => {
					/* Ignore errors */
				});

				return {
					...fileStats,
					fileType: 'video' as TFileType,
					thumb_url: apiVideoDetails?.assets?.thumbnail,
				};
			}),
		);

		const initialAttachments = [...convertedFiles, ...convertedImages, ...convertedVideos];
		setAttachments(initialAttachments);
	};

	useEffect(() => {
		if (savedAttachments === undefined) {
			// Handle the case when savedAttachments is undefined
			// You can set default values or perform any other required action
			return;
		}

		setAttachments(savedAttachments);
	}, [savedAttachments]);

	return {
		attachments,
		filesSize,
		removeAttachment,
		setInitialAttachments,
		setAttachments,
		setLoadingStateAttachments,
		loadingStateAttachments,
		onAddAttachmentsSuccess,
	};
};

// Helpers

const getType = (file: File): TFileType => {
	return file.type.includes('image') ? 'image' : file.type.includes('video') ? 'video' : 'file';
};

const calculateFileSize = (attachments: TAttachments[]) => {
	const totalSize = attachments.reduce((totalSize, attachment) => {
		if (attachment.fileType === 'file') {
			return (totalSize += attachment.size || 0);
		}
		return totalSize;
	}, 0);

	return byteToMb(Math.max(totalSize, 0));
};

//  Typings

interface IInitialAttachments {
	images?: string[];
	files?: string[];
	videos?: string[];
}

interface IUsePostAttachmentsStateProps {
	savedAttachments?: TAttachments[];
}

type TAttachmentsReducer<
	State = {
		attachments: TAttachments[];
		filesSize: number;
	},
> = (state: State, newAttachments: TAttachments[]) => State;
