import React, { memo, useState } from 'react';

import { ActivityIndicator, BlockLink, Modal, RightArrowIcon } from '@ui-kit';
import RestrictedShareCard from '@ui-modules/share/components/ShareableCard/RestrictedShareCard';
import ShareCardCloseButton from './ShareCardCloseButton';
import ShareCardContentIcon from './ShareCardContentIcon';
import ShareCardSourceDisplay from './ShareCardSourceDisplay';

import { useMe, useNotification, useService, useTranslation } from '@hooks';
import { useNetworkMutations } from '@ui-modules/networks/hooks/useNetworkMutations';
import { useSharedContent } from '@ui-modules/share/hooks/useSharedContent';
import { useUpdateUserMutation } from '@ui-modules/profile/hooks/useUpdateUserMutation';

import { clsx, InternalShareEntity, truncate } from '@utils';
import { getRestrictedCard, type TRestrictions } from '@ui-modules/share/utils/getRestrictedCard';

import styles from './ShareableCard.module.css';
import type { IInternalShare } from '@typings';

const internalShareEntity = new InternalShareEntity();
const MAX_AVAILABLE_CHARS = 30;

const ShareableCard = ({ clearInternalShare, containerClassName, sharedContent, onClick }: IShareableCardProps) => {
	const { user } = useMe();
	const { t } = useTranslation();
	const { joinNetwork, isLoading: isJoiningNetwork } = useNetworkMutations();
	const { mutateAsync: updateUser } = useUpdateUserMutation(false);
	const { data, isLoading, community, isError, isDeleted, isFetched } = useSharedContent(sharedContent);
	const internalSharedContentOrienteering = internalShareEntity.internalSharedContentOrienteering(sharedContent);

	const [showDialog, setShowDialog] = useState(false);
	const { showInfo } = useNotification();

	const analytics = useService('AnalyticsService');

	const shareType = internalShareEntity.getShareHumanReadableType({ sharedContent, t, data });

	/**
	 In rare cases, the shared post's identifier may be missing.
	 To handle this edge case, we mark the post as unavailable, FIXES: https://tiger21.atlassian.net/browse/T21C-8701?focusedCommentId=75489
	 @author Hrant Abrahamyan
	 * */
	if (!data?.id && isFetched) {
		return (
			<RestrictedShareCard
				clearInternalShare={clearInternalShare}
				title={t('This {{shareType}} is unavailable', { shareType })}
			/>
		);
	}

	// Early return for loading state
	if (isLoading) {
		return <RestrictedShareCard icon={<ActivityIndicator />} />;
	}

	// Early return if no data
	if (!data) {
		return null;
	}

	const restrictions: TRestrictions = {
		isTipsRestricted: internalShareEntity.isTipRestricted(sharedContent, user),
		isInvestRestricted: internalShareEntity.isDealRestricted(sharedContent, user),
		isPostUnavailable: internalShareEntity.isPostUnavailable(community, sharedContent, user),
		isPostInAccessible: internalShareEntity.isPostInAccessible(sharedContent, community),
		isCommunityMember: internalShareEntity.isCommunityMember(community, sharedContent, user),
	};

	const onUpdateDealSettings = async (enabled: boolean) => {
		if (user.features) {
			const features = { ...user.features, invest: { ...(user.features?.invest || {}), owner: enabled } };

			await updateUser(
				{ features },
				{
					onSuccess: () => {
						showInfo({
							title: t('Invest Feature Enabled'),
							subtitle: t('Manage this in Settings'),
						});
					},
				},
			);
			analytics.trackEvent('InvestFeaturePreferenceChanged', { invest_feature_hidden: !enabled });
		}
	};

	const handleCardClick = async () => {
		const isCommunityJoiningMandatory = internalShareEntity.isCommunityJoiningMandatory(
			internalSharedContentOrienteering?.type as IInternalShare['type'],
		);

		if (
			(restrictions.isPostInAccessible || !restrictions.isCommunityMember) &&
			isCommunityJoiningMandatory &&
			!!community
		) {
			setShowDialog(true);
			return;
		}

		if (restrictions.isInvestRestricted && internalSharedContentOrienteering?.type === 'deal_post') {
			await onUpdateDealSettings(true);
		}

		onClick?.();
	};

	const restrictedCard = getRestrictedCard({
		clearInternalShare,
		restrictions,
		shareType,
		data,
		isDeleted,
		isError,
		t,
		communityDefinition: community?.definition,
		communityMembershipType: community?.membershipType,
	});

	if (restrictedCard) {
		return restrictedCard;
	}

	const handleJoinNetwork = () => {
		if (community) {
			joinNetwork(community, {
				onSuccess: async () => {
					if (restrictions.isInvestRestricted && internalSharedContentOrienteering?.type === 'deal_post') {
						await onUpdateDealSettings(true);
					}
					onClick?.();
					setShowDialog(false);
				},
			});
		}
	};

	const isSpecialCard = data.compactEvent?.startDate || data.compactEvent?.endDate || data.type === 'tip';

	return (
		<>
			<BlockLink
				className={clsx(styles.card, isSpecialCard && styles.card_small, containerClassName)}
				onClick={handleCardClick}
			>
				<div className={styles.gradientBackground} />
				<div className={clsx(styles.content, isSpecialCard && styles.content_small)}>
					<ShareCardContentIcon data={data} />
					<div className={styles.information}>
						<ShareCardSourceDisplay data={data} />
						{data.title && (
							<div className={styles.title} title={data.title}>
								{truncate(data.title, { length: MAX_AVAILABLE_CHARS, omission: '...' })}
							</div>
						)}
						{data.subtitle && (
							<div className={styles.subtitle} title={data.subtitle}>
								{data.subtitle}
							</div>
						)}
					</div>
				</div>
				{clearInternalShare && <ShareCardCloseButton onClick={clearInternalShare} />}
			</BlockLink>
			<Modal
				cancelTitle={t('Cancel')}
				confirmIcon={<RightArrowIcon />}
				confirmTitle={t('Join Network')}
				isConfirmLoading={isJoiningNetwork}
				subTitle={t('This post is only visible to members of the {{title}} network.', { title: community?.name })}
				title={t('Join the {{title}} network to view', { title: community?.name })}
				variant="medium"
				visible={showDialog}
				onCancel={() => setShowDialog(false)}
				onConfirm={handleJoinNetwork}
			/>
		</>
	);
};

export type TSharedContentType = {
	type: string;
	image?: string;
	source?: string;
	title?: string;
	subtitle?: string;
	compactEvent?: {
		startDate?: string;
		endDate?: string;
	};
};

export interface IShareableCardProps {
	clearInternalShare?: () => void;
	containerClassName?: string;
	sharedContent: string;
	onClick?: () => void;
}

export default memo(ShareableCard);
