import {
	Avatar,
	ChaptersIcon,
	DealsIcon,
	EventsIcon,
	LampIconSolid,
	MemberGroupIcon,
	NetworkConnectionIcon,
	NetworkFilledIcon,
} from '@ui-kit';
import styles from './ShareableCard.module.css';
import EventAvatar from '@ui-modules/events/components/EventAvatar';
import type { TSharedContentType } from './ShareableCard';
import type { TEvent } from '@typings';

const ShareCardContentIcon: React.FC<{ data: TSharedContentType }> = ({ data }) => {
	if (!data) return null;

	switch (data.type) {
		case 'discussion':
			return <Avatar imageUri={data.image} outline={false} size={34} />;
		case 'network_connection':
		case 'network':
			return <NetworkFilledIcon className={styles.icon} />;
		case 'group':
			return data.image ? (
				<Avatar imageUri={data.image} outline={false} size={34} />
			) : (
				<MemberGroupIcon className={styles.icon} />
			);
		case 'chapter':
			return data.image ? (
				<Avatar imageUri={data.image} outline={false} size={34} />
			) : (
				<ChaptersIcon className={styles.icon} />
			);
		case 'network_connection_post':
			return <NetworkConnectionIcon className={styles.icon} />;
		case 'deal':
		case 'deal_post':
			return <DealsIcon className={styles.icon} />;
		case 'event':
		case 'meeting':
			return data.compactEvent?.startDate || data.compactEvent?.endDate ? (
				<div className={styles.eventAvatar}>
					<EventAvatar event={data.compactEvent as TEvent} />
				</div>
			) : (
				<EventsIcon className={styles.icon} />
			);
		case 'tip':
			return data.image ? (
				<div className={styles.tipImage} style={{ backgroundImage: `url(${data.image})` }} />
			) : (
				<div className={styles.tipWrapper}>
					<LampIconSolid fill="var(--color-primaryDarkGray)" height={34} width={34} />
				</div>
			);
		default:
			return null;
	}
};

export default ShareCardContentIcon;
