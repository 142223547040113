import { NOTIFICATION_SETTING_CHANNEL } from '@constants';
import type { TCommunityNotificationSetting, TSupportedChannel } from '@typings';
import { ActivityIndicator, Gap } from '@ui-kit';
import { useNetworksOrChaptersNotificationSettings } from '@ui-modules/settings/hooks/useNetworksNotificationSettings';
import { useCollapsedNotificationsSettings } from '@ui-modules/settings/hooks/useNotificationSettingsVisibility';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { isArray, without } from '@utils';
import SettingsCard from '../SettingsCard';

const NetworksSettingsCard = ({ settings }: INetworksSettingsCardProps) => {
	const { notificationSettings, isNotificationSettingsLoading } = useNetworksOrChaptersNotificationSettings('network');
	const { collapsedNotificationsSettings, addCollapsedNotificationsSettings, removeCollapsedNotificationsSettings } =
		useCollapsedNotificationsSettings();
	const { enabled: dealsEnabled } = useDealsEnabled();

	if (isNotificationSettingsLoading) return <ActivityIndicator size="medium" type="fit" />;
	if (!notificationSettings) return null;
	return (
		<div>
			{notificationSettings.map((network) => {
				const networkIdentifier = `Networks_${network.communityId}`;
				const showNewCommentForModeratorSettings =
					!isArray(network.membershipModeratorType) || network?.membershipModeratorType?.length > 0;
				const showNetworkConnectionSettings =
					!!network.networkConnectionsName && network.communityFeatures.includes('network_connections');
				const showDealsSettings = dealsEnabled && network.communityFeatures.includes('invest');

				const { initialSettings, isInitialExpanded } = getIndividualNetworkSettings(
					network.notificationSettings,
					settings,
					showNewCommentForModeratorSettings,
					showNetworkConnectionSettings,
					showDealsSettings,
				);

				const isDefaultExpanded = isInitialExpanded || collapsedNotificationsSettings.includes(networkIdentifier);

				const settingsArray = settings.map((setting) => ({
					...setting,
					name: setting.name.replace(
						/\[(networkConnectionName|networkConnection)\]/g,
						String(network.networkConnectionsName),
					),
				}));

				return (
					// Key will also be used to re-render the collapse to be the same as it is on the back-end [@hrant.abrahamyan]
					<div key={network.communityId + isDefaultExpanded}>
						<SettingsCard
							creationParams={{ sourceId: network.communityId, constraint: 'community' }}
							groupName={'Networks'}
							initialSettings={initialSettings}
							isDefaultExpanded={isDefaultExpanded}
							settingsArray={settingsArray}
							title={network.communityName}
							onChangeCollapsed={(collapsed) =>
								collapsed
									? addCollapsedNotificationsSettings(networkIdentifier)
									: removeCollapsedNotificationsSettings(networkIdentifier)
							}
						/>
						<Gap gap={12} />
					</div>
				);
			})}
		</div>
	);
};

type TSettingOption = {
	name: string;
	options: TSupportedChannel[];
	notificationId: string;
	group: string | null;
};

export interface INetworksSettingsCardProps {
	settings: TSettingOption[];
}

const getIndividualNetworkSettings = (
	notificationSettings: TCommunityNotificationSetting[],
	settings: TSettingOption[],
	showNewCommentForModeratorSettings: boolean,
	showNetworkConnectionSettings: boolean,
	showDealsSettings: boolean,
) => {
	const networkSettings = notificationSettings.map(({ id, notificationId, channels }) => ({
		id,
		notificationId,
		options: without(channels, NOTIFICATION_SETTING_CHANNEL.chat),
	}));
	const initialSettings = settings
		.map((defaultsSetting, index) => {
			const initialSetting = networkSettings.find(
				(settingsItem) => settingsItem.notificationId === defaultsSetting.notificationId,
			) || {
				id: null,
				notificationId: settings[index].notificationId,
				options: without(settings[index].options, NOTIFICATION_SETTING_CHANNEL.chat),
			};
			return initialSetting;
		})
		.filter((settings) =>
			// T21C-6543: hide "All replies in your Network" network notification settings if no permissions
			(settings.notificationId === 'new_comment_for_moderator' && !showNewCommentForModeratorSettings) ||
			// T21C-6615: hide Network Connection settings if no community feature, T21C-7030: Adapt FE to work with new like notifications
			([
				'network_connection_created',
				'network_connection_reply_created',
				'new_network_connection_post_like',
				'new_network_connection_reply_like',
			].includes(settings.notificationId) &&
				!showNetworkConnectionSettings) ||
			(['new_deal_post_like', 'new_deal_reply_like'].includes(settings.notificationId) && !showDealsSettings)
				? false
				: true,
		);

	const isNew = initialSettings.every((setting) => setting.id === null);
	const isSomeEnabled = initialSettings.some((setting) => !!setting.options.length);

	return { initialSettings, isInitialExpanded: isNew || isSomeEnabled };
};

export default NetworksSettingsCard;
