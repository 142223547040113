import styles from '@ui-modules/share/components/ShareableCard/ShareableCard.module.css';
import { CloseIcon } from '@ui-kit';

const ShareCardCloseButton = ({ onClick }: IShareCardCloseButtonProps) => (
	<button className={styles.closeButton} onClick={onClick}>
		<CloseIcon fill="#333" height={8} width={8} />
	</button>
);

export interface IShareCardCloseButtonProps {
	onClick: () => void;
}

export default ShareCardCloseButton;
