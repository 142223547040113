import EventFilePicker from '@ui-modules/events/components/EventFilePicker';
import { useCallback, useNotification, useTranslation } from '@hooks';
import type { TMeatballMenuOption } from '@ui-kit';
import { FormSection } from '@ui-kit';
import { Input, Gap, SpacedGroup, FileListItem, DeleteIcon, EyeClosedIcon, EyeIcon } from '@ui-kit';
import { useUploadMediaObjectMutation } from '@ui-modules/files/hooks/useUploadMediaObjectMutation';
import { useUploadMediaObjectSetViewerOnly } from '@ui-modules/files/hooks/useUploadMediaObjectSetViewerOnly';
import { extractMetaFromMediaObject } from '@ui-kit/components/FileListItem/FileListItem';
import type { TFile, TMediaObject, TNetworkConnection } from '@typings';
import { compact } from '@utils';
import { FileEntity } from '@utils';
import styles from './AttachmentsSection.module.css';
import type { TNetworkConnectionFormValues } from '../../ConnectionForm.schema';
import { connectionFormFields } from '../../ConnectionForm.schema';
import { useGetNetworkConnectionFilesQuery } from '@ui-modules/connections/hooks/useGetNetworkConnectionFilesQuery';
import type { FormikProps } from 'formik';

export const AttachmentsSection = ({ formProps, networkConnectionId }: IAttachmentsSectionProps) => {
	// Dependencies.
	const { t } = useTranslation();
	const { showError } = useNotification();

	// Data.
	const { data: files } = useGetNetworkConnectionFilesQuery(networkConnectionId);

	const {
		errors,
		values: {
			files: mediaObjects = [],
			videoUrl,
			deletedFiles = [],
			makeFileReadOnlyActions = [],
			makeFileDownloadableActions = [],
		},
		setFieldError,
		setFieldValue,
	} = formProps;

	// Mutations.
	const { mutate: uploadFile, isLoading } = useUploadMediaObjectMutation({
		onSuccess: (mediaObject) => setFieldValue(connectionFormFields.FILES, [...mediaObjects, mediaObject]),
	});
	const { mutateAsync: updateMediaObjectSetViewerOnly } = useUploadMediaObjectSetViewerOnly({
		networkConnectionId: networkConnectionId as string,
		folderId: null,
	});

	// Predicates.
	const isAllFilesDeleted = mediaObjects.length + (files?.length || 0) === deletedFiles.length;
	const isFileWillBeViewOnly = (file: TFile) =>
		makeFileReadOnlyActions.includes(file.mediaObject.id)
			? true
			: makeFileDownloadableActions.includes(file.mediaObject.id)
				? false
				: !!file.mediaObject.openInViewerOnly;

	// Media object actions.
	const onRemoveMediaObject = useCallback(
		(id: string) => {
			setFieldValue(
				connectionFormFields.FILES,
				mediaObjects.filter((media) => media.id !== id),
			);
		},
		[setFieldValue, mediaObjects],
	);
	const onChangeMediaObjectReadability = (id: string, openInViewerOnly: boolean) => {
		updateMediaObjectSetViewerOnly({ fileId: id, isViewerOnly: openInViewerOnly })
			.then(() => {
				setFieldValue(
					connectionFormFields.FILES,
					mediaObjects.map((media) => {
						if (media.id === id) {
							return { ...media, openInViewerOnly: openInViewerOnly };
						}
						return media;
					}),
				);
			})
			.catch(showError);
	};

	// File actions.
	const deleteFromMakeFileDownloadableActions = (mediaObjectId: TMediaObject['id']) => {
		const newMakeFileDownloadableActions = makeFileDownloadableActions.filter(
			(actionFileId) => actionFileId !== mediaObjectId,
		);
		if (newMakeFileDownloadableActions.length !== makeFileDownloadableActions.length) {
			setFieldValue(connectionFormFields.MAKE_FILE_DOWNLOADABLE_ACTIONS, newMakeFileDownloadableActions);
		}
	};
	const deleteFromMakeFileReadOnlyActions = (mediaObjectId: TMediaObject['id']) => {
		const newMakeFileReadOnlyActions = makeFileReadOnlyActions.filter((actionFileId) => actionFileId !== mediaObjectId);
		if (newMakeFileReadOnlyActions.length !== makeFileReadOnlyActions.length) {
			setFieldValue(connectionFormFields.READ_ONLY_FILES_ACTIONS, newMakeFileReadOnlyActions);
		}
	};
	const onSetFileDeleted = (file: TFile) => {
		setFieldValue(connectionFormFields.DELETED_FILES, [...deletedFiles, file.id]);
		deleteFromMakeFileDownloadableActions(file.mediaObject.id);
		deleteFromMakeFileReadOnlyActions(file.mediaObject.id);
	};
	const onSetFileReadOnly = (mediaObjectId: TMediaObject['id']) => {
		setFieldValue(connectionFormFields.READ_ONLY_FILES_ACTIONS, [...makeFileReadOnlyActions, mediaObjectId]);
		deleteFromMakeFileDownloadableActions(mediaObjectId);
	};
	const onSetFileDownloadable = (mediaObjectId: TMediaObject['id']) => {
		setFieldValue(connectionFormFields.MAKE_FILE_DOWNLOADABLE_ACTIONS, [...makeFileDownloadableActions, mediaObjectId]);
		deleteFromMakeFileReadOnlyActions(mediaObjectId);
	};

	return (
		<>
			<FormSection bordered={false} title={t('File Attachments (optional)')}>
				<EventFilePicker
					className={styles.fileSelector}
					description={t('Consider attaching a deck in PDF format.')}
					isLoading={isLoading}
					title={t('Attach files')}
					onUpload={(file) => uploadFile({ file })}
				/>
				{(mediaObjects?.length || files?.length) && !isAllFilesDeleted ? (
					<>
						<Gap gap={24} />
						<SpacedGroup className={styles.filesList} direction="vertical" gap={10}>
							{mediaObjects.map((mediaObject) => {
								const fileMenuOptions: TMeatballMenuOption[] = compact([
									mediaObject.mimeType === 'application/pdf' &&
										!mediaObject.openInViewerOnly && {
											text: t('Make Read Only'),
											icon: <EyeClosedIcon height={16} width={14} />,
											onClick: () => onChangeMediaObjectReadability(mediaObject.id, true),
											type: 'solid',
										},

									mediaObject.mimeType === 'application/pdf' &&
										mediaObject.openInViewerOnly && {
											text: t('Make Downloadable'),
											icon: <EyeIcon height={16} width={14} />,
											onClick: () => onChangeMediaObjectReadability(mediaObject.id, false),
											type: 'solid',
										},
									{
										text: t('Delete File'),
										icon: <DeleteIcon height={16} width={14} />,
										onClick: () => onRemoveMediaObject(mediaObject.id),
										type: 'destructive',
									},
								]);
								return (
									<FileListItem
										fileMenuOptions={fileMenuOptions}
										key={mediaObject.id}
										meta={extractMetaFromMediaObject(mediaObject)}
										openInViewerOnly={mediaObject.openInViewerOnly}
										type="fit-card"
										viewerModeText={t('read only')}
									/>
								);
							})}
							{files?.map((file) => {
								if (!deletedFiles.includes(file.id)) {
									const isWillBeViewOnly = isFileWillBeViewOnly(file);

									const fileMenuOptions: TMeatballMenuOption[] = compact([
										FileEntity.isPdfFile(file) &&
											!isWillBeViewOnly && {
												text: t('Make Read Only'),
												icon: <EyeClosedIcon height={16} width={14} />,
												onClick: () => onSetFileReadOnly(file.mediaObject.id),
												type: 'solid',
											},

										FileEntity.isPdfFile(file) &&
											isWillBeViewOnly && {
												text: t('Make Downloadable'),
												icon: <EyeIcon height={16} width={14} />,
												onClick: () => onSetFileDownloadable(file.mediaObject.id),
												type: 'solid',
											},
										{
											text: t('Delete File'),
											icon: <DeleteIcon height={16} width={14} />,
											onClick: () => onSetFileDeleted(file),
											type: 'destructive',
										},
									]);

									return (
										<FileListItem
											fileMenuOptions={fileMenuOptions}
											key={file.id}
											meta={extractMetaFromMediaObject({
												...file.mediaObject,
												owner: file.owner,
											} as unknown as TMediaObject)}
											openInViewerOnly={isWillBeViewOnly}
											type="fit-card"
											viewerModeText={t('read only')}
										/>
									);
								}
								return null;
							})}
						</SpacedGroup>
					</>
				) : null}
			</FormSection>
			{!formProps.values?.hiddenFields?.includes(connectionFormFields.VIDEO_URL) ? (
				<Input
					autoCapitalize="none"
					errorMessage={errors.videoUrl}
					label={t('Video URL (optional)')}
					placeholder={t('e.g. {{example}}', { example: 'https://www.youtube.com/kjhbc976gsdfbf2' })}
					value={videoUrl}
					onChange={(e) => {
						setFieldValue(connectionFormFields.VIDEO_URL, e.target.value);
						setFieldError(connectionFormFields.VIDEO_URL, undefined);
					}}
				/>
			) : null}
		</>
	);
};

interface IAttachmentsSectionProps {
	formProps: FormikProps<TNetworkConnectionFormValues>;
	networkConnectionId?: TNetworkConnection['id'];
}
