import { InternalShareEntity } from '@utils';
import type { IInternalShare, TCommunity, TDeal } from '@typings';
import { useGetDealQuery } from '@ui-modules/deals/hooks/useGetDealQuery';
import { useGetMeetingQuery } from '@ui-modules/events/hooks/useGetMeetingQuery';
import { useGetEventQuery } from '@ui-modules/events/hooks/useGetEventQuery';
import { useNetworkConnectionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionQuery';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { useActivityQuery } from '@ui-modules/feed/hooks/useActivityQuery';
import { useFeedActivityQuery } from '@ui-modules/feed/hooks/useFeedActivityQuery';

const internalShareEntity = new InternalShareEntity();

export const useSharedContent = (sharedContent: string | null | undefined): TUseSharedContent => {
	const orientedShare = internalShareEntity.internalSharedContentOrienteering(sharedContent);

	const {
		data: activity,
		isLoading: isFeedActivityLoading,
		isFetched: isActivityFetched,
	} = useFeedActivityQuery(orientedShare?.id, undefined, {
		enabled: orientedShare?.type === 'discussion',
		readonly: true,
	});

	const {
		isLoading: isStreamDiscussionLoading,
		isDeleted: isStreamDiscussionDeleted,
		isFetched: isStreamDiscussionFetched,
	} = useActivityQuery(activity?.foreign_id as string, {
		enabled: orientedShare?.type === 'discussion' && isActivityFetched && !!activity?.foreign_id,
	});

	const isCommunityShareType =
		orientedShare?.type === 'chapter' ||
		orientedShare?.type === 'group' ||
		orientedShare?.type === 'network' ||
		orientedShare?.type === 'deal' ||
		orientedShare?.type === 'network_connection';

	const communityId = isCommunityShareType ? orientedShare?.id : activity?.collectionCommunityReference?.id;

	const {
		data: networkConnection,
		isLoading: isNetworkConnectionLoading,
		isError: isNetworkConnectionError,
		isFetched: isNetworkConnectionFetched,
	} = useNetworkConnectionQuery(orientedShare?.id as string, {
		enabled: orientedShare?.type === 'network_connection_post',
	});

	const {
		data: deal,
		isLoading: isDealLoading,
		isError: isDealError,
		isFetched: isDealFetched,
	} = useGetDealQuery(orientedShare?.id as TDeal['id'], {
		enabled: orientedShare?.type === 'deal_post',
	});

	const {
		data: community,
		isLoading: isCommunityLoading,
		isFetched: isCommunityFetched,
	} = useCommunityQuery(communityId ?? networkConnection?.community.id ?? deal?.community?.id, {
		// Enable query when we have either a direct community ID or a community ID from a network connection or from deal
		enabled: !!communityId || !!networkConnection?.community.id || !!deal?.community?.id,
	});

	const {
		data: meeting,
		isLoading: isMeetingLoading,
		isError: isMeetingError,
		isDeleted: isMeetingDeleted,
		isFetched: isMeetingFetched,
	} = useGetMeetingQuery(orientedShare?.id as string, {
		enabled: orientedShare?.type === 'meeting',
	});

	const {
		data: event,
		isLoading: isEventLoading,
		isError: isEventError,
		isDeleted: isEventDeleted,
		isFetched: isEventFetched,
	} = useGetEventQuery(orientedShare?.id, {
		enabled: orientedShare?.type === 'event' || orientedShare?.type === 'meeting',
	});

	const {
		data: tip,
		isLoading: isTipLoading,
		isError: isTipError,
		isDeleted: isTipDeleted,
		isFetched: isTipFetched,
	} = useTipQuery(orientedShare?.id, undefined, {
		enabled: orientedShare?.type === 'tip',
	});

	const isActivityLoading =
		orientedShare?.type === 'discussion' ? isFeedActivityLoading || isStreamDiscussionLoading : false;

	const data = internalShareEntity.constructSharedContentData(
		{
			event,
			meeting,
			deal,
			networkConnection,
			community,
			activity,
			tip,
		},
		orientedShare,
	);

	return {
		data,
		isLoading:
			isCommunityLoading &&
			isDealLoading &&
			isNetworkConnectionLoading &&
			isMeetingLoading &&
			isEventLoading &&
			isTipLoading &&
			isActivityLoading,
		isError: isNetworkConnectionError || isDealError || isMeetingError || isEventError || isTipError,
		isFetched:
			isActivityFetched ||
			isStreamDiscussionFetched ||
			isNetworkConnectionFetched ||
			isDealFetched ||
			isCommunityFetched ||
			isMeetingFetched ||
			isEventFetched ||
			isTipFetched,
		community,
		isDeleted: isStreamDiscussionDeleted || isMeetingDeleted || isEventDeleted || isTipDeleted,
	};
};

type TUseSharedContent = {
	data: IInternalShare;
	isLoading: boolean;
	isError: boolean;
	isFetched: boolean;
	community?: TCommunity;
	isDeleted?: boolean;
};
