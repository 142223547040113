import { useMutation, useQueryClient, useService, useNotification } from '@hooks';
import type { AxiosError } from 'axios';
import type { TNotificationSettingCreationRequestDto, TNotificationSetting, TSupportedChannel } from '@typings';

export const useCreateNotificationSetting = (groupName?: string) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const queryClient = useQueryClient();
	const { showUnknownError } = useNotification();

	return useMutation<TNotificationSetting, AxiosError, TNotificationSettingCreationRequestDto>(
		['notifications.createNotificationSetting'],
		async (notificationSetting) => await api.notificationSettings.createNotificationsSettings(notificationSetting),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(
					groupName === 'Chapters' || groupName === 'Networks'
						? reactQuery.queryKeys.getCommunitiesNotificationSettings()
						: ['notifications.getSettings'],
				);
				queryClient.invalidateQueries(reactQuery.queryKeys.getNotificationSettings());
				queryClient.invalidateQueries(reactQuery.queryKeys.getDefaultNotificationSettings());
			},
			onError: async (error, data) => {
				// Handle case if record is already created. For example if settings are updated form different devices without refreshing app
				if (error?.response?.status === 422) {
					const result = await api.notificationSettings.getNotificationsSettings();
					const setting = result.find((item) => item.notificationId === data.notificationId);
					if (setting) {
						await api.notificationSettings.patchNotificationsSettings(setting.id, {
							...data,
							channels: data?.channels as TSupportedChannel[],
						});
						queryClient.invalidateQueries(
							groupName === 'Chapters'
								? reactQuery.queryKeys.getCommunitiesNotificationSettings()
								: ['notifications.getSettings'],
						);
					}
				} else {
					showUnknownError(error);
				}
				queryClient.invalidateQueries(reactQuery.queryKeys.getNotificationSettings());
				queryClient.invalidateQueries(reactQuery.queryKeys.getDefaultNotificationSettings());
			},
		},
	);
};
