import { useMe, useNavigate, useTranslation } from '@hooks';
import { CommunityEntity, InternalShareEntity } from '@utils';
import styles from './ChaptersShareModalContent.module.css';
import type { TContent } from '@ui-modules/share/components/InternalShareModal/InternalShareModal';
import { useInternalShareContext } from '@ui-modules/share/hooks/useInternalShare';
import { useAllChaptersQuery } from '@ui-modules/groups/hooks/useAllChaptersQuery';
import type { TCommunity } from '@typings';
import { ROUTES } from '@constants';
import { ActivityIndicator } from '@ui-kit';

// @todo separate this and make a new component
import NetworkList from '@ui-modules/share/components/NetworkShareModalContent/NetworkList';

const ChaptersShareModalContent = ({ handleContentChange }: IChaptersShareModalContentProps) => {
	const { internalShare, showInternalShareModal, resetInternalShare } = useInternalShareContext();
	const { user } = useMe();
	const { t } = useTranslation();

	const navigate = useNavigate();
	const internalShareEntity = new InternalShareEntity();

	const { data: chapters, isLoading } = useAllChaptersQuery();

	const availableChapters = chapters?.filter((chapter: TCommunity) =>
		CommunityEntity.shouldShowDiscussionsV2(chapter, user.roles),
	);

	const handleChapterClick = async (chapter: TCommunity) => {
		if (showInternalShareModal) {
			navigate(ROUTES.addPost(chapter.id, internalShareEntity.internalShareToShareableContent(internalShare)), {
				unstable_viewTransition: true,
			});
			resetInternalShare();
			handleContentChange(null);
		}
	};
	return (
		<div>
			{isLoading ? (
				<div className={styles.chaptersShare__loadingWrapper}>
					<ActivityIndicator />
				</div>
			) : (
				<NetworkList
					data={availableChapters as TCommunity[]}
					iconType="network"
					titleKey={`${t('New Discussion In')}...`}
					onClick={handleChapterClick}
				/>
			)}
		</div>
	);
};

export interface IChaptersShareModalContentProps {
	handleContentChange: (content: TContent) => void;
}

export default ChaptersShareModalContent;
