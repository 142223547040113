import { useEffect, useEvent, useService } from '@hooks';

const KEY = 'GlobalSearchExpandableResultsBlock3';
const formatValue = (def: string, searchQuery: string) => `${def}/${searchQuery}`;

export const useExpandableResultsBlockPersist = () => {
	const reactQuery = useService('ReactQueryService');

	const removeSessionStorageExpandableDefinition = useEvent(() => {
		window.sessionStorage.removeItem(KEY);
	});

	// we need to remove expandable flag before unload page, cause session operations are synchronous
	useEffect(() => {
		window.addEventListener('beforeunload', removeSessionStorageExpandableDefinition);
		return () => {
			window.removeEventListener('beforeunload', removeSessionStorageExpandableDefinition);
		};
	}, []);

	// when we click "Show all" button, we set flag of definition to storage
	// thus, when we go to the page of the found entity, when we return, we will have an expanded menu
	const setExpandableStatusToStorage = (def: string, searchQuery: string) => {
		const value = window.sessionStorage.getItem(KEY);
		const current = value ? JSON.parse(value) : [];
		const next = current.concat(formatValue(def, searchQuery));
		const nextValue = JSON.stringify(next);
		window.sessionStorage.setItem(KEY, nextValue);
	};
	const getExpandableStatusFromStorage = (def: string, searchQuery: string) => {
		const isSearchDataExist = reactQuery.queryClient.getQueryData(reactQuery.queryKeys.searchByIndex(def, searchQuery));
		if (!isSearchDataExist) return false;
		const value = window.sessionStorage.getItem(KEY);
		const current = value ? JSON.parse(value) : [];
		return current.includes(formatValue(def, searchQuery));
	};

	return { setExpandableStatusToStorage, getExpandableStatusFromStorage, removeSessionStorageExpandableDefinition };
};
