import { useService } from '@services';
import { useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { TDeal } from '@typings';
import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { httpCodes } from '../../../services/abstracts/AbstractAxiosService/httpCodes';

export const useGetDealQuery = (
	dealId: TDeal['id'] | undefined,
	options?: UseQueryOptions<TDeal, AxiosError, TDeal>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const query = useQuery<TDeal, AxiosError>(
		reactQuery.queryKeys.getDeal(dealId),
		async () => await api.invest.getDealById(dealId as string),
		{
			enabled: !!dealId,
			...options,
			onError(error) {
				reactQuery.refreshWaiverIfDealsForbiddenError(error);
				options?.onError?.(error);
			},
			refetchOnMount: 'always',
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
		},
	);

	return { ...query, isDeleted: query?.error?.response?.status === httpCodes?.NOT_FOUND };
};
