import { useCheckTextOnMaliciousUrls, useMutation, useNotification, useService, useTranslation } from '@hooks';
import type { TActivity, TAttachments, TComment, TCommentReactionData, TCommunity, TEvent } from '@typings';
import { useAddChildReactionMutation } from '@ui-modules/feed/hooks/useAddChildReactionMutation';
import { useAddReactionMutation } from '@ui-modules/feed/hooks/useAddReactionMutation';
import { useEditReactionMutation } from '@ui-modules/feed/hooks/useEditReactionMutation';
import { ActivityEntity, stripText } from '@utils';
import { filterAttachments, filterNestedArrs } from './CommentControls.utils';
import type { MutationOptions } from '@tanstack/react-query';

export const useSendCommentMutation = (
	{ isReply, activity, reaction, community }: IUseCommentControlsStateProps,
	mutationOptions?: MutationOptions<string, Error, IUseSendCommentMutationPayload>,
) => {
	const { t } = useTranslation();

	const { mutateAsync: addComment } = useAddReactionMutation(activity);
	const { mutateAsync: addReply } = useAddChildReactionMutation(activity);
	const { mutateAsync: onEditReaction } = useEditReactionMutation();
	const checkTextOnMaliciousUrls = useCheckTextOnMaliciousUrls({ throwError: true });
	const feedService = useService('FeedService');
	const analytics = useService('AnalyticsService');
	const { showUnknownError, showError } = useNotification();

	return useMutation<string, Error, IUseSendCommentMutationPayload>(
		async ({ attachments, comment, eventId, sharedContent }) => {
			await checkTextOnMaliciousUrls(comment);

			const images = filterAttachments(attachments.filter((attachment) => attachment.fileType === 'image'));
			const videos = filterAttachments(attachments.filter((attachment) => attachment.fileType === 'video'));
			const files = filterAttachments(attachments.filter((attachment) => attachment.fileType === 'file'));
			const imagesUrl = images.newAttachments?.length ? await feedService.uploadImages(images.newAttachments) : [];
			const videosUrl = videos.newAttachments?.length ? await feedService.uploadVideos(videos.newAttachments) : [];
			const filesUrl = files.newAttachments?.length ? await feedService.uploadDocuments(files.newAttachments) : [];

			const commentData: TCommentReactionData = {
				text: stripText(comment),
				images: imagesUrl as string[],
				videos: videosUrl as string[],
				files: filesUrl as string[],
				time: new Date().toISOString(),
				event: eventId ?? undefined,
				sharedContent: sharedContent ? [sharedContent] : undefined,
			};

			const isEdit = reaction?.id && !isReply;
			if (isEdit) {
				onEditReaction({
					comment: reaction,
					text: stripText(comment),
					activityId: activity.id,
					images: filterNestedArrs([imagesUrl, images?.uploadedAttachments?.map((item) => item.url)]) as string[],
					videos: filterNestedArrs([videosUrl, videos?.uploadedAttachments?.map((item) => item.url)]) as string[],
					files: filterNestedArrs([filesUrl, files?.uploadedAttachments?.map((item) => item.url)]) as string[],
					event: eventId ?? undefined,
					sharedContent: sharedContent ? [sharedContent] : undefined,
				});

				return reaction.id;
			} else if (isReply) {
				if (!reaction) throw new Error('No reaction provided for reply');

				const reply = await addReply({ reaction: reaction, comment: commentData, community });
				return reply.id;
			} else {
				const comment = await addComment({ comment: commentData, community });
				return comment.id;
			}
		},
		{
			...mutationOptions,
			onSuccess(data, variables, context) {
				const activityEntity = new ActivityEntity(activity);
				if (activityEntity.isFromLearn()) {
					analytics.trackEvent('LearnInteractions', {
						interaction_type: 'commented by user',
					});
				}
				mutationOptions?.onSuccess?.(data, variables, context);
			},
			onError(error, variables, context) {
				const parsedErrorMessage = JSON.parse(JSON.stringify(error)); // Handle error that returned from streamClient to show error details.
				parsedErrorMessage?.response?.data?.detail
					? showError({ title: t('Error uploading attachment'), subtitle: parsedErrorMessage?.response?.data?.detail })
					: showUnknownError(error as Error);

				mutationOptions?.onError?.(error, variables, context);
			},
		},
	);
};

interface IUseSendCommentMutationPayload {
	attachments: TAttachments[];
	comment: string;
	eventId: TEvent['id'] | null | undefined;
	sharedContent: string | null | undefined;
}

interface IUseCommentControlsStateProps {
	isReply?: boolean;
	activity: TActivity;
	reaction?: TComment;
	community?: TCommunity;
}
