import { clsx } from '@utils';
import styles from './ActivityIndicator.module.css';

/** Spinner to show loading state.
 *  Showed and small by default. Available sizes: small=24, medium=36, large=48.
 *  Can be hidden if pass hidden=true.
 * */
const ActivityIndicator = ({
	hidden = false,
	invisible = false,
	size = 'small',
	type = 'inline',
	testID,
	color = 'rgba(0, 0, 0, 1)',
	className,
}: IActivityIndicatorProps) => {
	return hidden ? null : (
		<div
			className={clsx(
				styles.activityIndicator__wrapper,
				invisible && styles.activityIndicator__wrapper_invisible,
				className,
			)}
			data-type={type}
		>
			<div
				aria-busy={!hidden && !invisible}
				className={styles.container}
				data-testid={testID}
				hidden={invisible}
				role="progressbar"
			>
				<span className={styles.spinner} style={{ width: sizeMap[size], height: sizeMap[size] }}>
					<span className={styles.bottom} style={{ padding: sizeMap[size] / 2 }}></span>
					<span className={styles.top} style={{ borderTopColor: color, padding: sizeMap[size] / 2 }}></span>
				</span>
			</div>
		</div>
	);
};

const sizeMap: Record<Required<IActivityIndicatorProps>['size'], number> = {
	extraSmall: 16,
	small: 24,
	medium: 36,
	large: 48,
};

export type TActivityIndicatorType = 'inline' | 'fit';
export interface IActivityIndicatorProps {
	/** Should it be hidden from the document. Default=false. */
	hidden?: boolean;
	/** Should it has visibility=hidden. In contrast to the 'hidden' prop it takes place in the DOM. Default=false. */
	invisible?: boolean;
	/** Radius of the spinner. Available sizes: small=24, medium=36, large=48.  */
	size?: 'extraSmall' | 'small' | 'medium' | 'large';
	/** Test ID */
	testID?: string;
	/** How to render a spinner. 'inline' if is a simple inline element, or 'fit' if it part of list or search results. */
	type?: TActivityIndicatorType;
	className?: string;
	/** Color of the spinner. Default 'black'. */
	color?: string;
}

export default ActivityIndicator;
