import RichMessageInputAttachments from '../RichMessageInputAttachments/RichMessageInputAttachments';
import ImagesLightBox from '@ui-modules/files/components/ImagesLightBox';
import { useRef, useMemo } from '@hooks';
import { useOpenFile } from '@ui-modules/files/hooks/useOpenFile';
import type { IRichMessageInputAttachmentsProps } from '../RichMessageInputAttachments/RichMessageInputAttachments';
import type { IImagesLightBoxMethods } from '@ui-modules/files/components/ImagesLightBox/ImagesLightBox';
import type { TRichMessageInputAttachment } from '../RichMessageInputAttachment/RichMessageInputAttachment';

const RichMessageInputAttachmentsWithPreview = ({ attachments }: IRichMessageInputAttachmentsWithPreviewProps) => {
	const imagesLightBoxRef = useRef<IImagesLightBoxMethods>(null);
	const imagesUrls = useMemo(() => filterImageUrls(attachments), [attachments]);
	const { openFileUrl, canOpenMimeType } = useOpenFile();

	const openImageHandler = () => (attachment: TRichMessageInputAttachment) => {
		const initialImageIndex = imagesUrls.findIndex((img: string | undefined) => img === attachment.uri);
		imagesLightBoxRef.current?.show(initialImageIndex);
	};
	const openFileHandler = () => (attachment: TRichMessageInputAttachment) => {
		openFileUrl(attachment.uri);
	};
	const attachmentsWithOnPressHandler = useMemo(
		() =>
			attachments.map((attachment) => {
				const canOpenAsImage = attachment.type === 'image';
				const canOpenAsFile = attachment.type === 'file' && canOpenMimeType(attachment.mimeType);
				return {
					...attachment,
					onPress: canOpenAsImage ? openImageHandler : canOpenAsFile ? openFileHandler : undefined,
				};
			}),
		[attachments],
	);

	return (
		<>
			<RichMessageInputAttachments attachments={attachmentsWithOnPressHandler} />
			<ImagesLightBox imagesUrls={imagesUrls} ref={imagesLightBoxRef} />
		</>
	);
};

const filterImageUrls = (attachments: TRichMessageInputAttachment[]) =>
	attachments.filter(({ type }) => type === 'image').map(({ uri }) => uri || '');

export interface IRichMessageInputAttachmentsWithPreviewProps extends IRichMessageInputAttachmentsProps {}

export default RichMessageInputAttachmentsWithPreview;
