import { useMutation, useNotification, useService, useTranslation } from '@hooks';
import { useFeedContext } from 'react-activity-feed';
import type { TActivity, TComment, TData } from '@typings';
import type { FeedContextValue } from 'react-activity-feed';
import type { MutationOptions } from '@tanstack/react-query';

export const useEditReactionMutation = (options?: MutationOptions<void, Error, TUseEditReactionMutationPayload>) => {
	const { t } = useTranslation();
	const { showSuccess, showUnknownError } = useNotification();
	const feedContext = useFeedContext() as FeedContextValueEnhanced;
	const feedStream = useService('FeedService');

	return useMutation<void, Error, TUseEditReactionMutationPayload>(
		['feedStream.editReaction'],
		async ({ comment, text, videos, images, files, event, sharedContent }) => {
			await feedStream.editReaction(comment.id, { ...comment.data, text, videos, images, files, event, sharedContent });
		},
		{
			...options,
			onSuccess(_, activity, context) {
				const isReply = !!activity.comment.parent;
				showSuccess({ title: isReply ? t('Your reply was edited') : t('Your comment was edited') });

				if (isReply) {
					// Custom update function for replies injected into source code via patch-package [@dmitriy.nikolenko].
					feedContext.updateChildReactionDataInState(
						'comment',
						activity.activityId,
						activity.comment.parent,
						activity.comment.id,
						{
							text: activity.text,
							images: activity.images,
							videos: activity.videos,
							files: activity.files,
							event: activity.event,
							sharedContent: activity.sharedContent,
						},
					);
				} else {
					// Custom update function for replies injected into source code via patch-package [@dmitriy.nikolenko].
					feedContext.updateReactionDataInState('comment', activity.activityId, activity.comment.id, {
						text: activity.text,
						images: activity.images,
						videos: activity.videos,
						files: activity.files,
						event: activity.event,
						sharedContent: activity.sharedContent,
					});
				}
				options?.onSuccess?.(_, activity, context);
			},
			onError(error, variables, context) {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

type TUseEditReactionMutationPayload = TData & {
	comment: TComment;
	activityId: TActivity['id'];
};

/** Due to the patch-package two new custom methods were added to the FeedManager and FeedContext. [@dmitriy.nikolenko] */
type FeedContextValueEnhanced = FeedContextValue & {
	updateChildReactionDataInState: (
		kind: 'comment',
		activityId: TActivity['id'],
		parentCommentId: TComment['id'],
		commentId: TComment['id'],
		commentData: Partial<TComment['data']>,
	) => void;
	updateReactionDataInState: (
		kind: 'comment',
		activityId: TActivity['id'],
		commentId: TComment['id'],
		commentData: Partial<TComment['data']>,
	) => void;
};
