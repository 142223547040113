import { combineRegexps, UrlParser } from '@utils';
import { compact, toString } from 'lodash';

export const makeHtmlContent = (content: string | undefined, mentions: string[] = []) => {
	// Dynamic create pattern to check mentioned users.
	const mentionsPattern = mentions.length ? new RegExp(`(${mentions.join('|')})`, 'g') : undefined;

	return toString(content).replaceAll(
		combineRegexps(compact([UrlParser.parseUrlAndEmailRegexp, mentionsPattern])),
		(urlOrMention: string) => {
			if (mentionsPattern && mentions.includes(urlOrMention)) {
				return `<span class="str-chat__message_mention">${urlOrMention}</span>`;
			} else {
				if (!UrlParser.urlHasKnownDomain(urlOrMention)) return urlOrMention; // return as a text
				const finalUrl = UrlParser.protocolifyUrl(urlOrMention);
				return `<a href="${finalUrl}" class="str-chat__message_link" target="_blank" onclick="event.stopPropagation()">${urlOrMention}</a>`;
			}
		},
	);
};
