import { ActivityIndicator, CloseIcon, When } from '@ui-kit';
import EventItem from '@ui-modules/events/components/EventItem';
import { useNavigate, useTranslation } from '@hooks';
import { useGetEventQuery } from '../../hooks/useGetEventQuery';
import { ROUTES } from '@constants';
import styles from './EventListItemAttachment.module.css';
import type { TEvent } from '@typings';

const EventListItemAttachment = ({ eventId, onRemove, onClick }: IEventListItemAttachmentProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { data: event, isLoading, isFetched } = useGetEventQuery(eventId);

	const onEventPressed = (event: TEvent) => {
		if (onClick) {
			return onClick();
		}
		if (event['@type'] === 'Event') {
			navigate(ROUTES.viewEvent(event.id));
		} else if (event['@type'] === 'Meeting') {
			navigate(ROUTES.viewMeeting(event.id));
		}
	};

	if (!eventId) {
		return null;
	}
	if (isLoading && !isFetched) {
		return (
			<div className={styles.eventLoading}>
				<ActivityIndicator size="small" type="fit" />
			</div>
		);
	}
	if (!event) {
		return (
			<div className={styles.eventMessage__hiddenMessageBox}>
				<span className={styles.eventMessage__hiddenMessage}>{t('This private event/meeting can not be viewed')}</span>
			</div>
		);
	}
	return (
		<div className={styles.event}>
			<EventItem
				event={event}
				hideBanner
				hideChevronIcon
				shouldShowMeetingAction={false}
				onClick={() => event && onEventPressed(event)}
			/>
			<When condition={!!onRemove}>
				<button className={styles.buttonRemove} onClick={() => event && onRemove?.(event)}>
					<CloseIcon height={8} width={8} />
				</button>
			</When>
		</div>
	);
};

export interface IEventListItemAttachmentProps {
	eventId: TEvent['id'] | undefined;
	onRemove?: (event: TEvent) => void;
	onClick?: () => void;
}

export default EventListItemAttachment;
