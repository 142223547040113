import { ActivityIndicator } from '@ui-kit';
import { Page } from '../../components';
import FeedDiscussions from '@ui-modules/feed/components/FeedDiscussions/FeedDiscussions';
import { useNavigate, useResizeObserver, useService, useTranslation } from '@hooks';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { NetworkConnectionEntity } from '@utils';
import { ROUTES } from '@constants';
import styles from './NetworkConnectionDiscussionsPage.module.css';
import type { TNetworkConnection } from '@typings';
import BlockedUserMessage from '@ui-modules/profile/components/BlockedUserMessage';
import { scrollToComment } from '@ui-modules/feed/utils/scrollToComment';

const NetworkConnectionDiscussionsPage = ({
	wrapperRef,
	networkConnection,
	networkConnectionName,
}: IDealDiscussionsPage) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');

	const [{ height: inputBoxHeight }, setObservableElement] = useResizeObserver();
	const streamActivityId = networkConnection.streamActivityId;
	const { data: community, isLoading } = useCommunityQuery(networkConnection.community.id);

	if (isLoading || !community) return <ActivityIndicator type="fit" />;

	const feedSlug = NetworkConnectionEntity.getNetworkConnectionsCommunityFeedSlug(community.slug);
	const goHome = () => navigate(ROUTES.home(), { replace: true });

	return (
		<Page title={t('Discussions | {{pageName}}', { pageName: networkConnection.title })}>
			<div className={styles.networkConnectionDiscussions__wrapper} style={{ paddingBottom: inputBoxHeight }}>
				<BlockedUserMessage
					blockedDescription={t('To view discussions on this {{entity}} by {{userName}}, please unblock them first.', {
						entity: networkConnectionName,
						userName: networkConnection.owner.fullName,
					})}
					blockingDescription={t('You are not allowed to view discussions on this {{entity}}.', {
						entity: networkConnectionName,
					})}
					userId={networkConnection.owner.id}
				>
					<FeedDiscussions
						feedSlug={feedSlug}
						setInputBoxRef={setObservableElement}
						streamActivityId={streamActivityId}
						onCommentLikeToggled={(liked) => {
							if (liked) {
								analytics.trackGenericEvent(
									'{{connectionName}}Interactions',
									{
										interaction_type: 'discussions',
										interaction_action: 'discussion like',
										'{{connectionName}}_id': networkConnection.id,
										'{{connectionName}}_name': networkConnection.title,
									},
									{
										connectionName: networkConnectionName,
									},
								);
							}
						}}
						onCommentSent={(isEditing, commentId) => {
							if (!isEditing) {
								analytics.trackGenericEvent(
									'{{connectionName}}Interactions',
									{
										interaction_type: 'discussions',
										interaction_action: 'discussion comment',
										'{{connectionName}}_id': networkConnection.id,
										'{{connectionName}}_name': networkConnection.title,
									},
									{
										connectionName: networkConnectionName,
									},
								);
							}
							scrollToComment(wrapperRef, isEditing, commentId);
						}}
						onErrorDisplaying={goHome}
					/>
				</BlockedUserMessage>
			</div>
		</Page>
	);
};

interface IDealDiscussionsPage {
	wrapperRef: React.RefObject<HTMLDivElement>;
	networkConnection: TNetworkConnection;
	networkConnectionName: string;
}

export default NetworkConnectionDiscussionsPage;
