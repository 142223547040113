import RichMessageInputActions from './components/RichMessageInputActions';
import RichMessageInputActionsWithPickers from './components/RichMessageInputActionsWithPickers/RichMessageInputActionsWithPickers';
import RichMessageInputAttachments from './components/RichMessageInputAttachments';
import RichMessageInputAttachmentsWithPreview from './components/RichMessageInputAttachmentsWithPreview/RichMessageInputAttachmentsWithPreview';
import RichMessageInputBox from './components/RichMessageInputBox';
import RichMessageInputCollapseHandle from './components/RichMessageInputCollapseHandle';
import { RichMessageAppearanceContextProvider } from './contexts/RichMessageAppearanceContext';
import { useState } from '@hooks';
import styles from './RichMessageInput.module.css';
import type { ReactNode } from 'react';
import type { TRichMessageInputActionAppearance } from './components/RichMessageInputAction';
import clsx from 'clsx';

/** Wrapper around TextInput which adding additional features like attachments, actions, etc.
 * 	Any textarea-like component is passed as children to not to limit the usage of the component.
 * 	Appearance will be automatically set to row when any interaction with the input happens.
 * 	@example
 * 		<RichMessage initialAppearance="row">
 * 			<RichMessage.InputBox isFocused={isFocused} sending={sending} sendDisabled={sendDisabled} onSend={onSend}>
 * 				<RichMessage.Attachments attachments={attachments} />
 * 				<TextInput />
 * 			</RichMessage.InputBox>
 * 			<RichMessage.Actions actions={actions} />
 * 		</RichMessage>
 */
function RichMessageInput({ children, initialAppearance = 'row', innerContainerClassName }: IRichMessageInputProps) {
	const [appearance, setAppearance] = useState<TRichMessageInputActionAppearance>(initialAppearance);

	return (
		<div>
			<div className={clsx(styles.richMessageInput, innerContainerClassName)} data-appearance={appearance}>
				<RichMessageAppearanceContextProvider appearance={appearance} setAppearance={setAppearance}>
					<RichMessageInputCollapseHandle />
					{children}
				</RichMessageAppearanceContextProvider>
			</div>
		</div>
	);
}

RichMessageInput.InputBox = RichMessageInputBox;
RichMessageInput.Attachments = RichMessageInputAttachments;
RichMessageInput.AttachmentsWithPreview = RichMessageInputAttachmentsWithPreview;
RichMessageInput.Actions = RichMessageInputActions;
RichMessageInput.ActionsWithPickers = RichMessageInputActionsWithPickers;

export interface IRichMessageInputProps {
	/** Own TextInput and any other renderings like event or sharing content cards. */
	children?: ReactNode | undefined;
	/** If it is not 'row' but 'column', it will be switched to 'row' with animation after the input is focused or action pressed or handle tapped */
	initialAppearance?: TRichMessageInputActionAppearance;
	innerContainerClassName?: string;
}

export default RichMessageInput;
