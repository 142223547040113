import { FormPageLayout, Page } from '../../components';
import { useTranslation, useMe, useMemo, useEffect, useParams, useLocation, useSearchParams } from '@hooks';
import styles from './PostCreationPage.module.css';
import { ActivityIndicator, CloseIcon, Formik, Gap, When } from '@ui-kit';
import { useMyLifestyleNetworksQuery } from '@ui-modules/networks/hooks/useMyLifestyleNetworksQuery';
import { MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE, MAX_ITEMS_FOR_UPLOAD, ROUTES } from '@constants';
import { usePostCreation } from '@ui-modules/feed/hooks/usePostCreation';
import { transformAttachments } from '@ui-modules/feed/utils/transformAttachments';
import type { TPostCreation } from '@schemas';
import { getPostEditingSchema, getPostCreationSchema } from '@schemas';
import { usePostEditing } from '@ui-modules/feed/hooks/usePostEditing';
import { usePostAttachmentsState } from '@ui-modules/feed/hooks/usePostAttachmentsState';
import { useFeedActivityQuery } from '@ui-modules/feed/hooks/useFeedActivityQuery';
import { useHandleActivityAddedSuccess } from '@ui-modules/feed/hooks/useHandleActivityAddedSuccess';
import PostCreationForm from '@ui-modules/feed/components/PostCreationForm/PostCreationForm';
import PostAttachments from '@ui-modules/feed/components/PostAttachments';
import PostFormInfoBar from '@ui-modules/feed/components/PostFormInfoBar';
import { useMyInvestNetworksQuery } from '@ui-modules/networks/hooks/useMyInvestNetworksQuery';
import { ActivityEntity, extractIdFromIri, noop, sortBy, xor } from '@utils';
import {
	checkInitialAttachmentsChanged,
	checkInitialAttachmentsItemsExceed,
	customDisabled,
	customEditFormDisabled,
} from './utils';
import type { TCommunity } from '@typings';
import ShareableCard from '@ui-modules/share/components/ShareableCard';
import EventListItemAttachment from '@ui-modules/events/components/EventListItemAttachment';
import type { FormikProps } from 'formik';

const PostCreationPage = () => {
	const { user } = useMe();
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const { state = {} }: IPostCreationLocationState = useLocation();
	const { networkId, definition: communityDefinition } = state || {};

	const activityId = useParams().activityId as IPostCreationPageParams['activityId'];
	const sharedActivityId = useParams().sharedActivityId as IPostCreationPageParams['sharedActivityId'];

	const internalShare = searchParams.get('internalShare') as string;
	const { data: activity, isLoading, refetch: refetchActivity, isRefetching } = useFeedActivityQuery(activityId);

	const communityId = activity?.collectionCommunityReference?.data?.id || activity?.community?.id; // migration to use "collectionCommunityReference" (T21C-2485)
	const communitySlug = activity?.collectionCommunityReference?.data?.slug || activity?.community?.slug || 'LEARNING';
	const ifEditingAndLoading = activityId && (isLoading || isRefetching);

	const isOnEditMode = !!activity?.id;
	const isLearnPost = useMemo(() => {
		const communityName = activity?.collectionCommunityReference?.data?.name || activity?.community?.name; // migration to use "collectionCommunityReference" (T21C-2485)
		return activity?.id && activity?.origin !== 'user:GLOBAL' && !communityName;
	}, [activity]);

	const handleActivityAddedSuccess = useHandleActivityAddedSuccess();
	const { onCommunityPostAdd } = usePostCreation({
		onSuccess: (createdPost, { network }) => {
			handleActivityAddedSuccess({ ...createdPost, network });
		},
	});
	const { onCommunityEditPostAction } = usePostEditing(communitySlug);
	const { attachments, filesSize, removeAttachment, setInitialAttachments, onAddAttachmentsSuccess } =
		usePostAttachmentsState({});

	const filesExceedingSize = filesSize > MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE;
	const itemsExceedingCount = attachments.length > MAX_ITEMS_FOR_UPLOAD;

	// Check if initial attachments count > MAX_ITEMS_FOR_UPLOAD then don't show error and enable editing.
	// Like for cases when staff upload 10 files and 1 api video. T21C-5535 [@julia]
	const isInitialItemsExceedingCount = useMemo(() => {
		return checkInitialAttachmentsItemsExceed(activity?.attachments, attachments, isOnEditMode);
	}, [activity?.attachments, attachments, isOnEditMode]);

	const isAttachmentsChanged = useMemo(() => {
		return checkInitialAttachmentsChanged(activity?.attachments, attachments);
	}, [activity?.attachments, attachments]);

	const { data: networks = [] } = useMyLifestyleNetworksQuery(undefined);
	const { data: investNetworks = [] } = useMyInvestNetworksQuery();
	const networkOptions = useMemo(() => {
		const networkOptions = sortBy([...networks, ...investNetworks], ['name']).map((network) => ({
			value: network['@id'],
			title: network.name,
			label: network.name,
		}));
		return [{ title: `– ${t('select a Network')} –`, value: '' }, ...networkOptions];
	}, [networks, investNetworks]);

	const getCommunityName = (networkId: string) => {
		return [...networks, ...investNetworks].find((network) => network['@id'] === networkId)?.name || '';
	};

	// Form initial values
	const initialValues = useMemo(() => {
		const sharedActivityIri = sharedActivityId ? `/api/communities/${sharedActivityId}` : undefined;
		const defaultInitialValues = {
			title: '',
			mind: '',
			network: networkId || sharedActivityIri || '',
			sharedContent: internalShare || '',
			event: undefined,
		};
		if (!activity) {
			return defaultInitialValues;
		}

		const activityEntity = new ActivityEntity(activity);
		const eventIri = activityEntity.eventId ? `/api/events/${activityEntity.eventId}` : undefined;
		const communityId = activity?.collectionCommunityReference?.data?.id || activity?.community?.id; // migration to use "collectionCommunityReference" (T21C-2485)
		return {
			...defaultInitialValues,
			title: activity?.subject || defaultInitialValues.title,
			mind: activity?.body.trim() || defaultInitialValues.mind,
			network: communityId || defaultInitialValues.network,
			sharedContent: activity?.sharedContent?.[0] || defaultInitialValues.sharedContent,
			event: eventIri || defaultInitialValues.event, // indeed 'event' consists calendarItemIri, it was unexpected implementation by BE.
		};
	}, [activity, networkId, sharedActivityId, internalShare]);

	const submit = async (values: TPostCreation) => {
		const data = {
			networkSlug: values.network,
			body: values.mind || ' ',
			subject: values.title,
			owner: user['@id'],
			sharedContent: values.sharedContent ? [values.sharedContent] : [],
			event: values.event, // indeed 'event' consists calendarItemIri, it was unexpected implementation by BE.
		};

		if (isOnEditMode) {
			await onCommunityEditPostAction({
				...data,
				id: activity?.foreign_id,
				postType: activity?.settings?.postType,
				...transformAttachments(attachments),
			});
		} else {
			const network = [...networks, ...investNetworks].find((community) => community['@id'] === values.network);

			await onCommunityPostAdd({
				...data,
				...transformAttachments(attachments),
				network,
			});
		}
	};

	useEffect(() => {
		if (isOnEditMode) {
			refetchActivity();
		}
	}, []);

	useEffect(() => {
		if (isOnEditMode) {
			setInitialAttachments({
				images: activity?.attachments?.images,
				files: activity?.attachments?.files,
				videos: activity?.attachments?.videos,
			});
		}
	}, [activity]);

	const isCustomFormDirty = (values: TPostCreation) => {
		// Check if the custom form is dirty, considering form attachments.
		if (!isOnEditMode) {
			return !!attachments?.length;
		}

		const initialAttachments = [
			...(activity?.attachments?.images ?? []),
			...(activity?.attachments?.videos ?? []),
			...(activity?.attachments?.files ?? []),
		];

		// If the lengths differ, the form is dirty.
		if (initialAttachments.length !== attachments?.length) {
			return true;
		}
		if ((activity.eventId || null) !== (values.event || null)) {
			return true;
		}
		if ((activity.sharedContent?.[0] || null) !== (values.sharedContent || null)) {
			return true;
		}

		// Check for differences in attachment URLs. If there are differences, the form is dirty.
		const attachmentsUrlsArray = attachments.map((attachment) => attachment.url);
		const arraysDifference = xor(initialAttachments, attachmentsUrlsArray);
		if (arraysDifference.length) {
			return !!arraysDifference.length;
		}
	};
	const postHasNormalType = activity?.settings.postType === 'post';

	const getPostSourceName = (network?: string) => {
		if (isLearnPost) return t('Learn');
		if (network) return getCommunityName(network);
		return '';
	};
	const getDisabled = (formProps: FormikProps<TPostCreation>) =>
		isOnEditMode
			? customEditFormDisabled(
					filesExceedingSize,
					itemsExceedingCount,
					isInitialItemsExceedingCount,
					!!attachments?.length,
					isAttachmentsChanged,
					formProps,
				)
			: customDisabled(
					filesExceedingSize,
					itemsExceedingCount,
					isInitialItemsExceedingCount,
					formProps.dirty,
					formProps.isValid,
				);

	return (
		<Page title={isOnEditMode ? t('Edit Post') : t('New Post')}>
			{ifEditingAndLoading ? (
				<ActivityIndicator />
			) : (
				<Formik
					enableReinitialize
					initialValues={initialValues as TPostCreation}
					validateOnBlur
					validateOnChange={false}
					validationSchema={isOnEditMode ? getPostEditingSchema(t, attachments) : getPostCreationSchema(t, attachments)}
					onSubmit={submit}
				>
					{(formProps) => {
						const { values, setFieldValue } = formProps;
						const postSourceName = getPostSourceName(values.network);
						const customFormDirty = isCustomFormDirty(formProps.values);

						return (
							<FormPageLayout
								allowedNextPaths={[ROUTES.editPost(), ROUTES.addPost()]}
								buttonBordered={false}
								customDisabled={getDisabled(formProps)}
								customLeftIcon={<CloseIcon className={styles.post__iconClose} height={20} width={13} />}
								formProps={formProps}
								headerTitle={isOnEditMode ? t('Edit Post') : t('New Post')}
								ignoreDirty={customFormDirty}
								saveButtonTitle={isOnEditMode ? t('Save') : t('Post')}
								submitDebounceDelay={5000}
							>
								<div className={styles.post}>
									{isOnEditMode ? (
										activity ? (
											<PostFormInfoBar
												avatarUrl={activity.actor.data.avatar}
												sourceName={postSourceName}
												time={activity?.time || ''}
												userName={activity.actor.data.name}
												userRoles={activity.actor.data.roles}
											/>
										) : (
											<ActivityIndicator type="fit" />
										)
									) : (
										<PostFormInfoBar
											avatarUrl={user.avatar?.contentUrl}
											sourceName={postSourceName}
											time={''}
											userName={`${user.firstName} ${user.lastName}`}
											userRoles={user.roles}
										/>
									)}
									<PostCreationForm
										formProps={formProps}
										hideNetworkList={!!internalShare}
										isOnEditMode={isOnEditMode}
										networkId={communityId || networkId}
										networkOptions={networkOptions}
									/>
									<When condition={!!values?.sharedContent}>
										<>
											<ShareableCard
												clearInternalShare={() => setFieldValue('sharedContent', '')}
												sharedContent={String(values?.sharedContent)}
											/>
											<Gap gap={12} horizontal={false} />
										</>
									</When>
									<When condition={!!values?.event}>
										<>
											<EventListItemAttachment
												eventId={extractIdFromIri(String(values?.event))}
												onClick={noop}
												onRemove={postHasNormalType ? () => setFieldValue('event', undefined) : undefined}
											/>
											<Gap gap={12} horizontal={false} />
										</>
									</When>
									<PostAttachments
										attachments={attachments}
										editable={true}
										errorMessage={formProps.errors.mind}
										fieldName="mind"
										filesSize={filesSize}
										isInitialItemsExceedingCount={isInitialItemsExceedingCount}
										removeAttachment={removeAttachment}
										setFieldValue={setFieldValue}
										value={String(values.mind)}
										onAddAttachmentsSuccess={onAddAttachmentsSuccess}
										onBlur={formProps.handleBlur('mind')}
										onSelectEvent={
											(calendarItemId, definition) => {
												if (definition === 'meeting') {
													setFieldValue('event', `/api/meetings/${calendarItemId}`);
												} else if (definition === 'event') {
													setFieldValue('event', `/api/events/${calendarItemId}`);
												}
											} // indeed 'event' consists calendarItemIri, it was unexpected implementation by BE.
										}
										onSelectShareContent={(sharedContent) => setFieldValue('sharedContent', sharedContent)}
									/>
									{/* hidden input to make file upload working */}
								</div>
							</FormPageLayout>
						);
					}}
				</Formik>
			)}
		</Page>
	);
};

interface IPostCreationPageParams {
	activityId: string;
	sharedActivityId?: string;
}

export interface IPostCreationLocationState {
	state: {
		networkId?: string;
		definition?: TCommunity['definition'];
	};
}

export default PostCreationPage;
