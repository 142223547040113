import { useMutation, useService, useTranslation } from '@hooks';
import { translateOktaUnsuitableResponse } from '../utils/translateOktaUnsuitableResponse';
import type { AxiosError } from 'axios';
import type { TVerificationResult } from '../../../services/interfaces/OktaService.interface';

export const useOktaResetPasswordMutation = ({
	onVerifyRequired,
	onSuccess,
}: IUseOktaResetPasswordMutationCallbacks) => {
	const { t } = useTranslation();
	const okta = useService('OktaService');
	const api = useService('ApiService');
	const crypto = useService('CryptoService');

	const mutation = useMutation<TVerificationResult, AxiosError<string>, { login: string }>(
		async ({ login: loginOrEmail }) => {
			const encryptedLoginOrEmail = await crypto.encryptAes256Cbc(loginOrEmail);
			const { login: encryptedLogin } = await api.auth.exchangeLoginV2(encryptedLoginOrEmail);
			const decryptedLogin = await crypto.decryptAes256Cbc(encryptedLogin);

			return await okta.recoverPassword(decryptedLogin);
		},
		{
			onSuccess: async (verificationResult) => {
				if (verificationResult === 'next-verification-required') onVerifyRequired();
				if (verificationResult === 'success') onSuccess();
			},
		},
	);

	const submitError = translateOktaUnsuitableResponse(t)(mutation?.error);

	return {
		...mutation,
		submitError,
	};
};

interface IUseOktaResetPasswordMutationCallbacks {
	onVerifyRequired: () => void;
	onSuccess: () => void;
}
