import styles from './BottomSheetModal.module.css';
import Portal from '../Portal';
import { motion, AnimatePresence } from 'motion/react';
import { forwardRef, type CSSProperties, type ForwardedRef, type ReactNode } from 'react';
import { useState, useEffect, useImperativeHandle, useRef } from '@hooks';
import { clsx } from '@utils';

const BottomSheetModal = (
	{ children, height, onExitComplete }: IBottomSheetModalProps,
	ref: ForwardedRef<IBottomSheetModalMethods>,
) => {
	const [visible, setVisible] = useState(false);

	const contentRef = useRef<HTMLDivElement>(null);
	useImperativeHandle(ref, () => ({
		show: () => setVisible(true),
		hide: () => setVisible(false),
	}));

	useEffect(
		function preventScrollingContentUnderModal() {
			if (visible) {
				document.body.classList.add(styles.bottomSheetModal__preventScrollingContentUnderModal);
			} else {
				document.body.classList.remove(styles.bottomSheetModal__preventScrollingContentUnderModal);
			}
		},
		[visible],
	);

	return (
		<AnimatePresence onExitComplete={onExitComplete}>
			{visible && (
				<Portal>
					<div className={styles.bottomSheetModal}>
						<AnimatePresence propagate>
							<motion.div
								animate={{ opacity: 1 }}
								className={styles.bottomSheetModal__backDrop}
								exit={{ opacity: 0 }}
								initial={{ opacity: 0 }}
								transition={{ duration: 0.2 }}
								onClick={() => setVisible(false)}
							/>
						</AnimatePresence>
						<AnimatePresence propagate>
							<motion.div
								animate={{ y: 0 }}
								className={clsx(styles.bottomSheetModal__content)}
								exit={{ y: '100%' }}
								initial={{ y: '100%' }}
								ref={contentRef}
								style={{ height }}
								transition={{ type: 'spring', stiffness: 300, damping: 28 }}
							>
								{children}
							</motion.div>
						</AnimatePresence>
					</div>
				</Portal>
			)}
		</AnimatePresence>
	);
};

export interface IBottomSheetModalProps {
	children: ReactNode;
	height?: CSSProperties['height'];
	onExitComplete?: () => void;
}

export interface IBottomSheetModalMethods {
	show: () => void;
	hide: () => void;
}

export default forwardRef(BottomSheetModal);
