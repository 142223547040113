import {
	ActivityIndicator,
	Avatar,
	ChaptersIcon,
	DealsIcon,
	EventsIcon,
	LampIconSolid,
	MemberGroupIcon,
	NetworkConnectionIcon,
	NetworkIcon,
	UserInActiveIcon,
} from '@ui-kit';

import { useGetUserQuery } from '@ui-modules/profile/hooks/useGetUserQuery';
import React from 'react';
import { useGetEventQuery } from '@ui-modules/events/hooks/useGetEventQuery';
import EventAvatar from '@ui-modules/events/components/EventAvatar/EventAvatar';
import styles from './ShareHeader.module.css';
import type { IInternalShare } from '@typings';
import { useState } from 'reinspect';

const InternalShareHeaderIcon = ({ sharedContent, isShareLoading }: IInternalShareHeaderIconProps) => {
	const isEventQueryEnabled = sharedContent.type === 'event';

	const { data: activityCreator, isLoading: isActivityCreatorLoading } = useGetUserQuery(sharedContent.userId);

	const { data: event, isLoading: isEventLoading } = useGetEventQuery(sharedContent.id, {
		enabled: sharedContent.type === 'meeting' || sharedContent.type === 'event',
	});

	const [hasImageError, setHasImageError] = useState(false);

	const isLoading =
		(isActivityCreatorLoading && sharedContent.type === 'discussion') || (isEventLoading && isEventQueryEnabled);

	const renderIcon = () => {
		switch (sharedContent?.type) {
			case 'discussion':
				if (activityCreator?.roles?.includes('ROLE_INACTIVE')) {
					return <UserInActiveIcon width={32} />;
				} else {
					return (
						<Avatar
							imageUri={sharedContent.image}
							outline={activityCreator?.roles?.includes('ROLE_CHAIR') as boolean}
							size={40}
							title={`${activityCreator?.firstName} ${activityCreator?.lastName}`}
						/>
					);
				}

			case 'group':
				return <MemberGroupIcon fill={'var(--color-primaryDarkGray)'} height={34} width={34} />;
			case 'chapter':
				return <ChaptersIcon fill={'var(--color-primaryDarkGray)'} height={34} width={34} />;
			case 'network':
			case 'network_connection':
				return <NetworkIcon fill={'var(--color-primaryDarkGray)'} height={34} width={34} />;
			case 'network_connection_post':
				return <NetworkConnectionIcon fill={'var(--color-primaryDarkGray)'} height={34} width={34} />;
			case 'deal':
			case 'deal_post':
				return <DealsIcon fill={'var(--color-primaryDarkGray)'} height={34} width={34} />;
			case 'event':
			case 'meeting':
				return event?.startDate || event?.endDate ? (
					<div className={styles.internalShare__eventIcon}>
						<EventAvatar event={event} />
					</div>
				) : (
					<EventsIcon fill={'var(--color-primaryDarkGray)'} height={34} width={34} />
				);
			case 'tip':
				if (sharedContent.image && !hasImageError) {
					return (
						<img
							alt={sharedContent.title}
							className={styles.internalShare__icon}
							src={sharedContent.image}
							onError={() => setHasImageError(true)}
						/>
					);
				} else {
					return <LampIconSolid fill={'var(--color-primaryDarkGray)'} height={34} width={34} />;
				}
			default:
				return null;
		}
	};

	return (
		<div className={styles.internalShare__iconWrapper}>
			{isLoading || isShareLoading ? <ActivityIndicator /> : renderIcon()}
		</div>
	);
};

export interface IInternalShareHeaderIconProps {
	sharedContent: IInternalShare;
	isShareLoading: boolean;
}

export default InternalShareHeaderIcon;
