import { useTranslation } from '@hooks';
import { getCompiledEventTitle } from '@utils';

import styles from './ShareableCard.module.css';

import type { TSharedContentType } from './ShareableCard';
import type { TEvent } from '@typings';

const ShareCardSourceDisplay = ({ data }: IShareCardSourceDisplayProps) => {
	const { t } = useTranslation();
	if (!data.source) return null;

	const { startDate, endDate } = data.compactEvent || {};
	const displayTitle = startDate || endDate ? getCompiledEventTitle(t)(data.compactEvent as TEvent) : data.source;

	return (
		<p className={styles.source} title={data.source}>
			{displayTitle}
		</p>
	);
};

interface IShareCardSourceDisplayProps {
	data: TSharedContentType;
}

export default ShareCardSourceDisplay;
