import { GLOBAL_SEARCH, GLOBAL_SEARCH_DEFINITION, GLOBAL_SEARCH_INPUT_ID_DOM_ATTRIBUTE } from '@constants';
import { EmptyStateMessage, Portal, RightIcon } from '@ui-kit';
import { useGlobalSearchState } from '@ui-modules/globalSearch/hooks/useGlobalSearchState';
import { useGlobalSearchHistory } from '@ui-modules/globalSearch/hooks/useGlobalSearchHistory';
import { useGlobalSearchResults } from '@ui-modules/globalSearch/hooks/useGlobalSearchResults';
import { useHandleClickGlobalSearchResult } from '@ui-modules/globalSearch/hooks/useHandleClickGlobalSearchResult';
import { useClickAway, useDebounce, useRef, useTranslation } from '@hooks';
import SearchResult from '../SearchResult/SearchResult';
import RecentSearches from '../RecentSearch';
import SearchNoMatch from '../SearchNoMatch';
import type { RefObject } from 'react';
import type { TSearchByIndexResponse } from '@typings';
import styles from './SearchModal.module.css';
import { SearchResultByDefinition } from '../SearchResult/SearchResultByDefinition';

const SearchModal = ({ searchInputRef }: ISearchModal) => {
	const { t } = useTranslation();

	const { setIsSearchActive, searchQuery: searchQueryInitial, goToResultsScreen } = useGlobalSearchState();
	const searchQuery = searchQueryInitial.trim();
	const [debouncedSearchQuery] = useDebounce(searchQuery, 400);
	const { recentSearches, addRecentSearch } = useGlobalSearchHistory(searchQuery, debouncedSearchQuery);
	const { data: searchResults = [], isFetched } = useGlobalSearchResults(
		debouncedSearchQuery,
		{},
		{
			select: (data) =>
				Object.values(data)
					.flat()
					.sort((a, b) => b.rank - a.rank) // sort by search match rank descending
					.slice(0, GLOBAL_SEARCH.limitOfSearchResults),
		},
	);
	const isSearchResultEmpty = isFetched && !searchResults?.length;
	const temporaryNoMatch = searchQuery.length >= GLOBAL_SEARCH.validSearchQueryLength && isSearchResultEmpty;

	const handleClickGlobalSearchResult = useHandleClickGlobalSearchResult(searchQuery);

	const portalRef = useRef() as RefObject<HTMLDivElement>;
	useClickAway(portalRef, (event) => {
		const target = event?.target as HTMLElement;
		if (target?.id === GLOBAL_SEARCH_INPUT_ID_DOM_ATTRIBUTE) return;
		setIsSearchActive(false);
	});

	const openAllSearchResults = () => {
		addRecentSearch(searchQuery);
		goToResultsScreen(searchQuery);
	};

	return (
		<Portal>
			<div
				className={styles.searchModal__globalSearchModal}
				ref={portalRef}
				style={getHintPopupStyle(searchInputRef.current?.getBoundingClientRect())}
			>
				{searchQuery.length > 0 && searchQuery.length < GLOBAL_SEARCH.validSearchQueryLength && (
					<EmptyStateMessage
						text={t(`Please enter at least {{limit}} characters`, { limit: GLOBAL_SEARCH.validSearchQueryLength })}
					/>
				)}
				{!recentSearches.length && searchQuery.length === 0 ? (
					<EmptyStateMessage text={t(`You don't have any recent searches`)} />
				) : null}
				{recentSearches.length ? <RecentSearches debouncedSearchQuery={searchQuery} /> : null}
				{isSearchResultEmpty && searchQuery.length ? <SearchNoMatch searchQuery={searchQuery} /> : null}
				{searchResults &&
				searchResults.length &&
				!temporaryNoMatch &&
				searchQuery.length >= GLOBAL_SEARCH.validSearchQueryLength ? (
					<div>
						{searchResults.map((result: TSearchByIndexResponse) => (
							<SearchResultByDefinition
								key={result.objectID}
								result={{ ...result, searchQuery, definition: result.definition }}
								onSelect={handleClickGlobalSearchResult}
							/>
						))}
						<div className={styles.seeResultsWrapper} onClick={openAllSearchResults} onKeyPress={openAllSearchResults}>
							<div className={styles.searchModal__seeAllResultsWrapper}>
								<span className={styles.searchModal__seeAllResultsText}>{`See all results for '${searchQuery}'`}</span>
								<RightIcon className={styles.searchModal__seeAllResultsArrow} />
							</div>
						</div>
					</div>
				) : null}
			</div>
		</Portal>
	);
};
export default SearchModal;

function getHintPopupStyle(searchBarRect: DOMRect | undefined) {
	if (!searchBarRect) return {};

	const PADDING = 16;
	const MINIMUM_PROPER_SEARCH_BAR_WIDTH = 300;

	const widthFromSearchBarToRightScreenEdge = document.body.clientWidth - searchBarRect.left - PADDING;
	const searchBarWidthWithPaddings = searchBarRect.width + PADDING * 2;
	const maxWidth =
		searchBarRect.width < MINIMUM_PROPER_SEARCH_BAR_WIDTH
			? widthFromSearchBarToRightScreenEdge
			: searchBarWidthWithPaddings;

	const heightBetweenSearchBarAndBottomScreenEdge =
		document.body.clientHeight - searchBarRect.top - searchBarRect.height - PADDING;

	return {
		top: searchBarRect.y + 40,
		left: searchBarRect.x - PADDING,
		maxHeight: heightBetweenSearchBarAndBottomScreenEdge,
		maxWidth,
	};
}

interface ISearchModal {
	searchInputRef: RefObject<HTMLInputElement>;
}
