import type { TSupportedChannel } from '@typings';
import { useCallback, useCollapse, useMe, useMemo, useRef, useTranslation } from '@hooks';

import styles from './SettingsCard.module.css';
import { Gap, SpacedGroup, SwitchButton } from '@ui-kit';
import NotificationsSettingsForm from '../NotificationsSettingsForm';
import OptionsBlock from '../OptionsBlock';
import { useCreateNotificationSetting } from '@ui-modules/settings/hooks/useCreateNotificationSettings';
import { useUpdateNotificationSettings } from '@ui-modules/settings/hooks/useUpdateNotificationSettings';
import { clsx } from '@utils';
import { NOTIFICATION_SETTING_CHANNEL } from '@constants';

const SettingsCard = ({
	title,
	settingsArray,
	initialSettings,
	isDefaultExpanded = false,
	onChangeCollapsed,
	groupName,
	EmailChapter,
	creationParams = {},
}: ISettingsCardProps) => {
	const { t } = useTranslation();
	const { mutate: createNotificationSetting, isLoading: isCreating } = useCreateNotificationSetting(groupName);
	const { mutate: updateSettings, isLoading: isUpdating } = useUpdateNotificationSettings(groupName);
	const { user } = useMe();
	const ref = useRef<HTMLDivElement | null>(null);

	const resetSettings = useCallback(() => {
		initialSettings.forEach((setting) => {
			if (setting.id) {
				updateSettings({ id: setting.id, notificationSetting: { channels: [] } });
			} else {
				createNotificationSetting({
					notificationId: setting.notificationId,
					owner: user['@id'],
					channels: [],
					constraint: creationParams.constraint ?? null,
					sourceId: creationParams.sourceId ?? null,
				});
			}
		});
	}, [updateSettings, initialSettings, createNotificationSetting, user]);

	const { getCollapseProps, isExpanded, setExpanded } = useCollapse({
		defaultExpanded: isDefaultExpanded,
	});

	const onSwitchChange = useCallback(() => {
		setExpanded(!isExpanded);
		onChangeCollapsed(!isExpanded);
		if (isExpanded) resetSettings();
		if (!isExpanded)
			setTimeout(() => {
				ref.current?.scrollIntoView({ behavior: 'smooth' });
			}, 200);
	}, [isExpanded, setExpanded, onChangeCollapsed, resetSettings]);

	const settingsPresence = useMemo(() => {
		const hasEmailSettings = settingsArray.some((setting) =>
			setting.options.includes(NOTIFICATION_SETTING_CHANNEL.email),
		);
		const hasPushSettings = settingsArray.some((setting) =>
			setting.options.includes(NOTIFICATION_SETTING_CHANNEL.push),
		);
		return { hasEmailSettings, hasPushSettings };
	}, [settingsArray]);

	const labels = useMemo(() => {
		const { hasEmailSettings, hasPushSettings } = settingsPresence;
		return (
			<>
				{hasEmailSettings && (
					<>
						<span>{t('email').toUpperCase()}</span>
						{hasPushSettings && <Gap gap={12} />}
					</>
				)}
				{hasPushSettings && <span>{t('push').toUpperCase()}</span>}
			</>
		);
	}, [settingsPresence, t]);

	return (
		<div className={styles.settingsCard} ref={ref}>
			<div className={styles.settingsCard__header}>
				<h3>{title}</h3>
				<SwitchButton disabled={isUpdating || isCreating} value={isExpanded} onChange={onSwitchChange} />
			</div>
			<div data-testid="CollapsibleSettingsCard__content" {...getCollapseProps()}>
				<div
					className={clsx(styles.settingsCard__content_visible, !isExpanded && styles.settingsCard__content_visible)}
				>
					<div className={styles.settingsCard__labels}>{labels}</div>
					<SpacedGroup direction="vertical" fullWidth>
						{EmailChapter ? <EmailChapter /> : null}
						{settingsArray.map(({ options, notificationId, name }) => {
							const initialSetting = initialSettings.find((settings) => settings.notificationId === notificationId);
							return initialSetting ? (
								<NotificationsSettingsForm initialValue={initialSetting} key={notificationId} visible={isExpanded}>
									<OptionsBlock
										creationParams={creationParams}
										groupName={groupName}
										hasPushSettings={settingsPresence.hasPushSettings}
										// this flag we provide for formik on nesting levels for correct render of checkbox
										isCustom={!!initialSetting}
										isOpened={isExpanded}
										notificationId={notificationId}
										options={options.sort()}
										title={name}
									/>
								</NotificationsSettingsForm>
							) : null;
						})}
					</SpacedGroup>
				</div>
			</div>
		</div>
	);
};

type TSettingsItem = {
	name: string;
	options: TSupportedChannel[];
	notificationId: string;
	group: string | null;
};

interface ISettingsCardProps {
	title: string;
	isDefaultExpanded: boolean;
	onChangeCollapsed: (isCollapsed: boolean) => void;
	settingsArray: TSettingsItem[];
	initialSettings: {
		id: string | null;
		notificationId: string;
		options: TSupportedChannel[];
	}[];
	groupName?: string;
	creationParams?: { sourceId?: string; constraint?: string };
	EmailChapter?: () => JSX.Element;
}

export default SettingsCard;
