import type { IInternalShare, TCommunity } from '@typings';
import { LampIconSolid } from '@ui-kit';
import RestrictedShareCard from '../components/ShareableCard/RestrictedShareCard';
import type { TFunction } from 'i18next';

export const getRestrictedCard = ({
	t,
	data,
	isError,
	isDeleted,
	shareType,
	restrictions,
	clearInternalShare,
	communityDefinition,
	communityMembershipType,
}: {
	t: TFunction;
	clearInternalShare: (() => void) | undefined;
	restrictions: TRestrictions;
	shareType: string | undefined;
	data: IInternalShare;
	isDeleted: boolean | undefined;
	isError: boolean | undefined;
	communityDefinition?: TCommunity['definition'];
	communityMembershipType?: TCommunity['membershipType'];
}) => {
	if (restrictions.isTipsRestricted) {
		return (
			<RestrictedShareCard
				clearInternalShare={clearInternalShare}
				icon={<LampIconSolid fill="var(--color-primaryDarkGray)" height={34} width={34} />}
				title={t('Tips are in beta and will be rolled out gradually to all Members.')}
			/>
		);
	}

	if (restrictions.isPostUnavailable) {
		return (
			<RestrictedShareCard
				clearInternalShare={clearInternalShare}
				title={t('This {{shareType}} is unavailable', { shareType })}
			/>
		);
	}

	if (shareType === 'post' && restrictions.isPostInAccessible && communityDefinition !== 'network') {
		return (
			<RestrictedShareCard
				clearInternalShare={clearInternalShare}
				title={t('This {{shareType}} is unavailable', { shareType })}
			/>
		);
	}

	if (restrictions.isPostInAccessible && communityMembershipType === 'requestOnly') {
		return (
			<RestrictedShareCard
				clearInternalShare={clearInternalShare}
				title={t('This {{shareType}} is unavailable', { shareType })}
			/>
		);
	}

	if (isDeleted) {
		return (
			<RestrictedShareCard
				clearInternalShare={clearInternalShare}
				title={t('This {{type}} has been deleted', {
					type: shareType,
				})}
			/>
		);
	}

	if (isError && !isDeleted) {
		return (
			<RestrictedShareCard
				clearInternalShare={clearInternalShare}
				title={
					data.type === 'tip'
						? t('This tip is not yet available')
						: t('This {{type}} is unavailable', { type: shareType })
				}
			/>
		);
	}

	return null;
};

export type TRestrictions = {
	isTipsRestricted: boolean;
	isInvestRestricted: boolean;
	isPostUnavailable: boolean;
	isPostInAccessible?: boolean;
	isCommunityMember?: boolean;
};
