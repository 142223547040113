import { useNavigate, useNotification, useTranslation } from '@hooks';
import { httpCodes } from '../../services/abstracts/AbstractAxiosService/httpCodes';
import type { AxiosError } from 'axios';
import type { TFunction } from 'i18next';

/** Generic hooks which should be used in single entity pages
 * to check if entity is deleted or unavailable.
 * It shows a relevant notification and navigates back by default.
 * Returns 'true' if entity is deleted or unavailable so that error was handled by the hook, 'false' otherwise.
 * @see T21C-8177
 * @see https://tiger21.atlassian.net/wiki/spaces/TRA/pages/592281604/T21C+Sharing#Phase-1%3A
 * @author DmitriyNikolenko
 */
export const useHandleEntityPageQueryError = (entityName: TEntityName, navigateCallback?: () => void) => {
	const { showInfo } = useNotification();
	const { t } = useTranslation();
	const navigate = useNavigate();

	return function onError(error: AxiosError) {
		if (error.response?.status === httpCodes.NOT_FOUND) {
			const translatedEntityName = translateEntityName(t)(entityName);
			showInfo({ title: t('This {{entity}} has been deleted', { entity: translatedEntityName }) });
			navigateCallback ? navigateCallback() : navigate(-1);
			return true;
		} else if (error.response?.status === httpCodes.FORBIDDEN) {
			const translatedEntityName = translateEntityName(t)(entityName);
			showInfo({ title: t('This {{entity}} is unavailable', { entity: translatedEntityName }) });
			navigateCallback ? navigateCallback() : navigate(-1);
			return true;
		}
		return false;
	};
};

function translateEntityName(t: TFunction) {
	return (entityName: TEntityName) => {
		switch (entityName) {
			case 'discussion':
				return t('discussion');
			case 'deal':
				return t('deal');
			case 'network':
				return t('network');
			case 'group':
				return t('group');
			case 'chapter':
				return t('chapter');
			case 'networkConnection':
				return t('network connection');
			case 'meeting':
				return t('meeting');
			case 'event':
				return t('event');
			case 'tip':
				return t('tip');
			default:
				return entityName;
		}
	};
}

type TEntityName =
	| 'discussion'
	| 'deal'
	| 'network'
	| 'group'
	| 'chapter'
	| 'networkConnection'
	| 'meeting'
	| 'event'
	| 'tip';
