import { useLayoutEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import type { RefObject } from 'react';

/** Uses ResizeObserver to observe the size of the element,
 *  but also provides an initial sizes for the element.
 *  Depends of hte attainability of the element you should use either 'initialRef' (if element is already mounted)
 *  or 'setObservableElement' (if element is mounted after the hook is called).
 *  @author DmitriyNikolenko
 */
export const useResizeObserver = (initialRef?: RefObject<HTMLElement>) => {
	const [observableElement, setObservableElement] = useState<HTMLElement | undefined>(initialRef?.current ?? undefined);

	const frame = useRef(0);
	const [domRect, setDomRect] = useState(emptyDOMRect);

	const [observer] = useState(
		() =>
			new ResizeObserver((entries) => {
				const entry = entries[0];
				if (!entry) return;
				cancelAnimationFrame(frame.current);
				frame.current = requestAnimationFrame(() => {
					setDomRect(entry.contentRect);
				});
			}),
	);

	useLayoutEffect(() => {
		if (observableElement) {
			setDomRect(observableElement.getBoundingClientRect() || emptyDOMRect);
			observer?.disconnect?.();
			observer.observe(observableElement);
		}

		return () => {
			observer?.disconnect?.();
		};
	}, [observableElement]);

	return [domRect, setObservableElement] as const;
};

const emptyDOMRect: DOMRect = {
	width: 0,
	height: 0,
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	x: 0,
	y: 0,
	toJSON: () => null,
};
