import { useState } from 'react';
import {
	getInitialPostingOwnership,
	type TPostingOwnership,
} from '@ui-modules/connections/components/ConnectionForm/ConnectionForm.utils';
import { useMe } from '@hooks';
import type { TRadioGroupInputOption } from '@ui-kit';
import { Gap, InputErrorMessage, RadioGroupInput } from '@ui-kit';
import UserSearchAutocomplete, {
	type IAutocompleteSearchUser,
} from '../../../../app/components/UserSearchAutocomplete/UserSearchAutocomplete';
import clsx from 'clsx';
import styles from './TipUserSelection.module.css';
import { useTranslation } from 'react-i18next';
import type { FormikProps } from 'formik';
import type { TTipReviewWithSharingForm } from '@schemas';
import { TFunction } from 'i18next';

const TipUserSelection = ({
	errorMessage,
	placeholder,
	disabled,
	isEdit,
	communityId,
	formProps,
}: ITipUserSelectionProps) => {
	const { user } = useMe();
	const { t } = useTranslation();
	const [ownershipOptions] = useState(() => getPostingOwnershipOptionsTips(t));
	const [postingOwnership, setPostingOwnership] = useState(() => getInitialPostingOwnership(user));
	const [selectedUser, setSelectedUser] = useState<IAutocompleteSearchUser | null>(null);

	const onSelectUser = (selectedUser: IAutocompleteSearchUser | null) => {
		setSelectedUser(selectedUser);
		if (selectedUser) {
			formProps.setFieldValue('ownerId', selectedUser.userId);
			formProps.setFieldError('ownerId', undefined);
		} else {
			formProps.setFieldValue('ownerId', undefined);
		}

		formProps.setFieldTouched('ownerId', true, false);
	};
	const onSelectPostOwnershipType = (newPostingOwnershipType: TPostingOwnership) => {
		setSelectedUser(null);
		setPostingOwnership(newPostingOwnershipType);

		if (newPostingOwnershipType === 'on_behalf_of') {
			formProps.setFieldValue('ownerId', undefined);
			formProps.validateField('ownerId');
		} else {
			formProps.setFieldValue('ownerId', user.id);
			formProps.setFieldTouched('ownerId', false, false);
		}
	};
	return (
		<>
			<RadioGroupInput
				backgroundColor="white"
				label={t('Owner')}
				labelType="bold"
				options={ownershipOptions}
				value={postingOwnership}
				onChange={onSelectPostOwnershipType}
			/>
			{postingOwnership === 'on_behalf_of' && (
				<div className={clsx(styles.userSearchAutocomplete, formProps.errors.ownerId && styles.input_error)}>
					<UserSearchAutocomplete
						communityId={communityId as string}
						defaultValue={
							isEdit
								? {
										fullName: user.firstName + ' ' + user.lastName,
										role: '',
										avatar: '',
										id: formProps.values.ownerId ?? '',
										enabled: true,
										profileID: '',
									}
								: selectedUser
						}
						disabled={disabled}
						indicateDisabledDealsUsers
						indicateNetworkUsers
						isClearable
						isErrored={!!errorMessage && formProps.touched.ownerId}
						placeholder={placeholder}
						showSearchIcon
						onSelect={onSelectUser}
					/>
					{formProps.errors.ownerId && formProps.touched.ownerId ? (
						<InputErrorMessage text={errorMessage} />
					) : (
						<Gap gap={16} />
					)}
				</div>
			)}
		</>
	);
};

interface ITipUserSelectionProps {
	isEdit?: boolean;
	formProps: FormikProps<TTipReviewWithSharingForm>;
	communityId?: string | null;
	disabled?: boolean;
	errorMessage?: string;
	placeholder: string;
}

export const getPostingOwnershipOptionsTips = (t: TFunction): Array<TRadioGroupInputOption<TPostingOwnership>> => [
	{
		label: t('Post as an owner'),
		value: 'as_an_owner',
	},
	{
		label: t('Post on behalf of your member'),
		value: 'on_behalf_of',
	},
];

export default TipUserSelection;
