import { clsx, NetworkConnectionEntity } from '@utils';
import { ROUTES } from '@constants';
import { useNavigate, useRef, useTranslation, useMemo } from '@hooks';
import type { TMeatballMenuOption } from '@ui-kit';
import {
	BlockLink,
	DynamicFieldDataGrid,
	HighlightedText,
	Post,
	When,
	DynamicLongTextSection,
	RibbonFlag,
} from '@ui-kit';
import styles from './NetworkConnectionPost.module.css';
import type { RefObject } from 'react';
import type { TNetworkConnectionWithReactions } from '@typings';

const NetworkConnectionPost = ({
	networkConnection,
	highlightedText,
	restoreScrollRoute,
	likingDisabled = false,
	previewMode = false,
	meatballMenuOptions = [],
	onLike,
	onClick,
	onTextCollapse,
}: INetworkConnectionPostProps) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const networkConnectionPostRef = useRef<HTMLElement>() as RefObject<HTMLElement>;

	const [isClosed, dynamicFields, categories, subcategories, longTextFields, label] = useMemo(
		function extractNetworkConnectionData() {
			const dynamicFields = NetworkConnectionEntity.extractDynamicFieldsDataV2(networkConnection, {
				currency: t('Currency'),
			}).filter((field) => field.label !== t('Currency')); // T21C-6844 hide currency field only in NC post
			const { categories, subcategories } = NetworkConnectionEntity.extractCategoriesAndSubcategories(
				networkConnection.categories,
			);
			const subCategoriesHidden = NetworkConnectionEntity.shouldHideSubCategoriesInCompactDetails(networkConnection);

			return [
				NetworkConnectionEntity.isNetworkClosed(networkConnection),
				dynamicFields,
				categories.join(', '),
				subCategoriesHidden ? null : subcategories.join(', '),
				NetworkConnectionEntity.extractLongTextFields(networkConnection),
				NetworkConnectionEntity.getLabel(networkConnection, t),
			] as const;
		},
		[networkConnection, i18n.language],
	);

	const handleClickDiscussions = () => {
		onClick?.();
		navigate(ROUTES.networkConnection(networkConnection.id, 'discussions'), { state: { restoreScrollRoute } });
	};

	return (
		<Post
			bodyClassName={styles.networkConnectionPost__body}
			footerElement={
				<When condition={!previewMode}>
					<Post.PostActionBar
						comments={networkConnection.commentsCount}
						hasMyLike={!!networkConnection.ownLikes?.length}
						likes={networkConnection.likesCount}
						likeTitle={t('Like')}
						likingDisabled={likingDisabled}
						replyTitle={t('Reply')}
						onClickComment={handleClickDiscussions}
						onClickReply={handleClickDiscussions}
						onLike={(newHasMyLike) => onLike(networkConnection, newHasMyLike)}
					/>
				</When>
			}
			headerElement={
				<>
					<Post.Header
						avatarProps={{
							imageUri: networkConnection.owner.avatarUrl,
							outline: false,
							size: 40,
						}}
						createdAt={networkConnection.createdAt}
						subtitle={NetworkConnectionEntity.getParentCategoryNamesString(networkConnection)}
						userId={networkConnection.owner.id}
						userName={networkConnection.owner.fullName}
						onClick={onClick}
						onProfileClicked={() => {
							if (networkConnection.owner.roles.includes('ROLE_INACTIVE')) return;
							navigate(ROUTES.memberProfile(networkConnection.owner.profileId));
						}}
					/>
				</>
			}
			isCard
			meatballMenuOptions={previewMode ? undefined : meatballMenuOptions}
			navigationState={{ state: { restoreScrollRoute } }}
			postLink={ROUTES.networkConnection(networkConnection.id, 'details')}
			ref={networkConnectionPostRef}
			withFooterSeparator={!previewMode}
			wrapperClassName={styles.networkConnectionPost}
			onClick={onClick}
		>
			<BlockLink
				className={styles.networkConnectionPost__bodyContent}
				navigationState={{ state: { restoreScrollRoute } }}
				to={ROUTES.networkConnection(networkConnection.id, 'details')}
				onClick={onClick}
			>
				<HighlightedText className={styles.networkConnectionPost__title} highlightedText={highlightedText}>
					{isClosed ? `${t('CLOSED:')} ${networkConnection.title}` : networkConnection.title}
				</HighlightedText>

				{label ? (
					<RibbonFlag
						className={styles.networkConnectionPost__ribbonFlag}
						color={label.color}
						size="small"
						title={label.title}
					/>
				) : null}

				<When condition={!!categories}>
					<div>
						<span className={clsx(styles.networkConnectionPost__textTitle)}>{t('Category')}</span>
						<span>{categories}</span>
					</div>
				</When>

				<When condition={!!subcategories}>
					<div>
						<span className={styles.networkConnectionPost__textTitle}>{t('Subcat')}</span>
						<span>{subcategories}</span>
					</div>
				</When>

				{!!dynamicFields?.length && (
					<div>
						<DynamicFieldDataGrid
							booleanFieldFalseLabel={t('No')}
							booleanFieldTrueLabel={t('Yes')}
							currency={networkConnection.currency}
							dynamicFields={dynamicFields}
							highlightedText={highlightedText}
							uniqueFieldStyle={dynamicFieldStyle}
						/>
					</div>
				)}
				{networkConnection?.description ? (
					<div>
						<span className={styles.networkConnectionPost__textTitle}>{t('Description')}</span>
						<Post.Content
							className={styles.networkConnectionPost__content}
							collapsedNumberOfLines={3}
							collapsedTitle={t('Read more')}
							expandedTitle={t('Show less')}
							highlightingText={highlightedText}
							toggleHidden={previewMode}
							onCollapse={onTextCollapse}
						>
							{networkConnection?.description}
						</Post.Content>
					</div>
				) : null}
				<DynamicLongTextSection
					collapsedNumberOfLines={3}
					collapsedTitle={t('Read more')}
					expandedTitle={t('Show less')}
					highlightedText={highlightedText}
					textFields={longTextFields}
					onCollapse={onTextCollapse}
				/>
			</BlockLink>
		</Post>
	);
};

// Set unique styles for DynamicFieldDataGrid depending on some static field labels.
const dynamicFieldStyle = {
	Subcat: styles.subcategoryField,
};

export interface INetworkConnectionPostProps {
	networkConnection: TNetworkConnectionWithReactions;
	highlightedText?: string;
	likingDisabled?: boolean;
	onLike: (networkConnection: TNetworkConnectionWithReactions, newHasMyLike: boolean) => void;
	onClick?: () => void;
	onTextCollapse?: () => void;
	restoreScrollRoute?: string;
	/** The preview mode is used when the post should not be intractable. Cases such as: Reporting a post, etc. */
	previewMode?: boolean;
	meatballMenuOptions?: TMeatballMenuOption[];
}

export default NetworkConnectionPost;
