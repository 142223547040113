import { yup, isUndefined, toString } from '@utils';
import type { SchemaOf } from '@utils';
import {
	DEAL_DESCRIPTION_CHARACTER_LIMIT,
	DEAL_FORM_PHONE_REGEXP,
	DEAL_OPTIONAL_FIELD_CHARACTER_LIMIT,
	DEAL_PRIOR_EXPERIENCE_CHARACTER_LIMIT,
	DEAL_TITLE_CHAR_LIMIT,
} from '@constants';
import type { TDealTemplateField, TMediaObject } from '@typings';
import { yupToFormErrors, validateYupSchema } from 'formik';
import { phoneNumberValidator } from '@schemas';
import type { TFunction } from 'i18next';

export const dealFormFields = {
	CREATOR: 'creator',
	COMMUNITY: 'community',
	CREATOR_TYPE: 'creatorType',
	ASSET_CLASS: 'dealAssetClass',
	SUB_CLASS: 'dealSubClass',
	TYPE: 'dealType',
	CONTACT_NAME: 'contactName',
	CONTACT_PHONE: 'contactPhone',
	CONTACT_EMAIL: 'contactEmail',
	TITLE: 'title',
	DESCRIPTION: 'description',
	PRIOR_EXPERIENCE: 'priorExperience',
	CLOSING_DATE: 'closingDate',
	DYNAMIC_DETAILS: 'detailsTemplate',
	CURRENCY: 'currency',
	MIN_INVESTMENT: 'minInvestment',
	DYNAMIC_FINANCIAL_DETAILS: 'financialDetailsTemplate',
	WEB_LINK_1: 'webLink1',
	WEB_LINK_2: 'webLink2',
	WEB_LINK_3: 'webLink3',
	FILES: 'files',
	VIDEO_URL: 'videoUrl',
	CREATOR_OPTION: 'creatorOption',
	COMPENSATION_DISCLOSURE: 'compensationDisclosure',
	// Service fields which are not displayed in the form but are used for performing actions during the form submission
	DELETED_FILES: 'deletedFiles',
	READ_ONLY_FILES_ACTIONS: 'makeFileReadOnlyActions',
	MAKE_FILE_DOWNLOADABLE_ACTIONS: 'makeFileDownloadableActions',
};

export const getDynamicDetailsFieldPath = (slug: string) => `detailsTemplate.${slug}`;
export const getDynamicDetailsFieldValuePath = (slug: string) => `${getDynamicDetailsFieldPath(slug)}.value`;

export const getDynamicFinancialDetailsFieldPath = (slug: string) => `financialDetailsTemplate.${slug}`;
export const getDynamicFinancialDetailsFieldValuePath = (slug: string) =>
	`${getDynamicFinancialDetailsFieldPath(slug)}.value`;

type TFieldWithValue = TDealTemplateField & { value: string };

export type TDynamicValues = {
	[fieldSlug: string]: TFieldWithValue;
};

type TDealFormRequiredValues = {
	creator: { iriId: string; name: string };
	community: string;
	creatorType: string;
	dealAssetClass: string;
	dealSubClass: string;
	dealType: string;
	contactName: string;
	contactPhone: string;
	contactEmail: string;
	title: string;
	compensationDisclosure: boolean;
};

export type TDealFormValues = TDealFormRequiredValues & {
	description: string;
	closingDate?: string | null;
	currency: string;
	minInvestment: string;
	webLink1?: string;
	webLink2?: string;
	webLink3?: string;
	files?: TMediaObject[];
	videoUrl?: string;
	detailsTemplate: TDynamicValues;
	financialDetailsTemplate: TDynamicValues;
	creatorOption?: 'me' | 'third-party';
	priorExperience?: string;
	// Service fields which are not displayed in the form but are used for performing actions during the form submission
	deletedFiles?: string[];
	makeFileReadOnlyActions?: TMediaObject['id'][];
	makeFileDownloadableActions?: TMediaObject['id'][];
};

export type TDealThirdPartyFormValues = TDealFormRequiredValues & {
	description?: string;
	closingDate?: string | null;
	currency?: string;
	minInvestment?: string;
	webLink1?: string;
	webLink2?: string;
	webLink3?: string;
	files?: TMediaObject[];
	videoUrl?: string;
	detailsTemplate?: TDynamicValues;
	financialDetailsTemplate?: TDynamicValues;
	deletedFiles?: string[];
	creatorOption?: 'me' | 'third-party';
	priorExperience?: string;
};

export const getDealFormSchema: (t: TFunction) => SchemaOf<TDealFormValues> = (t) =>
	yup.object({
		creator: yup.object().shape({
			iriId: yup.string().default(undefined).required('This field is required'),
			name: yup.string().default(undefined).required('This field is required'),
		}),
		community: yup.string().required(),
		creatorType: yup.string().required(t('Please indicate your relationship to this deal')),
		dealAssetClass: yup.string().required(t('You must select an asset class')),
		dealSubClass: yup.string().required(t('You must select a sub-class')),
		dealType: yup.string().required(t('You must select a type')),
		contactName: yup
			.string()
			.trim()
			.required(t('Name is required'))
			.min(3, t('Name must be at least 3 characters'))
			.max(255, t('Name must be max 255 characters'))
			.default(''),
		contactPhone: phoneNumberValidator(true, t('Phone number is not valid'))
			.trim()
			.required(t('Phone is required'))
			.default(''),
		contactEmail: yup.string().trim().email(t('Email is not valid')).required(t('Email is required')),
		title: yup
			.string()
			.max(
				DEAL_TITLE_CHAR_LIMIT,
				t(`Deal title must be max {{DEAL_TITLE_CHAR_LIMIT}}  characters`, { DEAL_TITLE_CHAR_LIMIT }),
			)
			.trim()
			.required(t('Please enter a title for this deal'))
			.default(''),
		description: yup
			.string()
			.trim()
			.required(t('Please enter a description for this deal'))
			.default('')
			.max(DEAL_DESCRIPTION_CHARACTER_LIMIT),
		closingDate: yup.string().nullable().optional(),
		currency: yup.string().required(t('Please select a currency for this deal')),
		minInvestment: yup
			.string()
			.test('maxValue', t('Maximum investment amount exceeded'), (value) => {
				if (!value) return true; // Skip the test if value is not provided
				const parsedValue = parseFloat(value.replace(/,/g, '')); // Parse value removing commas
				return parsedValue <= 2000000000; // Return true if value is less than or equal to 2b
			})
			.required(t('Minimum investment is required')),
		webLink1: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		webLink2: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		webLink3: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		files: yup.array().optional(),
		videoUrl: yup.string().trim().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		detailsTemplate: yup.object(),
		financialDetailsTemplate: yup.object(),
		creatorOption: yup.mixed().oneOf(['me', 'third-party']),
		compensationDisclosure: yup.boolean().required(),
		priorExperience: yup.string().optional().trim().default('').max(DEAL_PRIOR_EXPERIENCE_CHARACTER_LIMIT),
		deletedFiles: yup.array().optional(),
		makeFileReadOnlyActions: yup.array().optional(),
		makeFileDownloadableActions: yup.array().optional(),
	});

const getThirdPartyDealFormSchema: (t: TFunction) => SchemaOf<TDealThirdPartyFormValues> = (t) =>
	yup.object({
		creator: yup.object().shape({
			iriId: yup.string().default(undefined).required('This field is required'),
			name: yup.string().default(undefined).required('This field is required'),
		}),
		community: yup.string().required(),
		creatorType: yup.string().required(t('Please indicate your relationship to this deal')),
		dealAssetClass: yup.string().required(t('You must select an asset class')),
		dealSubClass: yup.string().required(t('You must select a sub-class')),
		dealType: yup.string().required(t('You must select a type')),
		contactName: yup
			.string()
			.trim()
			.required(t('Name is required'))
			.min(3, t('Name must be at least 3 characters'))
			.max(255, t('Name must be max 255 characters'))
			.default(''),
		contactPhone: phoneNumberValidator(true, t('Phone number is not valid'))
			.trim()
			.required(t('Phone is required'))
			.default(''),
		contactEmail: yup.string().trim().email(t('Email is not valid')).required(t('Email is required')),
		title: yup
			.string()
			.max(255, t('Deal title must be max 255 characters'))
			.trim()
			.required(t('Please enter a title for this deal'))
			.default(''),
		description: yup.string().optional().max(DEAL_DESCRIPTION_CHARACTER_LIMIT).default(''),
		closingDate: yup.string().nullable().optional(),
		currency: yup.string().optional(),
		minInvestment: yup
			.string()
			.test('maxValue', t('Maximum investment amount exceeded'), (value) => {
				if (!value) return true; // Skip the test if value is not provided
				const parsedValue = parseFloat(value.replace(/,/g, '')); // Parse value removing commas
				return parsedValue <= 2000000000; // Return true if value is less than or equal to 2b
			})
			.optional(),
		webLink1: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		webLink2: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		webLink3: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		files: yup.array().optional(),
		videoUrl: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		detailsTemplate: yup.object(),
		financialDetailsTemplate: yup.object(),
		creatorOption: yup.mixed().oneOf(['me', 'third-party']),
		compensationDisclosure: yup.boolean().required(),
		priorExperience: yup.string().optional().trim().max(DEAL_PRIOR_EXPERIENCE_CHARACTER_LIMIT).default(''),
		// Service fields which are not displayed in the form but are used for performing actions during the form submission
		deletedFiles: yup.array().optional(),
		makeFileReadOnlyActions: yup.array().optional(),
		makeFileDownloadableActions: yup.array().optional(),
	});

const validateField = (field: TFieldWithValue, t: TFunction) => {
	const isRequired = isUndefined(field.required) || field.required;
	if (isRequired && !toString(field.value).trim()) return t('{{value}} is required', { value: field.label });
	if (field.type === 'money' && field.value && Number(field.value) < 1) {
		return t('Input value cannot be lower than 0.');
	}
	if (field.type === 'string' && field?.value && field?.value?.length > DEAL_OPTIONAL_FIELD_CHARACTER_LIMIT) {
		return t(`{{value}} must be max {{maxLength}} characters`, {
			value: field.label,
			maxLength: DEAL_OPTIONAL_FIELD_CHARACTER_LIMIT,
		});
	}
};

const mapErrors = (values: TDynamicValues, t: TFunction) => {
	return Object.keys(values).reduce((acc, fieldSlug) => {
		const field: TFieldWithValue = values[fieldSlug];
		const error = validateField(field, t);
		if (error) {
			return {
				...acc,
				[fieldSlug]: error,
			};
		} else {
			return acc;
		}
	}, {});
};

export const getValidateHandler = (t: TFunction) => async (values: TDealFormValues) => {
	const validationSchema =
		values.creatorOption === 'third-party' ? getThirdPartyDealFormSchema(t) : getDealFormSchema(t);
	let errors = {};
	try {
		validateYupSchema(values, validationSchema, true, values);
	} catch (err) {
		errors = { ...yupToFormErrors(err) };
	}
	if (values.creatorOption === 'third-party') {
		return errors;
	}
	const detailsTemplate = mapErrors(values.detailsTemplate || {}, t);
	const financialDetailsTemplate = mapErrors(values.financialDetailsTemplate || {}, t);

	if (Object.keys(detailsTemplate).length) {
		errors = { ...errors, detailsTemplate };
	}

	if (Object.keys(financialDetailsTemplate).length) {
		errors = { ...errors, financialDetailsTemplate };
	}

	return errors;
};
