import { useSearchParams, useTranslation } from '@hooks';
import { useHandleClickGlobalSearchResult } from '@ui-modules/globalSearch/hooks/useHandleClickGlobalSearchResult';
import GlobalSearchResultList from '@ui-modules/globalSearch/components/GlobalSearchResultList';
import SearchNoMatch from '@ui-modules/globalSearch/components/SearchNoMatch';
import styles from './SearchResultsPage.module.css';

const SearchResultsPage = () => {
	const { t } = useTranslation();
	const searchQueryParam = useSearchParams()[0].get('searchQuery') || '';

	const handleClickGlobalSearchResult = useHandleClickGlobalSearchResult(searchQueryParam);

	return (
		<div className={styles.searchResults__wrapper}>
			<div className={styles.searchResults__header}>
				<span className={styles.searchResults__title}>{t('Search results for')}</span>
				<span className={styles.searchResults__query}>{`'${searchQueryParam}'`}</span>
			</div>
			<div className={styles.searchResults__categoryWrapper}>
				<GlobalSearchResultList
					EmptyComponent={
						<div>
							<SearchNoMatch searchQuery={searchQueryParam} />
						</div>
					}
					searchQuery={searchQueryParam}
					onSelect={handleClickGlobalSearchResult}
				/>
			</div>
		</div>
	);
};

export default SearchResultsPage;
