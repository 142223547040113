import { memo } from 'react';
import { clsx } from '@utils';
import FeedActivityWrapper, { type IFeedActivityWrapperProps } from './FeedActivityWrapper';

import styles from './FeedActivity.module.css';

import type { IFeedActivityProps } from './FeedActivity';
import type { TAdVideo } from '@typings';
import FeedAdVideo from '../FeedAdVideo';

const FeedActivityAdVideo = ({
	activity,
	onPressActivityLink,
	onInteracted,
	toggleDeleteActivityDialog,
	withSeparator,
	onActivityDeleted,
	isDeleteActivityDialogVisible,
	canDeletePost,
	onVideoPlayed,
}: IFeedActivityAdVideoProps) => {
	return (
		<FeedActivityWrapper
			activity={activity}
			isDeleteActivityDialogVisible={isDeleteActivityDialogVisible}
			toggleDeleteActivityDialog={toggleDeleteActivityDialog}
			onActivityDeleted={onActivityDeleted}
		>
			<div className={clsx(withSeparator && styles.feedActivity__post)}>
				<FeedAdVideo
					adVideo={(activity.settings as { adVideo: TAdVideo }).adVideo}
					canDeletePost={canDeletePost}
					title={activity.subject}
					onClick={(link) => {
						onPressActivityLink?.(link, activity);
						onInteracted?.('button');
					}}
					onDelete={() => toggleDeleteActivityDialog(true)}
					onVideoPlayed={(videoUrl) => {
						onVideoPlayed?.(videoUrl);
						onInteracted?.('video');
					}}
				/>
			</div>
		</FeedActivityWrapper>
	);
};

export interface IFeedActivityAdVideoProps extends Partial<IFeedActivityProps> {
	activity: IFeedActivityProps['activity'];
	canDeletePost: boolean;
	isDeleteActivityDialogVisible: boolean;
	toggleDeleteActivityDialog: IFeedActivityWrapperProps['toggleDeleteActivityDialog'];
}

export default memo(FeedActivityAdVideo);
