import { useService, useQuery } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TStreamActivity } from '@tiger21-llc/connect-shared/src/typings/entities/StreamActivity.type';
import type { AxiosError } from 'axios';
import { httpCodes } from '../../../services/abstracts/AbstractAxiosService/httpCodes';

export const useActivityQuery = <T = TUseActivityQueryData>(
	id: string,
	queryOptions?: UseQueryOptions<TUseActivityQueryData, AxiosError, T>,
) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');
	const query = useQuery<TUseActivityQueryData, AxiosError, T>(
		queryKeys.getStreamActivity(id),
		() => api.streamFeeds.getStreamActivityById(id),
		{
			staleTime: 0,
			cacheTime: 0,
			...queryOptions,
		},
	);

	return { ...query, isDeleted: query?.error?.response?.status === httpCodes?.NOT_FOUND };
};

type TUseActivityQueryData = TStreamActivity;
