import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useGetEventFilesQuery } from '@ui-modules/events/hooks/useGetEventFilesQuery';
import { useGetMeetingQuery } from '@ui-modules/events/hooks/useGetMeetingQuery';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { useMeetingTipsQuery } from '@ui-modules/tipJar/hooks/useMeetingTipsQuery';
import { useMemberMembershipQuery } from '@ui-modules/account/hooks/useMemberMembershipQuery';
import { useHandleEntityPageQueryError } from '../../../common/hooks';

export const useMeetingData = (meetingId?: string) => {
	const navigate = useNavigate();
	const handleEntityPageQueryError = useHandleEntityPageQueryError('meeting', () =>
		navigate(ROUTES.calendar(), { replace: true }),
	);

	const { data: meeting, isSuccess } = useGetMeetingQuery(meetingId, {
		onError: handleEntityPageQueryError,
	});

	const { data: tips = [], isLoading: isTipsLoading, isFetched: isTipsFetched } = useMeetingTipsQuery(meetingId);

	const { data: community } = useCommunityQuery(meeting?.groups?.[0]?.community?.id);

	const { data: eventFiles = [] } = useGetEventFilesQuery(meetingId);

	const { data: userMemberships = [] } = useMemberMembershipQuery();

	return { isSuccess, meeting, community, eventFiles, tips, userMemberships, isTipsLoading, isTipsFetched };
};
