import { Chat, ChannelList } from 'stream-chat-react';
import ChannelPreview from '@ui-modules/chat/components/ChannelPreview';
import ChatCreatingChannelPreview from '@ui-modules/chat/components/ChatCreatingChannelPreview';
import {
	useService,
	useMe,
	useNavigate,
	useQuery,
	useChat,
	useDebounce,
	useEvent,
	useLocation,
	useParams,
} from '@hooks';
import { useChannelFlaggedMessages } from '@ui-modules/chat/hooks/useChannelFlaggedMessages';
import type { MessageResponse, Channel, DefaultGenerics } from 'stream-chat';
import type { ChannelPreviewUIComponentProps } from 'stream-chat-react';
import { uniqBy } from '@utils';
import { ROUTES } from '@constants';
import { ActivityIndicator } from '@ui-kit';
import styles from './ChatChannelList.module.css';

const ChatChannelList = ({ filterQuery }: IChannelListProps) => {
	const { user } = useMe();
	const navigate = useNavigate();
	const location = useLocation();
	const channelId = useParams<{ channelId?: string }>().channelId;

	const {
		reset,
		setSelectedChannel,
		shouldUpdateChannel,
		setShouldUpdateChannel,
		selectedChannel,
		removedChannelIds,
		setRemovedChannelsId,
	} = useChat();
	const [debouncedSearchQuery] = useDebounce(filterQuery, 600);
	const { setFlaggedMessageIds } = useChannelFlaggedMessages();

	const chat = useService('ChatService');
	const filters = chat.getChannelListFilters(debouncedSearchQuery, user.slug);

	const isChannelArtificialPreviewVisible =
		location.pathname.startsWith(ROUTES.createChat()) || location.pathname.startsWith(ROUTES.createChatGroup(''));

	const { refetch } = useQuery(['chat.getFirstChannel'], async () => await chat.getFirstChannel(user.slug), {
		staleTime: 0,
		cacheTime: 0,
		onSuccess(firstChannel) {
			if (firstChannel && shouldUpdateChannel && !selectedChannel) {
				setSelectedChannel(firstChannel);
				reset();
				navigate(ROUTES.chatChannel(firstChannel.id));
			} else if (selectedChannel && shouldUpdateChannel) {
				navigate(ROUTES.chatChannel(selectedChannel.id));
				setShouldUpdateChannel(true);
			}
		},
		enabled: !channelId, // prevent race queries with ChatChannelPage when channelId is present.
	});
	/*
	  It this works then change it to onAddedToChannel or any other places we are using : (HARESH)
	*/
	const onRemovedFromChannel = async (setChannels: React.Dispatch<React.SetStateAction<Channel[]>>, event: any) => {
		const channelId = event.channel.id;
		const currentRemovedChannelIds = removedChannelIds ? [...removedChannelIds, channelId] : [channelId];
		setRemovedChannelsId(currentRemovedChannelIds);
		setChannels((channels: Channel[]) => {
			const updatedChannels = channels.filter((channel) => !currentRemovedChannelIds.includes(channel.id));
			if (updatedChannels.length > 0) {
				setSelectedChannel(updatedChannels[0]);
				navigate(ROUTES.chatChannel(updatedChannels[0].id));
			} else {
				setSelectedChannel(null);
				navigate(ROUTES.chat());
			}
			return updatedChannels;
		});

		const channel = chat.chatClient.channel(event.channel.type, event.channel.id);
		channel.stopWatching().catch((error) => {
			// ignore error. It's fine if the channel is already stopped. The error can be unexpectedly thrown when the chat channel is deletes (@see T21C-7371)
		});
	};
	const onAddedToChannel = async (setChannels: React.Dispatch<React.SetStateAction<Channel[]>>, event: any) => {
		const channel = chat.chatClient.channel(event.channel.type, event.channel.id);
		await channel.watch();
		setChannels((channels: Channel[]) => {
			return uniqBy([channel, ...channels], 'cid');
		});
		setRemovedChannelsId(removedChannelIds?.filter((channel) => channel !== event.channel.id) ?? []);
	};

	const onChannelItemPressed = (channel: Channel) => {
		if (selectedChannel && selectedChannel.id === channel.id) return;
		reset();
		setFlaggedMessageIds(undefined);
		navigate(ROUTES.chatChannel(channel.id));
	};

	const onChannelDeleted = async (setChannels: React.Dispatch<React.SetStateAction<Channel[]>>, event: any) => {
		const channel = chat.chatClient.channel(event.channel.type, event.channel.id);
		setChannels((channels: Channel[]) => {
			if (channels.length > 0) {
				if (channel.id === channels[0].id) {
					setSelectedChannel(channels[1]);
					navigate(ROUTES.chatChannel(channels[1].id));
				} else {
					setSelectedChannel(channels[0]);
					navigate(ROUTES.chatChannel(channels[0].id));
				}
			}
			return channels.filter((item) => item.id !== channel.id);
		});
		reset();
		refetch();
		channel.stopWatching().catch((error) => {
			// ignore error. It's fine if the channel is already stopped. The error can be unexpectedly thrown when the chat channel is deletes (@see T21C-7371)
		});
	};

	const Preview = useEvent((props: ChannelPreviewUIComponentProps<DefaultGenerics>) => {
		if (removedChannelIds?.includes(`${props.channel.id}`)) return null;
		return (
			<ChannelPreview
				currentChannel={props.channel}
				lastMessage={props.lastMessage as MessageResponse}
				unread={props.unread}
				onClick={onChannelItemPressed}
			/>
		);
	});

	return (
		<Chat client={chat.chatClient} theme="str-chat__theme-light">
			<ChatCreatingChannelPreview visible={isChannelArtificialPreviewVisible} />
			{!selectedChannel && !isChannelArtificialPreviewVisible && (
				<div className={styles.loadingContainer}>
					<ActivityIndicator type="fit" />
				</div>
			)}
			{!isChannelArtificialPreviewVisible && (
				<ChannelList
					customActiveChannel={selectedChannel?.id}
					filters={filters}
					Preview={Preview}
					setActiveChannelOnMount={true}
					onAddedToChannel={onAddedToChannel}
					onChannelDeleted={onChannelDeleted}
					onRemovedFromChannel={onRemovedFromChannel}
				/>
			)}
		</Chat>
	);
};

export interface IChannelListProps {
	filterQuery: string;
}

export default ChatChannelList;
