import ClampLines from 'react-multiline-clamp';
import { useService, useTranslation } from '@hooks';
import { useGlobalSearchNavigation } from '@ui-modules/globalSearch/hooks/useGlobalSearchNavigation';
import { useGlobalSearchState } from '@ui-modules/globalSearch/hooks/useGlobalSearchState';
import { useGlobalSearchHistory } from '@ui-modules/globalSearch/hooks/useGlobalSearchHistory';
import type { TSearchResult } from '@typings';
import styles from './SearchResult.module.css';
import { IconByDefinition } from './IconByDefinition';
import { SubtitleByDefinition } from './SubtitleByDefinition';
import { noop } from '@utils';

const SearchResult = ({
	onClick,
	...props
}: TSearchResult & {
	onClick: (searchResult: TSearchResult) => void;
}) => {
	const {
		title,
		definition,
		searchQuery,
		avatar,
		name,
		expertise,
		investmentExpertise,
		interests,
		communities,
		startDate,
		communityName,
		virtualType = false,
		inPersonType = false,
		biography,
		profileType,
		assetClass,
		mimeType,
		relatedEntityName,
		tags,
		reviewsCount,
	} = props;
	const { t } = useTranslation();

	const membersPotentialMatches = {
		expertise,
		interests,
		communities,
		biography,
		investmentExpertise,
	};

	const eventOrMeetingType =
		virtualType && inPersonType ? t('virtual • in person') : virtualType ? t('virtual') : t('in person event');

	const boldFragmentBySearchQuery = new RegExp(searchQuery, 'gi');
	const parsedName = name?.replace(boldFragmentBySearchQuery, (substring) => '<b>' + substring + '</b>') || '';
	const parsedTitle = title?.replace(boldFragmentBySearchQuery, (substring) => '<b>' + substring + '</b>') || '';

	return (
		<div className={styles.searchResult__wrapper} onClick={() => onClick(props)} onKeyPress={noop}>
			{IconByDefinition({ definition, avatar, name, profileType, mimeType })}
			<div className={styles.searchResult__dataWrapper}>
				{parsedName || parsedTitle ? (
					<ClampLines lines={3} showLessElement={() => null} showMoreElement={() => null}>
						<span
							className={styles.searchResult__title}
							dangerouslySetInnerHTML={{ __html: parsedName || parsedTitle }}
						></span>
					</ClampLines>
				) : null}
				<SubtitleByDefinition
					assetClass={assetClass}
					communityName={communityName}
					definition={definition}
					eventOrMeetingType={eventOrMeetingType}
					membersPotentialMatches={membersPotentialMatches}
					relatedEntityName={relatedEntityName}
					reviewsCount={reviewsCount}
					searchQuery={searchQuery}
					startDate={startDate}
					tags={tags}
				/>
			</div>
		</div>
	);
};
export default SearchResult;
