import { Outlet, Page, PageLayout, TabWrapper } from '../../components';
import { SearchableScreenHeader, Backdrop, Tabs, When } from '@ui-kit';
import {
	useTranslation,
	useState,
	useEvent,
	useNavigate,
	useLocation,
	useService,
	useRestoreVirtuosoInitialIndex,
} from '@hooks';
import { INVEST_NETWORKS_TAB_SLUG, ROUTES } from '@constants';
import { clsx } from '@utils';
import styles from './NetworksPage.module.css';
import type { TCommunityCompact, TCommunity } from '@typings';
import { useNetworksTabsState } from '@ui-modules/networks/hooks/useNetworksTabsState';
import NetworkSectionList from '@ui-modules/networks/components/NetworkSectionList/NetworkSectionList';
import { useNetworkQueriesBreakdown } from '@ui-modules/networks/hooks/useNetworkQueriesBreakdown';
import LearnTabList from '@ui-modules/feed/components/LearnTabList';
import { useNetworkSearchContext } from '@ui-modules/networks/contexts/NetworkSearchContext';

const NetworksPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [searchQuery, setSearchQuery] = useState('');
	const { communityTabs, currentTabIndex, onTabChanged, isTabRoute, networkId } = useNetworksTabsState(!!searchQuery);
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const { initialTopMostItemIndex, setInitialTopMostItemIndex, onRangeChanged } = useRestoreVirtuosoInitialIndex(
		'NetworksPage__NetworkSectionList',
	);

	const { isSearchActive, setIsSearchActive, searchLearnQuery, setSearchLearnQuery } = useNetworkSearchContext();

	const isLearnTab = location.pathname.includes(ROUTES.learn());

	const handleTabChanged = (index: number) => {
		const selectedTab = communityTabs[index];
		if (!selectedTab) {
			navigate(ROUTES.networkLearnTab('all'));
			onTabChanged(index);
		} else {
			onTabChanged(index);
		}
	};

	const handleSelectNetwork = useEvent((network: TCommunity | TCommunityCompact, networkTab: string, index: number) => {
		// re-fetch all the queries that have been created for latest deal filter details [@hrant]
		if (networkTab === INVEST_NETWORKS_TAB_SLUG && network.id) {
			queryClient.invalidateQueries(queryKeys.getDealFilterViaCommunityId(network.id));
			queryClient.refetchQueries(queryKeys.getAllDealFilters());
		}
		navigate(ROUTES.networkTab(network.id));
		if (!isSearchActive) setInitialTopMostItemIndex(index);
	});
	const handleFirstNetworkLoaded = useEvent((firstLoadedNetwork: TCommunityCompact | undefined) => {
		if (firstLoadedNetwork && (isTabRoute || location.pathname === ROUTES.networks())) {
			setTimeout(() => navigate(ROUTES.networkTab(firstLoadedNetwork.id), { replace: true }), 10); // To resolve "You should call navigate() in a React.useEffect(), not when your component is first rendered." [@dmitriy.nikolenko]
		}
	});

	const { isAnyNetworkLoaded } = useNetworkQueriesBreakdown('invest', '');

	const isInvestNetworkSelected = communityTabs[currentTabIndex]?.slug === INVEST_NETWORKS_TAB_SLUG;

	return (
		<Page title={t('Networks')}>
			<PageLayout
				containerMode={false}
				sidebarContent={
					<>
						<SearchableScreenHeader
							placeholder={isLearnTab ? t('Search') : t('Search networks')}
							searchQuery={isLearnTab ? searchLearnQuery : searchQuery}
							searchTitle={t('Search')}
							title={isLearnTab ? t('Learn') : t('Networks')}
							onBlur={() => setIsSearchActive(false)}
							onChangeSearchQuery={isLearnTab ? setSearchLearnQuery : setSearchQuery}
							onFocus={() => setIsSearchActive(true)}
						/>
						<Tabs selectedIndex={isLearnTab ? communityTabs.length : currentTabIndex} onSelect={handleTabChanged}>
							<When condition={isAnyNetworkLoaded}>
								<Tabs.List
									backgroundColor="#f9f9f9"
									className={clsx(
										styles.tabsList,
										navigator.userAgent.includes('Windows') &&
											(isInvestNetworkSelected ? styles.tabsList_invest : styles.tabsList_lifestyle),
									)}
								>
									{communityTabs.map((communityTab, index) => {
										const isInvestNetwork = communityTab.slug === INVEST_NETWORKS_TAB_SLUG;
										return (
											<Tabs.Tab
												disabled={currentTabIndex === index}
												key={communityTab.slug}
												selectedClassName={clsx(isInvestNetwork && styles.tab)}
											>
												{communityTab.label}
											</Tabs.Tab>
										);
									})}
									<Tabs.Tab disabled={currentTabIndex === communityTabs.length} key={'learn'}>
										{t('Learn')}
									</Tabs.Tab>
								</Tabs.List>
							</When>
							{communityTabs.map((communityTab) => {
								const isInvestNetwork = communityTab.slug === INVEST_NETWORKS_TAB_SLUG;
								const showTabPanel = isInvestNetwork ? isAnyNetworkLoaded : true;
								return (
									<When condition={showTabPanel} key={communityTab.slug}>
										<Tabs.Panel>
											<TabWrapper>
												<NetworkSectionList
													initialTopMostItemIndex={initialTopMostItemIndex}
													networkTab={communityTab.slug}
													rangeChanged={onRangeChanged}
													searchQuery={searchQuery}
													selectedNetworkId={networkId}
													onFirstNetworkLoaded={handleFirstNetworkLoaded}
													onSelectNetwork={(network, index) => handleSelectNetwork(network, communityTab.slug, index)}
												/>
											</TabWrapper>
										</Tabs.Panel>
									</When>
								);
							})}
							<Tabs.Panel>
								<TabWrapper>
									<LearnTabList />
								</TabWrapper>
							</Tabs.Panel>
						</Tabs>
					</>
				}
			>
				<Outlet />
				<Backdrop hidden={!isSearchActive || (isSearchActive && isLearnTab)} />
			</PageLayout>
		</Page>
	);
};
export default NetworksPage;
