import { useEvent, useService } from '@hooks';
import { useFileViewer } from '@ui-modules/files/components/FileViewer';
import DocumentFileViewer from '@ui-modules/files/components/FileViewer/DocumentFileViewer';
import PdfFileViewer from '@ui-modules/files/components/FileViewer/PdfFileViewer';
import type { TFile } from '@typings';
import { downloadFile, extractFileExtensionFromUrl } from '@utils';

export const useOpenFile = () => {
	const fileViewer = useFileViewer();
	const api = useService('ApiService');

	const canOpenFileExtension = useEvent((fileExtension: string) => {
		return (
			PdfFileViewer.canHandleFileExtension(fileExtension) || DocumentFileViewer.canHandleFileExtension(fileExtension)
		);
	});

	const canOpenMimeType = useEvent((mimeType: string) => {
		return PdfFileViewer.canHandleMimeType(mimeType) || DocumentFileViewer.canHandleMimeType(mimeType);
	});

	const openFile = useEvent(async (file: TFile, forbidDownloading?: boolean) => {
		if (canOpenMimeType(file.mediaObject.mimeType)) {
			fileViewer.open(file, forbidDownloading);
		} else {
			api.mediaObject.downloadNonEncryptedFileUrl(file);
		}
	});

	const openFileUrl = useEvent((fileUrl: string | Blob, forbidDownloading = false) => {
		if (typeof fileUrl !== 'string') {
			return fileViewer.open(fileUrl, forbidDownloading);
		}

		const fileExtension = extractFileExtensionFromUrl(fileUrl);
		if (canOpenFileExtension(fileExtension)) {
			fileViewer.open(fileUrl, forbidDownloading);
		} else {
			downloadFile(fileUrl);
		}
	});

	return { openFileUrl, openFile, canOpenFileExtension, canOpenMimeType };
};
