import { POST_TITLE_CHAR_LIMIT } from '@constants';
import { useTranslation } from '@hooks';
import type { TPostCreation } from '@schemas';
import { AutoGrowInput, DropdownMenu, Gap } from '@ui-kit';
import type { FormikProps } from 'formik';
import styles from './PostCreationForm.module.css';

const PostCreationForm = ({
	isOnEditMode,
	networkId,
	formProps,
	networkOptions,
	hideNetworkList,
}: IPostCreationFormProps) => {
	const { t } = useTranslation();
	const { values, errors, setFieldValue, handleBlur } = formProps;

	return (
		<div className={styles.post__destination}>
			{isOnEditMode || networkId || hideNetworkList ? null : (
				<DropdownMenu
					description={errors.network ? undefined : t('Posts in your Networks are also shown in Home')}
					errorMessage={errors.network ? t('Please select a Network to post in') : ''}
					label={t('Post in...')}
					options={networkOptions}
					placeholder={t('select a Network')}
					value={values.network}
					variant="medium"
					onChange={(item) => {
						setFieldValue('network', item, true);
					}}
				/>
			)}
			<div>
				<Gap gap={12} />
				<AutoGrowInput
					errorMessage={
						(values.title.length > POST_TITLE_CHAR_LIMIT &&
							t('{{countOfCharacters}} of {{maxCharacters}} characters max', {
								countOfCharacters: values.title.length,
								maxCharacters: POST_TITLE_CHAR_LIMIT,
							})) ||
						errors.title
					}
					fieldName="title"
					label={t('Title')}
					placeholder={t('Add a title')}
					rows={1}
					setFieldValue={setFieldValue}
					value={values.title}
					onBlur={handleBlur('title')}
				/>
			</div>
		</div>
	);
};

interface IPostCreationFormProps {
	formProps: FormikProps<TPostCreation>;
	isOnEditMode: boolean;
	networkId?: string;
	networkOptions: {
		value: string;
		title: string;
		label?: string;
	}[];
	hideNetworkList: boolean;
}
export default PostCreationForm;
