import { transformSearchDefinition } from './ExpandableResultsBlock.utils';
import { GLOBAL_SEARCH } from '@constants';
import { useCallback, useTranslation } from '@hooks';
import { useExpandableResultsBlockPersist } from './useExpandableResultsBlockPersist';
import { useSearchResultByDefinition } from '@ui-modules/globalSearch/hooks/useSearchResultByDefinition';
import type {
	TExpandableResultsBlockProps,
	TSearchHitEventOrMeeting,
	TSearchByIndexResponse,
	TSearchResult,
} from '@typings';
import styles from './ExpandableResultsBlock.module.css';
import { SearchResultByDefinition } from '../SearchResult/SearchResultByDefinition';

const ExpandableResultsBlock = ({
	searchQuery,
	definition,
	results,
	initialSearchResultCount = GLOBAL_SEARCH.limitOfSearchResultsByCategory,
	onClick,
}: TExpandableResultsBlockProps & {
	initialSearchResultCount?: number;
	onClick: (searchResult: TSearchResult) => void;
}) => {
	const { t } = useTranslation();

	const { setExpandableStatusToStorage, getExpandableStatusFromStorage } = useExpandableResultsBlockPersist();
	const forbidExpand = !searchQuery.length; // with empty search query number of results may be too big. That's why we should not allow a user to expand thousands of results but require to enter a search query

	// when we concat Events and Meetings for single Calendar group, we need to count correct nbHits for both categories
	const getNbHitsForCalendar = (results: TSearchByIndexResponse[], definition: string) => {
		if (definition === 'calendar') {
			const t = results as TSearchHitEventOrMeeting[];
			const eventNbHits = t?.find((item) => item.definition === 'event')?.nbHits;
			const meetingNbHits = t?.find((item) => item.definition === 'meeting')?.nbHits;
			const calendarNbHits = eventNbHits && meetingNbHits ? eventNbHits + meetingNbHits : results?.length;
			return calendarNbHits;
		} else {
			return results[0].nbHits;
		}
	};

	// data for working with search category
	const { searchResultByIndex, refetch } = useSearchResultByDefinition(
		searchQuery,
		definition,
		getNbHitsForCalendar(results, definition),
	);

	// formatting data for first render --- do we have 3 entities or more, do we need to render full list or not, etc.
	const moreThanThreeEntities = results.length > initialSearchResultCount;
	const entities = moreThanThreeEntities ? results.slice(0, initialSearchResultCount) : results;

	/* searchResultByIndex || entities is a back-up if searchResultByIndex is by any chance undefined [Issue Link](https://tiger21.atlassian.net/browse/T21C-7624) */
	const renderAllByExpandableState = getExpandableStatusFromStorage(definition, searchQuery)
		? searchResultByIndex || entities
		: entities;

	const getSeeAllButton = useCallback(
		(nbHits: number, def: string) => {
			return (
				<div
					className={styles.expandableBlock__showAllWrapper}
					onClick={() => {
						refetch();
						setExpandableStatusToStorage(definition, searchQuery);
					}}
					onKeyPress={() => {
						refetch();
						setExpandableStatusToStorage(definition, searchQuery);
					}}
				>
					<span>{`${t('Show all')} ${nbHits}`}</span>
					&nbsp;
					<span className={styles.expandableBlock__category}>{`${transformSearchDefinition(def, true)}`}</span>
				</div>
			);
		},
		[searchQuery],
	);

	return (
		<div
			style={{
				marginBottom: 20,
			}}
		>
			{entities.length ? (
				<span className={styles.expandableBlock__definition}>
					{transformSearchDefinition(definition, entities?.length > 1, true)}
				</span>
			) : null}
			{renderAllByExpandableState &&
				renderAllByExpandableState.map((result, index) => {
					return (
						result && (
							<div key={result.objectID}>
								<SearchResultByDefinition
									key={result.objectID}
									result={{ ...result, searchQuery, definition: result.definition }}
									onSelect={onClick}
								/>
								{moreThanThreeEntities &&
									!forbidExpand &&
									index + 1 === initialSearchResultCount &&
									!getExpandableStatusFromStorage(definition, searchQuery) &&
									getSeeAllButton(result.nbHits, definition)}
							</div>
						)
					);
				})}
		</div>
	);
};

export default ExpandableResultsBlock;
