import { useImperativeHandle, useRef } from '@hooks';
import styles from './SearchBottomSheetModal.module.css';
import { BackIcon, BottomSheetModal, HeaderSearchInput } from '@ui-kit';
import { forwardRef, type ForwardedRef } from 'react';
import type { IBottomSheetModalMethods, IBottomSheetModalProps } from '@ui-kit';

const SearchBottomSheetModal = (
	{ children, height, searchQuery, onExitComplete, onChangeSearchQuery }: ISearchBottomSheetModalProps,
	ref: ForwardedRef<IBottomSheetModalMethods>,
) => {
	const bottomSheetModalRef = useRef<IBottomSheetModalMethods>(null);
	useImperativeHandle(ref, () => bottomSheetModalRef.current as IBottomSheetModalMethods);

	return (
		<BottomSheetModal
			height={height}
			ref={bottomSheetModalRef}
			onExitComplete={() => {
				onChangeSearchQuery('');
				onExitComplete?.();
			}}
		>
			<div className={styles.searchBottomSheetModal__header}>
				<button
					className={styles.searchBottomSheetModal__closeButton}
					onClick={() => {
						bottomSheetModalRef.current?.hide?.();
					}}
				>
					<BackIcon />
				</button>
				<div className={styles.searchBottomSheetModal__inputBox}>
					<HeaderSearchInput
						autoFocus
						inputClassName={styles.searchBottomSheetModal__input}
						value={searchQuery}
						onChange={(event) => onChangeSearchQuery(event.target.value)}
						onSearchCleared={() => onChangeSearchQuery('')}
					/>
				</div>
			</div>
			<div className={styles.searchBottomSheetModal__body}>{children}</div>
		</BottomSheetModal>
	);
};

export interface ISearchBottomSheetModalProps extends IBottomSheetModalProps {
	searchQuery: string;
	onChangeSearchQuery: (value: string) => void;
}

export default forwardRef(SearchBottomSheetModal);
