import { compact, InternalShareEntity, uniq } from '@utils';
import type { MessageResponse } from 'stream-chat';
import type { TShareIntentInternalShareType } from '@typings';
import {
	ChatVideoIcon,
	ChatPhotoIcon,
	ChatFileIcon,
	ChatGiphyIcon,
	ChatEventIcon,
	DealsIcon,
	NetworkIcon,
	CalendarIcon,
	UsersIcon,
	LampIconSolid,
	AttachFileIcon,
	ChaptersIcon,
} from '@ui-kit/icons';
import type { FunctionComponent, SVGProps } from 'react';

export const getChatMessageAttachedContents = (lastMessage: MessageResponse | undefined): TAttachedContent[] => {
	if (!lastMessage) return [];

	const attachments = lastMessage?.attachments ?? [];
	const userAttachmentTypes = attachments
		.filter(
			(attachment) =>
				// exclude preview images made by GetStream for links
				(attachment.type === 'image' && !attachment.title_link) ||
				// exclude all empty type attachments
				(attachment.type && attachment.type !== 'image'),
		)
		.map((attachment) => attachment.type) as TAttachmentType[];
	const attachmentAttachedContents: TAttachedContent[] = uniq(userAttachmentTypes).map((type) => ({
		kind: 'attachment',
		type,
	}));

	const sharingType =
		typeof lastMessage.sharedContent === 'string'
			? (new InternalShareEntity().internalSharedContentOrienteering(lastMessage?.sharedContent)
					?.type as TShareIntentInternalShareType)
			: null;
	const sharingAttachedContent: TAttachedContent | null =
		sharingType && !sharingType.startsWith('undefined') ? { kind: 'sharing', type: sharingType } : null;

	const isMessageHasEvent = !!lastMessage?.eventId;
	const eventAttachedContent: TAttachedContent | null = isMessageHasEvent ? { kind: 'sharing', type: 'event' } : null;

	const attachedContents = compact([...attachmentAttachedContents, eventAttachedContent, sharingAttachedContent]);

	return attachedContents;
};

export const getChatMessageAttachedContentIcon = (
	attach: TAttachedContent,
): FunctionComponent<SVGProps<SVGSVGElement>> | null => {
	if (attach.kind === 'attachment') {
		switch (attach.type) {
			case 'video':
				return ChatVideoIcon;
			case 'image':
				return ChatPhotoIcon;
			case 'file':
				return ChatFileIcon;
			case 'giphy':
				return ChatGiphyIcon;
			case 'event':
				return ChatEventIcon;
		}
	} else if (attach.kind === 'sharing') {
		switch (attach.type) {
			case 'deal':
			case 'deal_post':
				return DealsIcon;
			case 'network':
				return NetworkIcon;
			case 'event':
			case 'meeting':
				return CalendarIcon;
			case 'group':
				return UsersIcon;
			case 'chapter':
				return ChaptersIcon;
			case 'discussion':
			case 'network_connection_post':
			case 'network_connection':
				return AttachFileIcon;
			case 'tip':
				return LampIconSolid;
		}
	}
	return null;
};

export type TAttachmentType = 'image' | 'video' | 'event' | 'file' | 'giphy';
export type TAttachedContent =
	| { kind: 'attachment'; type: TAttachmentType }
	| { kind: 'sharing'; type: TShareIntentInternalShareType };
