import { Page } from '../../components';
import { useParams, useMe, useTranslation, useEvent, useTrackAlgoliaEventEffect } from '@hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import { ActivityIndicator, Button, ExternalLinkIcon, Gap, ShareOutlineIcon } from '@ui-kit';
import EventLocationFlag from '@ui-modules/events/components/EventLocationFlag';
import { clsx, MeetingEntity } from '@utils';
import MeetingActionButton from '@ui-modules/events/components/MeetingActionButton';
import { useEventAttendanceMutations } from '@ui-modules/events/hooks/useEventAttendanceMutations';
import EventDateAndTimeCard from '@ui-modules/events/components/EventDateAndTimeCard';
import EventLocationCard from '@ui-modules/events/components/EventLocationCard';
import EventHostedByCard from '@ui-modules/events/components/EventHostedByCard';
import EventAgenda from '@ui-modules/events/components/EventAgenda';
import EventFileList from '@ui-modules/events/components/EventFileList';
import EventSpeakersDetails from '@ui-modules/events/components/EventSpeakersDetails';
import MeetingAttendeeList from '@ui-modules/events/components/MeetingAttendeeList';
import EventAddToCalendarButton from '@ui-modules/events/components/EventAddToCalendarButton';
import { useMeetingData } from './MeetingPage.useMeetingData';
import { MeetingPageToast } from './MeetingPageToast';
import styles from './MeetingPage.module.css';
import EventNewTip from '@ui-modules/events/components/EventNewTip';
import MeetingTipsList from '@ui-modules/tipJar/components/MeetingTipsList';
import CheckUserFeatureWrapper from '../../components/CheckUserFeatureWrapper';
import { useInternalShareContext } from '@ui-modules/share/hooks/useInternalShare';
import MeetingPageHeader from './MeetingPageHeader';

const MeetingPage = ({ scrollable = true }: IMeetingPageProps) => {
	const eventId = useParams<IMeetingPage>().eventId as string;
	const navigate = useNavigate();
	const { user } = useMe();
	const { t } = useTranslation();

	const { setInternalShare } = useInternalShareContext();

	const { isSuccess, meeting, community, eventFiles, tips, userMemberships, isTipsLoading, isTipsFetched } =
		useMeetingData(eventId);

	const {
		changeEventAttendanceMutation,
		requestEventAttendanceMutation,
		cancelEventAttendanceMutation,
		launchVirtualMeeting,
	} = useEventAttendanceMutations();

	const openMemberProfile = useEvent(({ profile }) => {
		if (profile?.roles?.includes('ROLE_FORMER')) {
			return navigate(ROUTES.memberProfile(profile.profileId));
		} else if (profile?.roles?.includes('ROLE_CHAIR')) {
			return navigate(ROUTES.memberProfile(profile.profile.id));
		}
		return navigate(ROUTES.memberProfile(profile.id));
	});

	useTrackAlgoliaEventEffect('Meeting Viewed', { meetingSlug: String(meeting?.id) }, [meeting?.id]);

	const onShareClickHandler = () => {
		setInternalShare({
			type: 'meeting',
			id: meeting?.id,
		});
	};

	return (
		<Page title={meeting?.title || '...'}>
			{!meeting && <ActivityIndicator size="medium" type="fit" />}
			{meeting && (
				<div>
					<MeetingPageHeader meeting={meeting} />
					<div className={clsx(styles.meetingPage__contentWrapper, !scrollable && styles.meetingPage__notScrollable)}>
						<div className={styles.meetingPage__content}>
							<MeetingPageToast meeting={meeting} />
							{isSuccess && (
								<>
									<div>
										{meeting.banner?.contentUrl ? (
											<div className={styles.meetingPag__bannerBox}>
												<img
													alt="Event banner"
													className={styles.meetingPage__banner}
													src={meeting.banner?.contentUrl}
												/>
											</div>
										) : null}
										<div className={styles.meetingPage__actionBox}>
											<div className={styles.meetingPage__locationFlag}>
												<EventLocationFlag eventLocationInfo={meeting} />
											</div>
											<div className={styles.meetingPage__actionButtons}>
												{MeetingEntity.shouldShowAttendanceUrl(meeting, user) ? (
													<>
														<Button
															icon={<ExternalLinkIcon height={14} width={16} />}
															iconPosition="right"
															title={t('Take Attendance')}
															type="outline"
															variant="medium"
															onClick={() => window.open(meeting.attendanceUrl, 'blank')}
														/>
														<Gap gap={8} />
													</>
												) : null}
												<MeetingActionButton
													detailed
													meeting={meeting}
													size="medium"
													onCancelRequestAttendance={cancelEventAttendanceMutation.mutateAsync}
													onChangeAttendance={changeEventAttendanceMutation.mutateAsync}
													onLaunchVirtualMeeting={launchVirtualMeeting}
													onRequestAttendance={requestEventAttendanceMutation.mutateAsync}
												/>
											</div>
										</div>
										<h2 className={styles.meetingPage__title}>{meeting.title}</h2>
									</div>
									<div className={styles.meetingPage__box}>
										{MeetingEntity.canBeAddedToCalendarV2(meeting, user) && eventId ? (
											<EventAddToCalendarButton eventId={eventId} />
										) : null}

										<CheckUserFeatureWrapper featureName="tips">
											<EventNewTip eventId={eventId} />
										</CheckUserFeatureWrapper>
										<Button
											className={styles.meetingPage__share}
											icon={<ShareOutlineIcon fill="var(--color-link)" height={18} width={18} />}
											iconPosition="right"
											title={t('Share')}
											type="clear"
											onClick={onShareClickHandler}
										/>
									</div>
									<Gap gap={26} />
									<EventDateAndTimeCard eventDatesInfo={meeting} />
									{MeetingEntity.shouldShowLocationAndAgendaV2(meeting, user, userMemberships) ? (
										<EventLocationCard
											event={meeting}
											showConferenceLink={MeetingEntity.shouldShowConferenceLinkV2(meeting, user, userMemberships)}
										/>
									) : null}
									{meeting.hosts?.length ? (
										<EventHostedByCard eventHosts={meeting.hosts} onClick={openMemberProfile} />
									) : null}
									{meeting.agenda && MeetingEntity.shouldShowLocationAndAgendaV2(meeting, user, userMemberships) ? (
										<EventAgenda agenda={meeting.agenda} />
									) : null}
									{MeetingEntity.shouldShowFiles(meeting, community, user) ? (
										<EventFileList
											canDelete={(file) => MeetingEntity.canDeleteFile(meeting, file, user, community)}
											canMakeDownloadable={(file) =>
												MeetingEntity.canMakeFileDownloadable(meeting, file, user, community)
											}
											canMakeViewOnly={(file) => MeetingEntity.canMakeFileViewOnly(meeting, file, user, community)}
											canUpload={MeetingEntity.canUploadFiles(meeting, community, user)}
											eventFiles={eventFiles}
											eventId={eventId as string}
										/>
									) : null}
									{meeting.speakers?.length ? <EventSpeakersDetails event={meeting} /> : null}
									{tips?.length && MeetingEntity.canSeeTipsV2(meeting, userMemberships, user) && eventId ? (
										<CheckUserFeatureWrapper featureName="tips">
											<MeetingTipsList
												eventId={eventId}
												isFetched={isTipsFetched}
												isLoading={isTipsLoading}
												meetingId={meeting.id}
												tips={tips}
											/>
										</CheckUserFeatureWrapper>
									) : null}
									<MeetingAttendeeList goToProfile={openMemberProfile} meetingId={meeting.id} />
								</>
							)}
						</div>
					</div>
				</div>
			)}
		</Page>
	);
};

interface IMeetingPageProps {
	scrollable?: boolean;
}

interface IMeetingPage extends Record<string, string | undefined> {
	eventId?: string;
}

export default MeetingPage;
