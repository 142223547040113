import { ActivityIndicator } from '@ui-kit';
import { FormPageLayout, Page } from '../../components';
import ReportContentForm from '@ui-modules/account/components/ReportContentForm';
import FeedActivityPreview from '@ui-modules/feed/components/FeedActivityPreview';
import FeedCommentPreview from '@ui-modules/feed/components/FeedCommentPreview';
import ChatMessagePreview from '@ui-modules/chat/components/ChatMessagePreview';
import DealPost from '@ui-modules/deals/components/DealPost';
import { useTranslation, useSearchParams, useHandleBackButton, useReportContent } from '@hooks';
import { noop } from '@utils';
import { ROUTES } from '@constants';
import styles from './ReportContentPage.module.css';
import type { TDealWithReactions, TNetworkConnectionWithReactions, TReportContentType } from '@typings';
import NetworkConnectionPost from '@ui-modules/connections/components/NetworkConnectionPost';
import TipReviewCard from '@ui-modules/tipJar/components/TipReviewCard';
import TipCard from '@ui-modules/tipJar/components/TipCard';

/** Allows user to send a complaint about inappropriate content. Covers both chat & feed. */
const ReportContentPage = () => {
	// Dependencies.
	const { t } = useTranslation();
	const { onBackButtonPress } = useHandleBackButton();

	// Route params.
	const [searchParams] = useSearchParams();
	const type = searchParams.get('type') as IReportContentPageParams['type'];
	const id = searchParams.get('id') as IReportContentPageParams['id'];
	const feedSlug = searchParams.get('feedSlug') as IReportContentPageParams['feedSlug'];

	const { reportContent, chatMessage, activity, comment, deal, networkConnection, tipReview, tip } = useReportContent(
		{ type, id, feedSlug },
		{ onSuccess: onBackButtonPress },
	);

	const renderContentPreview = () => {
		if (type === 'chat-message' && chatMessage) return <ChatMessagePreview message={chatMessage} />;
		else if (type === 'comment' && comment) return <FeedCommentPreview comment={comment} />;
		else if (type === 'activity' && activity) return <FeedActivityPreview activity={activity} />;
		else if (type === 'deal' && deal)
			return (
				<DealPost
					canSeePreview={false}
					deal={deal as TDealWithReactions}
					likingDisabled
					previewMode
					onLike={noop}
					onToggleAlerts={noop}
				/>
			);
		else if (type === 'connection' && networkConnection)
			return (
				<NetworkConnectionPost
					networkConnection={networkConnection as TNetworkConnectionWithReactions}
					previewMode
					onLike={noop}
				/>
			);
		else if (type === 'tip-review' && tipReview) return <TipReviewCard tipReview={tipReview} />;
		else if (type === 'tip' && tip) return <TipCard tip={tip} />;
		else return <ActivityIndicator size="small" type="fit" />;
	};

	return (
		<Page title={t('Report inappropriate content')}>
			<ReportContentForm
				contentPreviewElement={renderContentPreview()}
				renderWrapper={({ formProps, children }) => (
					<FormPageLayout
						allowedNextPaths={[ROUTES.reportContent()]}
						formProps={formProps}
						headerTitle={t('Report')}
						isBackgroundTransparent
						isSaveButtonHidden
					>
						<div className={styles.reportContentPage}>{children}</div>
					</FormPageLayout>
				)}
				type={type}
				onSubmit={reportContent}
			/>
		</Page>
	);
};

export interface IReportContentPageParams extends Record<string, string | undefined> {
	/** For which type of the entity a complaint is creating. */
	type: TReportContentType;
	/** Depends on 'type' may be Feed activityId, Feed commentId, Chat chatMessageId. */
	id: string;
	feedSlug?: string;
}

export default ReportContentPage;
