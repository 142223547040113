import {
	FixedWidthContainer,
	ActivityIndicator,
	Button,
	SpacedGroup,
	When,
	ExternalLinkIcon,
	ShareArrowIcon,
} from '@ui-kit';
import LeaveNetworkModal from '@ui-modules/networks/components/LeaveNetworkModal';
import { useChat, useNavigate, useToggle, useTranslation, useEvent } from '@hooks';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { CommunityEntity, translateCommunityMembershipType } from '@utils';
import NetworkAccessibilityHint from '../NetworkAccessibilityHint';
import styles from './NetworkHero.module.css';
import { DEFAULT_NETWORK_BACKGROUND_IMAGE_PATH, ROUTES } from '@constants';
import type { TCommunity } from '@typings';
import { useNetworkMutations } from '@ui-modules/networks/hooks/useNetworkMutations';
import NetworkMembershipButton from '../NetworkMembershipButton';
import { useInternalShareContext } from '@ui-modules/share/hooks/useInternalShare';

/** Banner with basic information about a network. */
const NetworkHero = ({ networkId, activeNetworkTab }: INetworkHeroProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { data: network, isLoading, isSuccess } = useCommunityQuery(networkId);
	const backgroundUrl = isSuccess
		? ((network?.backgroundUrl?.contentUrl || network?.backgroundUrl) ?? DEFAULT_NETWORK_BACKGROUND_IMAGE_PATH)
		: '';

	const { setShouldUpdateChannel } = useChat();

	// Actions.
	const [showDialog, toggleShowDialog] = useToggle(false);
	const openNetworkChat = (networkSlug: TCommunity['slug']) => {
		navigate(ROUTES.chatChannel(networkSlug));
		setShouldUpdateChannel(false);
	};
	const { leaveNetwork, Modals: NetworkMutationModals } = useNetworkMutations();
	const openMyDeals = useEvent(() => navigate(ROUTES.dealsTabs('my-deals')));

	const { setInternalShare } = useInternalShareContext();

	const onNetworkShare = () => {
		if (!activeNetworkTab) return;

		setInternalShare({
			type: activeNetworkTab === 'connections' ? 'network_connection' : 'network',
			id: networkId as string,
		});
	};

	if (!network) return null;
	return (
		<div className={styles.networkHero} style={{ backgroundImage: `url("${backgroundUrl}")` }}>
			<div className={styles.networkHero__content}>
				<FixedWidthContainer>
					<div>
						<div className={styles.networkHero__body}>
							<div className={styles.networkHero__bodyTitles}>
								<span className={styles.networkHero__networkName}>{network.name}</span>
							</div>
							<div>
								{isLoading ? <ActivityIndicator type="fit" /> : null}
								{isSuccess ? (
									<SpacedGroup>
										<NetworkMembershipButton
											buttonType="blurred"
											buttonVariant="medium"
											hidePendingRequests
											networkId={network.id}
										/>
										<When condition={CommunityEntity.hasChat(network)}>
											<Button
												icon={<ExternalLinkIcon fill="black" height={16} width={16} />}
												iconPosition="right"
												title={t('Chat')}
												type="outline"
												variant="medium"
												onClick={() => openNetworkChat(network.slug)}
											/>
										</When>
										<Button
											icon={<ShareArrowIcon fill="black" height={16} width={16} />}
											iconPosition="right"
											title={t('Share')}
											type="outline"
											variant="medium"
											onClick={onNetworkShare}
										/>
									</SpacedGroup>
								) : null}
							</div>
						</div>
						<div className={styles.networkHero__communityType}>
							<h4>{translateCommunityMembershipType(t)(network.communityType.visibility)}</h4>
							<NetworkAccessibilityHint networkId={networkId} />
						</div>
					</div>
				</FixedWidthContainer>
			</div>
			{/* Modals */}
			<LeaveNetworkModal
				network={network}
				visible={showDialog}
				onClose={toggleShowDialog}
				onLeaveNetwork={leaveNetwork}
				onOpenDeals={openMyDeals}
			/>
			{NetworkMutationModals}
		</div>
	);
};

export interface INetworkHeroProps {
	/** ID of the target network. */
	networkId: TCommunity['id'];
	activeNetworkTab?: string;
}

export default NetworkHero;
