import { memo } from 'react';
import { Post } from '@ui-kit';
import { ActivityEntity } from '@utils';
import { useTranslation } from '@hooks';
import { useHandleActivityLink } from '@ui-modules/feed/hooks/useHandleActivityLink';

import { type TActivity } from '@typings';
import { type IFeedActivityProps } from './FeedActivity';

const FeedActivityContent = ({
	activity,
	onInteracted,
	scrollIntoPostView,
	onLinkClicked,
	isAlwaysExpanded,
}: IFeedActivityContentProps) => {
	const { t } = useTranslation();
	const activityEntity = new ActivityEntity(activity);
	const openLink = useHandleActivityLink();

	return (
		<Post.Content
			collapsedNumberOfLines={getContentCollapsedNumberOfLines(!!isAlwaysExpanded)}
			collapsedTitle={t('Read more')}
			expandedTitle={t('Show less')}
			onClickLink={(url) => {
				openLink(url);
				onInteracted?.('link');
			}}
			onCollapse={() => {
				scrollIntoPostView();
				onInteracted?.('collapse');
			}}
			onExpand={() => onInteracted?.('expand')}
			onLinkClicked={(url) => {
				onLinkClicked?.(url);
			}}
		>
			{activityEntity.htmlContent}
		</Post.Content>
	);
};

export interface IFeedActivityContentProps extends Partial<IFeedActivityProps> {
	activity: TActivity;
	scrollIntoPostView: () => void;
}

function getContentCollapsedNumberOfLines(alwaysExpanded: boolean) {
	return alwaysExpanded ? Number.MAX_SAFE_INTEGER : 5;
}

export default memo(FeedActivityContent);
