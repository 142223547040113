import { useTranslation } from '@hooks';
import styles from './InitialShareModalContent.module.css';
import type { TContent } from '@ui-modules/share/components/InternalShareModal/InternalShareModal';
import { ChaptersIcon, CommentIcon, NetworkFilledIcon } from '@ui-kit';
import ShareOption from '../ShareOption';

const InitialShareModalContent = ({ handleContentChange }: IInitialShareModalContentProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.initialShareModal__container}>
			<ShareOption
				icon={<CommentIcon fill={'#333333'} height={24} width={30} />}
				title={t('Chat')}
				onClick={() => handleContentChange('chat')}
			/>
			<ShareOption
				icon={<NetworkFilledIcon fill={'#333333'} height={24} width={30} />}
				title={t('Network')}
				onClick={() => handleContentChange('network')}
			/>
			<ShareOption
				icon={<ChaptersIcon color={'#333333'} height={24} width={30} />}
				title={t('Chapters')}
				onClick={() => handleContentChange('chapters')}
			/>
		</div>
	);
};

export interface IInitialShareModalContentProps {
	handleContentChange: (content: TContent) => void;
}

export default InitialShareModalContent;
