import { Outlet, useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { ActivityIndicator, Button, SpacedGroup, ScreenHeader, When, ShareArrowIcon } from '@ui-kit';
import { CommentIcon } from '@ui-kit/icons';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import {
	useEffect,
	useParams,
	useTrackAlgoliaEventEffect,
	useTranslation,
	useChat,
	useMe,
	useHandleEntityPageQueryError,
} from '@hooks';
import { CommunityEntity } from '@utils';
import { ROUTES } from '@constants';
import type { TCommunity } from '@typings';
import { useInternalShareContext } from '@ui-modules/share/hooks/useInternalShare';

const GroupPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { groupId, groupTab } = useParams<IGroupPageParams>() as IGroupPageParams;
	const { setShouldUpdateChannel } = useChat();
	const { profile } = useMe();
	const userIsStaff = profile['@type'] === 'ProfileStaff';

	const handleEntityPageQueryError = useHandleEntityPageQueryError('group');
	const {
		data: group,
		isLoading,
		isSuccess,
	} = useCommunityQuery(groupId, {
		onError: handleEntityPageQueryError,
	});
	const titleGroupName = group?.name ? group.name : '...';
	const { setInternalShare } = useInternalShareContext();

	// Actions.
	const openGroupChat = (groupSlug: TCommunity['slug']) => {
		navigate(ROUTES.chatChannel(groupSlug));
		setShouldUpdateChannel(false);
	};

	useEffect(
		function redirectToDefaultTabIfItIsAbsent() {
			if (group && !groupTab) {
				navigate(ROUTES.groupTab(groupId), { replace: true });
			}
		},
		[groupTab, group],
	);

	const onGroupShare = () => {
		if (!group?.id) return;
		setInternalShare({
			type: group.definition,
			id: group?.id as string,
		});
	};

	useTrackAlgoliaEventEffect('Group Viewed', { communitySlug: String(groupId) }, [groupId]);

	return (
		<Page title={titleGroupName}>
			{isLoading ? <ActivityIndicator type="fit" /> : null}
			{isSuccess ? (
				<>
					<ScreenHeader title={group.name}>
						<SpacedGroup>
							<When condition={CommunityEntity.shouldShowGroupCommunicationFeatures(group) && !userIsStaff}>
								<Button
									icon={<CommentIcon fill="black" height={16} width={16} />}
									iconPosition="left"
									title={t('Group Chat')}
									type="outline"
									variant="small"
									onClick={() => openGroupChat(group.slug)}
								/>
							</When>
							<Button
								icon={<ShareArrowIcon fill="black" height={16} width={16} />}
								iconPosition="right"
								title={t('Share')}
								type="outline"
								variant="small"
								onClick={onGroupShare}
							/>
						</SpacedGroup>
					</ScreenHeader>
					<Outlet />
				</>
			) : null}
		</Page>
	);
};

export interface IGroupPageParams extends Record<string, string | undefined> {
	groupId: string;
}

export default GroupPage;
