import { forwardRef, type ForwardedRef } from 'react';
import {
	ReactionSelector,
	useChannelStateContext,
	useMessageContext,
	type ReactionSelectorProps,
} from 'stream-chat-react';
import { useService, useCallback } from '@hooks';

/** Wrapper around GetStream Reaction Selector component
 *  to track the reaction events.
 *  The GetStream docs example is used @see https://getstream.io/chat/docs/sdk/react/guides/theming/reactions/#custom-reaction-handler
 */
const ChatReactionSelector = (props: ReactionSelectorProps, ref: ForwardedRef<HTMLDivElement | null>) => {
	const {
		message: { own_reactions: ownReactions = [], id: messageId, cid },
	} = useMessageContext('CustomReactionSelector');
	const { channel } = useChannelStateContext('CustomReactionSelector');
	const analytics = useService('AnalyticsService');

	const trackReaction = useCallback(async (reactionType: string, action: 'added' | 'removed') => {
		analytics.trackEvent('ChatInteractions', {
			channel_id: String(cid),
			chat_message_id: messageId,
			reaction: reactionType,
			action_taken: action,
		});
	}, []);

	const handleReaction = useCallback(
		async (reactionType: string) => {
			const hasReactedWithType = (ownReactions ?? []).some((reaction) => reaction.type === reactionType) ?? false;
			if (hasReactedWithType) {
				await channel.deleteReaction(messageId, reactionType);
				trackReaction(reactionType, 'removed');
			} else {
				await channel.sendReaction(messageId, { type: reactionType });
				trackReaction(reactionType, 'added');
			}
		},
		[channel, ownReactions, messageId],
	);

	return <ReactionSelector {...props} handleReaction={handleReaction} ref={ref} />;
};

export default forwardRef(ChatReactionSelector);
