import { Gap, When, Virtuoso, EmptyStateMessage, ActivityIndicator } from '@ui-kit';

import {
	useRef,
	useEvent,
	useLocation,
	useTranslation,
	useFloatingHeader,
	useRestoreVirtuosoInitialIndex,
	useService,
	useToggleLikeMutation,
	useBlockedAndBlockingUsers,
} from '@hooks';
import { BOOKMARKS_TAB } from '@constants';
import { NetworkConnectionEntity, textRegisterPluralize } from '@utils';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useNetworkConnectionsSearch } from '@ui-modules/connections/hooks/useNetworkConnectionsSearch';
import { useGetNetworkConnectionListQuery } from '@ui-modules/connections/hooks/useGetNetworkConnectionListQuery';
import { useNetworkConnectionMeatballMenu } from '@ui-modules/connections/hooks/useNetworkConnectionMeatballMenu';
import { useCommunityModeratorMembersQuery } from '@ui-modules/communities/hooks/useCommunityModeratorMembersQuery';
import { useNetworkConnectionFilterFormRenderer } from '@ui-modules/connections/hooks/useNetworkConnectionFilterFormRenderer';

import NetworkConnectionPost from '@ui-modules/connections/components/NetworkConnectionPost';
import NetworkConnectionsWidgets from '@ui-modules/connections/components/NetworkConnectioWidgets';
import NetworkConnectionBookmarks from '@ui-modules/connections/components/NetworkConnectionBookmarks';

import styles from './NetworksConnections.module.css';

import NetworkConnectionsZeroState from '@ui-modules/connections/components/NetworkConnectionsZeroState';

import type { ItemContent, VirtuosoHandle } from 'react-virtuoso';
import type { TNetworkConnectionWithReactions } from '@typings';

const HEADER_PLACEHOLDER_ITEM_COUNT = 3;

/**
 * The 'NetworkConnections' component is designed for managing and displaying a list of network connections within a specific "dynamic" community.
 * It supports features like dynamic loading of network connection data, search functionality with live updating, etc.
 * Find this page on web /networks/<network_id>/connections
 * @author Hrant Abrahamyan
 */
const NetworkConnections = ({
	communityId,
	onChangeSearchQuery,
	initialSearchQuery = '',
}: INetworkConnectionsProps) => {
	const location = useLocation();

	const { t } = useTranslation();
	const queryEntries = new URLSearchParams(location.search);
	const queryParams: { subTab?: string } = Object.fromEntries(queryEntries);
	const listRef = useRef<VirtuosoHandle>(null);
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();

	const {
		data: networkConnections,
		isLoading: isNetworkConnectionsLoading,
		fetchMore,
		isFetchingNextPage,
		isFetching,
		setSelectedSortOption,
		selectedSortOption,
		sortOptions,
		isRefetching: isNetworkConnectionsRefetching,
		isFetched,
		hasDataByFilter,
	} = useGetNetworkConnectionListQuery(communityId);

	const { data: communityModerators = [] } = useCommunityModeratorMembersQuery(communityId);

	const { data: community } = useCommunityQuery(communityId);
	const { filterTitle, canRender, dataUpdatedAt } = useNetworkConnectionFilterFormRenderer(communityId);
	const RESTORE_KEY = `networkConnections-${communityId}-${dataUpdatedAt}-${JSON.stringify(['sortOptions'])}`;
	const { initialTopMostItemIndex, setInitialTopMostItemIndex, onRangeChanged } = useRestoreVirtuosoInitialIndex(
		RESTORE_KEY,
		HEADER_PLACEHOLDER_ITEM_COUNT,
	);
	const {
		searchResult,
		searchQuery,
		showSearchBar,
		showSearchResults,
		isSearchFetched,
		searchMore,
		setSearchQuery,
		setIsSearchFocused,
		isSearchFetching,
	} = useNetworkConnectionsSearch(communityId);

	/** Hook to handle floating header [widgets]*/
	const {
		floatingHeaderRef,
		floatingHeaderClassName,
		floatingHeaderHeight,
		Placeholder,
		onScroll,
		itemsRendered,
		dataWithPlaceholders: networkConnectionsWithFloatingHeader,
	} = useFloatingHeader<TNetworkConnectionWithReactions>(
		showSearchResults ? (searchResult as TNetworkConnectionWithReactions[]) : networkConnections,
		initialTopMostItemIndex < HEADER_PLACEHOLDER_ITEM_COUNT,
		showSearchBar ? 64 : undefined,
		styles.networkConnections__floatingHeader,
	);

	const [getMeatballMenuOptions, MeatballMenuDialogs] = useNetworkConnectionMeatballMenu({
		networkConnectionName: community?.networkConnectionsName,
		onSelect(action, networkConnection) {
			if (action !== 'delete') {
				const activatedNetworkConnectionIndex =
					networkConnectionsWithFloatingHeader?.indexOf(networkConnection as TNetworkConnectionWithReactions) || 0;
				setInitialTopMostItemIndex(activatedNetworkConnectionIndex);
			}
		},
		onSelectCallbackDependencies: [networkConnectionsWithFloatingHeader],
	});

	const reactQuery = useService('ReactQueryService');

	const { mutate: toggleLike } = useToggleLikeMutation({
		onSuccess: (updatedActivity) => reactQuery.refetchNetworkConnectionReactionsMutation(updatedActivity, communityId),
	});

	const renderNetworkConnectionItem = useEvent<ItemContent<TNetworkConnectionWithReactions, unknown>>(
		(index, networkConnection) => {
			if (index === 0 && canRender) {
				return (
					<NetworkConnectionsWidgets
						communityId={communityId}
						floatingHeaderClassName={floatingHeaderClassName}
						floatingHeaderRef={floatingHeaderRef}
						name={filterTitle as string}
						networkConnectionsName={String(community?.networkConnectionsName)}
						searchQuery={searchQuery}
						selectedSortOption={selectedSortOption}
						setIsSearchFocused={setIsSearchFocused}
						setSearchQuery={setSearchQuery}
						setSelectedSortOption={(option) => {
							listRef.current?.scrollTo({ top: 0 });
							setSelectedSortOption(option);
						}}
						showSearchBar={showSearchBar}
						sortDisabled={!hasDataByFilter} // show sort bar only when data by filter exists and there is no reloading with new sort options.
						sortOptions={sortOptions}
					/>
				);
			} else if (index === 1) {
				return <Placeholder />;
			}
			if (!networkConnection || NetworkConnectionEntity.isBlocked(blockedAndBlockingUsers, networkConnection))
				return <Gap gap={1} />;
			return (
				<NetworkConnectionPost
					highlightedText={searchQuery}
					likingDisabled={!('latestLikes' in networkConnection)} // duck typing to ensure it is TDealWithReaction but not a TDeal as when searching.}
					meatballMenuOptions={getMeatballMenuOptions(networkConnection, communityModerators)}
					networkConnection={networkConnection}
					onClick={() => {
						const clickedNetworkConnectionIndex = networkConnectionsWithFloatingHeader?.indexOf(networkConnection) || 0;
						setInitialTopMostItemIndex(clickedNetworkConnectionIndex);
					}}
					onLike={(networkConnection, newHasMyLike) => {
						toggleLike({ activity: networkConnection, liked: newHasMyLike });
					}}
					onTextCollapse={() => {
						listRef.current?.scrollToIndex({
							index: networkConnectionsWithFloatingHeader?.indexOf(networkConnection) || 0,
							offset: -floatingHeaderHeight,
							behavior: 'smooth',
						});
					}}
				/>
			);
		},
	);

	if (!networkConnections) return null;

	if (queryParams.subTab === BOOKMARKS_TAB) {
		return <NetworkConnectionBookmarks communityId={communityId} />;
	}

	return (
		<>
			{isNetworkConnectionsRefetching && !canRender ? (
				<ActivityIndicator color="black" size="medium" type="fit" />
			) : (
				<Virtuoso<TNetworkConnectionWithReactions>
					className={styles.networkConnections__list}
					components={{
						Footer: () => (
							<>
								<When condition={isNetworkConnectionsLoading || isFetchingNextPage || isSearchFetching || isFetching}>
									<ActivityIndicator color="black" size="medium" type="fit" />
								</When>
								<When condition={!searchResult.length && isSearchFetched}>
									<EmptyStateMessage
										colorMode="dark"
										hidden={false}
										text={t('Sorry, no {{name}} were found.', {
											name: textRegisterPluralize(community?.networkConnectionsName),
										})}
									/>
								</When>
								<Gap gap={8} />
							</>
						),
					}}
					computeItemKey={(index, item) => `${index}_${item?.id}`}
					data={networkConnectionsWithFloatingHeader}
					defaultItemHeight={480}
					endReached={showSearchResults ? searchMore : fetchMore}
					initialTopMostItemIndex={initialTopMostItemIndex}
					itemContent={renderNetworkConnectionItem}
					itemsRendered={itemsRendered}
					rangeChanged={onRangeChanged}
					ref={listRef}
					topItemCount={1}
					totalCount={networkConnectionsWithFloatingHeader?.length}
					onScroll={onScroll}
				/>
			)}
			{!networkConnections.length && canRender && isFetched ? (
				<>
					<Gap gap={64} />
					<NetworkConnectionsZeroState
						networkConnectionsName={textRegisterPluralize(community?.networkConnectionsName)}
					/>
				</>
			) : null}
			{MeatballMenuDialogs}
		</>
	);
};

export interface INetworkConnectionsProps {
	communityId: string;
	initialSearchQuery?: string;
	onChangeSearchQuery: (searchQuery: string) => void;
}

export default NetworkConnections;
