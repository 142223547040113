import type { TAttachments } from '@typings';
import type {
	TRichMessageInputAttachment,
	TRichMessageInputAttachmentType,
} from '@ui-modules/forms/components/RichMessageInput';

export function mapAttachmentsToRichTextInputFormat(
	imagesAndFiles: TAttachments[],
	loadingStateAttachments: TAttachments[],
	{ removeFile, removeMedia, openImage, openFile }: IMapChatAttachmentsToRichTextInputFormatCallbacks,
): TRichMessageInputAttachment[] {
	const toRichTextInputFormat = (item: TAttachments) => {
		const type = item?.fileType as TRichMessageInputAttachmentType;
		const uri = item.thumb_url || item.url || item.uri;
		const mimeType = String(item.mimeType);

		return {
			id: item.id,
			type: type,
			uri,
			mimeType,
			isUploading: false,
			onRemove:
				removeMedia && (type === 'image' || type === 'video')
					? () => removeMedia(item)
					: removeFile && type === 'file'
						? () => removeFile(item)
						: undefined,
			onPress:
				openImage && type === 'image'
					? () => openImage(item)
					: openFile && type === 'file'
						? () => openFile(item)
						: undefined,
		} as TRichMessageInputAttachment;
	};

	return [
		...imagesAndFiles.map((item) => toRichTextInputFormat(item)),
		...loadingStateAttachments.map((item) => ({
			...toRichTextInputFormat(item),
			isUploading: true,
		})),
	];
}

interface IMapChatAttachmentsToRichTextInputFormatCallbacks {
	openImage?: (image: TAttachments) => void;
	removeMedia?: (image: TAttachments) => void;
	openFile?: (file: TAttachments) => void;
	removeFile?: (file: TAttachments) => void;
}
