import React, { useState, useEffect } from 'react';
import { useService, useMe, useTranslation } from '@hooks';
import styles from './ChannelPreviewTitle.module.css';
import type { Channel as TChannel } from 'stream-chat';

interface IChannelProps {
	channel: TChannel;
}

const ChannelPreviewTitle = ({ channel }: IChannelProps) => {
	const chat = useService('ChatService');
	const { user } = useMe();
	const [channelName, setChannelName] = useState('');
	const { t } = useTranslation();

	useEffect(() => {
		async function fetchChannelName() {
			if (chat.isOneToOneWithOneMember(channel)) {
				const otherMemberId = chat.extractOtherOneToOneMemberId(channel, user.slug);
				const { name } = await chat.queryUser(otherMemberId);
				if (name) {
					setChannelName(name);
				}
			}
		}
		fetchChannelName();
	}, [channel.id, channel.data?.['memberIDs']]);

	const title = chat.isOneToOneWithOneMember(channel) ? channelName : chat.getChannelName(channel, user.slug, t);

	return (
		<h4 className={styles.channelPreviewTitle} title={title}>
			{title}
		</h4>
	);
};

export default ChannelPreviewTitle;
