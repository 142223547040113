import { FormPageLayout, Page } from '../../components';
import { useTranslation, useMemo, useLayoutEffect, useRef } from '@hooks';
import styles from './EditProfileInterestsPage.module.css';
import { Button, ChipSet } from '@ui-kit';
import { useUpdateProfileMutation } from '@ui-modules/profile/hooks/useUpdateProfileMutation';
import { ROUTES } from '@constants';
import type { TProfile } from '@typings';
import { FieldArray, Formik, type FormikProps } from 'formik';
import { useProfileTags } from '@ui-modules/profile/hooks/useProfileTags';
import type { TEditProfileInterests } from '@schemas';
import { editProfileInterestsSchema } from '@schemas';

const EditProfileInterestsPage = () => {
	const { t } = useTranslation();
	const { profile, mutateAsync } = useUpdateProfileMutation();
	const { clearSelectedTags, openTagsSelector, selectedTags } = useProfileTags('interests');
	const formikRef = useRef<FormikProps<TEditProfileInterests>>(null);

	const initialValues: Partial<TProfile> = useMemo(
		() => ({
			interests: profile.interests,
		}),
		[profile, selectedTags],
	);

	const onSubmit = async (values: TEditProfileInterests) => {
		await mutateAsync(values);
		clearSelectedTags();
	};

	useLayoutEffect(() => {
		if (selectedTags) {
			formikRef.current?.setFieldValue('interests', selectedTags);
			formikRef.current?.setFieldTouched('interests');
		}
	}, [selectedTags]);

	return (
		<Page title={t('Edit Profile Interests')}>
			<Formik<TEditProfileInterests>
				enableReinitialize
				initialValues={editProfileInterestsSchema.cast(initialValues) as TEditProfileInterests}
				innerRef={formikRef}
				validateOnMount
				validationSchema={editProfileInterestsSchema}
				onSubmit={onSubmit}
			>
				{(formProps) => {
					const { values } = formProps;
					return (
						<FormPageLayout
							allowedNextPaths={[ROUTES.editProfileInterests(), ROUTES.editProfileTags()]}
							formProps={formProps}
							headerTitle={t('My Interest')}
							saveTrackingName="profile--edit-interests"
						>
							<div className={styles.interest}>
								<div className={styles.interest__container}>
									<div>
										<span className={styles.interest__title}>{t('Talk to me about')}</span>
										<span className={styles.interest__subTitle}>
											{t('To encourage meaningful connections, we suggest displaying at least 3 interests.')}
										</span>
										<FieldArray
											name="interests"
											render={({ remove }) => <ChipSet value={values.interests} onRemove={remove} />}
										/>
									</div>

									<div className={styles.interest__buttonContainer}>
										<Button
											block="fit"
											title={t('Add Interests')}
											type="outline"
											variant="small"
											onClick={() => openTagsSelector(values.interests)}
										/>
									</div>
								</div>
							</div>
						</FormPageLayout>
					);
				}}
			</Formik>
		</Page>
	);
};

export interface IEditProfileInterestsPageParams extends Record<string, string | undefined> {
	id: string;
}

export default EditProfileInterestsPage;
