import { BellSlashIcon, EditPencilIcon, BellIcon } from '@ui-kit';
import { useNavigate, useTranslation } from '@hooks';

import { ROUTES } from '@constants';

import styles from './NetworkConnectionsFilterBar.module.css';

import type { TCommunity } from '@typings';
import { useConnectionsNotification } from '@ui-modules/connections/hooks/useConnectionsNotification';

/** NetworkConnectionsFilterBar component provides interactive filter options for network connections,
 *  including alert toggling and editing filter settings.
 *  @author Hrant Abrahamyan
 */
const NetworkConnectionsFilterBar = ({
	name,
	communityId,
	networkConnectionsName,
}: INetworkConnectionsFilterBarProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleConnectionEdit = () => {
		navigate(ROUTES.networkConnectionFilters(communityId));
	};

	const { muted, handleMuteToggle } = useConnectionsNotification(communityId, networkConnectionsName);

	const MuteIcon = muted ? BellIcon : BellSlashIcon;

	return (
		<div className={styles.container}>
			<div className={styles.alertsWrapper}>
				<button onClick={handleMuteToggle}>
					<MuteIcon className={styles.bellIcon} fill="#59A5FF" height={20} width={25} />
					<p className={styles.description}>{!muted ? t('Alerts off') : t('Alerts on')}</p>
				</button>
			</div>
			<div>
				<span className={styles.filterDescription}>{name}</span>
			</div>
			<div className={styles.editWrapper}>
				<button className={styles.alertsWrapper__button} onClick={handleConnectionEdit}>
					<EditPencilIcon fill="#59A5FF" height={20} width={25} />
					<p className={styles.description}>{t('Edit Filter')}</p>
				</button>
			</div>
		</div>
	);
};

export interface INetworkConnectionsFilterBarProps {
	/** Name of the Network Connection **/
	name: string;
	/** Parent community ID **/
	communityId: TCommunity['id'];
	/** Name of network connections in current community. e.g. Charity, NPO, etc. **/
	networkConnectionsName: string;
}

export default NetworkConnectionsFilterBar;
