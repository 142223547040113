import { startTransition } from 'react';
import { useDebouncedCallback } from '@hooks';
import styles from './RichMessageInputActions.module.css';
import RichMessageInputAction, { type TRichMessageInputAction } from '../RichMessageInputAction/RichMessageInputAction';
import { useRichMessageAppearanceContext } from '../../contexts/RichMessageAppearanceContext';
import { motion } from 'motion/react';

const RichMessageInputActions = ({ actions, onAction }: IRichMessageInputActionsProps) => {
	const [appearance, setAppearance] = useRichMessageAppearanceContext();

	const debouncedOnAction = useDebouncedCallback((actionCallback) => actionCallback(), 1000, {
		leading: true,
		trailing: false,
	});

	return (
		<motion.div className={styles.richMessageInputActions} data-appearance={appearance} layout>
			{actions.map((action, index) => (
				<RichMessageInputAction
					key={action.title + index}
					{...action}
					appearance={appearance}
					onPress={() => {
						debouncedOnAction(action.onPress);
						startTransition(() => setAppearance('row', 300));
						onAction?.(action);
					}}
				/>
			))}
		</motion.div>
	);
};

export interface IRichMessageInputActionsProps {
	actions: TRichMessageInputAction[];
	onAction?: (action: TRichMessageInputAction) => void;
}
export type TRichMessageInputActionAppearance = 'row' | 'column';

export default RichMessageInputActions;
