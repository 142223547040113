import { DeleteIcon, EditIcon, ReportIcon } from '@ui-kit/icons';
import { useCallback, useTranslation, useMe, useState } from '@hooks';
import { CommunityEntity, compact } from '@utils';
import { TipReviewEntity } from '@ui-modules/tipJar/utils/TipReviewEntity';
import { useDeleteTipReviewMutation } from '@ui-modules/tipJar/hooks/useDeleteTipReviewMutation';
import { useTipQuery } from '@ui-modules/tipJar/hooks//useTipQuery';
import type { TTip, TTipReview, TTipWithDetails } from '@typings';
import { ROUTES } from '@constants';
import { useNavigate } from 'react-router-dom';
import { Modal, type TMeatballMenuOption } from '@ui-kit';
import { TipEntity } from '../utils/TipEntity';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';

export const useGetTipReviewMeatballMenuOptions = (tipId: TTip['id'], contextCommunityId?: string | null) => {
	const { user } = useMe();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [tipReviewToDelete, setTipReviewToDelete] = useState<TTipReview | null>(null);
	const { data: group } = useCommunityQuery(contextCommunityId as string);
	const isCommunityModerator = group ? CommunityEntity.userIsModerator(group) : undefined;
	const { data: tip } = useTipQuery(tipId); // have to fetch tip without contexts to get strong data for permissions predicates
	const { mutate: deleteTipReview } = useDeleteTipReviewMutation(tip as TTipWithDetails, {
		onSuccess(status) {
			if (status === 'tip_removed') {
				navigate(-1);
			}
		},
	});

	const meatballMenuOptions = useCallback(
		(tipReview: TTipReview): TMeatballMenuOption[] => {
			return compact([
				TipReviewEntity.canEdit(tipReview, user, isCommunityModerator) && {
					text: t('Edit Review'),
					onClick: () => navigate(ROUTES.tipReview(tipId, tipReview.id)),
					icon: <EditIcon fill="black" height={16} width={16} />,
				},
				TipReviewEntity.canReport(tipReview, user) && {
					text: t('Report Review'),
					onClick: () => navigate(ROUTES.reportContent('tip-review', tipReview.tipId, tipReview.id)),
					icon: <ReportIcon fill="black" height={16} width={16} />,
				},
				tip &&
					TipReviewEntity.canDelete(tipReview, user, tip, isCommunityModerator) && {
						text: t('Delete'),
						type: 'destructive',
						onClick: () => setTipReviewToDelete(tipReview),
						icon: <DeleteIcon fill="#fa2f53" height={16} width={16} />,
					},
			]);
		},
		[tip],
	);

	const ConfirmationModals =
		tip && tipReviewToDelete ? (
			<>
				<Modal
					cancelTitle={t('Cancel')}
					confirmTitle={t('Delete Review')}
					subTitle={
						TipEntity.isOwnerReview(tip, tipReviewToDelete)
							? TipEntity.canBeTransferredToNewOwnerAfterDeletingReview(tip)
								? t(
										"Deleting your review will remove you as the tip's original creator, and can't be undone. Instead, you can go back and edit your review.",
									)
								: t(
										'Deleting your review will delete the entire tip, because at least one review is required. This cannot be undone.',
									)
							: t('This will permanently delete your review, and it cannot be undone.')
					}
					title={t('Are You Sure?')}
					titleCentered
					variant="medium"
					visible={!!tipReviewToDelete}
					onCancel={() => setTipReviewToDelete(null)}
					onConfirm={async () => {
						if (tipReviewToDelete) await deleteTipReview(tipReviewToDelete);
						setTipReviewToDelete(null);
					}}
				/>
			</>
		) : null;

	return [meatballMenuOptions, ConfirmationModals] as const;
};
