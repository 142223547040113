import { MentionsInput, Mention } from 'react-mentions';
import { useMemo, useRef, forwardRef, useEffect } from 'react';
import { useSearchUsersQuery, useTranslation, useUserGroups } from '@hooks';
import styles from './FeedCommentInput.module.css';
import './FeedCommentInput.overwrite.css';
import { suggestionTitle, mapSuggestionData } from './utils';
import SuggestionsWrapper from './SuggestionsWrapper';
import { SuggestionListItem } from '@ui-kit';
import { useDebouncedMentionSearchQuery } from '@ui-modules/feed/hooks/useDebouncedMentionSearchQuery';
import type { ForwardedRef, RefObject } from 'react';
import type { TAttachments, TCommunity, TFullUser } from '@typings';
import type { MentionsInputProps, SuggestionDataItem } from 'react-mentions';

/** FeedCommentInput is not a new component but renamed AttachmentTextArea and refactored to support new input UI (@see T21C-8808) [@DmitriyNikolenko] */
const FeedCommentInput = (
	{
		value,
		placeholder,
		disabled,
		onChange,
		resize,
		delayedResize,
		onPaste,
		networkId,
		inputBoxRef,
		...props
	}: IAttachmentTextAreaProps,
	ref: ForwardedRef<HTMLTextAreaElement>,
) => {
	const { t } = useTranslation();
	const containerRef = useRef<HTMLDivElement>(null);
	const mentionInputRef = useRef<any | null>(null); // FIXME

	const searchFilter = useDebouncedMentionSearchQuery(mentionInputRef);
	const userGroups = useUserGroups();
	const {
		data: users,
		isLoading,
		isFetching,
	} = useSearchUsersQuery({
		communityId: networkId,
		searchFilter: searchFilter,
	});

	const suggestionData = useMemo(() => mapSuggestionData(users), [users]);

	useEffect(() => {
		if (suggestionData?.length > 0 && !isFetching && !isLoading && mentionInputRef?.current) {
			const mentionState = mentionInputRef.current.state;
			const suggestionsList = mentionState?.suggestions || [];
			const currentSuggestions = suggestionsList[0];
			if (currentSuggestions) {
				currentSuggestions.results = suggestionData;
				mentionInputRef.current.updater.enqueueForceUpdate(mentionInputRef.current);
			}
		}
	}, [isFetching, isLoading, suggestionData, searchFilter]);

	return (
		<>
			<div
				ref={containerRef}
				onClick={() => {
					setTimeout(mentionInputRef.current.updateHighlighterScroll(), 0); //fixes desync of textarea and mentions in edge cases, when user click and scroll see T21C-7953
				}}
				onKeyDown={() => {
					setTimeout(mentionInputRef.current.updateHighlighterScroll(), 0);
				}}
			>
				<MentionsInput
					autoFocus={false}
					className="mentionTextarea"
					customSuggestionsContainer={(children) =>
						typeof searchFilter === 'string' ? (
							<SuggestionsWrapper
								anchorRef={inputBoxRef}
								isLoading={isLoading}
								title={suggestionTitle(t)(searchFilter)}
							>
								{children}
							</SuggestionsWrapper>
						) : null
					}
					disabled={disabled}
					EmptyStateComponent={() => (
						<div className={styles.suggestions__notFound}>
							<p className={styles.suggestions__notFoundInfo}>{t('No Matching Users Found')}</p>
						</div>
					)}
					forceSuggestionsAboveCursor
					inputRef={ref}
					placeholder={placeholder}
					ref={mentionInputRef}
					rows={1}
					style={{
						suggestions: {
							list: {
								maxHeight: 284,
								overflowY: 'auto',
							},
						},
					}}
					value={value}
					onChange={(event) => {
						resize();
						onChange?.(event.target.value);
					}}
					onCut={delayedResize}
					onDrop={delayedResize}
					onKeyDown={delayedResize}
					onPaste={(event) => {
						onPaste?.(event);
						delayedResize();
					}}
					{...props}
				>
					<Mention
						onAdd={() => {
							setTimeout(() => {
								onChange?.((prev) => prev.replace(/@$/, ''));
							}, 0);
						}} // T21C-7950 replace if user trigger with double "@@" to avoid "mention @"
						appendSpaceOnAdd
						className={styles.mentionHighlighted}
						data={suggestionData}
						isLoading={isLoading && typeof searchFilter === 'string'}
						key={mentionInputRef.current}
						renderSuggestion={(suggestion, search, highlightedDisplay, _, focused) => {
							return (
								<SuggestionListItem
									focused={focused}
									suggestion={suggestion as TFullUser & SuggestionDataItem}
									textToHighlight={searchFilter || ''}
									userSubtitle={userGroups[suggestion.id]}
								/>
							);
						}}
						trigger={/(?:^|\s|\p{P}|\p{S}|@)(@(\S*))$/u}
					/>
				</MentionsInput>
			</div>
		</>
	);
};

export interface IAttachmentTextAreaProps {
	value?: string;
	placeholder?: string;
	disabled?: boolean;
	attachmentEditingDisabled?: boolean;
	attachmentsError?: string;
	attachments: TAttachments[];
	onRemoveAttachment: (file: TAttachments) => void;
	delayedResize: () => void;
	resize: () => void;
	onChange: (value: string | ((prev: string) => string)) => void;
	onPaste: MentionsInputProps['onPaste'];
	networkId?: TCommunity['id'];
	inputBoxRef: RefObject<HTMLElement>;
}

export default forwardRef(FeedCommentInput);
