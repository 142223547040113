import { Page } from '../../components';
import {
	useTranslation,
	useNavigate,
	useCallback,
	useRestoreVirtuosoInitialIndex,
	useEffect,
	useUserSessionStorageValue,
	useMe,
	useService,
} from '@hooks';
import styles from './AssetAllocationNetworksPage.module.css';
import { useAssetAllocationCommunitiesQuery } from '@ui-modules/assetAllocation/hooks/useAssetAllocationCommunitiesQuery';
import { selectAssetAllocationNetworksFromCommunities } from '@ui-modules/assetAllocation/utils/selectAssetAllocationNetworksFromCommunities';
import AssetAllocationStaticEmptyState from '@ui-modules/assetAllocation/components/AssetAllocationStaticEmptyState';
import { translateMembers } from '@utils';
import { useMyAssetAllocationQuery } from '@ui-modules/assetAllocation/hooks/useMyAssetAllocationQuery';
import { ActivityIndicator, CommunityCard, EmptyStateMessage, FixedWidthContainer, Virtuoso } from '@ui-kit';
import type { TAssetAllocationTabSlug, TCommunityCompact } from '@typings';
import { ROUTES } from '@constants';
import type { ItemContent } from 'react-virtuoso';
import { isMyAssetAllocationAllowed } from '@tiger21-llc/connect-shared/src/utils/isMyAssetAllocationAllowed';

const AssetAllocationNetworksPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();
	const analytics = useService('AnalyticsService');

	const myAssetAllocationAllowed = isMyAssetAllocationAllowed(user);
	const { data: assetAllocationNetworks, isLoading } = useAssetAllocationCommunitiesQuery({
		select: selectAssetAllocationNetworksFromCommunities,
	});
	const { isSuccess: isMeSuccess, isLoading: isMeLoading } = useMyAssetAllocationQuery();

	const { storageSavedValue: selectedCommunityId, setStorageSavedValue: setSelectedNavigationCommunity } =
		useUserSessionStorageValue('assetAllocationNetworks');

	useEffect(() => {
		// If selected community present in storage auto navigate to the particular network
		if (selectedCommunityId) {
			navigate(ROUTES.assetAllocationNetwork(selectedCommunityId));
		}
	}, [selectedCommunityId]);

	const { initialTopMostItemIndex, setInitialTopMostItemIndex, onRangeChanged } =
		useRestoreVirtuosoInitialIndex('assetAllocationNetworks');

	const renderItem = useCallback<ItemContent<TCommunityCompact, unknown>>(
		(_, network: TCommunityCompact) => (
			<CommunityCard
				community={network}
				formatMembersCount={translateMembers(t)}
				style={styles.community__card}
				onClick={() => {
					const clickedDealIndex = assetAllocationNetworks?.indexOf(network) || 0;
					setInitialTopMostItemIndex(clickedDealIndex);
					navigate(ROUTES.assetAllocationNetwork(network.id));
					setSelectedNavigationCommunity(network.id);
				}}
			/>
		),
		[assetAllocationNetworks],
	);

	useEffect(function trackPageViewed() {
		analytics.trackEvent('AssetAllocationInteractions', {
			interaction_type: 'tab_viewed',
			tab_name: 'networks',
		});
	}, []);

	return (
		<Page title={t('Asset Allocation | {{tabName}}', { tabName: t('Networks') })}>
			{isLoading || isMeLoading ? (
				<ActivityIndicator size="medium" type="fit" />
			) : !isMeSuccess && myAssetAllocationAllowed ? (
				<AssetAllocationStaticEmptyState onOpenUserAllocation={() => navigate(ROUTES.assetAllocation('me'))} />
			) : (
				<Virtuoso<TCommunityCompact>
					components={{
						EmptyPlaceholder: () => (
							<FixedWidthContainer className={styles.communityList__emptyMessageContainer}>
								<EmptyStateMessage
									link={{
										text: t('View Invest Networks'),
										href: ROUTES.networksType('invest'),
									}}
									text={t('Please join an Invest Network to compare.')}
								/>
							</FixedWidthContainer>
						),
					}}
					data={assetAllocationNetworks}
					defaultItemHeight={156}
					initialTopMostItemIndex={initialTopMostItemIndex}
					itemContent={renderItem}
					overscan={-40}
					rangeChanged={onRangeChanged}
					totalCount={assetAllocationNetworks?.length}
				/>
			)}
		</Page>
	);
};

export interface IAssetAllocationNetworksPageParams extends Record<string, string | undefined> {
	tabName?: TAssetAllocationTabSlug;
	assetAllocationId?: string;
	networkId?: string;
}

export default AssetAllocationNetworksPage;
