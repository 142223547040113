import type { FormikProps } from 'formik';
import { FormSection } from '@ui-kit';
import { useTranslation } from '@hooks';
import { isUndefined, get } from '@utils';
import type { TDynamicField, TDynamicFieldLink, TDynamicFieldMultiSelect } from '@typings';
import type { TNetworkConnectionFormValues } from '../../ConnectionForm.schema';
import { connectionFormFields } from '../../ConnectionForm.schema';
import DetailsDynamicField from '../DetailsDynamicField/DetailsDynamicField';
import styles from './ConnectionDynamicSection.module.css';

const ConnectionDynamicSection = ({ formProps, isEdit, title, fields = [] }: IConnectionDynamicSectionProps) => {
	const { t } = useTranslation();
	const { values, errors, setFieldValue, setFieldError } = formProps;
	if (!fields.length) return null;

	return (
		<FormSection title={title}>
			<div className={styles.connectionDynamicSection__formSectionBody}>
				{fields
					?.sort((a, b) => a.position - b.position)
					.map((field) => {
						const fieldPath = `${connectionFormFields.DYNAMIC_FIELDS}.${field.name}`;
						const valuePath = `${fieldPath}.value`;
						const isRequired = isUndefined(field.required) || field.required;
						const value = get(values, valuePath);
						const errorMessage = get(errors, fieldPath);

						return (
							<DetailsDynamicField
								choices={(field as TDynamicFieldMultiSelect)?.meta.choices}
								errorMessage={errorMessage}
								fieldPath={fieldPath}
								isRequired={isRequired}
								key={field.name}
								label={isRequired ? field.label : t(`{{label}} (optional)`, { label: field.label })}
								meta={'meta' in field ? field.meta : undefined}
								placeholder={(field as TDynamicFieldLink).placeholder}
								setFieldError={setFieldError}
								setFieldValue={setFieldValue}
								slug={field.name}
								type={field.type}
								value={value}
								valuePath={valuePath}
							/>
						);
					})}
			</div>
		</FormSection>
	);
};

export default ConnectionDynamicSection;

interface IConnectionDynamicSectionProps {
	formProps: FormikProps<TNetworkConnectionFormValues>;
	isEdit?: boolean;
	title: string;
	fields: TDynamicField[];
}
