import { ActivityIndicator, EmptyStateMessage } from '@ui-kit';
import { useMemo, useTranslation } from '@hooks';
import {
	useGlobalSearchResults,
	type TUseGlobalSearchResultSearchOptions,
} from '@ui-modules/globalSearch/hooks/useGlobalSearchResults';
import ExpandableResultsBlock from '@ui-modules/globalSearch/components/ExpandableResultsBlock';
import { GLOBAL_SEARCH_DEFINITION } from '@constants';
import type { TSearchResult } from '@typings';
import type { ReactNode } from 'react';

const GlobalSearchResultList = ({
	searchQuery,
	omitDefinitions,
	pickDefinitions,
	hitsPerPage = 3,
	showInitialResults = false,
	EmptyComponent,
	onSelect,
}: IGlobalSearchResultListProps) => {
	const { t } = useTranslation();

	const finalOmitDefinitions = useMemo(() => {
		if (pickDefinitions)
			return Object.keys(GLOBAL_SEARCH_DEFINITION).filter(
				(definition) => !pickDefinitions.includes(definition as TSearchDefinition),
			) as TSearchDefinition[];
		return omitDefinitions;
	}, []);

	const {
		data: searchResults,
		isFetching,
		isSuccess,
	} = useGlobalSearchResults(searchQuery, {
		omitDefinitions: finalOmitDefinitions,
		hitsPerPage,
		showInitialResults,
	});
	const searchResultsGroups = useMemo(() => {
		if (!searchResults) return [];
		return Object.entries(searchResults).filter(([searchDefinition, resultsGroup]) => resultsGroup.length);
	}, [searchResults]);

	if (isFetching) return <ActivityIndicator size="medium" type="fit" />;
	if (isSuccess && !searchResultsGroups.length)
		return EmptyComponent || <EmptyStateMessage text={t('No matches found.')} />;
	return (
		<>
			{searchResultsGroups?.map?.(([searchDefinition, resultsGroup], index) => {
				return (
					<div key={index}>
						<ExpandableResultsBlock
							definition={searchDefinition as TSearchDefinition}
							initialSearchResultCount={hitsPerPage}
							results={resultsGroup}
							searchQuery={searchQuery}
							onClick={onSelect}
						/>
					</div>
				);
			})}
		</>
	);
};

type TSearchDefinition = keyof typeof GLOBAL_SEARCH_DEFINITION;

export interface IGlobalSearchResultListProps extends TUseGlobalSearchResultSearchOptions {
	searchQuery: string;
	pickDefinitions?: Array<TSearchDefinition>;
	EmptyComponent?: ReactNode;
	onSelect: (searchResult: TSearchResult) => void;
}
export default GlobalSearchResultList;
