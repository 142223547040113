import CommunityFilesSectionList from '../CommunityFilesSectionList';
import { AddFolderIcon, Button, FixedWidthContainer, SearchableScreenHeader, UploadFileIcon } from '@ui-kit';
import AddFolderModal from '@ui-modules/files/components/AddFolderModal';
import FileUploadDialogues from '@ui-modules/files/components/FileUploadDialogues';
import DownloadEncryptedFileModal from '@ui-modules/files/components/DownloadEncryptedFileModal';
import { useOpenFile } from '@ui-modules/files/hooks/useOpenFile';
import {
	useDebounce,
	useEvent,
	useMe,
	useNavigate,
	useRef,
	useService,
	useState,
	useToggle,
	useTranslation,
} from '@hooks';

import { useUploadCommunityFileMutation } from '@ui-modules/files/hooks/useUploadCommunityFileMutation';
import { usePickUpFile } from '@ui-modules/files/hooks/usePickUpFile';
import { ROUTES } from '@constants';
import type { TCommunity, TFile, TFolder } from '@typings';
import type { IFileUploadDialoguesApi } from '@ui-modules/files/components/FileUploadDialogues';

/** Combines tools to display list of files and folders for a certain community & toolbar to make changes with them or search. */
const CommunityFilesWidget = ({ communityId, communityDefinition, folderId, noSearch }: ICommunityFilesWidgetProps) => {
	// Dependencies.
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();
	const canUploadEncryptedFile = communityDefinition === 'group' && user.roles.includes('ROLE_CHAIR');

	// Page states.
	const [searchQuery, setSearchQuery] = useState('', 'CommunityFilesSectionList:searchQuery');
	const [debouncedSearchQuery] = useDebounce(searchQuery, 300);
	const [isAddFolderDialogVisible, toggleAddFolderDialog] = useToggle(false);
	const { openFile, openFileUrl } = useOpenFile();
	const fileUploadDialoguesRef = useRef<IFileUploadDialoguesApi>(null);
	const [fileToDownload, setFileToDownload] = useState<TFile | undefined>();

	// User actions.
	const openCommunityFile = useEvent((file: TFile) => {
		if (file.mediaObject.encrypted) {
			setFileToDownload(file);
		} else {
			openFile(file);
		}
	});
	const openFolder = useEvent((folder: TFolder) => {
		const routeBuilder =
			communityDefinition === 'chapter'
				? ROUTES.chapterFolder
				: communityDefinition === 'network'
					? ROUTES.networkFolder
					: ROUTES.groupFolder;
		navigate(routeBuilder(communityId, folder.id));
	});
	const { uploadFile, isLoading } = useUploadCommunityFileMutation(communityId, folderId, {
		onSuccess: () => fileUploadDialoguesRef.current?.closeDialogues?.(),
	});
	const { openFilePicker, getInputProps } = usePickUpFile((file) => {
		if (canUploadEncryptedFile) {
			fileUploadDialoguesRef.current?.openDialogue(file);
		} else {
			uploadFile({ file });
		}
	});

	return (
		<>
			<FixedWidthContainer autoSize>
				<SearchableScreenHeader
					customButtonsElement={
						<>
							<Button
								icon={<UploadFileIcon fill="#000000" height={12} width={14} />}
								iconPosition="right"
								loading={isLoading}
								title={t('Upload')}
								variant="small"
								onClick={openFilePicker}
							/>
							<Button
								icon={<AddFolderIcon fill="#000000" height={16} width={16} />}
								iconPosition="right"
								title={t('New folder')}
								variant="small"
								onClick={() => toggleAddFolderDialog()}
							/>
						</>
					}
					noSearch={noSearch}
					placeholder={t('Search files')}
					searchQuery={searchQuery}
					searchTitle={t('Search')}
					title=""
					onChangeSearchQuery={setSearchQuery}
				/>
			</FixedWidthContainer>
			<CommunityFilesSectionList
				communityId={communityId}
				folderId={folderId}
				searchQuery={debouncedSearchQuery}
				onClickFile={openCommunityFile}
				onClickFolder={openFolder}
			/>
			<AddFolderModal
				communityId={communityId}
				folderId={folderId}
				visible={isAddFolderDialogVisible}
				onClose={() => toggleAddFolderDialog()}
			/>
			{fileToDownload && (
				<DownloadEncryptedFileModal
					file={fileToDownload}
					onClose={() => setFileToDownload(undefined)}
					onOpenFile={(fileUrl) => {
						openFileUrl(fileUrl, fileToDownload.mediaObject.openInViewerOnly);
					}}
				/>
			)}
			<input {...getInputProps()} /> {/* hidden input to make file upload working */}
			<FileUploadDialogues
				isUploading={isLoading}
				passwordFormSubTitle={t(
					'Only share this password with your members. TIGER 21 will never have access to this password.',
				)}
				ref={fileUploadDialoguesRef}
				onUploadFile={uploadFile}
			/>
		</>
	);
};

export interface ICommunityFilesWidgetProps {
	/** Target Community ID which files and folders should be shown. */
	communityId: TCommunity['id'];
	/** Community definition to understand target page path (group or network). */
	communityDefinition: TCommunity['definition'];
	/** Target Community ID which files and folders should be shown. Pass 'null' to show the root folder. */
	folderId: TFolder['id'] | null;
	/** Somewhere we don't need 'search files' option */
	noSearch?: boolean;
}

export default CommunityFilesWidget;
