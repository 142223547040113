import { memo } from 'react';
import { Post } from '@ui-kit';
import { ActivityEntity } from '@utils';
import { useTranslation } from '@hooks';

import { type IFeedActivityProps } from './FeedActivity';
import { type TActivity } from '@typings';

const FeedActivityHeader = ({
	activity,
	onInteracted,
	profileLink,
	sourceLink,
	isSourceHidden,
}: IFeedActivityHeaderProps) => {
	const { t } = useTranslation();
	const activityEntity = new ActivityEntity(activity as TActivity);

	return (
		<Post.Header
			avatarProps={{
				imageUri: activityEntity.avatarUri,
				outline: activityEntity.isChair,
				size: 40,
				isInactive: activityEntity.isInactive,
				onClick: () => {
					onInteracted?.('avatar');
				},
			}}
			createdAt={activityEntity.createdAt}
			profileLink={profileLink}
			sourceLink={sourceLink}
			sourceName={isSourceHidden ? undefined : activityEntity.determineSourceName(t)}
			userId={activity?.actor?.data?.userId}
			userName={activityEntity.getDisplayingUserName(t)}
			onProfileClicked={() => {
				onInteracted?.('profile');
			}}
			onSourceNameClicked={() => {
				onInteracted?.('source');
			}}
		/>
	);
};

interface IFeedActivityHeaderProps extends Partial<IFeedActivityProps> {
	profileLink?: string;
	sourceLink?: string;
}

export default memo(FeedActivityHeader);
