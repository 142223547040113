import { useMemo, useQuery, useService } from '@hooks';

export const useNetworksOrChaptersNotificationSettings = (communityDefinition: 'chapter' | 'network') => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const { data: communitiesNotificationSettings, isLoading } = useQuery(
		reactQuery.queryKeys.getCommunitiesNotificationSettings(),
		async () => await api.notificationSettings.getCommunitiesNotificationSettings(),
	);

	const notificationSettings = useMemo(() => {
		if (!communitiesNotificationSettings) return [];
		// Response includes all type of communities. Need to show only networks with discussions enabled.
		return communitiesNotificationSettings.filter(
			(community) =>
				community.communityDefinition === communityDefinition &&
				community.communityTypeFeatures.includes('discussions'),
		);
	}, [communitiesNotificationSettings]);

	return {
		notificationSettings: notificationSettings,
		isNotificationSettingsLoading: isLoading,
	};
};
