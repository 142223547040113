import { isObject } from '@utils';
import { parseBlackthornUrl } from './parseBlackthornUrl';

/**
 * @link https://demo.events.blackthorn.io/blackthorn/advanced.html Blackthorn embedding documentation
 */
export const setUpBlackthornEventView = (registrationLink: string, callback?: () => void): EventsApp | undefined => {
	const { orgId, eventPath } = parseBlackthornUrl(registrationLink);

	if (eventPath && orgId) {
		const app = new EventsApp({
			orgId: orgId,
			path: eventPath,
			interceptors: [
				{
					event: 'ROUTE_CHANGE',
					handler: function (params, next) {
						next();
					},
				},
				{
					event: 'OPEN_EXTERNAL_LINK',
					handler: function (params, next) {
						if (isInterceptorAddOnlineToCalendarLink(params)) {
							next?.preventDefault?.();
							window.open(params.url, '_blank');
						}
						// As Blackthorn event is rendered in iframe, a user can't open Zoom link within. To fix it we initiate opening a new window from the parent context. (@see T21C-9061)
						else if (isOpeningLifeStream(params)) {
							openLifeStreamInNewWindow(params);
							next?.preventDefault?.();
						} else {
							next();
						}
					},
				},
			],
		});
		app.mount('.events-container');
		callback?.();

		return app;
	}
};

/** Check if the link is a link to add the event to the calendar
 * 	which should be opened in a new tab instead of the app (as fix for T21C-5555).
 * 	because it doesn't download the .ics/.ical file but opens the external app dialog
 * 	(which isn't open within the iframe).
 * 	Makes sense when you registered for the event, opened 'Add to Calendar' dropdown and chose an options with "(online)" title.
 * 	Examples of the links:
 * 	 - "http://events.blackthorn.io/00D8D0000008j1WUAQ/ical/a2j8D000001ebwgQAA?p=google"
 *   - "http://events.blackthorn.io/00D8D0000008j1WUAQ/ical/a2j8D000001ebwgQAA?p=office365"
 * 	 - "http://events.blackthorn.io/00D8D0000008j1WUAQ/ical/a2j8D000001ebwgQAA?p=outlook.com"
 * 	 - "http://events.blackthorn.io/00D8D0000008j1WUAQ/ical/a2j8D000001ebwgQAA?p=yahoo&offset=-180"
 *  Next links should be skipped because they are not downloading in the new window, we can rely on the "download" property in the interceptor "params" object:
 * 	 - "http://events.blackthorn.io/00D8D0000008j1WUAQ/ical/a2j8D000001ebwgQAA"
 *  @author DmitriyNikolenko
 */
function isInterceptorAddOnlineToCalendarLink(
	params: TBlackthornOpenExternalLinkInterceptorParams,
): params is TBlackthornOpenExternalLinkInterceptorDownloadLinkParams {
	return (
		isObject(params) &&
		'url' in params &&
		params.url.includes('events.blackthorn.io') &&
		params.url.includes('/ical/') &&
		(!('download' in params) || ('download' in params && !params.download))
	);
}

function isOpeningLifeStream(
	params: TBlackthornOpenExternalLinkInterceptorParams,
): params is TBlackthornOpenExternalLinkInterceptorExternalLinkParams {
	return isObject(params) && 'url' in params && params.url.includes('/live/');
}

function openLifeStreamInNewWindow(params: TBlackthornOpenExternalLinkInterceptorExternalLinkParams) {
	const windowUrl = `http://events.blackthorn.io${params.url}`;
	const windowName = 'windowName' in params ? params.windowName : '_blank';
	const windowFeatures = 'windowFeatures' in params ? params.windowFeatures : undefined;
	window.open(windowUrl, windowName, windowFeatures);
}

type TBlackthornOpenExternalLinkInterceptorParams =
	| {
			path: string; // e.g. '/tiger-talk-october-5a2j8D1ebwg/confirmation';
			viewName: string; // e.g. 'eventDetails';
			viewNamesFromRoot: string[]; // e.g.  ['eventDetails'];
	  }
	| TBlackthornOpenExternalLinkInterceptorExternalLinkParams
	| TBlackthornOpenExternalLinkInterceptorDownloadLinkParams;
type TBlackthornOpenExternalLinkInterceptorExternalLinkParams = {
	url: string; // e.g. '/00DEi000008E6CDMA0/live/sJcoqOuqTCXOnsWkag0uggtZc…BDt99xmeiMbhEXklBYKkuTR?contextKey=popup:14df554e'
	windowFeatures: string | undefined; // e.g. 'toolbar=no,directories=no,status=no,menubar=no,wid…560000000004,top=346.6072,left=233.39999999999998'
	windowName: '_system' | string; // e.g. 'livestreampopup:14df554e'
};
type TBlackthornOpenExternalLinkInterceptorDownloadLinkParams = {
	url: string;
	download: true;
};
