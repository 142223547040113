import { FormPageLayout, Page } from '../../components';
import { ActivityIndicator, EmptyStateMessage, Gap } from '@ui-kit';
import TipReviewForm from '@ui-modules/tipJar/components/TipReviewForm';
import TipDetails from '@ui-modules/tipJar/components/TipDetails';
import {
	useTranslation,
	useNavigate,
	useLayoutEffect,
	useSetState,
	useSearchParams,
	useNotification,
	useMe,
} from '@hooks';
import { useTipCreateDataSearchParams } from '@ui-modules/tipJar/hooks/useTipCreateDataSearchParams';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { useSendTipReviewMutation } from '@ui-modules/tipJar/hooks/useSendTipReviewMutation';
import { useCreateTipMutation } from '@ui-modules/tipJar/hooks/useCreateTipMutation';
import { ROUTES } from '@constants';
import styles from './CreateTipReviewPage.module.css';
import { useTipContextQueryParams } from '@ui-modules/tipJar/hooks/useTipContextQueryParams';
import { useEditTipReviewMutation } from '@ui-modules/tipJar/hooks/useEditTipReviewMutation';
import { useMyTipReviewQuery } from '@ui-modules/tipJar/hooks/useMyTipReviewQuery';
import { createTipFormSchema } from '@schemas';
import { compact, CommunityEntity } from '@utils';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import { useGroupMeetingsForTipCreationQuery } from '@ui-modules/tipJar/hooks/useGroupMeetingsForTipCreationQuery';
import { useAttachedMeetingsForTipCreationQuery } from '@ui-modules/tipJar/hooks/useAttachedMeetingsForTipCreationQuery';
import { useTipReviewQuery } from '@ui-modules/tipJar/hooks/useTipReviewQuery';
import { useTipImageIndexState } from '@ui-modules/tipJar/hooks/useTipImageIndexState';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import type { TCommunity } from '@typings';
import { useState } from 'react';

const CreateTipReviewPage = () => {
	const { t } = useTranslation();
	const { user } = useMe();
	const navigate = useNavigate();
	const { showSuccess } = useNotification();

	const [tipCreateFormValidationError, initialTipCreateFormValues] = useTipCreateDataSearchParams();
	const [tipCreateFormValues, setTipCreateFormValues] = useSetState(initialTipCreateFormValues);
	const tipId = useSearchParams()[0].get('tipId');
	const { contextCalendarItemId, contextCommunityId } = useTipContextQueryParams();
	const { data: group } = useCommunityQuery(contextCommunityId as string);
	const [localOwnerId, setLocalOwnerId] = useState<string | null | undefined>(null);

	useLayoutEffect(() => {
		if (!tipId && tipCreateFormValidationError) navigate(-1);
	}, [tipId, tipCreateFormValues]);

	const { mutateAsync: editTipReview } = useEditTipReviewMutation({
		onSuccess: () => {
			showSuccess({ title: t('Tip Saved') });
			navigate(-[ROUTES.newTip, ROUTES.newTipCategories, ROUTES.newTipMatches, ROUTES.createTip].length);
		},
	});

	const tipQuery = useTipQuery(tipId);
	const tip = tipId ? tipQuery.data : tipCreateFormValues;
	const isTipEditable = tipId ? !!tipQuery.data && TipEntity.canEditTip(tipQuery.data, user) : true;
	const myTipReviewQuery = useMyTipReviewQuery(tipId as string);
	const tipReviewId = myTipReviewQuery?.data?.id;
	const editing = !!myTipReviewQuery.data;
	const tipReviewQuery = useTipReviewQuery(tipId as string, tipReviewId);
	const [coverImageIndex, setCoverImageIndex] = useTipImageIndexState(tipQuery.data);

	const createTipMutation = useCreateTipMutation();
	const sendTipReviewMutation = useSendTipReviewMutation({
		onSuccess: () => {
			showSuccess({ title: t('Tip Saved') });
			navigate(-[ROUTES.newTip, ROUTES.newTipCategories, ROUTES.newTipMatches, ROUTES.createTip].length);
		},
	});

	const { data: groupMeetingsForTipCreation = [] } = useGroupMeetingsForTipCreationQuery(
		contextCalendarItemId ?? undefined,
		tipReviewQuery.data?.owner.id || (localOwnerId as string),
		undefined,
		canDoOnBehalfOfOtherMember(group, contextCommunityId) || null,
	);

	const { data: attachedMeetingsForTipCreation = [], isFetching: isFetchingAttachedMeetings } =
		useAttachedMeetingsForTipCreationQuery(tipReviewId! || contextCalendarItemId!, {
			select: (data) => compact(data.map((meeting) => meeting.meetingId)),
		});
	return (
		<Page title={t('Review Tip')}>
			{tipQuery.isFetching || myTipReviewQuery.isFetching || tipReviewQuery.isFetching || isFetchingAttachedMeetings ? (
				<ActivityIndicator type="fit" />
			) : tipQuery.isError || myTipReviewQuery.isError || tipCreateFormValidationError ? (
				<EmptyStateMessage text={tipCreateFormValidationError?.message || t('Something went wrong')} />
			) : (
				<TipReviewForm
					canDoOnBehalfOfOtherMember={canDoOnBehalfOfOtherMember(group, contextCommunityId)}
					contextCommunityId={contextCommunityId}
					editing={editing}
					ignoreDirty={editing}
					initialValues={{
						...myTipReviewQuery.data,
						ownerId: user.id,
						calendarItemsId: compact([contextCalendarItemId, ...attachedMeetingsForTipCreation]),
					}}
					renderWrapper={({ formProps, children }) => {
						if (formProps.values.ownerId !== localOwnerId) {
							setLocalOwnerId(formProps.values.ownerId);
						}
						return (
							<FormPageLayout
								allowedNextPaths={[ROUTES.createTipReview()]}
								formProps={formProps}
								headerTitle={t('Review Tip')}
								isSaveButtonHidden
								scrollEnabled
							>
								<div className={styles.createTipReviewPage}>
									{tip ? (
										<TipDetails
											initialDisplayedGalleryItemIndex={coverImageIndex ?? 0}
											isTipEditable={isTipEditable}
											tip={tip}
											onDisplayedGalleryItemChanged={(index, currentItem) =>
												setCoverImageIndex(currentItem?.type === 'image' ? index : null)
											}
											onTipPatched={setTipCreateFormValues}
										/>
									) : null}
									<hr className={styles.separator} />
									{children}
									<Gap gap={24} />
								</div>
							</FormPageLayout>
						);
					}}
					secondaryFieldLabelsBold
					submitButtonDisabled={tipId ? false : !createTipFormSchema.isValidSync(tipCreateFormValues)}
					submitLabel={t('Submit')}
					targetMeetings={groupMeetingsForTipCreation}
					title={editing ? t('Review Details') : t('Write a Review')}
					onSubmit={async (formValues) => {
						if (tipId && tipReviewId) {
							await editTipReview({ tipId, tipReviewId, ...formValues });
						} else if (tipId && !tipReviewId) {
							await sendTipReviewMutation.mutateAsync({ ...formValues, tipId });
						} else {
							await createTipMutation
								.mutateAsync({ coverImageIndex, ...tipCreateFormValues })
								.then((createdTip) => sendTipReviewMutation.mutateAsync({ ...formValues, tipId: createdTip.id }));
						}
					}}
				/>
			)}
		</Page>
	);
};

function isCommunityModerator(community?: TCommunity) {
	return community ? CommunityEntity.userIsModerator(community) : undefined;
}

function canDoOnBehalfOfOtherMember(group?: TCommunity, contextCommunityId?: string | null): boolean | undefined {
	return isCommunityModerator(group) && !!contextCommunityId;
}

export interface ICreateTipReviewPageParams {
	tipId: string;
}

export default CreateTipReviewPage;
