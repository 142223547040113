import { forwardRef } from 'react';
import MeatballMenu from '../MeatballMenu';
import BlockLink from '../BlockLink';
import { clsx } from '@utils';
import styles from './Post.module.css';
import type { ReactNode, ForwardRefExoticComponent, RefAttributes, ForwardedRef } from 'react';
import type { TMeatballMenuOption } from '../MeatballMenu';

import PostHeader from './PostHeader';
import PostTitle from './PostTitle';
import PostContent from './PostContent';
import PostActionBar from './PostActionBar';
import CommentActionBar from './PostCommentActionBar';
import PostImageGallery from './PostImageGallery';
import PostAttachments from './PostAttachments';
import PostButton from './PostButton';
import type { NavigateOptions } from 'react-router-dom';
import type { IFeedActivityProps } from '@ui-modules/feed/components/FeedActivity';

/** Compound post component allows to construct appropriate components for posts, comments, cards etc. */
const Post = (
	{
		meatballMenuOptions,
		headerElement,
		footerElement = null,
		withFooterSeparator = false,
		children,
		className,
		wrapperClassName,
		bodyClassName,
		isCard = false,
		postLink,
		postContentLink,
		headerClassName,
		footerClassName,
		navigationState,
		onClick,
		onMenuOpened,
		disableLinkNavigation,
	}: IPostProps,
	ref: ForwardedRef<HTMLElement>,
) => {
	return (
		<BlockLink
			className={wrapperClassName}
			navigationState={navigationState}
			to={disableLinkNavigation ? undefined : postLink}
			onClick={onClick}
		>
			<article className={clsx(styles.post, isCard && styles.post_card, className)} ref={ref}>
				<div className={clsx(styles.post__headerWrapper, headerClassName)}>
					{headerElement}
					{meatballMenuOptions?.length ? (
						<MeatballMenu
							options={meatballMenuOptions}
							position="bottom-left"
							testID="Post__MeatballMenu"
							onMenuOpened={onMenuOpened}
						/>
					) : null}
				</div>
				<BlockLink
					className={clsx(styles.post__bodyWrapper, bodyClassName)}
					navigationState={navigationState}
					to={disableLinkNavigation ? undefined : postContentLink}
					onClick={onClick}
				>
					{children}
				</BlockLink>
				<div
					className={clsx(
						styles.post__footerWrapper,
						withFooterSeparator && styles.post__footerWrapper_withSeparator,
						footerClassName,
					)}
				>
					{footerElement}
				</div>
			</article>
		</BlockLink>
	);
};

export interface IPostProps {
	/** React Element which is rendering on the top of the card. */
	headerElement: ReactNode;
	/** React Element which is rendering on the bottom of the card. */
	footerElement?: ReactNode;
	/** React Element which is rendering as a content the card. */
	children: ReactNode;
	/** Should a gold line separate content and footer. Default 'false'. */
	withFooterSeparator?: boolean;
	/** If passed the meatball menu is shown on the top right corner. */
	meatballMenuOptions?: TMeatballMenuOption[];
	/** CSS Class name passed to the card container. */
	className?: string;
	/** CSS Class name passed to the highest level container. */
	wrapperClassName?: string;
	/** CSS Class name passed to body container. */
	bodyClassName?: string;
	/** CSS Class name passed to header wrapper. */
	headerClassName?: string;
	/** CSS Class name passed to footer wrapper. */
	footerClassName?: string;
	/** Should it be rendered as an independent card (if true) or a list ros (if false). */
	isCard: boolean;
	/** Link around the whole post. */
	postLink?: string;
	/** Optional navigation state that would be passed when navigate to a new Page */
	navigationState?: NavigateOptions;
	/** Link around the post content. */
	postContentLink?: string;
	/** String which highlighted with  */
	highlightedText?: string;
	/** Click callback called when postLink or postContentLink opened. */
	onClick?: () => void;
	/** Called when the meatball menu is opened. */
	onMenuOpened?: () => void;
	/** URL to the single post screen can be disabled with this boolean passed as: True */
	disableLinkNavigation?: IFeedActivityProps['disableLinkNavigation'];
}

const PostCompoundComponent = forwardRef(Post) as TPostCompoundComponent;

type TPostCompoundComponent = ForwardRefExoticComponent<IPostProps & RefAttributes<HTMLElement>> & {
	Header: typeof PostHeader;
	Title: typeof PostTitle;
	Content: typeof PostContent;
	PostActionBar: typeof PostActionBar;
	CommentActionBar: typeof CommentActionBar;
	ImageGallery: typeof PostImageGallery;
	Attachments: typeof PostAttachments;
	Button: typeof PostButton;
};

PostCompoundComponent.Header = PostHeader;
PostCompoundComponent.Title = PostTitle;
PostCompoundComponent.Content = PostContent;
PostCompoundComponent.PostActionBar = PostActionBar;
PostCompoundComponent.CommentActionBar = CommentActionBar;
PostCompoundComponent.ImageGallery = PostImageGallery;
PostCompoundComponent.Attachments = PostAttachments;
PostCompoundComponent.Button = PostButton;

export default PostCompoundComponent;
