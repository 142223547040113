import type { IAppConfig } from '../interfaces/AppConfig.interface';
import { yup } from '@utils';
import { ValidationError } from 'yup';
import { THREE_DAYS_IN_HOURS } from '@constants';

/** Parses and collects environment variables to share with another services. */
export class AppConfigService implements IAppConfig {
	static inject = [] as const;
	constructor() {
		try {
			const parsedConfig = this.#environmentSchema.validateSync(
				{
					NODE_ENV: import.meta.env.VITE_NODE_ENV,
					APP_ENV: import.meta.env.VITE_APP_ENV,
					APP_HIDDEN: import.meta.env.VITE_APP_HIDDEN,
					API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
					AVATAR_BASE_URI: import.meta.env.VITE_AVATAR_BASE_URI,
					SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
					DEBUG: import.meta.env.VITE_DEBUG,
					OKTA_CLIENT_ID: import.meta.env.VITE_OKTA_CLIENT_ID,
					OKTA_DOMAIN: import.meta.env.VITE_OKTA_DOMAIN,
					ALGOLIA_APP_ID: import.meta.env.VITE_ALGOLIA_APP_ID,
					ALGOLIA_INDEX_PREFIX: import.meta.env.VITE_ALGOLIA_INDEX_PREFIX,
					STREAM_API_KEY: import.meta.env.VITE_STREAM_API_KEY,
					STREAM_APP_ID: import.meta.env.VITE_STREAM_APP_ID,
					GOOGLE_PLACE_API_KEY: import.meta.env.VITE_GOOGLE_PLACE_API_KEY,
					API_VIDEO_API_KEY: import.meta.env.VITE_API_VIDEO_API_KEY,
					DEAL_CLOSING_DATE_LIMIT: import.meta.env.VITE_DEAL_CLOSING_DATE_LIMIT,
					MIX_PANEL_TOKEN: import.meta.env.VITE_MIX_PANEL_TOKEN,
					CRYPTO_SECRET: import.meta.env.VITE_CRYPTO_SECRET,
					TIPS_AI_IMAGE_CLASSIFIER_URL: import.meta.env.VITE_TIPS_AI_IMAGE_CLASSIFIER_URL,
					TIPS_AI_SEARCH_DESCRIPTION_URL: import.meta.env.VITE_TIPS_AI_SEARCH_DESCRIPTION_URL,
				},
				{
					abortEarly: false,
					stripUnknown: true,
				},
			);

			// Setup env vars.
			this.NODE_ENV = parsedConfig.NODE_ENV;
			this.APP_ENV = parsedConfig.APP_ENV;
			this.APP_HIDDEN = parsedConfig.APP_HIDDEN;
			this.DEBUG = parsedConfig.DEBUG;
			this.API_BASE_URL = parsedConfig.API_BASE_URL;
			this.AVATAR_BASE_URI = parsedConfig.AVATAR_BASE_URI;
			this.SENTRY_DSN = parsedConfig.SENTRY_DSN;
			this.OKTA_CLIENT_ID = parsedConfig.OKTA_CLIENT_ID;
			this.OKTA_DOMAIN = parsedConfig.OKTA_DOMAIN;
			this.ALGOLIA_APP_ID = parsedConfig.ALGOLIA_APP_ID;
			this.ALGOLIA_INDEX_PREFIX = parsedConfig.ALGOLIA_INDEX_PREFIX;
			this.STREAM_API_KEY = parsedConfig.STREAM_API_KEY;
			this.STREAM_APP_ID = parsedConfig.STREAM_APP_ID;
			this.GOOGLE_PLACE_API_KEY = parsedConfig.GOOGLE_PLACE_API_KEY;
			this.API_VIDEO_API_KEY = parsedConfig.API_VIDEO_API_KEY;
			this.MIX_PANEL_TOKEN = parsedConfig.MIX_PANEL_TOKEN;
			this.CRYPTO_SECRET = parsedConfig.CRYPTO_SECRET;
			this.DEAL_CLOSING_DATE_LIMIT = parsedConfig.DEAL_CLOSING_DATE_LIMIT;
			this.TIPS_AI_IMAGE_CLASSIFIER_URL = parsedConfig.TIPS_AI_IMAGE_CLASSIFIER_URL;
			this.TIPS_AI_SEARCH_DESCRIPTION_URL = parsedConfig.TIPS_AI_SEARCH_DESCRIPTION_URL;
		} catch (error) {
			if (error instanceof ValidationError) {
				console.info('Validation error', error.errors);
			}
			throw error;
		}
	}

	#environmentSchema = yup.object({
		NODE_ENV: yup.mixed<NodeJS.ProcessEnv['NODE_ENV']>().oneOf(['development', 'production', 'test']).required(),
		APP_ENV: yup
			.mixed<NodeJS.ProcessEnv['APP_ENV']>()
			.oneOf(['dev', 'production', 'qa', 'qa2', 'staging', 'test'])
			.required(),
		APP_HIDDEN: yup.boolean().default(false).required(),
		DEBUG: yup.boolean().default(false).required(),
		API_BASE_URL: yup.string().required(),
		AVATAR_BASE_URI: yup.string().required(),
		SENTRY_DSN: yup.string().required(),
		OKTA_CLIENT_ID: yup.string().required(),
		OKTA_DOMAIN: yup.string().required(),
		ALGOLIA_APP_ID: yup.string().required(),
		ALGOLIA_INDEX_PREFIX: yup.string().required(),
		STREAM_API_KEY: yup.string().required(),
		STREAM_APP_ID: yup.string().required(),
		GOOGLE_PLACE_API_KEY: yup.string().required(),
		API_VIDEO_API_KEY: yup.string().required(),
		MIX_PANEL_TOKEN: yup.string().required(),
		CRYPTO_SECRET: yup.string().required(),
		DEAL_CLOSING_DATE_LIMIT: yup.string().default(THREE_DAYS_IN_HOURS).required(),
		TIPS_AI_IMAGE_CLASSIFIER_URL: yup.string().required(),
		TIPS_AI_SEARCH_DESCRIPTION_URL: yup.string().required(),
	});

	readonly NODE_ENV: NodeJS.ProcessEnv['NODE_ENV'];
	readonly APP_ENV: NodeJS.ProcessEnv['APP_ENV'];
	readonly APP_HIDDEN: boolean;
	readonly API_BASE_URL: NodeJS.ProcessEnv['API_BASE_URL'];
	readonly AVATAR_BASE_URI: NodeJS.ProcessEnv['AVATAR_BASE_URI'];
	readonly SENTRY_DSN: string;
	readonly OKTA_CLIENT_ID: string;
	readonly OKTA_DOMAIN: string;
	readonly DEBUG: boolean;
	readonly ALGOLIA_APP_ID: string;
	readonly ALGOLIA_INDEX_PREFIX: string;
	readonly STREAM_API_KEY: string;
	readonly STREAM_APP_ID: string;
	readonly GOOGLE_PLACE_API_KEY: string;
	readonly API_VIDEO_API_KEY: string;
	readonly MIX_PANEL_TOKEN: string;
	readonly CRYPTO_SECRET: string;
	readonly DEAL_CLOSING_DATE_LIMIT: string;
	readonly TIPS_AI_IMAGE_CLASSIFIER_URL: string;
	readonly TIPS_AI_SEARCH_DESCRIPTION_URL: string;
}
