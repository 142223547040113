import { Outlet, useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { ActivityIndicator } from '@ui-kit';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useEffect, useParams, useTrackAlgoliaEventEffect, useHandleEntityPageQueryError } from '@hooks';
import { ROUTES } from '@constants';
import { useTransformNetworkParams } from '@ui-modules/networks/hooks/useNetworksTabsState';

const NetworkPage = () => {
	const navigate = useNavigate();
	const networkTab = useParams<INetworkPageParams>().networkTab;
	const { networkId } = useTransformNetworkParams(useParams<INetworkPageParams>());
	const handleEntityPageQueryError = useHandleEntityPageQueryError('network');

	const { data: network, isLoading, isSuccess } = useCommunityQuery(networkId, { onError: handleEntityPageQueryError });
	const titleNetworkName = network?.name ? network.name : '...';

	useEffect(
		function redirectToDefaultTabIfItIsAbsent() {
			if (network && !networkTab) {
				navigate(ROUTES.networkTab(networkId), { replace: true });
			}
		},
		[networkTab, network],
	);

	useTrackAlgoliaEventEffect('Network Viewed', { networkSlug: String(networkId) }, [networkId]);

	return (
		<Page title={titleNetworkName}>
			{isLoading ? <ActivityIndicator type="fit" /> : null}
			{isSuccess ? <Outlet /> : null}
		</Page>
	);
};

export interface INetworkPageParams extends Record<string, string | undefined> {
	networkId: string;
}

export default NetworkPage;
