import NetworkConnectionFilesWidget from '@ui-modules/connections/components/NetworkConnectionFilesWidget';
import { useOpenFile } from '@ui-modules/files/hooks/useOpenFile';
import { useNavigate, useParams, useEvent, useSearchParams, useCallback } from '@hooks';
import { ROUTES } from '@constants';
import type { TFolder } from '@typings';

const NetworkConnectionFilesPage = () => {
	const navigate = useNavigate();
	const params = useParams<INetworkConnectionFilesPageParams>();
	const [searchParams] = useSearchParams();

	const networkConnectionId = params.id as string;
	const folderId = searchParams.get('folderId') ?? null;
	const { openFile } = useOpenFile();

	const openFolder = useEvent((openFolderId: TFolder['id'] | null | undefined, replace = true) => {
		if (folderId === openFolderId) return; // do not open the same folder twice that breaks the back button.
		navigate(ROUTES.networkConnectionFolder(networkConnectionId, openFolderId), { replace });
	});
	const makeFolderLink = useCallback(
		(folderId: TFolder['id'] | null) => ROUTES.networkConnectionFolder(networkConnectionId, folderId),
		[],
	);

	return (
		<>
			<NetworkConnectionFilesWidget
				folderId={folderId ?? null}
				makeFolderLink={makeFolderLink}
				networkConnectionId={networkConnectionId}
				onOpenFile={openFile}
				onOpenFolder={openFolder}
			/>
		</>
	);
};

export interface INetworkConnectionFilesPageParams extends Record<string, string | undefined> {
	id: string;
	folderId: string | undefined;
}

export default NetworkConnectionFilesPage;
