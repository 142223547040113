import styles from './PostLinksPreview.module.css';
import { useLinkPreviewQuery } from '@ui-modules/media/hooks/useLinkPreviewQuery';
import { useHandleActivityLink } from '@ui-modules/feed/hooks/useHandleActivityLink';
import type { KeyboardEventHandler, MouseEventHandler } from 'react';
import { ActivityIndicator, When } from '@ui-kit';
import { clsx } from '@utils';
import Clamp from 'react-multiline-clamp';

/** Card link with URLs. It uploads the link data from external resource. */
const LinkPreview = ({ link, onLinkClicked, style }: ILinkPreviewProps) => {
	const { data: linkPreview, isLoading } = useLinkPreviewQuery(link);
	const openLink = useHandleActivityLink();

	const handleClick: MouseEventHandler & KeyboardEventHandler = (event) => {
		event.preventDefault();
		event.stopPropagation();
		openLink(link);
		onLinkClicked?.(link);
	};

	if (isLoading)
		return (
			<div className={styles.linkPreviewLoading}>
				<ActivityIndicator size="extraSmall" type="fit" />
			</div>
		);

	if (!(linkPreview && linkPreview.error == undefined)) return null;
	return (
		<div className={clsx(styles.linkPreview, style)}>
			{/* <div className={styles.linkPreview__body}> */}
			<When condition={!!linkPreview.banner}>
				<img alt="" className={styles.linkPreview__image} src={linkPreview.banner} srcSet="" />
			</When>
			<div className={styles.linkPreview__content}>
				<span className={styles.linkPreview__domain}>{linkPreview.domain}</span>
				<Clamp lines={2}>
					<a className={styles.linkPreview__title} href={link} target="blank" onClick={handleClick}>
						{linkPreview.title}
					</a>
				</Clamp>
				<Clamp lines={2}>
					<span className={styles.linkPreview__description}>{linkPreview.description}</span>
				</Clamp>
			</div>
		</div>
	);
};

export interface ILinkPreviewProps {
	/** URL to show. */
	link: string;
	/** Callback to handle link click. */
	onLinkClicked?: (link: string) => void;
	style?: string;
}

export default LinkPreview;
