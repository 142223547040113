import RichMessageInputAttachment from '../RichMessageInputAttachment';
import styles from './RichMessageInputAttachments.module.css';
import type { TRichMessageInputAttachment } from '../RichMessageInputAttachment';

const RichMessageInputAttachments = ({ attachments }: IRichMessageInputAttachmentsProps) => {
	if (!attachments.length) return null;
	return (
		<div className={styles.richMessageInputAttachments}>
			{attachments.map((attachment, index) => (
				<RichMessageInputAttachment attachment={attachment} key={String(attachment?.id)} />
			))}
		</div>
	);
};

export interface IRichMessageInputAttachmentsProps {
	attachments: TRichMessageInputAttachment[];
}

export default RichMessageInputAttachments;
