import {
	useChat,
	useCheckTextOnMaliciousUrls,
	useMe,
	useMutation,
	useNotification,
	useService,
	useTranslation,
} from '@hooks';
import type { TAttachments, TEvent } from '@typings';
import type { Message, MessageResponse, UserResponse } from 'stream-chat';
import type { DefaultStreamChatGenerics } from 'stream-chat-react';
import { useChannelActionContext, useChannelStateContext } from 'stream-chat-react';
import { POST_MESSAGE_CHAR_LIMIT, STREAM_CHAT_BASE_URL } from '@constants';
import { compact, mapByFoundOrCreated, toString, uniq } from '@utils';
import { parseChatMessageUrls } from '../utils/parseChatMessageUrls';
import { useDraftAPI } from '@ui-modules/chat/hooks/useDraftAPI';
import type { TChatInteractionsMediaType } from '../../../services/interfaces/AnalyticsService.MixPanel.interface';
import { useTrackMessageSent } from './useTrackMessageSentQuery';

export const useSendMessage = (
	editMessage: MessageResponse | null,
	quotedMessageId: string,
	onSuccess?: () => void,
) => {
	const chat = useService('ChatService');
	const feed = useService('FeedService');
	const { channel } = useChannelStateContext();
	const { reset, selectedChannel } = useChat();
	const { user } = useMe();
	const checkTextOnMaliciousUrls = useCheckTextOnMaliciousUrls({ showMessage: true });
	const { showError } = useNotification();
	const { t } = useTranslation();
	const analytics = useService('AnalyticsService');
	const trackMessageSent = useTrackMessageSent(channel);

	const { draftMessage } = useDraftAPI(selectedChannel?.cid as string);

	const mentioned_users = (draftMessage?.mentioned_users || []) as UserResponse[];
	const getMentionUsers = (messageText = '') => {
		const mentionedUsersIds = [...mentioned_users, ...(editMessage?.mentioned_users || [])]
			.filter((el) => {
				const mentionPattern = new RegExp(`@${el.name}`, 'i');
				return mentionPattern.test(messageText);
			})
			.map((el) => el.id);

		return uniq(mentionedUsersIds);
	};

	const { jumpToMessage } = useChannelActionContext();

	const enhanceUrlPreviewImages = async (editMessage: MessageResponse, text?: string) => {
		const previousAttachments = (editMessage.attachments ?? []) as TAttachments[];
		const previewUrlImages = previousAttachments.filter((item) => 'title_link' in item || 'og_scrape_url' in item);

		const links = parseChatMessageUrls(text);
		const reducedLinks = uniq(links).slice(0, 3);

		const newPreviewUrlImages = await mapByFoundOrCreated(
			reducedLinks,
			previewUrlImages,
			'og_scrape_url' as never,
			async (link) => {
				try {
					const ogInfo = await feed.getOpenGraphInfo(link);
					if (ogInfo) {
						return {
							id: String(Date.now() + Math.random()),
							name: toString(ogInfo.title),
							isFile: false,
							og_scrape_url: 'original_url' in ogInfo ? ogInfo.original_url : ogInfo.url,
							title_link: 'original_url' in ogInfo ? ogInfo.original_url : ogInfo.url,
							author_name: ogInfo.site_name,
							title: ogInfo.title,
							image_url: ogInfo.images?.[0]?.url,
							text: ogInfo.description,
						};
					}
				} catch (error) {
					return null;
				}
			},
		);

		return compact(newPreviewUrlImages);
	};

	/** used to send direct or qouted  message */
	/** cannot be used for sending GIFs because they need to be first added to a virtual message list */
	const sendAndReplyMessage = async ({
		attachments,
		text,
		eventId,
		storedShareContent,
	}: IUseSendMessageMutationPayload) => {
		// Remove leading '/' characters
		while (text?.charAt(0) === '/') {
			text = text.substring(1);
		}

		if (String(text)?.length > POST_MESSAGE_CHAR_LIMIT) {
			throw new Error(
				t('The message you are trying to send exceeds {{limit}} characters limit', { limit: POST_MESSAGE_CHAR_LIMIT }),
			);
		}

		const options: Message<DefaultStreamChatGenerics> = {
			text: text ?? '',
			quoted_message_id: quotedMessageId,
			attachments: mapInternalAttachmentsToGetStreamAttachments(attachments),
			mentioned_users: getMentionUsers(text),
			eventId: eventId ?? null,
			sharedContent: storedShareContent ?? null,
		};
		const sentMessageResponse = await channel.sendMessage(options);

		analytics.trackEvent('ChatInteractions', {
			chat_type: `${chat.getChatType(channel)}-chat`,
			channel_id: String(channel.id),
			contact_id: chat.getOneToOneChatAnotherUserId(channel, user.id),
			chat_message_id: sentMessageResponse?.message?.id ?? 'unknown',
			media_type: compact([
				attachments.find((attachment) => attachment.type === 'image') && 'media',
				attachments.find((attachment) => attachment.type === 'file') && 'file',
				eventId && 'event',
				storedShareContent && 'sharedContent',
			] as TChatInteractionsMediaType[]),
		});
		trackMessageSent();

		return sentMessageResponse;
	};

	/** used to  send edited message */
	const sendEditedMessage = async ({
		attachments,
		text,
		eventId,
		storedShareContent,
	}: IUseSendMessageMutationPayload) => {
		if (editMessage) {
			const filterPreviewUrlImages = attachments?.filter((item) => item.url?.includes(STREAM_CHAT_BASE_URL));
			const newUniquePreviewUrlImages = await enhanceUrlPreviewImages(editMessage, text);

			if (String(text).length > POST_MESSAGE_CHAR_LIMIT) {
				throw new Error(
					t('The message you are trying to send exceeds {{limit}} characters limit', {
						limit: POST_MESSAGE_CHAR_LIMIT,
					}),
				);
			}

			const updatePayload = {
				text: text || ' ',
				eventId: eventId || null,
				attachments: filterPreviewUrlImages
					? mapInternalAttachmentsToGetStreamAttachments(filterPreviewUrlImages)
					: newUniquePreviewUrlImages,
				mentioned_users: getMentionUsers(text),
				sharedContent: storedShareContent || null,
			};
			await chat.chatClient.partialUpdateMessage(editMessage.id, { set: updatePayload });
		}
	};

	return useMutation(
		async ({ attachments, text, eventId, storedShareContent }: IUseSendMessageMutationPayload) => {
			if (await checkTextOnMaliciousUrls(String(text))) {
				return false;
			}
			if (chat.isOneToOneWithOneMember(channel)) {
				const contactUserId = chat.extractOtherOneToOneMemberId(channel, user.slug);
				if (contactUserId) {
					await chat.getOrMarkOneToOneChatWithUser(contactUserId, user.slug);
				}
			}
			if (editMessage) {
				await sendEditedMessage({ attachments, text, eventId, storedShareContent });
				jumpToMessage(editMessage.id);
			} else {
				await sendAndReplyMessage({ attachments, text, eventId, storedShareContent });
			}
			reset();
		},
		{
			onSuccess: () => onSuccess?.(),
			onError(error) {
				if (error instanceof Error) {
					showError({
						title: t('Error'),
						subtitle: error.message,
					});
				}
			},
		},
	);
};

/** this is the type of attechment object accepted by stream */
const mapInternalAttachmentsToGetStreamAttachments = (attachments: TAttachments[]) => {
	const streamAttachments = attachments.map((attachment) => {
		if (attachment.fileType === 'image') {
			return {
				image_url: attachment.url,
				fallback: attachment.name,
				type: attachment.fileType,
				original_height: attachment?.height,
				original_width: attachment?.width,
				file_size: attachment?.size,
			};
		} else if (attachment.type === 'giphy') {
			return attachment;
		} else {
			return {
				asset_url: attachment.url,
				type: attachment.fileType,
				title: attachment.name,
				mime_type: attachment.mimeType,
				thumb_url: attachment.thumb_url,
				original_height: attachment?.height,
				original_width: attachment?.width,
				file_size: attachment?.size,
			};
		}
	});
	return streamAttachments;
};

interface IUseSendMessageMutationPayload {
	attachments: TAttachments[];
	text?: string;
	eventId: TEvent['id'] | null;
	storedShareContent?: string | null | undefined;
}
