import styles from './RichMessageInputAction.module.css';
import { AnimatePresence, motion } from 'motion/react';

const MessageInputAction = ({
	title,
	description,
	Icon,
	color,
	onPress,
	disabled,
	appearance,
}: IRichMessageInputActionProps) => {
	return (
		<motion.button
			className={styles.richMessageInputAction}
			data-appearance={appearance}
			disabled={disabled}
			style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
			onClick={onPress}
		>
			<motion.div className={styles.richMessageInputAction__iconBox} data-disabled={disabled} key={title} layout>
				<Icon fill={color} height={24} width={24} />
			</motion.div>
			<AnimatePresence>
				{appearance === 'column' && (
					<motion.div
						animate={{ height: 'auto', width: 'auto' }}
						className={styles.richMessageInputAction__text}
						data-disabled={disabled}
						exit={{ height: 0, width: 0 }}
						initial={{ height: 0 }}
						key={title}
					>
						<span className={styles.richMessageInputAction__title} style={{ color }}>
							{title}
						</span>
						{description ? (
							<span className={styles.richMessageInputAction__description} style={{ color }}>
								{description}
							</span>
						) : null}
					</motion.div>
				)}
			</AnimatePresence>
		</motion.button>
	);
};

export type TRichMessageInputAction<T extends string = string> = {
	name: T;
	title: string;
	description?: string;
	Icon: React.FC<React.SVGProps<SVGSVGElement>>;
	color: string | undefined;
	onPress: () => void;
	disabled?: boolean;
};

export interface IRichMessageInputActionProps extends TRichMessageInputAction {
	appearance: TRichMessageInputActionAppearance;
}

export type TRichMessageInputActionAppearance = 'row' | 'column';

export default MessageInputAction;
