import { useMemo } from 'react';
import {
	useCallback,
	useNavigate,
	useOpenOrJoinCommunity,
	useService,
	useToggle,
	useTranslation,
	useUserSessionStorageValue,
} from '@hooks';
import { useOpenFile } from '@ui-modules/files/hooks/useOpenFile';
import { Modal, ActivityIndicator } from '@ui-kit';
import { compact, find, noop } from '@utils';
import styles from './AssetAllocationRecommendations.module.css';
import { ROUTES } from '@constants';
import FeedActivity from '@ui-modules/feed/components/FeedActivity';
import type { TActivity, TEvent, TSearchDiscussion } from '@typings';
import { useActivitiesQuery } from '@ui-modules/feed/hooks/useActivitiesQuery';
import { useHandleActivityLink } from '@ui-modules/feed/hooks/useHandleActivityLink';
import { useState } from 'reinspect';

const ARTICLES_DISPLAY_AMOUNT = 3;

const RecommendationsArticlesBlock = ({ posts, networkName }: IRecommendationsArticlesBlockProps) => {
	const navigate = useNavigate();
	const [joiningNetworkName, setJoiningNetworkName] = useState<null | string>(null);
	const analytics = useService('AnalyticsService');
	const { openFileUrl } = useOpenFile();

	const {
		data: activities,
		isLoading: isActivitiesLoading,
		isFetched: isArticlesFetched,
	} = useActivitiesQuery(compact(posts?.map((post) => post.streamActivityId)));

	const { t } = useTranslation();

	const onPostOpen = (activityId: TActivity['id'], globalFeedName: string | undefined) => {
		navigate(ROUTES.post(activityId, globalFeedName));
		analytics.trackEvent('AssetAllocationInteractions', {
			interaction_type: 'recommendation_interaction',
			action: 'view_discussion',
		});
	};

	const { mutateAsync: onArticleClick, isJoiningNetwork } = useOpenOrJoinCommunity({ onPostOpen });

	const { storageSavedValue: isArticlesSectionExpanded, setStorageSavedValue: setIsArticlesSectionExpanded } =
		useUserSessionStorageValue(`assetAllocationNetworks_articlesToggle_${networkName}`);

	const [expanded, setExpanded] = useToggle(
		isArticlesSectionExpanded ? Boolean(JSON.parse(isArticlesSectionExpanded)) : false,
	);

	const onToggleClick = () => {
		setIsArticlesSectionExpanded(JSON.stringify(!expanded));
		setExpanded(!expanded);
	};

	const openEvent = (event: TEvent) =>
		navigate(event['@type'] === 'Event' ? ROUTES.calendarEvent(event.id) : ROUTES.calendarMeeting(event.id));

	const onPressActivityLink = useHandleActivityLink();

	const articleBlock = useCallback(
		(activity: TActivity) => {
			const post = find(posts, (item) => item.streamActivityId === activity.id);
			const isLearnPost = post?.globalFeedName?.startsWith('L--');

			return (
				<FeedActivity
					activity={{ ...activity, origin: isLearnPost ? 'user:L--' : activity?.origin }}
					disableLinkNavigation={true}
					feedUserId={post?.globalFeedName}
					isCard={true}
					isMeatBallMenuVisible={false}
					isSourceHidden={false}
					key={activity.id}
					showFooter={false}
					withSeparator={true}
					onClickComment={noop}
					onClickEvent={openEvent}
					onClickReply={noop}
					onFileClick={openFileUrl}
					onInteracted={async (interactionSource) => {
						if (interactionSource === 'card body') {
							setJoiningNetworkName((activity?.collectionCommunityReference?.data?.name as string) || '');
							await onArticleClick({
								communityId: activity.collectionCommunityReference?.id,
								activityId: activity.id,
								globalFeedName: post?.globalFeedName || String(activity?.collectionCommunityReference?.data?.slug),
							});
						}
					}}
					onPressActivityLink={onPressActivityLink}
				/>
			);
		},
		[activities, isActivitiesLoading, isArticlesFetched, posts],
	);

	const getSeeAllButton = useCallback(() => {
		return (
			<div>
				<div className={styles.expandableBlock__showAllWrapper} onClick={onToggleClick} onKeyDown={onToggleClick}>
					<span>{!expanded ? t('Load more') : t('Show less')}</span>
				</div>
			</div>
		);
	}, [expanded]);

	const renderArticles = useMemo(() => {
		const initialArticles = activities?.slice(0, ARTICLES_DISPLAY_AMOUNT);
		const hiddenArticles = activities?.slice(ARTICLES_DISPLAY_AMOUNT);
		return { initialArticles, hiddenArticles };
	}, [activities]);

	if (isActivitiesLoading) {
		return <ActivityIndicator />;
	}

	return (
		<div>
			<Modal
				contentClassName={styles.modalContent}
				customHeader={
					<div className={styles.modalCustomHeaderWrapper}>
						<ActivityIndicator />
					</div>
				}
				subTitle={joiningNetworkName || ''}
				subtitleClassName={styles.modalSubtitle}
				title={t('Joining Network')}
				titleCentered
				visible={isJoiningNetwork}
			/>

			{renderArticles.initialArticles?.map((activity) => articleBlock(activity))}
			{expanded &&
				renderArticles?.hiddenArticles &&
				renderArticles?.hiddenArticles.map((activity) => articleBlock(activity))}
			{Number(activities?.length) > ARTICLES_DISPLAY_AMOUNT && getSeeAllButton()}
		</div>
	);
};

interface IRecommendationsArticlesBlockProps {
	posts?: TSearchDiscussion[];
	networkName?: string;
}

export default RecommendationsArticlesBlock;
