import { useService, useQuery } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TEvent } from '@typings';
import type { AxiosError } from 'axios';
import { httpCodes } from '../../../services/abstracts/AbstractAxiosService/httpCodes';

export const useGetMeetingQuery = (
	meetingId?: TEvent['id'],
	options: UseQueryOptions<TEvent, AxiosError, TEvent> = {},
) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');

	const query = useQuery<TEvent, AxiosError, TEvent>(
		queryKeys.getMeeting(String(meetingId)),
		async () => await api.meeting.getMeeting(meetingId as TEvent['id']),
		{
			retry: false,
			...options,
		},
	);

	return { ...query, isDeleted: query?.error?.response?.status === httpCodes?.NOT_FOUND };
};
