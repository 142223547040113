import { useEffect, useMe, useQuery, useQueryClient, useService } from '@hooks';
import { GLOBAL_SEARCH, type GLOBAL_SEARCH_DEFINITION } from '@constants';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { compact, dayjs } from '@utils';
import { ProfileEntity } from '@ui-modules/profile/utils/ProfileEntity';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TSearchData } from '@tiger21-llc/connect-shared/src/services/types/SearchService.interface';

export const useGlobalSearchResults = <T = TSearchData>(
	searchQuery: string,
	searchOptions?: TUseGlobalSearchResultSearchOptions,
	options?: UseQueryOptions<TSearchData, unknown, T>,
) => {
	const algoliaSearch = useService('AlgoliaSearchService');
	const queryClient = useQueryClient();
	const { user } = useMe();
	const { enabled: isDealsEnabled } = useDealsEnabled();

	const finalSearchQuery = searchQuery.trim();

	useEffect(() => {
		if (searchQuery?.length < GLOBAL_SEARCH.validSearchQueryLength) {
			queryClient.removeQueries(['globalSearch']);
		}
	}, [searchQuery]);

	useEffect(() => {
		if (searchQuery.length >= GLOBAL_SEARCH.validSearchQueryLength) {
			queryClient.refetchQueries(['globalSearch']);
		}
	}, [searchQuery]);

	return useQuery<TSearchData, unknown, T>(
		['globalSearch', finalSearchQuery, searchOptions?.omitDefinitions],
		async () =>
			await algoliaSearch.searchByAllIndexes(searchQuery.trim(), {
				omitDefinitions: compact([
					...(isDealsEnabled ? [] : (['deal', 'dealDocuments'] as const)),
					...(ProfileEntity.isFeatureEnabled(user, 'tips') ? [] : (['tip'] as const)),
					...(searchOptions?.omitDefinitions ?? []),
				]),
				// @TODO fixes [https://tiger21.atlassian.net/browse/T21C-9214]
				// hitsPerPage: searchOptions?.hitsPerPage,
			}),
		{
			enabled: searchOptions?.showInitialResults
				? finalSearchQuery.length === 0 || finalSearchQuery.length >= GLOBAL_SEARCH.validSearchQueryLength
				: finalSearchQuery.length >= GLOBAL_SEARCH.validSearchQueryLength,
			staleTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			cacheTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			...options,
		},
	);
};

type TGlobalSearchDefinition = keyof typeof GLOBAL_SEARCH_DEFINITION;

export type TUseGlobalSearchResultSearchOptions = {
	omitDefinitions?: TGlobalSearchDefinition[];
	hitsPerPage?: number;
	showInitialResults?: boolean;
};
