import { useQuery, useService } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TCommunity } from '@typings';
import { dayjs } from '@utils';
import { httpCodes } from '../../../services/abstracts/AbstractAxiosService/httpCodes';

import type { AxiosError } from 'axios';
import { LEARN } from '@ui-modules/networks/constants';

export const useCommunityQuery = (
	communityId: TCommunity['id'] | undefined,
	options?: UseQueryOptions<TCommunity, AxiosError, TCommunity>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const query = useQuery<TCommunity, AxiosError, TCommunity>(
		reactQuery.queryKeys.getCommunity(String(communityId)),
		async () => api.community.getCommunity(communityId as TCommunity['id']),
		{
			enabled: !!communityId && communityId !== LEARN,
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
			...options,
		},
	);

	return { ...query, isDeleted: query?.error?.response?.status === httpCodes?.NOT_FOUND };
};
