import { useMutation, useNavigate, useNotification, useService, useTranslation } from '@hooks';
import { ROUTES } from '@constants';
import { extractIdFromIri } from '@utils';
import type { TCommunity, TLatestActivity } from '@typings';

// Because GetStream activity become available with a significant delay, we need to retry to get the activity id. (@see T21C-8719) [@DmitriyNikolenko]
export const useHandleActivityAddedSuccess = () => {
	const { showSuccess } = useNotification();
	const { t } = useTranslation();
	const feedService = useService('FeedService');
	const navigate = useNavigate();

	return useMutation(
		async (options: Pick<TLatestActivity, '@id' | 'createdAt'> & { network: TCommunity | undefined }) => {
			const { createdAt, '@id': iri } = options;
			const foreignId = extractIdFromIri(String(iri));
			const activityId = await feedService.findActivityIdByForeignItAndTime(String(foreignId), String(createdAt));
			if (!activityId) {
				throw new Error('Activity not found');
			}
			return activityId;
		},
		{
			retry: 7,
			retryDelay: 1000,
			onSuccess(activityId, variables) {
				const networkName = variables.network?.name;
				showSuccess({
					onClick: () => navigate(ROUTES.post(activityId)),
					title: networkName
						? t('Successfully posted in {{communityName}}', { communityName: networkName })
						: t('Successfully posted'),
					subtitle: t('Tap to visit post'),
				});
			},
			onError() {
				// Fallback message with no ability to navigate to the post in case if the activity was not added until the end of the retry period.
				showSuccess({ title: t('Successfully posted') });
			},
		},
	).mutate;
};
