import { AlertIcon, DeleteIcon, Modal, PencilBoldIcon, ShareArrowIcon } from '@ui-kit';
import { useTranslation, useNavigate, useState, useMe, useCallback } from '@hooks';
import { compact, NetworkConnectionEntity } from '@utils';
import { useDeleteNetworkConnectionMutation } from './useDeleteNetworkConnectionMutation';
import { ROUTES } from '@constants';
import type { TMeatballMenuOption } from '@ui-kit';
import type { TCommunityMember, TNetworkConnection } from '@typings';
import { useInternalShareContext } from '@ui-modules/share/hooks/useInternalShare';

/** Prepares meatball menu options and related modal components for a single network connection.
 *  Can be used for in a single page and in the list because has the callback to prepare for an avery passed network connection.
 */
export const useNetworkConnectionMeatballMenu = (options?: {
	onDeleted?: (networkConnection: TNetworkConnection) => void;
	networkConnectionName?: string;
	onSelect?: (action: 'edit' | 'report' | 'delete', networkConnection: TNetworkConnection) => void;
	onSelectCallbackDependencies?: unknown[];
}) => {
	const { t } = useTranslation();
	const { user } = useMe();
	const navigate = useNavigate();

	const [deletingNetworkConnection, setDeletingNetworkConnection] = useState<TNetworkConnection | null>(null);
	const { mutate: deleteNetworkConnection, isLoading: isDeleting } = useDeleteNetworkConnectionMutation(
		options?.networkConnectionName,
		{
			onSettled: () => setDeletingNetworkConnection(null),
			onSuccess: (_, networkConnection) => options?.onDeleted?.(networkConnection),
		},
	);

	const { setInternalShare } = useInternalShareContext();

	const onShareClickHandler = (id: string) => {
		setInternalShare({
			type: 'network_connection_post',
			id: id,
		});
	};

	const getMeatballMenuOptions = useCallback(
		(networkConnection: TNetworkConnection, communityModerators: TCommunityMember[]): TMeatballMenuOption[] =>
			compact([
				NetworkConnectionEntity.canEdit(networkConnection, user) && {
					text: t('Edit'),
					onClick: () => {
						options?.onSelect?.('edit', networkConnection);
						navigate(ROUTES.editNetworkConnection(networkConnection.id));
					},
					icon: <PencilBoldIcon fill="black" height={16} strokeWidth={4} width={16} />,
					description: t('Change the information.'),
					type: 'solid',
				},
				!NetworkConnectionEntity.isCreator(networkConnection, user) && {
					text: t('Report This {{connectionName}}', { connectionName: options?.networkConnectionName }),
					onClick: () => {
						options?.onSelect?.('report', networkConnection);
						navigate(ROUTES.reportContent('connection', networkConnection.id));
					},
					icon: <AlertIcon fill="#000000" />,
					description: t('Flag this {{connectionName}} for further review.', {
						connectionName: options?.networkConnectionName,
					}),
					type: 'solid',
				},
				{
					text: t('Share'),
					onClick: () => {
						onShareClickHandler(networkConnection.id);
					},
					icon: <ShareArrowIcon height={16} strokeWidth={4} width={16} />,
					type: 'solid',
				},
				communityModerators &&
					NetworkConnectionEntity.canDelete(networkConnection, user, communityModerators) && {
						text: t('Delete'),
						onClick: () => {
							options?.onSelect?.('delete', networkConnection);
							setDeletingNetworkConnection(networkConnection);
						},
						type: 'destructive',
						icon: <DeleteIcon />,
					},
			]),
		options?.onSelectCallbackDependencies ?? [],
	);

	const ConfirmationModals = (
		<>
			{deletingNetworkConnection ? (
				<Modal
					cancelTitle={t('Cancel')}
					confirmTitle={t('Delete')}
					isConfirmLoading={isDeleting}
					key={String(deletingNetworkConnection?.id)}
					subTitle={t('Deleting “{{networkConnectionTitle}}” is permanent and cannot be undone.', {
						networkConnectionTitle: deletingNetworkConnection.title,
					})}
					title={t('Delete {{connectionName}}?', { connectionName: options?.networkConnectionName })}
					variant="medium"
					visible={!!deletingNetworkConnection}
					onCancel={() => setDeletingNetworkConnection(null)}
					onConfirm={() => deleteNetworkConnection(deletingNetworkConnection)}
				/>
			) : null}
		</>
	);

	return [getMeatballMenuOptions, ConfirmationModals] as const;
};
