import CameraPhotoInput, { type ICameraPhotoInputProps } from '../CameraPhotoInput';
import ModalContainer from '../ModalContainer';
import { forwardRef, useImperativeHandle, useState, type ForwardedRef } from 'react';

const CAMERA_WIDTH = 574; // equals to default width of ModalContainer
const CAMERA_HEIGHT = (574 / 4) * 3; // calculates with 4:3 aspect ratio

/** Modal window which allows to make a photo from Web Camera. */
const CameraPhotoModal = (
	{ t, value = undefined, onSelect }: ICameraPhotoModalProps,
	ref: ForwardedRef<IICameraPhotoModalMethods>,
) => {
	const [cameraModalVisible, setCameraModalVisible] = useState(false);

	useImperativeHandle(
		ref,
		() => ({
			show: () => setCameraModalVisible(true),
			hide: () => setCameraModalVisible(false),
		}),
		[],
	);

	return (
		<ModalContainer
			backdropClose
			showClose={false}
			visible={cameraModalVisible}
			onClose={() => setCameraModalVisible(false)}
		>
			<>
				<CameraPhotoInput
					height={CAMERA_HEIGHT}
					t={t}
					value={value}
					width={CAMERA_WIDTH}
					onChange={(response) => {
						setCameraModalVisible(false);
						if (!response) return;
						const file = new File([response.blob], `camera-photo-${Date.now()}`, {
							type: response.blob.type,
						});
						onSelect(file);
					}}
				/>
			</>
		</ModalContainer>
	);
};

export interface ICameraPhotoModalProps
	extends Pick<ICameraPhotoInputProps, 't'>,
		Partial<Pick<ICameraPhotoInputProps, 'value'>> {
	onSelect: (file: File) => void;
}

export interface IICameraPhotoModalMethods {
	show: () => void;
	hide: () => void;
}

export default forwardRef(CameraPhotoModal);
