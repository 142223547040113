import { Page } from '../../components';
import {
	useTranslation,
	useRouteSearchQueryState,
	useToggle,
	useFloatingHeader,
	useRestoreVirtuosoInitialIndex,
	useNavigate,
} from '@hooks';
import styles from './CommunityTipsPage.module.css';
import { clsx, startCase } from '@utils';
import { ActivityIndicator, Button, EditIcon, Gap, SearchableCompactScreenHeader, When } from '@ui-kit';
import TipFiltersDropdown from '@ui-modules/tipJar/components/TipFiltersDropdown';
import { useTipCategoriesSearchParams } from '@ui-modules/tipJar/hooks/useTipCategorySearchParams';
import { useCommunityTipsQuery } from '@ui-modules/tipJar/hooks/useCommunityTipsQuery';
import TipsSortWidget from '@ui-modules/tipJar/components/TipsSortWidget';
import TipList from '@ui-modules/tipJar/components/TipList';
import TipSearchResultList from '@ui-modules/tipJar/components/TipSearchResultList';
import type { TCommunityDefinition, TTip } from '@typings';
import { ROUTES } from '@constants';
import TipJarIntroModal from '@ui-modules/tipJar/components/TipJarIntroModal';

const CommunityTipsPage = ({ communityId, communityDefinition }: ICommunityTipsPageParams) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [searchQuery, setSearchQuery] = useRouteSearchQueryState();
	const [isSearchFocused, setIsSearchActive] = useToggle(false);

	const { tipCategories, selectedTipCategories, handleApplyCategories } = useTipCategoriesSearchParams();
	const { selectedSortOption, sortOptions, setSelectedSortOption } = useTipCategoriesSearchParams();

	const {
		data: tips,
		isFetched,
		isFetchingNextPage,
		isLoading,
		fetchMore,
	} = useCommunityTipsQuery(communityId, {
		categories: selectedTipCategories,
		sort: selectedSortOption,
	});
	const RESTORE_KEY = `communityTips-${communityId}-${JSON.stringify({
		selectedSortOption,
		selectedTipCategories,
		searchQuery,
	})}`;
	const { initialTopMostItemIndex, onRangeChanged } = useRestoreVirtuosoInitialIndex(RESTORE_KEY);

	const { floatingHeaderRef, floatingHeaderClassName, onScroll, itemsRendered, Placeholder, floatingHeaderHeight } =
		useFloatingHeader<TTip>(tips, initialTopMostItemIndex < 2, undefined);

	return (
		<Page title={t('{{communityDefinition}} Tips', { communityDefinition: startCase(communityDefinition) })}>
			<>
				<TipJarIntroModal />
				<div className={clsx(floatingHeaderClassName, styles.communityTips)} ref={floatingHeaderRef}>
					<Gap gap={8} />
					<SearchableCompactScreenHeader
						customButtonsElement={
							<Button
								icon={<EditIcon height={14} width={14} />}
								title={t('New')}
								type="outline"
								variant="small"
								onClick={() =>
									navigate(
										ROUTES.newTip({
											contextCommunityId: communityId,
											tags: [],
										}),
									)
								}
							/>
						}
						placeholder={t('Search tips')}
						searchQuery={searchQuery}
						searchTitle={t('Search')}
						title={t('Tips')}
						titleAs="h3"
						onChangeSearchQuery={(query) => {
							setSearchQuery(query);
						}}
						onFocus={() => setIsSearchActive(true)}
						onSearchCanceled={() => {
							setSearchQuery('');
							setIsSearchActive(false);
						}}
					/>
					{tipCategories ? (
						<>
							<div className={styles.communityTips__headerFilter}>
								<TipFiltersDropdown
									initialSelectedCategories={selectedTipCategories}
									tipCategories={tipCategories}
									onApply={handleApplyCategories}
								/>
							</div>
						</>
					) : null}

					<When condition={!isSearchFocused}>
						<Gap gap={16} />
						<TipsSortWidget
							selectedOption={selectedSortOption}
							sortOptions={sortOptions}
							title={t('Sort By')}
							onSelect={setSelectedSortOption}
						/>
					</When>
					<Gap gap={16} />
				</div>
				<div className={clsx(styles.communityTips__list)}>
					{searchQuery.length ? (
						<>
							<Gap gap={16} />
							<TipSearchResultList
								HeaderComponent={Placeholder}
								itemsRendered={itemsRendered}
								searchQuery={searchQuery}
								tipFilters={{ categories: selectedTipCategories, communityId }}
								onScroll={onScroll}
							/>
						</>
					) : (
						<>
							{isLoading ? (
								<div style={{ marginTop: floatingHeaderHeight }}>
									<ActivityIndicator size="medium" type="fit" />
								</div>
							) : (
								<TipList
									data={tips}
									endReached={fetchMore}
									HeaderComponent={Placeholder}
									initialTopMostItemIndex={initialTopMostItemIndex}
									isFetched={isFetched}
									isFetchingNextPage={isFetchingNextPage}
									isLoading={isLoading}
									itemsRendered={itemsRendered}
									key={RESTORE_KEY}
									rangeChanged={onRangeChanged}
									tipCardClassName={styles.tipCardCommunity}
									tipContext={{ contextCommunityId: communityId }}
									onScroll={onScroll}
								/>
							)}
						</>
					)}
				</div>
			</>
		</Page>
	);
};

interface ICommunityTipsPageParams extends Record<string, string | undefined> {
	communityId: string;
	communityDefinition: TCommunityDefinition;
}

export default CommunityTipsPage;
