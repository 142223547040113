import React, { useRef, useState } from 'react';
import styles from './NetworkShareModalContent.module.css';
import type { TCommunity } from '@typings';
import CollapsibleTextButton from '@ui-kit/components/CollapsibleText/CollapsibleTextButton';
import { useTranslation } from '@hooks';
import { ArrowRightIcon, NetworkFilledIcon, DealsIcon } from '@ui-kit';

const NetworkList: React.FC<INetworkListProps> = ({ data, titleKey, iconType, onClick }) => {
	const [slicedDataLength, setSlicedDataLength] = useState(3);
	const [imageErrors, setImageErrors] = useState<{ [key: string]: boolean }>({});
	const ref = useRef<HTMLUListElement>(null);
	const { t } = useTranslation();

	const handleImageError = (networkId: string) => {
		setImageErrors((prev) => ({ ...prev, [networkId]: true }));
	};

	const renderIcon = (network: TCommunity) => {
		if ((network.backgroundUrl || network.avatarUrl) && !imageErrors[network.id]) {
			return (
				<img
					alt={network.name}
					className={styles.networkList__avatar}
					src={(network.backgroundUrl || network.avatarUrl) as unknown as string}
					onError={() => handleImageError(network.id)}
				/>
			);
		}

		return iconType === 'network' ? (
			<NetworkFilledIcon fill="var(--color-primaryDarkGray)" height={34} width={34} />
		) : (
			<DealsIcon fill="var(--color-primaryDarkGray)" height={34} width={34} />
		);
	};

	return (
		<div className={styles.networkList__container}>
			{titleKey && <h3 className={styles.networkList__newDiscussionsTitle}>{titleKey}</h3>}

			<ul className={styles.networkList__networkListWrapper} ref={ref}>
				{data.slice(0, slicedDataLength).map((network) => {
					return (
						<li key={network.id}>
							<button className={styles.networkList__shareOptionContainer} onClick={() => onClick(network)}>
								<div className={styles.networkList__shareOption}>
									{renderIcon(network)}
									<span>{network.name}</span>
								</div>
								<ArrowRightIcon fill={'#000'} height={16} width={14} />
							</button>
						</li>
					);
				})}
			</ul>
			{data.length > 3 && data.length > slicedDataLength && (
				<div className={styles.networkList__showMoreContainer}>
					<CollapsibleTextButton
						className={styles.networkList__showMore}
						title={t('Show More')}
						onToggle={() => {
							setSlicedDataLength(data.length);
							ref.current?.scrollTo({ top: ref.current.scrollHeight, behavior: 'smooth' });
						}}
					/>
				</div>
			)}
		</div>
	);
};

interface INetworkListProps {
	data: TCommunity[];
	titleKey?: string;
	iconType: 'network' | 'invest';
	onClick: (network: TCommunity) => void;
}

export default NetworkList;
