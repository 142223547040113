import { OktaError, OktaInvalidCredentialsError } from '../../../services/implementations/OktaService';
import type { IToastProps } from '@tiger21-llc/connect-shared/src/typings';
import type { TFunction } from 'i18next';
import { AxiosError } from 'axios';
import { toString, get, dayjs } from '@utils';
import { httpCodes } from '../../../services/abstracts/AbstractAxiosService/httpCodes';

export const translateOktaUnsuitableResponse =
	(t: TFunction) =>
	(error: OktaError | Error | null | AxiosError): Pick<IToastProps, 'title' | 'subtitle'> | null => {
		if (!error) return null;

		if (error instanceof AxiosError) {
			if (error.response?.status === httpCodes.NOT_FOUND) return new OktaInvalidCredentialsError().getMessage(t);
			else if (error.response?.status === httpCodes.TOO_MANY_REQUESTS) {
				const nextRenewalUnixTimestamp = error.response.headers?.['X-Rate-Limiter-Next-Renewal'];
				const numberOfMinutes = nextRenewalUnixTimestamp
					? dayjs.unix(Number.parseInt(nextRenewalUnixTimestamp, 10)).diff(dayjs(), 'minutes', false)
					: 10;
				return {
					title: t('Too many requests'),
					subtitle: t('Please, try again after {{numberOfMinutes}} minutes', { numberOfMinutes }),
				};
			} else
				return {
					title: t('Something went wrong'),
					subtitle: toString(get(error, ['response', 'data', 'message'])) || toString(error?.message),
				};
		} else if (error instanceof OktaError) return error.getMessage(t);
		else
			return {
				title: t('Error'),
				subtitle: error.message,
			};
	};
