import { ROUTES } from '@constants';
import { useEffect, useState, useNavigate, useService, useTranslation } from '@hooks';
import type { TDealFilter } from '@typings';
import { BellSlashIcon, EditPencilIcon, BellIcon, ActivityIndicator } from '@ui-kit';
import { getDealFilterStatus } from '@ui-modules/networks/utils/getDealFilterStatus';
import { toString, uniq } from '@utils';

import styles from './DealFilters.module.css';
import useDealFiltersSettingsMutation from '@ui-modules/deals/hooks/useDealFiltersSettingsMutation';

const DealFilters = ({ filters, communityId = '', filterTitle, title, isLoading }: IDealFiltersProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const analytics = useService('AnalyticsService');

	const [isMuted, setIsMuted] = useState<boolean | null>(null);

	const { patchDealFiltersSettings, isPatchingDealFiltersSettingsLoading } = useDealFiltersSettingsMutation(false);

	useEffect(() => {
		setIsMuted(Boolean(filters?.alerts));
	}, [filters?.alerts]);

	useEffect(() => {
		return () => {
			queryClient.invalidateQueries(queryKeys.getDealSettings());
			queryClient.refetchQueries(queryKeys.getDealSettings());
		};
	}, [queryClient, queryKeys]);

	const MuteIcon = !isMuted ? BellIcon : BellSlashIcon;

	const handleMuteToggle = () => {
		let arr: string[] = [];
		if (isMuted) {
			arr = [];
		} else {
			arr = ['email', 'push/expo'];
		}

		setIsMuted(!isMuted);

		patchDealFiltersSettings({
			id: filters.id as string,
			data: { notificationChannels: uniq(arr), alerts: !isMuted },
		});

		analytics.trackEvent('InvestAlertsChanged', { invest_alerts_preference: !isMuted });
		analytics.trackEvent('DealInteractions', {
			page_name: title,
			action_taken: !isMuted ? 'alerts on' : 'alerts off',
			interaction_type: 'alerts toggled',
		});
	};

	const handleDealEdit = () => {
		if (communityId) {
			navigate(ROUTES.dealFilters(communityId));
		} else {
			navigate(ROUTES.allDealFilters());
		}
	};

	const statusOfDealFilter = getDealFilterStatus(t, filters?.status || null);

	return (
		<div className={styles.container}>
			<div className={styles.alertsWrapper}>
				<button
					className={styles.itemWrapper}
					disabled={isPatchingDealFiltersSettingsLoading || isMuted === null || isLoading}
					onClick={handleMuteToggle}
				>
					{isPatchingDealFiltersSettingsLoading || isMuted === null ? (
						<div className={styles.activityIndicator}>
							<ActivityIndicator color="var(--color-link)" />
						</div>
					) : (
						<>
							<MuteIcon className={styles.bellIcon} height={20} width={25} />
							<span className={styles.description}>{isMuted ? t('Alerts off') : t('Alerts on')}</span>
						</>
					)}
				</button>
			</div>
			<div>
				{isLoading ? (
					<ActivityIndicator className={styles.activityIndicatorTitle} color="var(--color-white)" />
				) : (
					<span className={styles.filterDescription}>
						{t('{{status}} Deals in ', {
							status: statusOfDealFilter,
						})}
						{toString(filterTitle)}
						{t(' with filters')}
					</span>
				)}
			</div>
			<div className={styles.editWrapper}>
				<button className={styles.itemWrapper} onClick={handleDealEdit}>
					<EditPencilIcon fill="#59A5FF" height={20} width={25} />
					<span className={styles.description}>{t('Edit Filter')}</span>
				</button>
			</div>
		</div>
	);
};

export interface IDealFiltersProps {
	communityNames?: string[];
	communityId?: string;
	filters: Partial<TDealFilter>;
	filterTitle: string | undefined;
	title: string;
	isLoading?: boolean;
}

export default DealFilters;
