import { Outlet, useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { ActivityIndicator, Button, SpacedGroup, ScreenHeader, When, ShareArrowIcon } from '@ui-kit';
import { CommentIcon, EmailIcon } from '@ui-kit/icons';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import {
	useEffect,
	useParams,
	useTrackAlgoliaEventEffect,
	useTranslation,
	useChat,
	useMe,
	useHandleEntityPageQueryError,
} from '@hooks';
import { useSendEmailsToCommunityMutation } from '@ui-modules/communities/hooks/useSendEmailsToCommunityMutation';
import { CommunityEntity } from '@utils';
import { ROUTES } from '@constants';
import type { TChapterTabName, TCommunity } from '@typings';
import { useInternalShareContext } from '@ui-modules/share/hooks/useInternalShare';

const ChapterPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { chapterId, chapterTab } = useParams<IChapterPageParams>() as IChapterPageParams;
	const { setShouldUpdateChannel } = useChat();

	const handleEntityPageQueryError = useHandleEntityPageQueryError('chapter');
	const {
		data: chapter,
		isLoading,
		isSuccess,
	} = useCommunityQuery(chapterId, {
		onError: handleEntityPageQueryError,
	});
	const chapterName = chapter?.name ? chapter.name : '...';
	const { setInternalShare } = useInternalShareContext();

	// Actions.
	const { mutate: sendEmailsToCommunity, isLoading: isCommunityEmailsLoading } = useSendEmailsToCommunityMutation();
	const openGroupChat = (chapterSlug: TCommunity['slug']) => {
		navigate(ROUTES.chatChannel(chapterSlug));
		setShouldUpdateChannel(false);
	};

	useEffect(
		function redirectToDefaultTabIfItIsAbsent() {
			if (chapter && !chapterTab) {
				navigate(ROUTES.chapterTab(chapterId), { replace: true });
			}
		},
		[chapterTab, chapter],
	);

	useTrackAlgoliaEventEffect('Chapter Viewed', { communitySlug: String(chapterId) }, [chapterId]);

	const onChapterShare = () => {
		if (!chapter?.id) return;
		setInternalShare({
			type: 'chapter',
			id: chapter?.id as string,
		});
	};

	return (
		<Page title={chapterName}>
			{isLoading ? <ActivityIndicator type="fit" /> : null}
			{isSuccess ? (
				<>
					<ScreenHeader title={chapter.name}>
						<SpacedGroup>
							<When condition={CommunityEntity.shouldShowGroupCommunicationFeatures(chapter)}>
								<Button
									icon={<EmailIcon fill="black" height={16} width={16} />}
									iconPosition="left"
									loading={isCommunityEmailsLoading}
									title={t('Email Chapter')}
									type="outline"
									variant="small"
									onClick={() => sendEmailsToCommunity(chapter.id)}
								/>
							</When>
							<When condition={CommunityEntity.shouldShowGroupCommunicationFeatures(chapter)}>
								<Button
									icon={<CommentIcon fill="black" height={16} width={16} />}
									iconPosition="left"
									title={t('Chapter Chat')}
									type="outline"
									variant="small"
									onClick={() => openGroupChat(chapter.slug)}
								/>
							</When>
							<Button
								icon={<ShareArrowIcon fill="black" height={16} width={16} />}
								iconPosition="right"
								title={t('Share')}
								type="outline"
								variant="small"
								onClick={onChapterShare}
							/>
						</SpacedGroup>
					</ScreenHeader>
					<Outlet />
				</>
			) : null}
		</Page>
	);
};

export interface IChapterPageParams extends Record<string, string | undefined> {
	chapterId: string;
	chapterTab: TChapterTabName;
}

export default ChapterPage;
