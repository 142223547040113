import { clsx } from '@utils';
import styles from './ShareableCard.module.css';
import type { ReactNode } from 'react';
import ShareCardCloseButton from '@ui-modules/share/components/ShareableCard/ShareCardCloseButton';

const RestrictedShareCard = ({ containerClassName, title, icon, clearInternalShare }: IRestrictedShareCardProps) => {
	return (
		<div className={clsx(styles.card, containerClassName)}>
			<div className={styles.gradientBackground} />
			<div className={clsx(styles.content, styles.content_small)}>
				{!!icon && <div className={styles.restrictedIconWrapper}>{icon}</div>}

				{title && <p className={clsx(styles.information)}>{title}</p>}
			</div>
			{clearInternalShare && <ShareCardCloseButton onClick={clearInternalShare} />}
		</div>
	);
};

interface IRestrictedShareCardProps {
	containerClassName?: string;
	icon?: ReactNode;
	title?: string;
	clearInternalShare?: () => void;
}

export default RestrictedShareCard;
