import { Page } from '../../components';
import { ActionListItem, EmptyStateMessage, Gap, PlusIcon, When } from '@ui-kit';
import { SimplePageLayout } from '../../components/SimplePageLayout/SimplePageLayout';
import TipCard from '@ui-modules/tipJar/components/TipCard';
import { useTranslation, useNavigate } from '@hooks';
import { useSearchTipsQuery } from '@ui-modules/tipJar/hooks/useSearchTipsQuery';
import { useTipsAiSuggestionsQuery } from '@ui-modules/tipJar/hooks/useTipsAiSuggestionsQuery';
import { useTipFormSearchParams } from '@ui-modules/tipJar/hooks/useTipFormSearchParams';
import { useGuessTipInputQuery } from '@ui-modules/tipJar/hooks/useGuessTipInputQuery';
import { compact } from '@utils';
import { ROUTES } from '@constants';
import styles from './NewTipMatchesPage.module.css';
import type { TPatchTipForm } from '@schemas';

const NewTipMatchesPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [tipFormParamsValidationError, tipFormValues] = useTipFormSearchParams();
	const guessTipInputQuery = useGuessTipInputQuery(tipFormValues);
	const guessTipInputPatchForm = guessTipInputQuery.data ?? ({} as TPatchTipForm);
	const tipsSearchQuery =
		guessTipInputPatchForm.title ?? guessTipInputPatchForm.description ?? tipFormValues.description;
	const tipMatchesQuery = useSearchTipsQuery(
		tipsSearchQuery,
		{},
		{ hitsPerPage: 5 },
		{ enabled: guessTipInputQuery.isSuccess || guessTipInputQuery.isError },
	);
	const aiTipsQuery = useTipsAiSuggestionsQuery(tipFormValues, guessTipInputPatchForm, {
		enabled: guessTipInputQuery.isSuccess,
	});

	const loadingFinished = !tipMatchesQuery.isFetching && !aiTipsQuery.isFetching && !guessTipInputQuery.isFetching;
	const someMatchesFound = !!tipMatchesQuery.data?.length || !!aiTipsQuery.data?.length;
	const successAndThreeMatchesInEachResponseFound =
		loadingFinished && tipMatchesQuery.data?.length >= 3 && aiTipsQuery.data && aiTipsQuery.data?.length >= 3;
	const title = !loadingFinished
		? t('Looking for matches...')
		: someMatchesFound
			? t('We found these matches..')
			: t('No matches found');

	if (tipFormParamsValidationError)
		return (
			<div className={styles.newTipMatchesPage__emptyMessageBox}>
				<EmptyStateMessage text={t('Something went wrong')} />
			</div>
		);
	return (
		<Page title={t('Select Match')}>
			<SimplePageLayout headerTitle={t('Select Match')} scrollEnabled>
				<div className={styles.newTipMatchesPage__pageContent}>
					<h2>{title}</h2>
					<Gap gap={16} />
					<p className={styles.newTipMatchesPage__description}>
						{loadingFinished && !someMatchesFound
							? t('The A.I. engine couldn’t find any matches. Go back to try again, or create the tip yourself.')
							: t('Select the one that most closely matches your Tip or create your own.')}
					</p>
					<Gap gap={16} />
					<When condition={!loadingFinished || !someMatchesFound}>
						<ActionListItem
							Icon={<PlusIcon fill={'black'} height={24} width={24} />}
							note={t('Don’t see your match?')}
							title={t('Create My Own Tip')}
							onClick={() => navigate(ROUTES.createTip(tipFormValues))}
						/>
						<Gap gap={16} />
					</When>

					{loadingFinished && !someMatchesFound ? (
						<div className={styles.newTipMatchesPage__emptyMessageBox}>
							<EmptyStateMessage text={t('No matches found')} />
						</div>
					) : (
						<div className={styles.newTipMatchesPage__tipMatchesList}>
							{tipMatchesQuery.data.map((tipMatch, index) => (
								<TipCard
									key={tipMatch.id + index}
									tip={tipMatch}
									onClick={() => {
										navigate(ROUTES.createTipReview({ tipId: tipMatch.id, ...tipFormValues }));
									}}
								/>
							))}
							{successAndThreeMatchesInEachResponseFound ? (
								<ActionListItem
									Icon={<PlusIcon fill={'black'} height={24} width={24} />}
									note={t('Don’t see your match?')}
									title={t('Create My Own Tip')}
									onClick={() => navigate(ROUTES.createTip(tipFormValues))}
								/>
							) : null}
							{aiTipsQuery.isFetching ? (
								<>
									<div className={styles.newTipMatchesPage__loadingCard}>
										<span className={styles.newTipMatchesPage__loadingCardLabel}>{t('AI finding matches...')}</span>
									</div>
									{Array.from({ length: 5 }).map((_, index) => (
										<TipCard.Skeleton key={`TipCard.Skeleton-${index}`} />
									))}
								</>
							) : (
								aiTipsQuery.data?.map?.((aiTip, index) => (
									<TipCard
										key={index}
										tip={aiTip}
										onClick={() => {
											navigate(
												ROUTES.createTip({
													...tipFormValues,
													...aiTip,
													galleryFiles: compact([
														aiTip.galleryFiles?.find((image) => image.isAiGenerated),
														tipFormValues.galleryFiles?.find((image) => !image.isAiGenerated),
													]),
												}),
											);
										}}
									/>
								))
							)}
							<When condition={loadingFinished}>
								<ActionListItem
									Icon={<PlusIcon fill={'black'} height={24} width={24} />}
									note={t('Don’t see your match?')}
									title={t('Create My Own Tip')}
									onClick={() => navigate(ROUTES.createTip(tipFormValues))}
								/>
								<p className={styles.newTipMatchesPage__description}>
									{t('All results have loaded. No match? Feel free to go back and alter your input.')}
								</p>
							</When>
						</div>
					)}
					<Gap gap={24} />
				</div>
			</SimplePageLayout>
		</Page>
	);
};

export interface INewTipMatchesPageParams extends Record<string, string | undefined> {
	id: string;
}

export default NewTipMatchesPage;
