import { ActivityIndicator, EmptyStateMessage, Virtuoso } from '@ui-kit';
import { SearchResultByDefinition } from '../SearchResult/SearchResultByDefinition';
import { useCallback, useTranslation } from '@hooks';
import { useSearchInIndexInfiniteQuery } from '../../hooks/useSearchInIndexInfiniteQuery';
import type { TSearchByIndexResponse, TSearchResult, TSearchableDefinition } from '@typings';
import type { ReactNode } from 'react';
import type { ItemContent } from 'react-virtuoso';

const GlobalSearchResultInfiniteList = ({
	searchQuery,
	definition,
	EmptyComponent,
	showInitialResults,
	className,
	onSelect,
}: IGlobalSearchResultInfiniteListProps) => {
	const { t } = useTranslation();

	const {
		data: searchResults,
		isFetching,
		fetchMore,
		finalSearchQuery,
		searchEnabled,
	} = useSearchInIndexInfiniteQuery(searchQuery, definition, {
		showInitialResults,
	});

	const renderSearchResult: ItemContent<TSearchByIndexResponse, unknown> = useCallback(
		(index, result) => {
			const searchResult = {
				...result,
				searchQuery: finalSearchQuery,
				definition: definition as TSearchResult['definition'],
			} as TSearchResult;
			return <SearchResultByDefinition result={searchResult} onSelect={onSelect} />;
		},
		[finalSearchQuery],
	);

	return (
		<Virtuoso<TSearchByIndexResponse>
			className={className}
			components={{
				Footer: () => (isFetching ? <ActivityIndicator size="medium" type="fit" /> : null),
				EmptyPlaceholder: () =>
					searchEnabled && !isFetching ? EmptyComponent || <EmptyStateMessage text={t('No matches found.')} /> : null,
			}}
			data={searchResults}
			defaultItemHeight={80}
			endReached={fetchMore}
			itemContent={renderSearchResult}
			totalCount={searchResults.length}
		/>
	);
};

export interface IGlobalSearchResultInfiniteListProps {
	searchQuery: string;
	definition: TSearchableDefinition;
	EmptyComponent?: ReactNode;
	showInitialResults?: boolean;
	className?: string;
	onSelect: (searchResult: TSearchResult) => void;
}
export default GlobalSearchResultInfiniteList;
