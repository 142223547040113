import { ActivityIndicator, Portal } from '@ui-kit';
import styles from './FeedCommentInput.module.css';
import type { ReactNode } from 'react';
import { useResizeObserver } from '@hooks';

const SuggestionsWrapper = ({ children, isLoading, title, anchorRef }: ISuggestionsWrapperProps) => {
	const [containerPosition] = useResizeObserver(anchorRef);

	return (
		<Portal>
			<div
				className={styles.suggestionsContainer}
				style={{
					bottom: containerPosition.height + 2,
				}}
			>
				<div className={styles.suggestionsContainer__titleWrapper}>
					<p>{title}</p>
				</div>
				<div>
					{isLoading ? (
						<div className={styles.suggestionsContainer__activityIndicator}>
							<ActivityIndicator />
						</div>
					) : (
						children
					)}
				</div>
			</div>
		</Portal>
	);
};

export interface ISuggestionsWrapperProps {
	children: ReactNode;
	isLoading: boolean;
	title: string;
	/** Container to which the suggestions will be attached */
	anchorRef: React.RefObject<HTMLElement>;
}

export default SuggestionsWrapper;
