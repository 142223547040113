import { useCheckTextOnMaliciousUrls, useMe, useMutation, useNotification, useService, useTranslation } from '@hooks';
import { useUpdateActivityInFeedCache } from './useUpdateActivityInFeedCache';
import { useUpdateSingleActivityInFeedCache } from './useUpdateSingleActivityInFeedCache';
import { pick } from '@utils';
import type { TActivity, TLatestActivity, TPostEditParams } from '@typings';

export const usePostEditing = (communitySlug?: string) => {
	const feedService = useService('FeedService');
	const { t } = useTranslation();
	const { showSuccess, showUnknownError, showError } = useNotification();
	const updateActivityInFeedCache = useUpdateActivityInFeedCache();
	const updateSingleActivityCache = useUpdateSingleActivityInFeedCache();
	const checkTextOnMaliciousUrls = useCheckTextOnMaliciousUrls({ throwError: true });
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const { user } = useMe();
	const onCommunityEditPostAction = useMutation<TLatestActivity, Error, TPostEditParams>(
		['streamActivityDiscussionPost.editPost'],
		async ({
			networkSlug,
			body,
			subject,
			owner,
			id,
			postType,
			files,
			images,
			videos,
			sharedContent,
			event,
		}: TPostEditParams) => {
			await checkTextOnMaliciousUrls(String(body));
			return await feedService.updatePost({
				networkSlug,
				body,
				subject,
				owner,
				id,
				postType,
				files: files?.length ? files : [],
				images: images?.length ? images : [],
				videos: videos?.length ? videos : [],
				sharedContent,
				event,
			});
		},
		{
			async onSuccess(data, activity) {
				showSuccess({ title: t('Post Edited') });
				if (data.streamActivityId) {
					await updateActivityInFeedCache(data.streamActivityId, {
						...(pick(activity, EDITABLE_ACTIVITY_FIELDS) as Partial<TActivity>),
						eventId: activity.event,
					});
					await new Promise((resolve) => setTimeout(resolve, 2500)); // temp fix to resolve T21C-4634: editing post are not reflected on the post screen with out page refresh [@dmitriy.nikolenko]
					await updateSingleActivityCache(data.streamActivityId, data);

					// Updating the feed
					communitySlug && queryClient.removeQueries(queryKeys.getUserFeed(communitySlug));
					queryClient.removeQueries(queryKeys.getUserFeed(user.slug));
					queryClient.removeQueries(queryKeys.getPostActivityReactions(data.streamActivityId));
				}
			},
			onError: (error: Error) => {
				const parsedErrorMessage = JSON.parse(JSON.stringify(error)); // Handle error that returned from streamClient to show error details.
				parsedErrorMessage?.response?.data?.detail
					? showError({ title: t('Error uploading attachment'), subtitle: parsedErrorMessage?.response?.data?.detail })
					: showUnknownError(error);
			},
		},
	);

	return {
		onCommunityEditPostAction: onCommunityEditPostAction.mutateAsync,
	};
};

const EDITABLE_ACTIVITY_FIELDS: (keyof TActivity)[] = ['subject', 'body', 'attachments', 'sharedContent'];
