import { useEvent, useService } from '@hooks';
import { useGlobalSearchHistory } from '@ui-modules/globalSearch/hooks/useGlobalSearchHistory';
import { useGlobalSearchNavigation } from '@ui-modules/globalSearch/hooks/useGlobalSearchNavigation';
import { useGlobalSearchState } from '@ui-modules/globalSearch/hooks/useGlobalSearchState';
import type { TSearchResult } from '@typings';

export const useHandleClickGlobalSearchResult = (searchQuery: string) => {
	const { resetSearchQuery, setIsSearchActive } = useGlobalSearchState();
	const navigateToEntity = useGlobalSearchNavigation();
	const { addRecentSearch } = useGlobalSearchHistory(searchQuery);
	const analytics = useService('AnalyticsService');

	return useEvent((searchResult: TSearchResult) => {
		const {
			definition,
			objectID,
			relatedEntityId,
			relatedEntityDefinition,
			communitySlug,
			streamActivityId,
			streamFeedId,
			folderID,
			searchQuery,
			fileOrFolder,
		} = searchResult;
		resetSearchQuery();
		setIsSearchActive(false);
		setTimeout(() => {
			navigateToEntity({
				definition: relatedEntityDefinition ?? definition,
				objectID: relatedEntityId ?? objectID,
				communitySlug,
				streamActivityId,
				streamFeedId,
				fileOrFolder,
				folderID: fileOrFolder === 'folder' ? objectID : folderID,
			});
		}, 100);
		addRecentSearch(searchQuery);
		analytics.trackEvent('SearchInteractions', { search_location: definition, search_query: searchQuery });
	});
};
