import { ActivityIndicator } from '@ui-kit';
import { SendButtonIcon } from '@ui-kit/icons';
import styles from './RichMessageInputSendButton.module.css';

const RichMessageInputSendButton = ({ onSend, disabled, loading }: IRichMessageInputSendButton) => {
	return (
		<button
			aria-busy={loading}
			aria-disabled={disabled || loading}
			className={styles.richMessageInputSendButton}
			data-testid="RichMessageInputSendButton"
			disabled={disabled || loading}
			onClick={onSend}
		>
			{loading ? (
				<ActivityIndicator color={'var(--color-lightGold'} size="extraSmall" />
			) : (
				<SendButtonIcon
					className={styles.richMessageInputSendButton__icon}
					fill={disabled ? 'var(--color-darkGrey)' : 'var(--color-lightGold'}
				/>
			)}
		</button>
	);
};

interface IRichMessageInputSendButton {
	onSend: () => void;
	disabled: boolean;
	loading?: boolean;
}
export default RichMessageInputSendButton;
