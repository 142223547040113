import { IMAGE, FILE, VIDEO } from '@constants';
import { getVideoCover } from '@utils';
import { STREAM_CHAT_BASE_URL } from '@constants';
import type { TFunction } from 'i18next';
import type { Channel } from 'stream-chat';
import type { IToastProps } from '@services';
import type { TAttachments, TEvent } from '@typings';
import type { Attachment, DefaultGenerics, MessageResponse } from 'stream-chat';

const checkImages = (attachments: TAttachments[]) => {
	return attachments.filter((item) => item.fileType === 'image' || item.fileType === 'video').length > 0;
};

const checkFiles = (attachments: TAttachments[]) => {
	return attachments.filter((item) => item.fileType === 'file').length > 0;
};

const isDraftMessageAttachment = (item: Attachment) => 'fileType' in item;

export const getMessageAttachment = (attachments: Attachment[]) => {
	const streamAttachments = attachments.map((attachment, index) => {
		if (isDraftMessageAttachment(attachment)) {
			// in case if it is file from the our custom drafts store return as it is
			return attachment;
		} else if (attachment.type === 'image') {
			return {
				url: attachment.image_url,
				name: `${attachment.fallback}_${index}`,
				fileType: attachment.type,
				height: attachment.original_height,
				width: attachment.original_width,
			};
		} else if (attachment.type === 'giphy') {
			return attachment;
		} else if (attachment.type === 'video') {
			return {
				url: attachment.asset_url,
				fileType: attachment.type,
				name: `${attachment.title}_${index}`,
				mimeType: attachment.mime_type,
				height: attachment.original_height,
				width: attachment.original_width,
				thumb_url: attachment.thumb_url,
			};
		} else {
			return {
				url: attachment.asset_url,
				fileType: attachment.type,
				name: `${attachment.title}_${index}`,
				mimeType: attachment.mime_type,
				isFile: true,
			};
		}
	});
	return streamAttachments;
};

export const parseAttachmentsFromEditingMessage = (editMessageAttachments: Attachment[] | undefined) => {
	if (!editMessageAttachments) {
		return [];
	}

	const filterPreviewUrlImages = editMessageAttachments?.filter(
		(item) =>
			item.image_url?.includes(STREAM_CHAT_BASE_URL) ||
			item.asset_url?.includes(STREAM_CHAT_BASE_URL) ||
			isDraftMessageAttachment(item), // skip for draft message
	);

	return getMessageAttachment(filterPreviewUrlImages) as TAttachments[];
};

const isAttachmentLoading = (attachments: TAttachments[]) => {
	return attachments.some((item: TAttachments) => item.url?.startsWith('blob'));
};

const shouldEnableIcon = (
	name: string,
	editMessage: MessageResponse | null,
	attachments: TAttachments[],
	selectedEvent: TEvent | null,
	showGifInput: boolean | false,
) => {
	if (
		!selectedEvent?.id &&
		!editMessage?.attachments?.length &&
		!editMessage?.eventId &&
		!showGifInput &&
		!attachments?.length &&
		editMessage?.text
	) {
		return true;
	}

	if (attachments.length === 0 && selectedEvent === null && !showGifInput) {
		return true;
	} else if (name === 'image' && checkImages(attachments)) {
		return true;
	} else if (name === 'file' && checkFiles(attachments)) {
		return true;
	} else if (name === 'event' && selectedEvent) {
		return true;
	} else if (name === 'giphy' && showGifInput) {
		return true;
	} else {
		return false;
	}
};

export const uploadAttachmentsInStream = async (
	attachments: TAttachments[],
	channel: Channel,
	showError: (args: IToastProps) => void,
) => {
	const newAttachment: TAttachments[] = [];
	for (let i = 0; i < attachments.length; i++) {
		const uploadedAttachment = await uploadAttachmentInStream(attachments[i], channel, showError);
		if (uploadedAttachment) {
			newAttachment.push(uploadedAttachment);
		}
	}
	return newAttachment;
};

export const uploadAttachmentInStream = async (
	attachment: TAttachments,
	channel: Channel,
	showError: (args: IToastProps) => void,
) => {
	try {
		const attachmentBlob = await fetch(`${attachment.url}`).then((res) => res.blob());
		const attachmentFile = new File([attachmentBlob], attachment.name);

		if (attachment.url?.startsWith('blob')) {
			if (attachment.fileType === IMAGE) {
				attachment.url = (await channel.sendImage(attachmentFile)).file || '';
			} else if (attachment.fileType === FILE) {
				attachment.url = (await channel.sendFile(attachmentFile)).file || '';
			} else if (attachment.fileType === VIDEO) {
				attachment.url = (await channel.sendFile(attachmentFile)).file || '';
				const thumbnailUrl = await getVideoCover(attachment.url);
				const thumbnailBlob = await fetch(thumbnailUrl).then((r) => r.blob());
				const thumbnailFile = new File([thumbnailBlob], `${attachment.name}-thumbnail`);
				const thumbnail = await channel.sendImage(thumbnailFile);
				attachment.thumb_url = thumbnail.file;
			}
		}
		return attachment;
	} catch (error) {
		console.error(error);
		if (error instanceof Error) {
			showError({ title: `Error uploading attachment ${attachment.name}:`, subtitle: error.message });
		}
	}
};

// Determines if a specific media attachment type should be enabled.
export const shouldEnableKindOfMediaAttachment = (
	disabled: boolean,
	type: 'image' | 'file' | 'event' | 'giphy',
	editMessage: MessageResponse<DefaultGenerics> | null,
	attachments: TAttachments[],
	selectedEvent: TEvent | null,
	showGifInput: boolean,
) => {
	return !disabled && shouldEnableIcon(type, editMessage, attachments, selectedEvent, showGifInput);
};

export const shouldDisableSendButton = (
	attachments: TAttachments[],
	text: string,
	eventId: TEvent['id'] | null,
	sharedContent: string | null | undefined,
) => {
	if (eventId) return false;
	else
		return (
			(attachments.length === 0 && text.trim().length === 0 && !eventId && !sharedContent) ||
			isAttachmentLoading(attachments)
		);
};

export const getChatInputPlaceholder = (t: TFunction) => (props: { showGifInput: boolean; disabled: boolean }) => {
	if (props.showGifInput) return t('Search GIFs...');
	else if (props.disabled) return t('(blocked user)');
	else return t('Send a message');
};
