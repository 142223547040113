import { useRichMessageAppearanceContext } from '../../contexts/RichMessageAppearanceContext';
import styles from './RichMessageInputCollapseHandle.module.css';

const RichMessageInputCollapseHandle = () => {
	const [appearance, setAppearance] = useRichMessageAppearanceContext();

	return (
		<div className={styles.richMessageInput__collapseHandleBox} data-appearance={appearance}>
			<button
				className={styles.richMessageInput__collapseHandle}
				data-testid="RichMessageInput__collapseHandle"
				onClick={() => setAppearance('row', 0)}
			/>
		</div>
	);
};

export default RichMessageInputCollapseHandle;
