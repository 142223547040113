import { useCallback, useNavigate, useState, useTranslation } from '@hooks';
import { debounce, InternalShareEntity, throttle } from '@utils';
import styles from './ChatShareModalContent.module.css';
import type { TContent } from '@ui-modules/share/components/InternalShareModal/InternalShareModal';
import { useGetPaginatedChatsQuery } from '@ui-modules/chat/hooks/useGetPaginatedChatsQuery';
import { useInternalShareContext } from '@ui-modules/share/hooks/useInternalShare';
import ChannelPreviewTitle from '@ui-modules/chat/components/ChannelPreviewTitle';
import { ArrowRightIcon, NewChatIcon } from '@ui-kit/icons';
import ChannelPreviewAvatar from '@ui-modules/chat/components/ChannelPreviewAvatar';
import ShareOption from '../ShareOption';
import { ActivityIndicator } from '@ui-kit';
import { Virtuoso } from 'react-virtuoso';
import CollapsibleTextButton from '@ui-kit/components/CollapsibleText/CollapsibleTextButton';
import { ROUTES } from '@constants';

const ChatShareModalContent = ({ handleContentChange }: IChatShareModalContentProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const internalShareEntity = new InternalShareEntity();

	const [isShownMoreButtonShow, setIsShownMoreButtonShow] = useState<boolean>(true);

	const {
		fetchMore,
		isLoading,
		hasNextPage,
		data: chats,
		isFetchingNextPage,
	} = useGetPaginatedChatsQuery(isShownMoreButtonShow ? 3 : 10);

	const { resetInternalShare, internalShare, closeInternalShareModal } = useInternalShareContext();

	const onMessageClick = useCallback((channelId?: string) => {
		if (!channelId) return;

		navigate(ROUTES.chatChannel(channelId, internalShareEntity.internalShareToShareableContent(internalShare)), {
			unstable_viewTransition: true,
		});
		handleContentChange(null);
		resetInternalShare();
	}, []);

	const onNewMessageClick = useCallback(() => {
		if (!window.history.state?.usr?.includes(ROUTES.chat())) {
			navigate(ROUTES.chat(), { unstable_viewTransition: true });
		}

		const debouncedClose = debounce(() => {
			navigate(ROUTES.createChat(internalShareEntity.internalShareToShareableContent(internalShare)), {
				unstable_viewTransition: true,
			});
			closeInternalShareModal();
			handleContentChange(null);
		}, 1200);

		debouncedClose();
	}, [internalShare]);

	const renderChatItem = useCallback(
		(index: number) => {
			const channel = chats?.[index];
			return (
				<button className={styles.chatShare__chatConversationItemWrapper} onClick={() => onMessageClick(channel?.id)}>
					<div className={styles.chatShare__chatConversationItemAvatarWrapper}>
						<ChannelPreviewAvatar channel={channel} />
						<ChannelPreviewTitle channel={channel} />
					</div>
					<ArrowRightIcon fill={'#000'} height={16} width={14} />
				</button>
			);
		},
		[chats, onMessageClick],
	);

	const loadMoreChats = throttle(() => {
		if (hasNextPage && !isFetchingNextPage) {
			setIsShownMoreButtonShow(false);
			fetchMore();
		}
	}, 500);

	return (
		<div>
			<ShareOption
				icon={<NewChatIcon fill={'#333'} height={24} width={24} />}
				title={t('New Chat')}
				onClick={onNewMessageClick}
			/>
			<p className={styles.chatShare__title}>{t('Chat Conversations')}</p>
			{isLoading ? (
				<div className={styles.chatShare__activityIndicator}>
					<ActivityIndicator />
				</div>
			) : (
				<Virtuoso
					components={{
						Footer: () =>
							isFetchingNextPage ? (
								<div className={styles.chatShare__activityIndicator}>
									<ActivityIndicator />
								</div>
							) : hasNextPage && isShownMoreButtonShow ? (
								<div className={styles.chatShare__showMore}>
									<CollapsibleTextButton title={t('Show More')} onToggle={loadMoreChats} />
								</div>
							) : null,
					}}
					data={chats}
					endReached={() => {
						if (hasNextPage && !isFetchingNextPage && !isShownMoreButtonShow) {
							loadMoreChats();
						}
					}}
					itemContent={(index) => renderChatItem(index)}
					style={{ height: '198px' }}
				/>
			)}
		</div>
	);
};

export interface IChatShareModalContentProps {
	handleContentChange: (content: TContent) => void;
}

export default ChatShareModalContent;
