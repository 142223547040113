import { noop } from 'lodash';
import { createContext, useContext, useMemo, type ReactNode } from 'react';
import type { TRichMessageInputActionAppearance } from '../components/RichMessageInputAction';

type TRichMessageInputActionAppearanceContext = [
	TRichMessageInputActionAppearance,
	(appearance: TRichMessageInputActionAppearance, delay?: number) => void,
];

const EMPTY_APPEARANCE: TRichMessageInputActionAppearanceContext = ['row', noop];

const RichMessageAppearanceContext = createContext<TRichMessageInputActionAppearanceContext>(EMPTY_APPEARANCE);

export const useRichMessageAppearanceContext = () => {
	return useContext(RichMessageAppearanceContext) ?? EMPTY_APPEARANCE;
};

export const RichMessageAppearanceContextProvider = ({
	children,
	appearance,
	setAppearance,
}: {
	children: ReactNode;
	appearance: TRichMessageInputActionAppearance;
	setAppearance: (appearance: TRichMessageInputActionAppearance) => void;
}) => {
	const setAppearanceWithDelay = (appearance: TRichMessageInputActionAppearance, delay = 0) => {
		setTimeout(() => {
			setAppearance(appearance);
		}, delay);
	};

	const contextValue = useMemo<TRichMessageInputActionAppearanceContext>(
		() => [appearance, setAppearanceWithDelay],
		[appearance],
	);

	return <RichMessageAppearanceContext.Provider value={contextValue}>{children}</RichMessageAppearanceContext.Provider>;
};
