import { startTransition } from 'react';
import { Button, EditIcon, Modal, NotificationIcon, NotificationOffIcon, SearchableScreenBar, When } from '@ui-kit';
import Feed from '@ui-modules/feed/components/Feed';
import FeedSearchResultList from '@ui-modules/feed/components/FeedSearchResultList';
import { useMe, useNavigate, useState, useToggle, useTranslation } from '@hooks';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { CommunityEntity } from '@utils';
import { ROUTES } from '@constants';
import styles from './ChapterDiscussions.module.css';
import type { TCommunity } from '@typings';
import { useCollapsedNotificationsSettings } from '@ui-modules/settings/hooks/useNotificationSettingsVisibility';
import { useCommunityNotifications } from '@ui-modules/networks/hooks/useCommunityNotifications';

const ChapterDiscussions = ({
	communityId,
	initialSearchQuery = '',
	onChangeSearchQuery,
}: IChapterDiscussionsProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();
	const [modalVisible, setModalVisible] = useToggle(false);
	const { data: group } = useCommunityQuery(communityId as string);
	const { addCollapsedNotificationsSettings, removeCollapsedNotificationsSettings } =
		useCollapsedNotificationsSettings();

	const {
		alertsOn,
		mutateNotifications,
		isLoadingNotificationsSettings,
		isLoading: alertsMutationLoading,
	} = useCommunityNotifications({ communityId: communityId as string, groupName: 'Chapters' });

	const onAlertsToggle = async () => {
		const chaptersIdentifier = `Chapter_${communityId}`;

		if (alertsOn) {
			setModalVisible(true);
		} else {
			mutateNotifications({ alertsOn: true, notificationId: 'post_created', constraint: 'community' });
			mutateNotifications({ alertsOn: true, notificationId: 'new_comment', constraint: 'community' });
			mutateNotifications({
				alertsOn: true,
				notificationId: 'new_discussion_post_like',
				constraint: 'community',
			});
			mutateNotifications({
				alertsOn: true,
				notificationId: 'new_discussion_post_comment_like',
				constraint: 'community',
			});
			isCommunityModerator(group) &&
				mutateNotifications({
					alertsOn: true,
					notificationId: 'new_comment_for_moderator',
					constraint: 'community',
				});
			addCollapsedNotificationsSettings(chaptersIdentifier);
		}
	};

	const { data: community } = useCommunityQuery(communityId);
	const [searchQuery, setSearchQuery] = useState(initialSearchQuery, 'ChapterDiscussions:searchQuery');
	const [isSearchFocused, setIsSearchActive] = useToggle(false);
	const showSearchResults = isSearchFocused || !!searchQuery.length;

	const openNewPostPage = () =>
		navigate(ROUTES.addPost(), { state: { networkId: community?.['@id'], definition: community?.definition } });

	if (!community) return null;
	return (
		<>
			<div className={styles.chapterDiscussions__headerContainer}>
				<div className={styles.chapterDiscussions__header}>
					<SearchableScreenBar
						placeholder={t('Search posts')}
						searchQuery={searchQuery}
						searchTitle={t('Search')}
						onBlur={() => setIsSearchActive(false)}
						onChangeSearchQuery={(searchQuery) => {
							setSearchQuery(searchQuery);
							onChangeSearchQuery(searchQuery);
						}}
						onFocus={() => setIsSearchActive(true)}
					>
						<When condition={CommunityEntity.shouldShowDiscussionsV2(community, user.roles)}>
							<Button
								icon={<EditIcon fill="#000000" height={14} width={14} />}
								iconPosition="left"
								title={t('New Post')}
								variant="small"
								onClick={openNewPostPage}
							/>
						</When>
						<Button
							icon={
								!alertsOn ? (
									<NotificationIcon className={styles.chaptersDiscussions__notificationIcon} height={14} width={22} />
								) : (
									<NotificationOffIcon
										className={styles.chaptersDiscussions__notificationIcon}
										height={20}
										width={20}
									/>
								)
							}
							loading={isLoadingNotificationsSettings || alertsMutationLoading}
							title={alertsOn ? t('Mute') : t('Unmute')}
							variant="small"
							onClick={onAlertsToggle}
						/>
					</SearchableScreenBar>
				</div>
			</div>

			<When condition={!!showSearchResults}>
				<FeedSearchResultList
					communityName={community.name}
					searchQuery={searchQuery}
					onSearch={(searchQuery) => {
						startTransition(() => {
							onChangeSearchQuery(searchQuery);
						});
					}}
				/>
			</When>
			<Feed hidden={showSearchResults} isActivitySourceHidden userId={community.slug} />

			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('Confirm')}
				modalContent={
					<div>
						<p className={styles.chaptersDiscussions__modalContent}>
							{t('This will turn off all Notifications, both email and messages to your device.')} {t('Please go to')}
							<span
								role="presentation"
								onClick={() => {
									navigate(ROUTES.notificationSettingsGroup('Chapters'));
									setModalVisible(false);
								}}
							>
								{t('Settings -> Notifications -> Chapters')}
							</span>
							{t('if you only want to turn off one of these options.')}
						</p>
					</div>
				}
				variant="medium"
				visible={modalVisible}
				onCancel={() => {
					setModalVisible(false);
				}}
				onConfirm={async () => {
					setModalVisible(false);
					await mutateNotifications({
						alertsOn: false,
						notificationId: 'post_created',
						constraint: 'community',
					});
					await mutateNotifications({
						alertsOn: false,
						notificationId: 'new_comment',
						constraint: 'community',
					});
					await mutateNotifications({
						alertsOn: false,
						notificationId: 'new_discussion_post_like',
						constraint: 'community',
					});
					await mutateNotifications({
						alertsOn: false,
						notificationId: 'post_created',
						constraint: 'community',
					});
					await mutateNotifications({
						alertsOn: false,
						notificationId: 'new_discussion_post_comment_like',
						constraint: 'community',
					});

					isCommunityModerator(group) &&
						(await mutateNotifications({
							alertsOn: false,
							notificationId: 'new_comment_for_moderator',
							constraint: 'community',
						}));

					const chaptersIdentifier = `Chapters_${communityId}`;
					removeCollapsedNotificationsSettings(chaptersIdentifier);
				}}
			/>
		</>
	);
};

function isCommunityModerator(community?: TCommunity) {
	return community ? CommunityEntity.userIsModerator(community) : undefined;
}

export interface IChapterDiscussionsProps {
	communityId: TCommunity['id'];
	initialSearchQuery: string;
	onChangeSearchQuery: (searchQuery: string) => void;
}

export default ChapterDiscussions;
