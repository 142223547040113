import TabWrapper from '../../components/TabWrapper';
import { Card, FixedWidthContainer, Gap, Tabs } from '@ui-kit';
import NetworkDiscussions from '@ui-modules/networks/components/NetworkDiscussions';
import CommunityMemberSectionList from '@ui-modules/communities/components/CommunityMemberSectionList';
import CommunityCalendarItemsSectionList from '@ui-modules/communities/components/CommunityCalendarItemsSectionList';
import CommunityResource from '@ui-modules/communities/components/CommunityResource';
import NetworkConnections from '@ui-modules/networks/components/NetworkConnections';
import NetworkHero from '@ui-modules/networks/components/NetworkHero';
import CommunityFilesWidget from '@ui-modules/files/components/CommunityFilesWidget';
import { useTranslation, useParams, useMe, useNavigate, useEvent, useRouteSearchQueryState, useMemo } from '@hooks';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useNetworkTabsHandler } from '@ui-modules/networks/hooks/useNetworkTabsHandler';
import { getNetworkTabsPermissions } from '@ui-modules/networks/utils/getNetworkTabsPermissions';
import { CommunityEntity, textRegisterPluralize } from '@utils';
import { ROUTES } from '@constants';
import styles from './NetworkTabsPage.module.css';
import type { TEvent, TNetworkTabName, TUserProfileRecord } from '@typings';
import { useTransformNetworkParams } from '@ui-modules/networks/hooks/useNetworksTabsState';
import NetworkDeals from '@ui-modules/networks/components/NetworkDeals';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';

const NetworkTabsPage = () => {
	// Dependencies.
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();

	// Input network data.
	const { networkId } = useTransformNetworkParams(useParams<INetworkTabsPageParams>());
	const { data: network } = useCommunityQuery(networkId);

	// Route data.
	const [routeSearchQuery, setRouteSearchQuery] = useRouteSearchQueryState();

	// Tabs logic.
	const { enabled: isInvestTabShowed } = useDealsEnabled();
	const networkTab = useParams<INetworkTabsPageParams>().networkTab as INetworkTabsPageParams['networkTab'];
	const { tabs } = useMemo(
		() => getNetworkTabsPermissions(network, user.roles, isInvestTabShowed),
		[network, user.roles, isInvestTabShowed],
	);
	const { currentTabIndex, onChangeTab } = useNetworkTabsHandler(
		network?.id,
		tabs,
		String(networkTab),
		String(network?.name),
	);

	// User actions.
	const openMemberProfile = useEvent(({ profile }: TUserProfileRecord) => navigate(ROUTES.memberProfile(profile.id)));
	const openCalendarItem = useEvent((calendarItem: TEvent) => navigate(ROUTES.viewEvent(calendarItem.id)));

	return currentTabIndex === NOT_FOUND || !network ? null : (
		<>
			<Tabs selectedIndex={currentTabIndex} onSelect={onChangeTab}>
				<NetworkHero activeNetworkTab={networkTab} networkId={network.id} />
				<Tabs.List backgroundColor="transparent" className={styles.tabsContainer}>
					{tabs.map((tab) => {
						switch (tab) {
							case 'about':
								return (
									<Tabs.Tab
										key="about"
										nonSelectedClassName={styles.tab__not_selected}
										selectedClassName={styles.tab__selected}
									>
										{t('About')}
									</Tabs.Tab>
								);
							case 'discussions':
								return (
									<Tabs.Tab
										key="discussions"
										nonSelectedClassName={styles.tab__not_selected}
										selectedClassName={styles.tab__selected}
									>
										{t('Discussions')}
									</Tabs.Tab>
								);
							case 'connections':
								return (
									<Tabs.Tab
										key="connections"
										nonSelectedClassName={styles.tab__not_selected}
										selectedClassName={styles.tab__selected}
									>
										{textRegisterPluralize(network?.networkConnectionsName)}
									</Tabs.Tab>
								);
							case 'events':
								return (
									<Tabs.Tab
										key="events"
										nonSelectedClassName={styles.tab__not_selected}
										selectedClassName={styles.tab__selected}
									>
										{t('Events')}
									</Tabs.Tab>
								);
							case 'files':
								return (
									<Tabs.Tab
										key="files"
										nonSelectedClassName={styles.tab__not_selected}
										selectedClassName={styles.tab__selected}
									>
										{t('Files')}
									</Tabs.Tab>
								);
							case 'members':
								return (
									<Tabs.Tab
										key="members"
										nonSelectedClassName={styles.tab__not_selected}
										selectedClassName={styles.tab__selected}
									>
										{t('Members')}
									</Tabs.Tab>
								);
							case 'resources':
								return (
									<Tabs.Tab
										key="resources"
										nonSelectedClassName={styles.tab__not_selected}
										selectedClassName={styles.tab__selected}
									>
										{t('Resources')}
									</Tabs.Tab>
								);
							case 'deals':
								return (
									<Tabs.Tab key="deals" nonSelectedClassName={styles.tab__not_selected} selectedClassName={styles.tab}>
										{t('Invest')}
									</Tabs.Tab>
								);
						}
					})}
				</Tabs.List>
				{tabs.map((tab) => {
					switch (tab) {
						case 'about':
							return (
								<Tabs.Panel key="about">
									<TabWrapper>
										<FixedWidthContainer>
											<div className={styles.networkTabsPage__aboutWrapper}>
												<Card title={t('Member Network')}>
													{CommunityEntity.shouldShowDescription(network) ? <span>{network.description}</span> : null}
												</Card>
											</div>
										</FixedWidthContainer>
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'discussions':
							return (
								<Tabs.Panel key="discussions">
									<TabWrapper>
										<NetworkDiscussions
											initialSearchQuery={routeSearchQuery}
											networkId={network.id}
											onChangeSearchQuery={setRouteSearchQuery}
										/>
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'connections':
							return (
								<Tabs.Panel key="connections">
									<TabWrapper>
										<NetworkConnections
											communityId={network.id}
											initialSearchQuery={routeSearchQuery}
											onChangeSearchQuery={setRouteSearchQuery}
										/>
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'events':
							return (
								<Tabs.Panel key="events">
									<TabWrapper>
										<CommunityCalendarItemsSectionList
											communityId={network.id}
											emptyMessage={t('No upcoming events')}
											nextItemsTitle={t('Next Network Events')}
											onClick={openCalendarItem}
										/>
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'files':
							return (
								<Tabs.Panel key="files">
									<TabWrapper>
										<Gap gap={12} />
										<CommunityFilesWidget communityDefinition="network" communityId={network?.id} folderId={null} />
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'members':
							return (
								<Tabs.Panel key="members">
									<TabWrapper>
										<CommunityMemberSectionList
											communityId={network?.id}
											moderatorMembersTitle={t('Network Leadership')}
											regularMembersTitle={t('Members')}
											onClick={openMemberProfile}
										/>
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'resources':
							return (
								<Tabs.Panel key="resources">
									<TabWrapper>
										<FixedWidthContainer>
											<Gap gap={24} />
											<CommunityResource resourceUrl={String(network?.resourceUrl)} />
										</FixedWidthContainer>
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'deals':
							return (
								<Tabs.Panel key="deals">
									<TabWrapper className={styles.dealTabWrapper}>
										<NetworkDeals networkId={network?.id} />
									</TabWrapper>
								</Tabs.Panel>
							);
					}
				})}
			</Tabs>
		</>
	);
};

const NOT_FOUND = -1; // Array.findIndex not found value.

export interface INetworkTabsPageParams extends Record<string, string | undefined> {
	networkId: string;
	networkTab: TNetworkTabName;
}

export default NetworkTabsPage;
