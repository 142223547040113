import LightBox from 'react-image-lightbox';
import { useImageView } from './hooks/useImageView';
import { forwardRef, useImperativeHandle, type ForwardedRef } from 'react';

const ImagesLightBox = ({ imagesUrls }: IImagesLightBoxProps, ref: ForwardedRef<IImagesLightBoxMethods>) => {
	const { currentImage, nextImage, prevImage, handleClick, handleClose, handleMovePrev, handleMoveNext } =
		useImageView(imagesUrls);

	useImperativeHandle(
		ref,
		() => ({
			show: (initialImageIndex = 0) => handleClick(initialImageIndex),
			hide: handleClose,
		}),
		[],
	);

	if (typeof currentImage !== 'number') return null;
	return (
		<LightBox
			enableZoom={false}
			mainSrc={currentImage}
			mainSrcThumbnail={currentImage}
			nextSrc={nextImage}
			nextSrcThumbnail={nextImage}
			prevSrc={prevImage}
			prevSrcThumbnail={prevImage}
			onCloseRequest={handleClose}
			onMoveNextRequest={handleMoveNext}
			onMovePrevRequest={handleMovePrev}
		/>
	);
};

export interface IImagesLightBoxProps {
	imagesUrls: string[];
}

export interface IImagesLightBoxMethods {
	show: (initialImageIndex?: number) => void;
	hide: () => void;
}

export default forwardRef(ImagesLightBox);
