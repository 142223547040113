import { Post } from '@ui-kit';
import { type IFeedActivityProps } from './FeedActivity';
import { ActivityEntity } from '@utils';
import { type TActivity } from '@typings';
import { useTranslation } from '@hooks';
import { useToggleActivityBookmarkMutation } from '@ui-modules/feed/hooks/useToggleActivityBookmarkMutation';
import { useToggleActivityLikeMutation } from '@ui-modules/feed/hooks/useToggleActivityLikeMutation';
import { memo } from 'react';

const FeedActivityFooter = ({
	activity,
	onInteracted,
	onClickComment,
	onClickReply,
	showBookmark,
}: IFeedActivityFooterProps) => {
	const { t } = useTranslation();
	const activityEntity = new ActivityEntity(activity);

	// Action handlers.
	const { mutate: toggleLike } = useToggleActivityLikeMutation();
	const { mutate: toggleBookmark } = useToggleActivityBookmarkMutation();

	const handleClickComment = () => {
		onClickComment?.(activity.id);
		onInteracted?.('comments');
	};

	const handleClickReply = () => {
		onClickReply?.(activity.id);
		onInteracted?.('reply');
	};
	const handleLike = () => {
		toggleLike(activity);
		onInteracted?.('like');
	};

	return (
		<Post.PostActionBar
			bookmarked={activityEntity.bookmarked}
			comments={activityEntity.comments}
			hasMyLike={activityEntity.hasMyLike}
			likes={activityEntity.likes}
			likeTitle={t('Like')}
			replyTitle={t('Reply')}
			showBookmark={showBookmark || activityEntity.shouldShowBookmark()}
			onBookmark={() => {
				toggleBookmark(activity);
				onInteracted?.('bookmark');
			}}
			onClickComment={handleClickComment}
			onClickReply={handleClickReply}
			onLike={handleLike}
		/>
	);
};

export interface IFeedActivityFooterProps extends Partial<IFeedActivityProps> {
	activity: TActivity;
}

export default memo(FeedActivityFooter);
