import { Gap, Input, Modal } from '@ui-kit';
import { useTranslation, useFormik } from '@hooks';
import { editFolderSchema } from '@schemas';
import type { IModalProps } from '@ui-kit';
import type { TFolder } from '@typings';
import type { TEditFolderForm } from '@schemas';
import type { IFilesWidgetApiCallbacks } from '../FilesWidgetApiCallbacks.interface';

/** Asks for the name of a new folder in a modal window and creates one. */
const AddFolderModal = ({ visible, onClose, onCreateFolder }: IAddFolderModalProps) => {
	const { t } = useTranslation();

	const releaseDialog = () => {
		resetForm();
		onClose();
	};

	const {
		values,
		errors,
		handleChange,
		handleBlur,
		submitForm,
		handleSubmit,
		dirty,
		resetForm,
		isSubmitting,
		isValid,
	} = useFormik<TEditFolderForm>({
		validateOnBlur: true,
		validateOnChange: false,
		validationSchema: editFolderSchema,
		initialValues: { name: '' },
		onSubmit: async (formValues) => {
			await onCreateFolder(formValues);
			releaseDialog();
		},
	});

	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Save Changes')}
			confirmTrackingName="files--create-folder"
			isConfirmDisabled={!isValid || !dirty}
			isConfirmLoading={isSubmitting}
			modalContent={
				<form onSubmit={handleSubmit}>
					<Gap gap={8} />
					<Input
						autoFocus
						errorMessage={errors.name}
						label={t('Folder name')}
						required
						value={values.name}
						onBlur={handleBlur('name')}
						onChange={handleChange('name')}
					/>
				</form>
			}
			title={t('Create Folder')}
			titleCentered
			variant="medium"
			visible={visible}
			onCancel={releaseDialog}
			onConfirm={submitForm}
		/>
	);
};

export interface IAddFolderModalProps
	extends Pick<IModalProps, 'visible'>,
		Pick<IFilesWidgetApiCallbacks, 'onCreateFolder'> {
	/** Target Folder ID. */
	folderId: TFolder['id'] | null;
	/** Calls when the modal should be closed to allow to toggle 'visible' property value. */
	onClose: () => void;
}

export default AddFolderModal;
