import { COPIED_TEXT, STREAM_CHAT_BASE_URL } from '@constants';
import { useEffect, useService, useDeferredValue } from '@hooks';
import { toString } from '@utils';
import type { TAttachments, TFileType } from '@typings';
import type { Attachment } from 'stream-chat';

/** It observes the current chat message text
 * 	to determine the moment when the text is pasted from the copied chat message (because of specific format).
 * 	When the copied message is pasted, it
 * 	 - extracts the message ID from the text
 *   - fetches the message by ID
 *   - calls callbacks to set the message context into the input field.
 */
export const usePasteCopiedChatMessageEffect = (
	currentText: string,
	setText: (text: string) => void,
	setAttachments: (attachments: TAttachments[]) => void,
	setEvent: (calendarItemId: string | null) => void,
	setSharedContent?: (sharedContent: string | null) => void,
) => {
	const chat = useService('ChatService');

	const pasteMessage = async (messageId: string) => {
		if (!messageId) return;
		const { message } = await chat.chatClient.getMessage(messageId);
		if (!message) return;

		const { text, attachments = [] } = message;
		const preparedAttachments = excludeImagePreviewAttachments(attachments).map((attachment) =>
			mapGetStreamAttachmentResponseToAttachment(attachment),
		);
		const eventId = typeof message.eventId === 'string' ? message.eventId : null;
		const sharedContent = typeof message.sharedContent === 'string' ? message.sharedContent : null;

		setText(toString(text));
		setAttachments(preparedAttachments);
		setEvent(eventId);
		setSharedContent?.(sharedContent);
	};

	const deferredText = useDeferredValue(currentText);
	useEffect(
		function determinePastingCopiedMessage() {
			if (deferredText.includes(COPIED_TEXT)) {
				const messageId = deferredText.split(COPIED_TEXT)[1];
				pasteMessage(messageId);
			}
		},
		[deferredText],
	);
};

function excludeImagePreviewAttachments(attachments: Attachment[]) {
	return attachments.filter(
		(item) => item.asset_url?.includes(STREAM_CHAT_BASE_URL) || item.image_url?.includes(STREAM_CHAT_BASE_URL),
	);
}

function mapGetStreamAttachmentResponseToAttachment(attachment: Attachment): TAttachments {
	const fileType = attachment.type as TFileType;
	const isFile = fileType === 'file';
	const fileName = attachment.title ?? attachment.fallback ?? crypto.randomUUID();
	const url = attachment.image_url ?? attachment.asset_url;
	const mimeType = attachment.mime_type;
	const thumb_url = attachment.thumb_url;

	return {
		id: fileName,
		name: fileName,
		url,
		mimeType,
		isFile,
		thumb_url,
		fileType,
		mediaType: fileType,
	};
}
