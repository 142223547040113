import { useService, useQuery } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TNetworkConnection } from '@typings';
import type { AxiosError } from 'axios';
import { httpCodes } from '../../../services/abstracts/AbstractAxiosService/httpCodes';

export const useNetworkConnectionQuery = (
	id: string,
	queryOptions?: UseQueryOptions<TNetworkConnection, AxiosError, TNetworkConnection>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const query = useQuery<TNetworkConnection, AxiosError, TNetworkConnection>(
		reactQuery.queryKeys.getNetworkConnection(id),
		async () => await api.networkConnection.getNetworkConnection(id),
		{
			// staleTime: dayjs.duration(15, 'minutes').asMilliseconds(), // T21C-6808 Need to refech as data is out of date.
			enabled: !!id,
			...queryOptions,
		},
	);

	return { ...query, isDeleted: query?.error?.response?.status === httpCodes?.NOT_FOUND };
};
