import styles from '../CameraPhotoInput.module.css';
import { ImageAttachmentIcon } from '../../../icons';

const OpenGalleryButton = ({ onClick }: IOpenGalleryButtonProps) => {
	if (!onClick) return null;
	return (
		<button aria-label="Open gallery" className={styles.cameraPhotoInput__openImageGalleryButton} onClick={onClick}>
			<ImageAttachmentIcon className={styles.cameraPhotoInput__openImageGalleryButtonIcon} height={14} width={14} />
		</button>
	);
};

export interface IOpenGalleryButtonProps
	extends Pick<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'onClick'> {}

export default OpenGalleryButton;
