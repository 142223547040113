import type { NavigateOptions } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { noop } from 'lodash';
import type { DetailedHTMLProps, HTMLAttributes, MouseEventHandler, PropsWithChildren } from 'react';

/** Clickable block implemented as an anchor. */
const BlockLink = ({ to, children, navigationState, onClick, ...props }: IBlockLinkProps) => {
	const navigate = useNavigate();
	const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
		if (window.getSelection()?.toString().length) return; // prevent handle click when it was text selection.
		to && navigate(to, navigationState);
		event.stopPropagation();
		onClick?.(event);
	};

	return (
		<div
			onClick={handleClick}
			{...props}
			role="link"
			style={{ cursor: to ? 'pointer' : 'default' }}
			tabIndex={0}
			onKeyPress={noop}
		>
			{children}
		</div>
	);
};

export interface IBlockLinkProps
	extends PropsWithChildren,
		DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	to?: string;
	navigationState?: NavigateOptions;
}

export default BlockLink;
