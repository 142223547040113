import RichMessageInputSendButton from '../RichMessageInputSendButton';
import { useDebouncedCallback } from '@hooks';
import { useRichMessageAppearanceContext } from '../../contexts/RichMessageAppearanceContext';
import styles from './RichMessageInputBox.module.css';
import type { ReactNode } from 'react';

const RichMessageInputBox = ({
	children,
	sending = false,
	sendDisabled = false,
	onSend,
}: IRichMessageInputBoxProps) => {
	const [, setAppearance] = useRichMessageAppearanceContext();

	const debouncedOnSend = useDebouncedCallback(onSend, 500, { leading: true, trailing: false });

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<div
			className={styles.richMessageInputBox}
			onChange={() => setAppearance('row')}
			onClick={() => setAppearance('row')}
		>
			<div className={styles.richMessageInputBox__contentBox}>{children}</div>
			<RichMessageInputSendButton disabled={sendDisabled} loading={sending} onSend={debouncedOnSend} />
		</div>
	);
};

export interface IRichMessageInputBoxProps {
	/** Own TextInput and any other renderings like event or sharing content cards. */
	children?: ReactNode | undefined;
	/** It will be used to show the loading indicator */
	sending?: boolean;
	/** It will be used to disable the send button */
	sendDisabled?: boolean;
	/** Called after submission the message with significant debounce */
	onSend: () => void;
}

export default RichMessageInputBox;
