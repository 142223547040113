import { yup } from '@utils';
import type { SchemaOf } from '@utils';
import type { TFunction } from 'i18next';
import { NETWORK_CONNECTION_DESCRIPTION_CHARACTER_LIMIT, DEAL_TITLE_CHAR_LIMIT } from '@constants';
import { validateYupSchema, yupToFormErrors } from 'formik';
import type { TDynamicValues } from '@ui-modules/connections/utils/dynamicFieldValidation';
import { mapErrors } from '@ui-modules/connections/utils/dynamicFieldValidation';
import type {
	TDynamicField,
	TMediaObject,
	TNetworkConnection,
	TNetworkConnectionAction,
	TNetworkConnectionCategory,
	TNetworkConnectionMemberRelationStatus,
	TNetworkConnectionTemplate,
	TUser,
} from '@typings';
import { convertToNestedCategories } from '@ui-modules/connections/hooks/useGetNetworkConnectionTemplateQuery';
import { phoneNumberValidator } from '@schemas';

export const connectionFormFields = {
	CREATOR: 'creator',
	RELATION: 'relation',
	CONTACT_NAME: 'contactName',
	CONTACT_PHONE: 'contactPhone',
	CONTACT_EMAIL: 'contactEmail',
	CATEGORIES: 'categories',
	TITLE: 'title',
	DESCRIPTION: 'description',
	CURRENCY: 'currency',
	DYNAMIC_FIELDS: 'dynamicFields',
	VIDEO_URL: 'videoUrl',
	FILES: 'files',
	DELETED_FILES: 'deletedFiles',
	READ_ONLY_FILES_ACTIONS: 'makeFileReadOnlyActions',
	MAKE_FILE_DOWNLOADABLE_ACTIONS: 'makeFileDownloadableActions',
	DONATION_BUTTON_LINK: 'donationButtonLink',
	SELECTED_TEMPLATE_VERSION: 'selectedTemplateVersion',
};

export const networkConnectionStaticSectionsDetailsNames = {
	CONTACT: 'contact',
	DETAILS: 'details',
	ATTACHMENTS: 'attachments',
};

// Schema for each category in the categories array
const categorySchema = yup.object().shape({
	value: yup.boolean().required(),
	label: yup.string().required(),
	id: yup.string().required(),
});

export const getConnectionFormSchema: (
	t: TFunction,
	networkConnectionName: string,
	formTemplate?: TNetworkConnectionTemplate,
	memberRelations?: TNetworkConnectionMemberRelationStatus[],
) => SchemaOf<TNetworkConnectionFormValues> = (t, networkConnectionName, formTemplate, memberRelations) =>
	yup.object({
		creator: yup.object().shape({
			iriId: yup.string().default(undefined).required('This field is required'),
			name: yup.string().default(undefined).required('This field is required'),
		}),
		relation:
			(memberRelations || [])?.length > 1
				? yup.string().required(t('This field is required'))
				: yup.string().optional(),
		contactName: yup
			.string()
			.trim()
			.required(t('Name is required'))
			.min(3, t('Name must be at least 3 characters'))
			.max(255, t('Name must be max 255 characters'))
			.default(''),
		contactPhone: phoneNumberValidator(true, t('Phone number is not valid'))
			.trim()
			.required(t('Phone is required'))
			.default(''),
		contactEmail: yup.string().trim().email(t('Email is not valid')).required(t('Email is required')),
		community: yup.string().required(),
		categories: yup
			.array()
			.of(categorySchema)
			.test('checkSelection', t('At least one Category and Subcategory must be selected'), (categories) => {
				if (!categories?.length) return true;
				return !!categories?.some((category) => category.value === true);
			}),
		title: yup
			.string()
			.max(
				DEAL_TITLE_CHAR_LIMIT,
				t('{{networkConnectionName}} title must be max {{DEAL_TITLE_CHAR_LIMIT}} characters', {
					networkConnectionName,
					DEAL_TITLE_CHAR_LIMIT,
				}),
			)
			.trim()
			.required(t('Please enter a title for this {{networkConnectionName}}', { networkConnectionName }))
			.default(''),
		description: yup
			.string()
			.trim()
			.required(t('Please enter a description for this {{networkConnectionName}}', { networkConnectionName }))
			.default('')
			.max(NETWORK_CONNECTION_DESCRIPTION_CHARACTER_LIMIT),
		currency:
			// If currency in template is null it mean this filed is dynamic and don't need validation [T21C-6727]
			formTemplate?.currency == null
				? yup.string().optional().nullable()
				: yup
						.string()
						.required(t('Please select a currency for this {{networkConnectionName}}', { networkConnectionName })),
		dynamicFields: yup.object(),
		videoUrl: yup.string().trim().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		files: yup.array().optional(),
		// Service fields which are not displayed in the form but are used for performing actions during the form submission
		deletedFiles: yup.array().optional(),
		makeFileReadOnlyActions: yup.array().optional(),
		makeFileDownloadableActions: yup.array().optional(),
		donationButtonLink: yup
			.string()
			.trim()
			.url(t('Not a valid URL. Enter a valid URL starting with “http”'))
			.optional(),
		selectedTemplateVersion:
			(formTemplate?.availableTemplateVersions || [])?.length > 1
				? yup.string().required(t('This field is required'))
				: yup.string().optional(),
		staticSectionsDetails: yup.array().optional(),
		hiddenFields: yup.array().optional(),
	});

export type TNetworkConnectionFormValues = {
	creator: { iriId: string; name: string };
	relation?: string;
	contactName: string;
	contactPhone: string;
	contactEmail: string;
	community: string;
	categories: Array<TCategory>;
	description: string;
	title: string;
	currency?: string | null;
	dynamicFields: TDynamicValues;
	videoUrl?: string;
	files?: TMediaObject[];
	// Service fields which are not displayed in the form but are used for performing actions during the form submission
	deletedFiles?: string[];
	makeFileReadOnlyActions?: TMediaObject['id'][];
	makeFileDownloadableActions?: TMediaObject['id'][];
	donationButtonLink?: string;
	selectedTemplateVersion?: string;
	staticSectionsDetails?: TNetworkConnectionStaticSectionsDetails[];
	hiddenFields?: string[];
};

export type TNetworkConnectionStaticSectionsDetailsNames = 'details' | 'contact';
export type TNetworkConnectionStaticSectionsDetails = {
	name: TNetworkConnectionStaticSectionsDetailsNames;
	label: TNetworkConnectionStaticSectionsDetailsNames;
	description: string;
};

export type TCategory = {
	label: string;
	value: boolean;
	id: string;
};

export const getValidateHandler =
	(
		t: TFunction,
		networkConnectionName: string,
		formTemplate?: TNetworkConnectionTemplate,
		memberRelations?: TNetworkConnectionMemberRelationStatus[],
	) =>
	async (values: TNetworkConnectionFormValues) => {
		const validationSchema = getConnectionFormSchema(t, networkConnectionName, formTemplate, memberRelations);
		let errors = {};
		try {
			validateYupSchema(values, validationSchema, true, values);
		} catch (err) {
			errors = { ...yupToFormErrors(err) };
		}

		const dynamicFields = mapErrors(values.dynamicFields || {}, t);

		if (Object.keys(dynamicFields).length) {
			errors = { ...errors, dynamicFields };
		}

		return errors;
	};
export const getEditInitialValues = ({
	connection,
	template,
	relationStatus,
	currentUser,
	memberRelations = [],
	networkConnectionAction,
}: {
	connection?: TNetworkConnection;
	template?: TNetworkConnectionTemplate;
	relationStatus?: TNetworkConnectionMemberRelationStatus;
	currentUser?: TUser;
	memberRelations: TNetworkConnectionMemberRelationStatus[];
	networkConnectionAction?: TNetworkConnectionAction | null;
}): TNetworkConnectionFormValues | undefined => {
	if (connection) {
		const ownerRelationStatus =
			connection.owner?.id === currentUser?.id
				? relationStatus?.id
				: memberRelations?.find((el) => el?.title === connection?.ownerRelationStatus?.title)?.id;

		const currency = template?.currency === null ? null : connection.currency || template?.currency;
		const donationButtonLink = networkConnectionAction?.buttonLink || '';

		const selectedTemplateVersion =
			connection.selectedTemplateVersion || template?.availableTemplateVersions?.[0]?.name || undefined;

		return {
			creator: {
				iriId: connection.owner.id,
				name: connection.owner.fullName,
			},
			community: connection.community.id,
			relation: ownerRelationStatus || '',
			contactName: connection.contactName,
			contactPhone: connection.contactPhone,
			contactEmail: connection.contactEmail,
			title: connection.title,
			description: connection.description,
			currency,
			dynamicFields: mapDynamicValues(connection.dynamicFields, template, selectedTemplateVersion),
			categories: mapCategoryValues(connection?.categories || [], template),
			videoUrl: connection.videoUrl || '',
			files: [],
			donationButtonLink,
			selectedTemplateVersion: selectedTemplateVersion,
		};
	}
};

const mapDynamicValues = (
	section?: TDynamicField[],
	template?: TNetworkConnectionTemplate,
	selectedTemplateVersion?: string,
) => {
	if (!template) return {};
	const filteredTemplateByVersion = selectedTemplateVersion
		? template.dynamicFields.filter(
				(field) =>
					!field.meta?.dynamicFieldTemplateVersions?.length ||
					field.meta.dynamicFieldTemplateVersions?.includes(selectedTemplateVersion),
			)
		: template.dynamicFields;
	return filteredTemplateByVersion.reduce((acc, field) => {
		if (field.type === 'heading') return acc;
		const isSectionFilled = section?.find((sectionField) => {
			return sectionField?.name === field?.name;
		});
		return { ...acc, [field.name]: { ...field, value: isSectionFilled?.value } };
	}, {});
};

const mapCategoryValues = (categories?: TNetworkConnectionCategory[], template?: TNetworkConnectionTemplate) => {
	const initialSelected = categories?.map((el) => el.id) || [];

	// Get last template categories (in case it changed) and check already marked items.
	const categoriesWithValue =
		template?.categories?.map((templateCategory) => {
			return { ...templateCategory, value: initialSelected.includes(templateCategory.id) };
		}) || [];

	return convertToNestedCategories(categoriesWithValue);
};
