import FileViewer from './FileViewer';
import { createContext, useContext, useRef } from 'react';
import type { PropsWithChildren, RefObject } from 'react';
import type { IFileViewerApi } from './FileViewer';

const FileViewerContext = createContext<RefObject<IFileViewerApi>>({
	current: {
		open: () => console.error('FileViewerProvider not found'),
		close: () => console.error('FileViewerProvider not found'),
	},
});

export const FileViewerProvider = ({ children }: PropsWithChildren) => {
	const fileViewerRef = useRef<IFileViewerApi>(null);

	return (
		<FileViewerContext.Provider value={fileViewerRef}>
			{children}
			<FileViewer ref={fileViewerRef} />
		</FileViewerContext.Provider>
	);
};

export const useFileViewer = () => useContext(FileViewerContext).current as IFileViewerApi;
