import { useEvent, useService, useState } from '@hooks';
import styles from './PdfViewer.module.css';
import { Portal, When } from '@ui-kit';
import { CloseIcon, DownloadIcon } from '@ui-kit/icons';
import { forwardRef, useImperativeHandle } from 'react';
import { isObject, downloadFile, extractFileExtensionFromUrl } from '@utils';
import type { ForwardedRef } from 'react';
import type { TFile } from '@tiger21-llc/connect-shared/src/typings';
import PdfFileViewer from './PdfFileViewer';
import DocumentFileViewer from './DocumentFileViewer';

const DEFAULT_FORBID_DOWNLOADING = false;

const FileViewer = ({ onOpened, onClosed }: IFileViewerProps, ref: ForwardedRef<IFileViewerApi>) => {
	const api = useService('ApiService');

	const [fileUrlOrFile, setFileUrlOrFile] = useState<TFileParam | null>(null);
	const [forbidDownloading, setForbidDownloading] = useState(DEFAULT_FORBID_DOWNLOADING);

	// External API available under the ref.
	const open = useEvent<IFileViewerApi['open']>((fileUrlOrFile, forbidDownloading = false) => {
		setFileUrlOrFile(fileUrlOrFile);
		setForbidDownloading(forbidDownloading);
		onOpened?.();
	});
	const close = useEvent(() => {
		setFileUrlOrFile(null);
		setForbidDownloading(DEFAULT_FORBID_DOWNLOADING);
		onClosed?.();
	});
	useImperativeHandle(ref, () => ({ open, close }));

	if (!fileUrlOrFile) return null;

	const isFile = isObject(fileUrlOrFile) && 'id' in fileUrlOrFile;
	const fileUrl = isFile ? fileUrlOrFile.mediaObject.contentUrl : fileUrlOrFile;
	const fileExtension = typeof fileUrl === 'string' ? extractFileExtensionFromUrl(fileUrl) : undefined;
	const canDownload = (isFile && !fileUrlOrFile.mediaObject.openInViewerOnly) || (!isFile && !forbidDownloading);
	const download = () => {
		if (!canDownload) return;
		if (isFile) {
			api.mediaObject.downloadNonEncryptedFileUrl(fileUrlOrFile);
		} else if (typeof fileUrlOrFile === 'string') {
			window.open(fileUrlOrFile, '_blank');
		} else {
			const data = window.URL.createObjectURL(fileUrlOrFile);
			downloadFile(data, 'file');
		}
	};

	return (
		<Portal>
			<div aria-labelledby="PDF Viewer" className={styles.modal__container} role="dialog">
				<div className={styles.modal__titleWrapper}>
					<span
						aria-label="close"
						className={styles.modal__toolbarButton}
						role="button"
						tabIndex={-1}
						onClick={close}
						onKeyPress={close}
					>
						<CloseIcon height={16} width={16} />
					</span>

					<When condition={canDownload}>
						<span
							aria-label="download"
							className={styles.modal__toolbarButton}
							role="button"
							tabIndex={-1}
							onClick={download}
							onKeyPress={download}
						>
							<DownloadIcon height={16} width={16} />
						</span>
					</When>
				</div>
				{!fileExtension ? (
					<PdfFileViewer fileUrl={fileUrl} onClose={close} />
				) : DocumentFileViewer.canHandleFileExtension(fileExtension) ? (
					<DocumentFileViewer fileUrl={fileUrl as string} />
				) : fileExtension && PdfFileViewer.canHandleFileExtension(fileExtension) ? (
					<PdfFileViewer fileUrl={fileUrl} onClose={close} />
				) : null}
			</div>
		</Portal>
	);
};

export interface IFileViewerProps {
	onOpened?: () => void;
	onClosed?: () => void;
}
type TFileParam = string | Blob | TFile;
export interface IFileViewerApi {
	open: (fileUrlOrFile: TFileParam, forbidDownloading?: boolean) => void;
	close: () => void;
}

export default forwardRef(FileViewer);
