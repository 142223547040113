import { useService, useQuery } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TEvent } from '@typings';
import type { AxiosError } from 'axios';
import { httpCodes } from '../../../services/abstracts/AbstractAxiosService/httpCodes';

export const useGetEventQuery = (
	eventId: TEvent['id'] | undefined,
	options?: UseQueryOptions<TEvent, AxiosError, TEvent>,
) => {
	const api = useService('ApiService');

	const query = useQuery<TEvent, AxiosError, TEvent>(
		['calendarItem.getCalendarItem', eventId],
		async () => await api.calendarItem.getCalendarItem(eventId as TEvent['id']),
		{
			enabled: !!eventId,
			...options,
		},
	);

	return { ...query, isDeleted: query?.error?.response?.status === httpCodes?.NOT_FOUND };
};
