import { FormPageLayout, Page } from '../../components';
import { ActivityIndicator, EmptyStateMessage, Gap } from '@ui-kit';
import TipReviewForm from '@ui-modules/tipJar/components/TipReviewForm';
import { useTranslation, useNavigate, useParams, useLayoutEffect, useNotification, useMe } from '@hooks';
import { useEditTipReviewMutation } from '@ui-modules/tipJar/hooks/useEditTipReviewMutation';
import { useSendTipReviewMutation } from '@ui-modules/tipJar/hooks/useSendTipReviewMutation';
import { ROUTES } from '@constants';
import styles from './TipReviewPage.module.css';
import type { TUid } from '@tiger21-llc/connect-shared/src/typings/base/Uid.type';
import { useTipContextQueryParams } from '@ui-modules/tipJar/hooks/useTipContextQueryParams';
import { CommunityEntity, compact } from '@utils';
import { useMyTipReviewQuery } from '@ui-modules/tipJar/hooks/useMyTipReviewQuery';
import { useAttachedMeetingsForTipCreationQuery } from '@ui-modules/tipJar/hooks/useAttachedMeetingsForTipCreationQuery';
import { useGroupMeetingsForTipCreationQuery } from '@ui-modules/tipJar/hooks/useGroupMeetingsForTipCreationQuery';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { useTipReviewQuery } from '@ui-modules/tipJar/hooks/useTipReviewQuery';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import type { TCommunity } from '@typings';
import type { TFunction } from 'i18next';
import { TTipReview } from '@tiger21-llc/connect-shared/src/typings/entities/TipReview.type';
import { useState } from 'react';

const TipReviewPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { showSuccess } = useNotification();
	const { user } = useMe();
	const { tipId, tipReviewIdOrNew } = useParams<Partial<ITipReviewPageParams>>() as ITipReviewPageParams;
	const { contextCalendarItemId, contextCommunityId } = useTipContextQueryParams();
	const tipReviewId = tipReviewIdOrNew === 'new' ? undefined : tipReviewIdOrNew;
	const myTipReviewQuery = useMyTipReviewQuery(tipId as string);
	const tipReviewQuery = useTipReviewQuery(tipId as string, tipReviewId);
	const [localOwnerId, setLocalOwnerId] = useState<string | null | undefined>(null);

	const isAdmin = user.roles.includes('ROLE_ADMIN');
	const isChair = user.roles.includes('ROLE_CHAIR');
	const { data: group } = useCommunityQuery(contextCommunityId as string);
	const editing = getEditingStatus(group, isAdmin, tipReviewId, myTipReviewQuery.data);

	useLayoutEffect(() => {
		if (!tipId) navigate(-1);
	}, [tipId]);

	const tipQuery = useTipQuery(tipId);
	const { data: groupMeetingsForTipCreation = [] } = useGroupMeetingsForTipCreationQuery(
		contextCalendarItemId,
		getOwnerId(tipReviewIdOrNew, isAdmin, user.id, tipReviewQuery.data?.owner.id, localOwnerId),
		!isAdmin ? tipReviewIdOrNew : undefined,
		isCommunityModerator(group) || null,
	);

	const { mutateAsync: editTipReview } = useEditTipReviewMutation({
		onSuccess: () => {
			navigate(-1);
		},
	});

	const { data: attachedMeetingsForTipCreation = [], isFetching: isFetchingAttachedMeetings } =
		useAttachedMeetingsForTipCreationQuery(tipReviewId!, {
			select: (data) => compact(data.map((meeting) => meeting.meetingId)),
		});
	const sendTipReviewMutation = useSendTipReviewMutation({
		onSuccess: () => {
			showSuccess({ title: t('Tip Saved') });
			navigate(-1);
		},
	});

	return (
		<Page title={getPageTitle(tipReviewId, t)}>
			{tipQuery.isLoading || myTipReviewQuery.isFetching || isFetchingAttachedMeetings || tipReviewQuery.isFetching ? (
				<ActivityIndicator type="fit" />
			) : tipQuery.isError || myTipReviewQuery.isError ? (
				<EmptyStateMessage text={t('Something went wrong')} />
			) : (
				<TipReviewForm
					canDoOnBehalfOfOtherMember={isCommunityModerator(group)}
					contextCommunityId={contextCommunityId}
					editing={editing}
					initialValues={{
						ownerId: user.id,
						...(isAdmin || isChair ? tipReviewQuery.data : myTipReviewQuery.data),
						calendarItemsId: compact([contextCalendarItemId, ...attachedMeetingsForTipCreation]),
					}}
					renderWrapper={({ formProps, children }) => {
						if (formProps.values.ownerId !== localOwnerId) {
							setLocalOwnerId(formProps.values.ownerId);
						}
						return (
							<FormPageLayout
								allowedNextPaths={[ROUTES.createTipReview()]}
								formProps={formProps}
								headerTitle={getPageTitle(tipReviewId, t)}
								isSaveButtonHidden
								scrollEnabled
							>
								<div className={styles.tipReviewPage}>
									{children}
									<Gap gap={24} />
								</div>
							</FormPageLayout>
						);
					}}
					submitLabel={tipReviewId ? t('Save') : t('Submit')}
					targetMeetings={groupMeetingsForTipCreation}
					title={tipReviewId ? t('Review Details') : t('Review “{{tipName}}”', { tipName: tipQuery.data?.title })}
					onSubmit={async (formValues) => {
						if (tipId && tipReviewId) {
							await editTipReview({ tipId, tipReviewId, ...formValues });
						} else {
							await sendTipReviewMutation.mutateAsync({ ...formValues, tipId });
						}
					}}
				/>
			)}
		</Page>
	);
};

function isCommunityModerator(community?: TCommunity) {
	return community ? CommunityEntity.userIsModerator(community) : undefined;
}

function getPageTitle(tipReviewId: string | undefined, t: TFunction<'translation', undefined>) {
	return tipReviewId ? t('Edit Review') : t('Add Review');
}

function getEditingStatus(
	group: TCommunity | undefined,
	isAdmin: boolean,
	tipReviewId: string | undefined,
	myTipReviewData?: TTipReview | null,
) {
	return isCommunityModerator(group) ? !!(isAdmin && tipReviewId) : !!(myTipReviewData || (isAdmin && tipReviewId));
}

export interface ITipReviewPageParams {
	tipId: TUid;
	tipReviewIdOrNew: TUid | 'new';
}

function getOwnerId(
	tipReviewIdOrNew: string,
	isAdmin: boolean,
	userId: string,
	ownerId?: string,
	localOwnerId?: string | null,
): string {
	return tipReviewIdOrNew === 'new' && isAdmin ? userId : (ownerId ?? (localOwnerId as string));
}

export default TipReviewPage;
