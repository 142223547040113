import { useMe, useService, useTranslation } from '@hooks';
import styles from './AssetAllocationIntroModal.module.css';
import { FeatureSlider } from '@ui-kit';
import type { PropsWithChildren } from 'react';
import { ModalHeader, SecondPageBody, ThirdPageBody, FourthPageBody } from './FeatureComponents';
import { useAssetAllocationIntroduced } from '@ui-modules/assetAllocation/hooks/useAssetAllocationIntroduced';
import { isMyAssetAllocationAllowed } from '@ui-modules/assetAllocation/utils/isMyAssetAllocationAllowed';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import { useImpersonate } from '@ui-modules/auth/hooks/useImpersonate';
import { createGlobalState } from 'react-use';

const useIntroducedUsersAA = createGlobalState<Set<string>>(new Set());
const AssetAllocationIntroModal = ({ children }: IAssetAllocationIntroModalProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();
	const analytics = useService('AnalyticsService');
	const { impersonated } = useImpersonate();
	const [introducedUsersAAGlobal, setIntroducedUsersAAGlobal] = useIntroducedUsersAA();

	const [isAssetAllocationIntroduced, setAssetAllocationIntroduced] = useAssetAllocationIntroduced();
	const myAssetAllocationAllowed = isMyAssetAllocationAllowed(user);

	const dismissModal = () => {
		setAssetAllocationIntroduced();
		analytics.trackEvent('AssetAllocationInteractions', {
			interaction_type: 'intro_slider_interaction',
			action: 'close',
			slide_name: 'Get personalized recommendations',
		});
	};

	const dismissModalAdmin = () => {
		if (impersonated && user?.id) {
			setIntroducedUsersAAGlobal((prev) => new Set(prev).add(user.id));
		}
	};
	const onOpenMyAssetAllocation = () => {
		navigate(ROUTES.assetAllocation('me'));

		if (!impersonated) {
			setAssetAllocationIntroduced();
			analytics.trackEvent('AssetAllocationInteractions', {
				interaction_type: 'intro_slider_interaction',
				action: 'update_aa',
				slide_name: 'Get personalized recommendations',
			});
		} else {
			introducedUsersAAGlobal.add(user.id);
		}
	};

	return (
		<FeatureSlider
			impersonated={impersonated}
			slides={[
				{
					header: (
						<div className={styles.firstPageHeader}>
							<ModalHeader mode="large" />
						</div>
					),
					body: (
						<div className={styles.firstPageBody}>
							<p>{t('Continue to learn what you can do...')}</p>
						</div>
					),
					name: 'Swipe to learn what you can do',
				},
				{
					header: <ModalHeader />,
					body: <SecondPageBody />,
					name: 'Update your own allocation any time',
				},
				{
					header: <ModalHeader />,
					body: <ThirdPageBody />,
					name: 'Compare your allocation to',
				},
				{
					header: <ModalHeader />,
					body: (
						<FourthPageBody
							onDismiss={impersonated ? dismissModalAdmin : dismissModal}
							onOpenMyAssetAllocation={onOpenMyAssetAllocation}
						/>
					),
					name: 'Get personalized recommendations',
				},
			]}
			visible={
				impersonated ? !introducedUsersAAGlobal.has(user?.id) : !isAssetAllocationIntroduced && myAssetAllocationAllowed
			}
			onDismissedByBackdrop={dismissModalAdmin}
			onSlideShown={(slide) => {
				analytics.trackEvent('AssetAllocationInteractions', {
					interaction_type: 'intro_slider_interaction',
					action: 'slide',
					slide_name: slide.name,
				});
			}}
		>
			{children}
		</FeatureSlider>
	);
};

export interface IAssetAllocationIntroModalProps extends PropsWithChildren {}

export default AssetAllocationIntroModal;
