import { ChevronRightIcon, FileListItem } from '@ui-kit';
import { useOpenFile } from '@ui-modules/files/hooks/useOpenFile';
import { useEvent, useTranslation } from '@hooks';
import { extractMetaFromFile } from '@ui-kit/components/FileListItem/FileListItem';
import styles from './FilesSection.module.css';
import type { TFile } from '@typings';
import { clsx } from '@utils';

const FilesSection = ({ title, files, onFileOpened }: IFilesSectionProps) => {
	const { t } = useTranslation();

	const { openFile } = useOpenFile();

	const openItemFile = useEvent((file: TFile) => {
		openFile(file);
		onFileOpened?.(file);
	});

	if (!files?.length) return null;
	return (
		<div className={styles.filesSection}>
			<div className={styles.filesSection__headerTitle}>{title}</div>
			{files?.map((file) => (
				<FileListItem
					className={clsx(
						styles.filesSection__file,
						(file.status === 'updated' || file.status === 'new') && styles.filesSection__file_updated,
						file.status === 'deleted' && styles.filesSection__file_deleted,
					)}
					key={file.id}
					meta={extractMetaFromFile(file as any)}
					miniDesignMode
					OpenIconComponent={ChevronRightIcon}
					openInViewerOnly={file.mediaObject.openInViewerOnly}
					showOpenIcon
					type="card"
					viewerModeText={t('read only')}
					onClick={() => openItemFile(file)}
				/>
			))}
		</div>
	);
};

export interface IFilesSectionProps {
	title: string;
	files: (TFile & { status?: string })[] | undefined;
	onFileOpened?: (file: TFile) => void;
}

export default FilesSection;
