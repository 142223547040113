import { getDynamicFieldsRenderData, clsx, toString } from '@utils';
import HighlightedText from '../HighlightedText';
import styles from './DynamicFieldDataGrid.module.css';
import type { TDynamicFieldData } from '@typings';
import { InfoCircleIcon, When } from '@ui-kit';

const DynamicFieldDataGrid = ({
	dynamicFields = [],
	highlightedText,
	booleanFieldTrueLabel,
	booleanFieldFalseLabel,
	currency,
	uniqueFieldStyle = {},
}: IDynamicFieldDataGridProps) => {
	if (!dynamicFields.length) return null;

	const dynamicFieldsRenderData = getDynamicFieldsRenderData(
		dynamicFields,
		{
			booleanFieldTrueLabel,
			booleanFieldFalseLabel,
		},
		currency,
	);
	return (
		<div className={styles.dynamicFieldDataGrid__rowContainer}>
			{dynamicFieldsRenderData.map(({ label: title, value: subtitle, infoText, multiselectValues }, index) => {
				if (!subtitle) return null;
				const span = multiselectValues && multiselectValues?.length > 1 ? 2 : 1;
				return (
					<div key={title + index} style={{ gridColumn: `span ${span}` }}>
						<span className={styles.dynamicFieldDataGrid__textTitle}>{title}</span>
						<When condition={!!multiselectValues}>
							{multiselectValues?.map((value) => (
								<FieldElement
									highlightedText={highlightedText}
									key={value.choice}
									subtitle={toString(value.choice)}
									tooltip={value.infoText}
								/>
							))}
						</When>
						<When condition={!multiselectValues}>
							<FieldElement
								elementClassName={uniqueFieldStyle?.[title]}
								highlightedText={highlightedText}
								subtitle={toString(subtitle)}
								tooltip={infoText}
							/>
						</When>
					</div>
				);
			})}
		</div>
	);
};

const FieldElement = ({ tooltip, highlightedText, subtitle, elementClassName }: IFieldElementProps) => {
	return (
		<div className={styles.dynamicFieldDataGrid__textContainer}>
			<HighlightedText
				className={clsx(
					styles.dynamicFieldDataGrid__text,
					tooltip && styles.dynamicFieldDataGrid__text_link,
					elementClassName,
				)}
				highlightedText={highlightedText}
			>
				{toString(subtitle)}
			</HighlightedText>
			{tooltip ? <span className={styles.dynamicFieldDataGrid__tooltipText}>{tooltip}</span> : null}
			{tooltip ? <InfoCircleIcon className={styles.dynamicFieldDataGrid__icon} height={16} width={16} /> : null}
		</div>
	);
};

interface IFieldElementProps {
	tooltip?: string;
	highlightedText?: string;
	subtitle: string;
	elementClassName?: string;
}

export interface IDynamicFieldDataGridProps {
	dynamicFields: TDynamicFieldData[];
	booleanFieldTrueLabel: string;
	booleanFieldFalseLabel: string;
	highlightedText?: string;
	currency?: string;
	uniqueFieldStyle?: {
		[key: string]: string;
	};
}

export default DynamicFieldDataGrid;
