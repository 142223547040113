import React, { useEffect, useState, useContext } from 'react';
import { isEmpty, noop } from '@utils';

import type { IInternalShare } from '@typings';

const INTERNAL_SHARE_DEFAULT_VALUE = {} as IInternalShare;

const InternalShareContext = React.createContext({
	showInternalShareModal: false,
	internalShare: INTERNAL_SHARE_DEFAULT_VALUE,
	resetInternalShare: noop,
	setInternalShare: noop,
	closeInternalShareModal: noop,
});

const useInternalShare = () => {
	const [internalShare, setInternalShare] = useState<IInternalShare>(INTERNAL_SHARE_DEFAULT_VALUE);
	const [showInternalShareModal, setShowInternalShareModal] = useState(false);

	useEffect(() => {
		if (!isEmpty(internalShare)) {
			setShowInternalShareModal(true);
		}
	}, [internalShare]);

	const resetInternalShare = () => {
		setInternalShare(INTERNAL_SHARE_DEFAULT_VALUE);
		setShowInternalShareModal(false);
	};

	const closeInternalShareModal = () => {
		setShowInternalShareModal(false);
	};

	return {
		showInternalShareModal,
		internalShare,
		resetInternalShare,
		setInternalShare,
		closeInternalShareModal,
	};
};

function useInternalShareContext() {
	return useContext(InternalShareContext);
}

function InternalShareProvider({ children }: IInternalShareProvideProps) {
	const internalShare = useInternalShare();

	return <InternalShareContext.Provider value={internalShare}>{children}</InternalShareContext.Provider>;
}

export interface IInternalShareProvideProps {
	children: React.ReactNode;
}

export { useInternalShareContext, InternalShareProvider };
