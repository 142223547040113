import styles from './ChatInputBarEditMessageContainer.module.css';
import { CloseIcon } from '@ui-kit';
import { useTranslation } from '@hooks';

const EditMessageContainer = ({ editing, replying, onReset }: IEditMessageContainerProps) => {
	const { t } = useTranslation();
	return (
		<div className={styles.chatInputBarEditMessageContainer__sendButtonSection}>
			{editing || replying ? (
				<div className={styles.chatInputBarEditMessageContainer__editOrReplyContainer}>
					<span className={styles.chatInputBarEditMessageContainer__editAndReply}>
						{editing && t('Editing Message')}
						{replying && t('Reply to Message')}
					</span>
					<button className={styles.chatInputBarEditMessageContainer__closeIconContainer} onClick={onReset}>
						<CloseIcon fill="#7a7a7a" height={12} width={13} />
					</button>
				</div>
			) : null}
		</div>
	);
};

interface IEditMessageContainerProps {
	editing: boolean;
	replying: boolean;
	onReset: () => void;
}
export default EditMessageContainer;
