import {
	MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE,
	MAX_ITEMS_FOR_UPLOAD,
	MAXIMUM_ALLOWED_ATTACHMENTS_COUNT,
} from '@constants';
import { useContext, useEffect, useMemo, useRef, useState, useTranslation } from '@hooks';
import type { TActivity, TCommunity } from '@typings';
import FeedCommentInput, { useTextAreaResize } from '../FeedCommentInput';
import DiscardPageChangesModal from '@ui-modules/forms/components/DiscardPageChangesModal';
import { usePostAttachmentsState } from '@ui-modules/feed/hooks/usePostAttachmentsState';
import { useSendCommentMutation } from './useSendCommentMutation';
import { CommentControlsEditingContext, CommentControlsEditingContextProvider } from './CommentControlsEditingContext';
import { clsx, debounce, noop, onClipboardPaste } from '@utils';
import styles from './CommentControls.module.css';
import { useFeedContext } from 'react-activity-feed';
import RichMessageInput, { mapAttachmentsToRichTextInputFormat } from '@ui-modules/forms/components/RichMessageInput';
import ShareableCard from '@ui-modules/share/components/ShareableCard';
import EventListItemAttachment from '@ui-modules/events/components/EventListItemAttachment';
import { useStoredShareContent } from '@ui-modules/share/hooks/useStoredShareContent';

export const DEBOUNCE_REFRESH_TIMEOUT = 800;

const CommentControls = ({ activity, onCommentSent, customClass, community }: ICommentControlsProps) => {
	const { t } = useTranslation();

	const inputBoxRef = useRef<HTMLDivElement>(null);
	const ref = useRef<HTMLTextAreaElement>(null);
	const [comment, setComment] = useState('');

	const [editingReaction, setEditingReaction] = useContext(CommentControlsEditingContext);
	const isReply = !!editingReaction?.reply;
	const reaction = editingReaction?.comment;
	const isEditing = !!reaction?.id && !isReply;
	const onDiscardReply = () => {
		setComment('');
		setAttachments([]);
		setEditingReaction(null);
		setStoredShareContent(null);
		setEventId(null);
	};

	const { resize, delayedResize } = useTextAreaResize({ ref, maxRows: 6, minRows: 1 });
	const {
		attachments,
		filesSize,
		removeAttachment,
		setAttachments,
		setInitialAttachments,
		onAddAttachmentsSuccess,
		loadingStateAttachments,
	} = usePostAttachmentsState({});
	const { storedShareContent, clearStoredShareContent, setStoredShareContent } = useStoredShareContent();
	const [eventId, setEventId] = useState<string | null>(null);

	const filesExceedingSize = filesSize > MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE;
	const itemsExceedingCount = attachments.length > MAX_ITEMS_FOR_UPLOAD;
	const { isLoading: sending, mutate: onSendPressed } = useSendCommentMutation(
		{
			isReply,
			activity,
			reaction,
			community,
		},
		{
			onSuccess: (id) => resetData(id),
		},
	);
	const { refresh } = useFeedContext();

	const debouncedRefresh = debounce(() => {
		refresh();
	}, DEBOUNCE_REFRESH_TIMEOUT);

	const resetData = (commentId: string) => {
		onDiscardReply();
		onCommentSent?.(isEditing, commentId, isReply);

		if (isReply) {
			// @todo update the mentioned link if fixed, because now we do not have any workaround [https://github.com/GetStream/react-activity-feed/issues/304] [@author Hrant Abrahamyan]
			debouncedRefresh();
		}
	};

	useEffect(() => {
		resize(() => ref?.current?.scrollIntoView?.());
	}, [comment]);

	useEffect(() => {
		if (reaction?.id && !isReply) {
			setComment(reaction?.data?.text);
			setInitialAttachments({
				images: reaction?.data.images,
				videos: reaction?.data.videos,
				files: reaction?.data?.files,
			});
			setStoredShareContent(reaction?.data?.sharedContent?.[0]);
			setEventId(reaction?.data?.event ?? null);
		} else if (isReply) {
			setComment('');
		} else {
			setComment('');
		}
		ref?.current?.focus();
	}, [reaction, isReply]);

	const sendDisabled = useMemo(() => {
		return (
			(!comment.trim().length && !attachments.length && !storedShareContent && !eventId) ||
			filesExceedingSize ||
			itemsExceedingCount
		);
	}, [comment, attachments, filesExceedingSize, storedShareContent, eventId]);

	const richMessageInputAttachments = useMemo(
		() =>
			mapAttachmentsToRichTextInputFormat(attachments, loadingStateAttachments, {
				removeFile: removeAttachment,
				removeMedia: removeAttachment,
			}),
		[attachments, loadingStateAttachments],
	);
	const placeholder = useMemo(() => {
		if (!isReply && reaction?.id && !!reaction?.parent) {
			t('Edit reply');
		} else if (!isReply && reaction?.id && !reaction?.parent) {
			t('Edit comment');
		} else if (isReply) {
			return t('Write a reply');
		} else {
			return t('Write a comment');
		}
	}, [isReply, reaction]);

	return (
		<div className={clsx(styles.comment, customClass)} ref={inputBoxRef}>
			<RichMessageInput initialAppearance={activity?.reaction_counts?.comment ? 'row' : 'column'}>
				{isReply || isEditing ? (
					<div className={styles.replyBlock}>
						<div>
							<span className={styles.replyBlock__label}>{`${
								isEditing
									? t('Editing my {{isReply}}', { isReply: reaction?.parent ? t('reply') : t('comment') })
									: t('Reply to')
							} `}</span>
							<span className={styles.replyBlock__userName}>{reaction?.user?.data.name}</span>
						</div>
						<button className={styles.commentButton} onClick={onDiscardReply}>
							<span className={styles.replyBlock__cancel}>{t('Cancel')}</span>
						</button>
					</div>
				) : null}
				<RichMessageInput.InputBox
					sendDisabled={sendDisabled}
					sending={sending}
					onSend={() => {
						onSendPressed({ attachments, comment, eventId, sharedContent: storedShareContent });
					}}
				>
					<RichMessageInput.AttachmentsWithPreview attachments={richMessageInputAttachments} />
					<FeedCommentInput
						attachmentEditingDisabled={false}
						attachments={attachments}
						attachmentsError={
							filesExceedingSize
								? t('Size should be limited up to {{ maxLimit }} /{{ limit }}MB', {
										maxLimit: MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE,
										limit: String(filesSize).slice(0, 5),
									})
								: itemsExceedingCount
									? t("You can't attach more then {{count}} items", { count: MAX_ITEMS_FOR_UPLOAD })
									: ''
						}
						delayedResize={delayedResize}
						inputBoxRef={inputBoxRef}
						networkId={activity?.collectionCommunityReference?.id}
						placeholder={placeholder}
						ref={ref}
						resize={resize}
						value={comment}
						onChange={(event) => setComment(event)}
						onPaste={(event) => onClipboardPaste(event, (files) => onAddAttachmentsSuccess?.(files))}
						onRemoveAttachment={removeAttachment}
					/>

					{eventId ? (
						<div className={styles.attachmentCardBox}>
							<EventListItemAttachment
								eventId={eventId}
								onClick={noop}
								onRemove={() => {
									setEventId(null);
								}}
							/>
						</div>
					) : null}

					{storedShareContent ? (
						<div className={styles.attachmentCardBox}>
							<ShareableCard
								clearInternalShare={() => {
									clearStoredShareContent();
								}}
								sharedContent={storedShareContent}
							/>
						</div>
					) : null}
				</RichMessageInput.InputBox>
				<RichMessageInput.ActionsWithPickers
					attachmentAmountExceeded={attachments.length > MAXIMUM_ALLOWED_ATTACHMENTS_COUNT}
					onSelectEvent={setEventId}
					onSelectFiles={onAddAttachmentsSuccess}
					onSelectMedia={onAddAttachmentsSuccess}
					onSelectShareContent={setStoredShareContent}
				/>
			</RichMessageInput>
			<DiscardPageChangesModal condition={!sendDisabled} />
		</div>
	);
};

export interface ICommentControlsProps {
	activity: TActivity;
	onCommentSent?: (wasEditing: boolean, commentId?: string, isReply?: boolean) => void;
	customClass?: string;
	community?: TCommunity;
}

CommentControls.EditingContext = CommentControlsEditingContext;
CommentControls.EditingContextProvider = CommentControlsEditingContextProvider;
export default CommentControls;
