import { memo } from 'react';
import { Card, SpacedGroup, UserListItem } from '@ui-kit';
import { useTranslation } from '@hooks';
import type { TEvent, TUserProfileRecord } from '@typings';
import { formatUserSubTitle } from '@utils';

const EventHostedByCard = ({ eventHosts = [], onClick }: IEventHostedByCardProps) => {
	const { t } = useTranslation();

	return (
		<Card backgroundPlacement="none" title={t('Hosted by')}>
			<SpacedGroup direction="vertical" gap={14}>
				{eventHosts.map((hostUser) => (
						<UserListItem
							isBackgroundTransparent
							key={hostUser.id}
							renderUserSubTitle={formatUserSubTitle(t)}
							size="small"
							userProfileRecord={hostUser}
							onClick={() => onClick({ profile: hostUser })}
						/>
				))}
			</SpacedGroup>
		</Card>
	);
};

export interface IEventHostedByCardProps {
	eventHosts: TEvent['hosts'];
	onClick: (data: { profile: TUserProfileRecord }) => void;
}

export default memo(EventHostedByCard);
