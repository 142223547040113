import { memo } from 'react';
import { clsx } from '@utils';
import FeedActivityWrapper, { type IFeedActivityWrapperProps } from './FeedActivityWrapper';

import FeedBanner from '../FeedBanner';

import styles from './FeedActivity.module.css';

import type { IFeedActivityProps } from './FeedActivity';
import type { TAdImage } from '@typings';

const FeedActivityAdImage = ({
	activity,
	onPressActivityLink,
	onInteracted,
	toggleDeleteActivityDialog,
	withSeparator,
	onActivityDeleted,
	isDeleteActivityDialogVisible,
	canDeletePost,
}: IFeedActivityAdImageProps) => {
	return (
		<FeedActivityWrapper
			activity={activity}
			isDeleteActivityDialogVisible={isDeleteActivityDialogVisible}
			toggleDeleteActivityDialog={toggleDeleteActivityDialog}
			onActivityDeleted={onActivityDeleted}
		>
			<div className={clsx(withSeparator && styles.feedActivity__post)}>
				<FeedBanner
					canDeletePost={canDeletePost}
					className={styles.adImage}
					imageUrl={(activity.settings as { adImage: TAdImage }).adImage.image}
					link={(activity.settings as { adImage: TAdImage }).adImage.link}
					onClick={(link) => {
						onPressActivityLink?.(link, activity);
						onInteracted?.('card body');
					}}
					onDelete={() => toggleDeleteActivityDialog(true)}
				/>
			</div>
		</FeedActivityWrapper>
	);
};

export interface IFeedActivityAdImageProps extends Partial<IFeedActivityProps> {
	activity: IFeedActivityProps['activity'];
	canDeletePost: boolean;
	isDeleteActivityDialogVisible: boolean;
	toggleDeleteActivityDialog: IFeedActivityWrapperProps['toggleDeleteActivityDialog'];
}

export default memo(FeedActivityAdImage);
