import {
	MAX_POST_IMAGE_HEIGHT,
	MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE,
	MAX_ITEMS_FOR_UPLOAD,
	POST_MESSAGE_CHAR_LIMIT,
} from '@constants';
import ImagesLightBox from '@ui-modules/files/components/ImagesLightBox';
import { useMemo, useRef, useState, useTranslation } from '@hooks';
import type { TAttachments, TEvent } from '@tiger21-llc/connect-shared/src/typings';
import { AutoGrowInput, InputErrorMessage } from '@ui-kit';
import { clsx, onClipboardPaste } from '@utils';
import PostAttachment from '../PostAttachment';
import styles from './PostAttachments.module.css';
// import { useImageView } from '../AttachmentTextArea/useImageView';
import { useOpenFile } from '@ui-modules/files/hooks/useOpenFile';
import type { FocusEventHandler } from 'react';
import type { IImagesLightBoxMethods } from '../../../files/components/ImagesLightBox/ImagesLightBox';
import RichMessageInput from '../../../forms/components/RichMessageInput';
import type { IRichMessageInputActionsWithPickersProps } from '../../../forms/components/RichMessageInput/components/RichMessageInputActionsWithPickers/RichMessageInputActionsWithPickers';

const PostAttachments = ({
	editable,
	attachments,
	filesSize,
	setFieldValue,
	fieldName,
	value,
	forcedResize,
	errorMessage,
	isInitialItemsExceedingCount,
	onBlur,
	availableActions,
	onSelectEvent,
	onSelectShareContent,
	removeAttachment,
	onAddAttachmentsSuccess,
}: IPostAttachmentsProps) => {
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const imagesUrls = attachments.filter((attachment) => attachment.fileType === 'image').map((el) => el.url || '');
	const imagesLightBoxRef = useRef<IImagesLightBoxMethods>(null);
	// const { currentImage, nextImage, prevImage, handleClick, handleClose, handleMovePrev, handleMoveNext } =
	// 	useImageView(imagesUrls);

	const mediaCount = attachments.filter((attachment) => attachment.fileType !== 'file')?.length;
	const { t } = useTranslation();
	const [isFocused, setIsFocused] = useState(false);
	const { openFileUrl } = useOpenFile();
	const charactersError = useMemo(() => {
		return value.length > POST_MESSAGE_CHAR_LIMIT
			? t('{{countOfCharacters}} of {{maxCharacters}} characters max', {
					countOfCharacters: value.length,
					maxCharacters: POST_MESSAGE_CHAR_LIMIT,
				})
			: '';
	}, [value]);

	const filesSizeError = useMemo(() => {
		return filesSize > MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE
			? t('Size should be limited up to {{ maxLimit }} /{{ limit }}MB', {
					maxLimit: MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE,
					limit: String(filesSize).slice(0, 5),
				})
			: attachments.length > MAX_ITEMS_FOR_UPLOAD
				? t("You can't attach more then {{count}} items", { count: MAX_ITEMS_FOR_UPLOAD })
				: '';
	}, [filesSize, attachments]);

	return (
		<div>
			<div
				className={clsx(
					styles.attachments__container,
					isFocused && styles.attachments__container_focus,
					(charactersError || (isInitialItemsExceedingCount ? null : filesSizeError)) &&
						styles.attachments__container_error,
				)}
			>
				<div
					className={styles.attachments__textarea}
					onClick={(e) => {
						if (e.target !== e.currentTarget) return;
						textareaRef.current?.focus();
					}}
					onKeyDown={(e) => {
						if (e.target !== e.currentTarget) return;
						textareaRef.current?.focus();
					}}
				>
					<AutoGrowInput
						errorMessage={errorMessage}
						fieldName={fieldName}
						forcedResize={forcedResize}
						keepMarginBottom={false}
						maxRows={18}
						placeholder={t('Write a new post')}
						ref={textareaRef}
						setFieldValue={setFieldValue}
						size="medium"
						transparentMode
						value={value}
						onBlur={(event) => {
							setIsFocused(false);
							onBlur?.(event);
						}}
						onFocus={() => setIsFocused(true)}
						onPaste={(event) => onClipboardPaste(event, (files) => onAddAttachmentsSuccess?.(files))}
					/>
					{attachments?.length ? (
						<div className={clsx(styles.post__files, attachments.length > 3 && styles.post__filesSpacing)}>
							{attachments.map((file, index) => {
								return (
									<PostAttachment
										editable={!editable}
										file={file}
										key={`${file.name}-${file.url}-${index}`}
										mediaHeight={MAX_POST_IMAGE_HEIGHT / mediaCount}
										onDelete={(file: TAttachments) => removeAttachment(file)}
										onFileClick={() => openFileUrl(String(file.url))}
										onImageClick={() => imagesLightBoxRef.current?.show(index)}
									/>
								);
							})}
						</div>
					) : null}
				</div>
				<div className={clsx(styles.attachment, isFocused && styles.attachments__container_focus)}>
					<RichMessageInput.ActionsWithPickers
						attachmentAmountExceeded={attachments.length > MAX_ITEMS_FOR_UPLOAD}
						availableActions={availableActions}
						onSelectEvent={onSelectEvent}
						onSelectFiles={onAddAttachmentsSuccess}
						onSelectMedia={onAddAttachmentsSuccess}
						onSelectShareContent={onSelectShareContent}
					/>
				</div>
			</div>
			<InputErrorMessage text={charactersError || (isInitialItemsExceedingCount ? undefined : filesSizeError)} />
			<ImagesLightBox imagesUrls={imagesUrls} />
		</div>
	);
};

export interface IPostAttachmentsProps
	extends Pick<
		IRichMessageInputActionsWithPickersProps,
		'availableActions' | 'onSelectEvent' | 'onSelectShareContent'
	> {
	editable: boolean;
	filesSize: number;
	value: string;
	fieldName: string;
	errorMessage?: string;
	setFieldValue: (field: string, value: string) => void;
	uploadAttachments?: () => void;
	attachments: TAttachments[];
	removeAttachment: (attachment: TAttachments) => void;
	onBlur?: FocusEventHandler<HTMLTextAreaElement>;
	forcedResize?: boolean;
	isInitialItemsExceedingCount?: boolean;
	onAddAttachmentsSuccess?: (files: File[]) => void;
}

export default PostAttachments;
