import { Modal, CameraPhotoModal, type IICameraPhotoModalMethods } from '@ui-kit';
import RichMessageInput, { useRichMessageInputActions } from '@ui-modules/forms/components/RichMessageInput';
import GlobalSearchBottomSheetModal from '@ui-modules/globalSearch/components/GlobalSearchBottomSheetModal';
import { useEvent, useRef, useTranslation, useState, useEffect } from '@hooks';
import { usePickUpAttachments } from '@ui-modules/feed/hooks/usePickUpAttachments';
import { MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE, MAXIMUM_ALLOWED_ATTACHMENTS_COUNT } from '@constants';
import { InternalShareEntity } from '@utils';
import type { IInternalShare, TSearchResult } from '@typings';
import type { IGlobalSearchBottomSheetModalMethods } from '@ui-modules/globalSearch/components/GlobalSearchBottomSheetModal';
import GlobalSearchResultInfiniteListBottomSheetModal, {
	type IGlobalSearchResultInfiniteListBottomSheetModalMethods,
} from '@ui-modules/globalSearch/components/GlobalSearchResultInfiniteListBottomSheetModal';
import type { IUseChatRichMessageInputCurrentState } from '../../hooks/useRichMessageInputActions';

const RichMessageInputActionsWithPickers = ({
	attachmentAmountExceeded,
	availableActions,
	onSelectEvent,
	onSelectFiles,
	onSelectMedia,
	onSelectShareContent,
}: IRichMessageInputActionsWithPickersProps) => {
	const { t } = useTranslation();

	const pickUpFileAttachments = usePickUpAttachments(
		(files) => onSelectMedia?.(files),
		false,
		true,
		MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE * 1024 * 1024,
	);
	const pickUpMediaAttachments = usePickUpAttachments(
		(files) => onSelectFiles?.(files),
		true,
		false,
		MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE * 1024 * 1024,
	);
	const eventsGlobalSearchRef = useRef<IGlobalSearchResultInfiniteListBottomSheetModalMethods>(null);
	const tipsGlobalSearchRef = useRef<IGlobalSearchResultInfiniteListBottomSheetModalMethods>(null);
	const sharedContentGlobalSearchRef = useRef<IGlobalSearchBottomSheetModalMethods>(null);
	const cameraPhotoModalRef = useRef<IICameraPhotoModalMethods>(null);
	const setSharedContentFromGlobalSearch = useEvent(({ streamActivityId, definition, objectID }: TSearchResult) => {
		const internalShareEntity = new InternalShareEntity();

		const sharedContentType = internalShareEntity.getSharedContentTypeByGlobalSearchDefinition(definition);
		if (!sharedContentType) return;
		const sharedContent = internalShareEntity.internalShareToShareableContent({
			id: streamActivityId || objectID,
			type: sharedContentType,
		} as IInternalShare);
		onSelectShareContent?.(sharedContent);
	});

	const handleSelectEvent = useEvent(({ objectID: eventId, definition }: TSearchResult) =>
		onSelectEvent?.(eventId, definition),
	);
	const handleSelectCameraPhoto = useEvent((file) => onSelectMedia?.([file]));

	const [isMaximumFilesAmountReachedModalShown, setMaximumFilesAmountReachedShown] = useState(false);
	const { actions } = useRichMessageInputActions(
		{ attachmentAmountExceeded, availableActions },
		{
			onOpenCamera: () => cameraPhotoModalRef.current?.show(),
			onOpenMediaPicker: () => pickUpMediaAttachments.openFilePicker(),
			onOpenFilePicker: () => pickUpFileAttachments.openFilePicker(),
			onOpenEventPicker: () => eventsGlobalSearchRef.current?.show(),
			onOpenTipPicker: () => tipsGlobalSearchRef.current?.show(),
			onOpenSharedContentPicker: () => sharedContentGlobalSearchRef.current?.show(),
			onMaximumFilesAmountReached: () => setMaximumFilesAmountReachedShown(true),
		},
	);
	useEffect(() => {
		if (attachmentAmountExceeded) {
			setMaximumFilesAmountReachedShown(true);
		}
	}, [attachmentAmountExceeded]);

	return (
		<>
			<RichMessageInput.Actions actions={actions} />

			<input {...pickUpFileAttachments.getInputProps()} readOnly={true} />
			<input {...pickUpMediaAttachments.getInputProps()} readOnly={true} />
			<GlobalSearchResultInfiniteListBottomSheetModal
				definition="event"
				ref={eventsGlobalSearchRef}
				showInitialResults
				onSelect={handleSelectEvent}
			/>
			<GlobalSearchResultInfiniteListBottomSheetModal
				definition="tip"
				ref={tipsGlobalSearchRef}
				showInitialResults
				onSelect={setSharedContentFromGlobalSearch}
			/>
			<GlobalSearchBottomSheetModal
				pickDefinitions={SHARED_CONTENT_PICK_DEFINITIONS}
				ref={sharedContentGlobalSearchRef}
				onSelect={setSharedContentFromGlobalSearch}
			/>
			<CameraPhotoModal ref={cameraPhotoModalRef} t={t} onSelect={handleSelectCameraPhoto} />
			<Modal
				confirmTitle={t('OK')}
				subTitle={t("You can't attach more then {{count}} items", { count: MAXIMUM_ALLOWED_ATTACHMENTS_COUNT })}
				title={t('Maximum number of files reached')}
				visible={isMaximumFilesAmountReachedModalShown}
				onConfirm={() => setMaximumFilesAmountReachedShown(false)}
			/>
		</>
	);
};

const SHARED_CONTENT_PICK_DEFINITIONS = [
	'deal' as const,
	'network' as const,
	'group' as const,
	'chapter' as const,
	'discussion' as const,
	'networkConnection' as const,
];

export interface IRichMessageInputActionsWithPickersProps extends IUseChatRichMessageInputCurrentState {
	onSelectEvent?: (eventId: string, definition: TSearchResult['definition']) => void;
	onSelectFiles?: (files: File[]) => void;
	onSelectMedia?: (files: File[]) => void;
	onSelectShareContent?: (sharedContent: string) => void;
}

export default RichMessageInputActionsWithPickers;
