import styles from './TipJarIntroModal.module.css';
import { useMe, useTranslation } from '@hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import { FeatureSlider } from '@ui-kit';
import {
	ModalHeader,
	FourthPageBody,
	ThirdPageBody,
	SecondPageBody,
} from '@ui-modules/tipJar/components/TipJarIntroModal/FeatureComponents';
import FiltersPageBody from '@ui-modules/tipJar/components/TipJarIntroModal/FeatureComponents/FiltersPageBody';
import { useTipsSliderIntroduced } from '@ui-modules/tipJar/hooks/useTipsSliderIntroduced';
import { useImpersonate } from '@ui-modules/auth/hooks/useImpersonate';
import TipShareImage from './assets/tip-share-insights-slide.png';
import FiltersSlide from './assets/filters-slide.png';
import TipAiSlide from './assets/tip-ai-slide.svg';
import { createGlobalState } from 'react-use';

const useIntroducedTipsAA = createGlobalState<Set<string>>(new Set());
const TipJarIntroModal = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { impersonated } = useImpersonate();
	const [isTipsSliderIntroduced, setTipsSliderIntroduced] = useTipsSliderIntroduced();
	const { user } = useMe();
	const [introducedUsersTipsGlobal, setIntroducedUsersTipsGlobal] = useIntroducedTipsAA();

	const dismissModal = () => {
		setTipsSliderIntroduced();
	};

	const dismissModalAdmin = () => {
		if (impersonated && user?.id) {
			setIntroducedUsersTipsGlobal((prev) => new Set(prev).add(user.id));
		}
	};

	const onOpenNewTip = () => {
		navigate(ROUTES.newTip());
		if (!impersonated) {
			setTipsSliderIntroduced();
		} else {
			introducedUsersTipsGlobal.add(user.id);
		}
	};

	return (
		<>
			<div className={styles.hidden}>
				<img alt="" src={TipShareImage} />
				<img alt="" src={FiltersSlide} />
				<img alt="" src={TipAiSlide} />
			</div>
			<FeatureSlider
				impersonated={impersonated}
				slides={[
					{
						header: (
							<div className={styles.firstPageHeader}>
								<ModalHeader mode="large" />
							</div>
						),
						body: (
							<div className={styles.firstPageBody}>
								<p>{t('Swipe to learn what you can do...')}</p>
							</div>
						),
						name: 'Swipe to learn what you can do',
					},
					{
						header: <ModalHeader />,
						body: <SecondPageBody />,
						name: 'Share recommendations with your Group and others',
					},
					{
						header: <ModalHeader />,
						body: <FiltersPageBody />,
						name: 'Find your next discovery',
					},
					{
						header: <ModalHeader />,
						body: <ThirdPageBody />,
						name: 'Our secure AI makes it fast and easy',
					},
					{
						header: <ModalHeader />,
						body: (
							<FourthPageBody onCreateTip={onOpenNewTip} onDismiss={impersonated ? dismissModalAdmin : dismissModal} />
						),
						name: 'Try it now. Create your first Tip!',
					},
				]}
				visible={impersonated ? !introducedUsersTipsGlobal.has(user?.id) : !isTipsSliderIntroduced}
				onDismissedByBackdrop={dismissModalAdmin}
			/>
		</>
	);
};

export default TipJarIntroModal;
