import { useTranslation } from '@hooks';
import type { TTipReviewWithReaction, TTipWithDetails } from '@typings';
import styles from './TipPrimaryReviewSection.module.css';
import TipReviewCard from '@ui-modules/tipJar/components/TipReviewCard';
import { useTipReviewThumbUpMutation } from '@ui-modules/tipJar/hooks/useTipReviewThumbUpMutation';
import { useGetTipReviewMeatballMenuOptions } from '@ui-modules/tipJar/hooks/useGetTipReviewMeatballMenuOptions';
import { useOwnerTipReviewQuery } from '@ui-modules/tipJar/hooks/useOwnerTipReviewQuery';
import { useTipContextQueryParams } from '@ui-modules/tipJar/hooks/useTipContextQueryParams';

const TipPrimaryReviewSection = ({ tip, onClickUser }: ITipPrimaryReviewSectionProps) => {
	const { t } = useTranslation();
	const { contextCommunityId } = useTipContextQueryParams();
	const ownerTipReviewQuery = useOwnerTipReviewQuery(tip.id);
	const [meatballMenuOptions, TipReviewMeatballMenuModals] = useGetTipReviewMeatballMenuOptions(
		tip.id,
		contextCommunityId,
	);
	const tipReviewThumbUpMutation = useTipReviewThumbUpMutation(tip.id);

	if (!ownerTipReviewQuery.data) return null;
	return (
		<div className={styles.tipPrimaryReviewSection}>
			<h5 className={styles.tipPrimaryReviewSection__title}>{t('Creator’s review')}</h5>
			<TipReviewCard
				meatballsMenuOptions={meatballMenuOptions(ownerTipReviewQuery.data)}
				tipReview={ownerTipReviewQuery.data}
				onClickUser={onClickUser}
				onThumbUp={() =>
					ownerTipReviewQuery.data &&
					'reactions' in ownerTipReviewQuery.data &&
					tipReviewThumbUpMutation.mutate(ownerTipReviewQuery.data as TTipReviewWithReaction)
				}
			/>
			{TipReviewMeatballMenuModals}
		</div>
	);
};

export interface ITipPrimaryReviewSectionProps {
	tip: TTipWithDetails;
	onClickUser?: (() => void) | undefined;
}

export default TipPrimaryReviewSection;
