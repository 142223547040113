import { Page } from '../../components';
import { ROUTES } from '@constants';
import { ActivityIndicator } from '@ui-kit';
import { useTranslation, useNavigate } from '@hooks';
import { uniq, capitalize } from '@utils';
import { useNotificationsSettings } from '@ui-modules/settings/hooks/useNotificationsSettings';
import NavigationCard from '@ui-modules/settings/components/NavigationCard';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { useLatestWaiverQuery } from '@ui-modules/deals/hooks/useLatestWaiverQuery';
import styles from './NotificationsSettingsPage.module.css';
import { useMemo } from 'react';
import { useNetworksOrChaptersNotificationSettings } from '@ui-modules/settings/hooks/useNetworksNotificationSettings';

const NotificationsSettingsPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const {
		defaultsSettings = [],
		isLoadingOfDefaultsSettings,
		isLoadingNotificationsSettings,
	} = useNotificationsSettings();
	const { enabled } = useDealsEnabled();
	const { data: latestWaiver, isLoading: isLoadingOfWaiver } = useLatestWaiverQuery(enabled);

	const isLoadingOfDefaultOrNotificationsSettings = isLoadingOfDefaultsSettings || isLoadingNotificationsSettings;
	const conditionForActivityIndicator = enabled
		? isLoadingOfDefaultOrNotificationsSettings || isLoadingOfWaiver
		: isLoadingOfDefaultOrNotificationsSettings;
	const { notificationSettings, isNotificationSettingsLoading } = useNetworksOrChaptersNotificationSettings('chapter');

	const conditionOfDealsRender = enabled && !!latestWaiver;
	const combinedSettings = useMemo(() => {
		return [...defaultsSettings];
	}, [defaultsSettings]);

	const settingsItems = uniq(
		combinedSettings
			?.reduce<string[]>(
				(acc, setting) => [...acc, setting.notificationGroup as string],
				// If there is no approved Waiver, then hide Deal Notifications
				// If there is ANY approved Waiver (even an old version), then we show the Deal Notifications settings
				// if the Deals feature is disabled, we will not be able to request a Waiver and will get a 403
				// but if the feature is enabled, we need to make sure that at least one waiver is available
				conditionOfDealsRender ? ['Invest'] : [],
			)
			.sort(),
	);

	const filteredSettingsItems = useMemo(() => {
		return settingsItems.filter((item) => {
			if (item === 'Chapters') {
				return notificationSettings.length > 0;
			}
			return true;
		});
	}, [notificationSettings, settingsItems]);

	return (
		<Page title={t('Notifications')}>
			{conditionForActivityIndicator || isNotificationSettingsLoading ? (
				<ActivityIndicator size="medium" type="fit" />
			) : (
				<div className={styles.notificationsSettings}>
					<h3 className={styles.notificationsSettings__title}>{t('Notifications')}</h3>
					<div className={styles.navigation__items}>
						{filteredSettingsItems.map(
							(group) =>
								group && (
									<div key={group}>
										<NavigationCard
											title={capitalize(group)}
											onClick={() => navigate(ROUTES.notificationSettingsGroup(capitalize(group)))}
										/>
										<div className={styles.divider} />
									</div>
								),
						)}
					</div>
				</div>
			)}
		</Page>
	);
};

export default NotificationsSettingsPage;
