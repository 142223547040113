import { ActivityIndicator, FileTypeAvatar } from '@ui-kit';
import { CloseIcon, VideoAttachmentIcon } from '@ui-kit/icons';
import styles from './RichMessageInputAttachment.module.css';
import clsx from 'clsx';

const RichMessageInputAttachment = ({ attachment }: IRichMessageInputAttachmentProps) => {
	return (
		<div
			aria-busy={attachment.isUploading}
			aria-disabled={attachment.isUploading || !attachment.onPress}
			className={styles.richMessageInputAttachment}
			data-testid="RichMessageInputAttachment"
			onClick={attachment.onPress}
			onKeyUp={attachment.onPress}
		>
			{attachment.isUploading ? (
				<div className={styles.richMessageInputAttachment__content}>
					<ActivityIndicator color="var(--color-primaryDarkGray)" size="extraSmall" />
				</div>
			) : attachment.type === 'file' ? (
				<div
					className={clsx(styles.richMessageInputAttachment__content, styles.richMessageInputAttachment__content_file)}
				>
					<FileTypeAvatar height={42} mimeType={attachment.mimeType} width={36} />
				</div>
			) : attachment.type === 'image' ? (
				<img
					alt="Attachment"
					className={clsx([
						styles.richMessageInputAttachment__content,
						styles.richMessageInputAttachment__content_media,
					])}
					src={attachment.uri}
				/>
			) : attachment.type === 'video' ? (
				<>
					<img
						alt="Video Attachment"
						className={clsx([
							styles.richMessageInputAttachment__content,
							styles.richMessageInputAttachment__content_media,
						])}
						src={attachment.uri}
					/>
					<VideoAttachmentIcon className={styles.richMessageInputAttachment__videoIcon} fill="var(--color-lightGold)" />
				</>
			) : null}

			{attachment.isUploading || !attachment.onRemove ? null : (
				<button
					className={styles.richMessageInputAttachment__closeButton}
					data-testid="RichMessageInputAttachment__removeButton"
					type="button"
					onClick={attachment.onRemove}
				>
					<CloseIcon fill="var(--color-primaryGrey)" height={8} width={8} />
				</button>
			)}
		</div>
	);
};

export type TRichMessageInputAttachmentType = 'image' | 'video' | 'file';
export type TRichMessageInputAttachmentPayload =
	| {
			type: 'image' | 'video';
			uri: string;
			mimeType: void;
	  }
	| {
			type: 'file';
			mimeType: string;
			uri: string;
	  };
export type TRichMessageInputAttachment = TRichMessageInputAttachmentPayload & {
	id?: string;
	isUploading?: boolean;
	onPress?: () => void;
	onRemove?: () => void;
};

interface IRichMessageInputAttachmentProps {
	attachment: TRichMessageInputAttachment;
}

export default RichMessageInputAttachment;
