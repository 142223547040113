import type { TSearchResult } from '@typings';
import SearchResult from './SearchResult';
import { GLOBAL_SEARCH_DEFINITION } from '@constants';

export const SearchResultByDefinition = ({ result, onSelect }: ISearchResultByDefinitionProps) => {
	switch (result.definition) {
		case GLOBAL_SEARCH_DEFINITION.member:
			return (
				<SearchResult
					avatar={result.avatar}
					biography={result.biography}
					communities={result.communities}
					definition={result.definition}
					expertise={result.expertise}
					interests={result.interests}
					investmentExpertise={result.investmentExpertise}
					key={result.objectID}
					name={result.name}
					objectID={result.profileID ?? result.objectID}
					profileType={result.profileType}
					searchQuery={result.searchQuery}
					onClick={onSelect}
				/>
			);
		case GLOBAL_SEARCH_DEFINITION.network:
		case GLOBAL_SEARCH_DEFINITION.group:
		case GLOBAL_SEARCH_DEFINITION.chapter:
			return (
				<SearchResult
					definition={result.definition}
					key={result.objectID}
					objectID={result.objectID}
					searchQuery={result.searchQuery}
					title={result.title}
					onClick={onSelect}
				/>
			);
		case GLOBAL_SEARCH_DEFINITION.event:
		case GLOBAL_SEARCH_DEFINITION.meeting:
			return (
				<SearchResult
					communityName={result.communityName}
					definition={result.definition}
					key={result.objectID}
					objectID={result.objectID}
					searchQuery={result.searchQuery}
					startDate={result.startDate}
					title={result.title}
					onClick={onSelect}
				/>
			);
		case GLOBAL_SEARCH_DEFINITION.discussion:
			return (
				<SearchResult
					communityName={result.communityName}
					communitySlug={result.communitySlug}
					definition={result.definition}
					key={result.objectID}
					objectID={result.objectID}
					searchQuery={result.searchQuery}
					startDate={result.startDate}
					streamActivityId={result.streamActivityId}
					streamFeedId={result.streamFeedId}
					title={result.title}
					onClick={onSelect}
				/>
			);
		case GLOBAL_SEARCH_DEFINITION.learn:
			return (
				<SearchResult
					definition={result.definition}
					key={result.objectID}
					objectID={result.objectID}
					searchQuery={result.searchQuery}
					startDate={result.startDate}
					streamActivityId={result.streamActivityId}
					streamFeedId={result.streamFeedId}
					title={result.title}
					onClick={onSelect}
				/>
			);
		case GLOBAL_SEARCH_DEFINITION.deal:
			return (
				<SearchResult
					assetClass={result.assetClass}
					avatar={result.avatar}
					definition="deal"
					key={result.objectID}
					objectID={result.objectID}
					searchQuery={result.searchQuery}
					startDate={result.startDate}
					title={result.title}
					onClick={onSelect}
				/>
			);
		case GLOBAL_SEARCH_DEFINITION.networkConnection:
			return (
				<SearchResult
					avatar={result.avatar}
					communityName={result.communityName}
					definition="networkConnection"
					key={result.objectID}
					objectID={result.objectID}
					searchQuery={result.searchQuery}
					startDate={result.startDate}
					title={result.title}
					onClick={onSelect}
				/>
			);
		case GLOBAL_SEARCH_DEFINITION.document:
			return (
				<SearchResult
					definition={GLOBAL_SEARCH_DEFINITION.document}
					fileOrFolder={result.fileOrFolder}
					folderID={result.folderID}
					key={result.objectID}
					mimeType={result.mimeType}
					name={result.title}
					objectID={result.objectID}
					relatedEntityDefinition={result.relatedEntityDefinition}
					relatedEntityId={result.relatedEntityId}
					relatedEntityName={result.relatedEntityName}
					searchQuery={result.searchQuery}
					startDate={result.startDate}
					onClick={onSelect}
				/>
			);
		case GLOBAL_SEARCH_DEFINITION.tip:
			return (
				<SearchResult
					definition={GLOBAL_SEARCH_DEFINITION.tip}
					key={result.objectID}
					objectID={result.objectID}
					reviewsCount={result.reviewsCount}
					searchQuery={result.searchQuery}
					tags={result.tags}
					title={result.title}
					onClick={onSelect}
				/>
			);
		default:
			return null;
	}
};

interface ISearchResultByDefinitionProps {
	result: TSearchResult;
	onSelect: (result: TSearchResult) => void;
}
